/* eslint-disable */
import { ChevronLeft, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PlaceOrderBg from '../../assets/images/PlaceOrderBg.png'
import { Label } from '../../Components/ui/label'
import { Input } from '../../Components/ui/input'
import { Button } from '../../Components/ui/button'
import { toCurrency } from '../../utils/CurrencyUtil'
import { useModal } from '../../hooks/use-modal-store'
import env from '../../env/env'
import { post } from '../../http/restservice'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { messageModalState } from '../../store/Feedback'
import { MutualFundsSelectorDetails } from '../../store/MFStore'
import { Card } from '../../Components/ui/card'
import { AccountBalance } from '@mui/icons-material'
import { BankDetails } from '../../store/AccountDetailsStore'

const MFBasketRedeem = () => {
  const location = useLocation()
  const [basket, setBasket] = useState(location?.state?.basket)

  const { contents: bankDetails } = useRecoilValueLoadable(BankDetails)
  const navigate = useNavigate()
  const { onOpen } = useModal()
  const [redemptionAmount, setRedemptionAmount] = useState(0)

  const [isLoading, setIsLoading] = useState(false)

  const userData = JSON.parse(localStorage.getItem('userData'))
  const clientCode = userData?.uid.split('-')[0]

  const [src, setSrc] = useState('')
  const handleError = () => {
    setSrc(null)
  }

  let ifscCode = bankDetails?.ifscCode
  const extractedBranch = ifscCode?.match(/^[A-Za-z]{4}/)[0]?.toLowerCase()
  const iconSrc = `${env.BANK_LOGOS}/${extractedBranch}/symbol.svg`
  useEffect(() => {
    setSrc(iconSrc)
  }, [iconSrc])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  useEffect(() => {
    const getTotalCurrentAmount = () => {
      const total = basket.reduce((acc, plan) => {
        return acc + plan.currentNav * plan.netUnits
      }, 0)
      return total.toFixed(3)
    }

    setRedemptionAmount(getTotalCurrentAmount())
  }, [])

  const handleRedemptionAmountChange = (e) => {
    let value = e.target.value

    // Prevent non-numeric characters except dot
    value = value.replace(/[^0-9.]/g, '')

    // Allow only two decimal places
    if (value.includes('.')) {
      const [integerPart, decimalPart] = value.split('.')
      value = decimalPart.length > 2 ? `${integerPart}.${decimalPart.slice(0, 2)}` : value
    }

    // Convert to number and handle edge cases
    const numericValue = Number(value)
    if (!isNaN(numericValue) && numericValue >= 0) {
      setRedemptionAmount(value)
    }
  }

  const handleBasketRedeem = async (basket) => {
    try {
      setIsLoading(true)

      const orderData = basket?.map((fund) => ({
        schemeCode: fund.bseSchemeCode,
        orderVal: fund.currentNav * fund.netUnits,
        qty: fund.netUnits,
        customerBasketInvestmentId: fund.customerBasketInvestmentId,
      }))

      const payload = {
        orderType: 'REDEMPTION',
        orderData: orderData,
        clientCode: clientCode.toUpperCase(),
      }

      const response = await post('mutualfundservice/api/basket/oneTimeOrder', payload)
      setIsLoading(false)

      if (response.isError) {
        onOpen('openPopup', {
          title: 'Redemption Failed',
          message:
            'There was an issue processing your redemption order. Please try again later or contact support for assistance.',
          severity: 'error',
        })
        return
      }

      if (!response.isError) {
        onOpen('openActionPlanPopup', {
          title: 'Redemption Processing',
          message:
            'Your redemption order is currently being processed and will be completed within 2 working days. You will be notified once the process is done.',
          severity: 'info',
          redirectTo: -1,
        })
        return
      }
    } catch (error) {
      setIsLoading(false)

      onOpen('openPopup', {
        title: 'Redemption Failed!',
        message:
          'There was an issue processing your redemption order. Please try again later or contact support for assistance.',
        severity: 'error',
      })
      return
    }
  }

  return (
    <>
      <div
        className='flex gap-x-1 items-center font-semibold cursor-pointer lg:py-2.5 p-4 lg:px-0'
        onClick={() => navigate(-1)}
      >
        <ChevronLeft className='h-5 w-5' />
        Redeem
      </div>
      <div>
        <div className='max-w-md mx-auto min-h-[calc(100vh-80px)] pb-12'>
          <div className='max-w-[360px] mx-auto h-full flex flex-col gap-y-12 justify-between'>
            <div className='h-[170px] w-full bg-cover flex justify-center items-center z-0 relative'>
              <div
                className='absolute inset-0 bg-cover w-full h-full'
                style={{ backgroundImage: `url(${PlaceOrderBg})` }}
              />
              <div
                className='absolute inset-0'
                style={{
                  background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
                  // boxShadow: '0px 16px 24px rgba(65, 85, 141, 0.08)',
                }}
              />
              <div className='flex items-center space-x-4 z-10'>
                <img
                  src={`${env.SVGS}/basket.svg`}
                  alt={basket[0]?.customerBasketName}
                  className='w-12 h-12'
                />
                <div>
                  <h3
                    className='font-semibold text-sm leading-tight text-wrap w-48'
                    style={{ textWrap: 'pretty' }}
                  >
                    {basket[0]?.customerBasketName}
                  </h3>
                </div>
              </div>
            </div>

            <div>
              <div className='flex justify-between'>
                <span className='text-sm text-black-400'>Current amount</span>
                <span className='font-semibold text-black-500'>₹ {redemptionAmount}</span>
              </div>
            </div>

            <div className='flex flex-col justify-center items-center gap-1.5'>
              <Label htmlFor='amount' className='text-black-400 text-xs'>
                Redemption amount
              </Label>
              <div className='relative'>
                <Input
                  disabled={true}
                  startAdornment='₹'
                  type='amount'
                  id='amount'
                  placeholder='Amount'
                  className='border-none outline-none max-w-[120px] mx-auto'
                  value={redemptionAmount}
                  onChange={handleRedemptionAmountChange}
                />
                {/* {redemptionAmount > 0 && (
                  <Button
                    type='button'
                    variant='ghost'
                    size='sm'
                    className='absolute right-2 top-1/2 h-auto -translate-y-1/2 p-0.5 bg-black-200 text-white rounded-full hover:bg-black-200'
                    onClick={() => setRedemptionAmount(0)}
                  >
                    <X className='h-3 w-3 text-white' />
                    <span className='sr-only'>Clear input</span>
                  </Button>
                )} */}
              </div>
              {/* <p className='text-xs text-error-600 text-center'>
                Minimum first investment amount is ₹ {toCurrency(fundDetails?.minInvt)}
              </p> */}
            </div>

            {/* <div className='flex justify-center gap-x-2'>
              {[25, 50, 75].map((btn, index) => (
                <Button
                  key={(btn + index).toString()}
                  size='simple'
                  className='px-3 py-[2px] rounded-[50px] bg-error-50 hover:bg-error-50 text-error-500'
                  //   onClick={() => setRedemptionAmount(((fund?.holding / 100) * btn).toFixed(2))}
                >
                  {toCurrency(btn)} %
                </Button>
              ))}

              <Button
                size='simple'
                className='px-3 py-[2px] rounded-[50px] bg-error-50 hover:bg-error-50 text-error-500'
                // onClick={() => setRedemptionAmount(fund?.holding.toFixed(2))}
              >
                All
              </Button>
            </div> */}

            <div className='flex flex-col gap-y-4'>
              <Card className='flex items-center gap-3 p-4 w-full bg-white shadow-none border-[#D3DBEC] rounded-2xl'>
                <div className='flex items-center justify-center w-10 h-10 rounded-full border'>
                  {src ? (
                    <img src={src} alt='bank logos' onError={handleError} height={24} width={24} />
                  ) : (
                    <AccountBalance className='text-primary-200 w-5 h-5' />
                  )}
                  {/* <AccountBalance className='w-5 h-5' /> */}
                </div>
                <div className='flex flex-col'>
                  <h3 className='font-semibold leading-6 text-black-500'>
                    {bankDetails && bankDetails?.accountHolderName}
                  </h3>
                  <p className='text-sm text-black-400 leading-5 text-nowrap'>
                    <span className='font-semibold'>{bankDetails && bankDetails?.bankName}</span>
                    <span className='font-medium'> A/c ending in </span>
                    <span className='font-semibold'>
                      {bankDetails && bankDetails?.accountNo && bankDetails?.accountNo.slice(-4)}
                    </span>
                  </p>
                </div>
              </Card>

              <Button
                className='rounded-xl flex-1 bg-error-500 hover:bg-error-400 disabled:bg-error-200'
                loading={isLoading}
                // disabled={!redemptionAmount || redemptionAmount > fund?.holding}

                onClick={() => handleBasketRedeem(location?.state?.basket)}
              >
                Redeem
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MFBasketRedeem
