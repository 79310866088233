/* eslint-disable */

import { useEffect, useState } from 'react'
import scanner from '../../../src/assets/PaymentGateway/document_scanner.svg'
import spark from '../../assets/PaymentGateway/spark.svg'
import account from '../../assets/PaymentGateway/account_balance.svg'
import additionalImage from '../../assets/PaymentGateway/account_balance_blue.svg'
import tick from '../../assets/PaymentGateway/Frame 354190.svg'
import { Button } from '../../Components/ui/button'
import { useLocation, useNavigate } from 'react-router-dom'
import { useModal } from '../../hooks/use-modal-store'
import { post } from '../../http/restservice'
import { Input } from '../../Components/ui/input'
import { ChevronLeft } from 'lucide-react'
import { messageModalState } from '../../store/Feedback'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { BankDetails } from '../../store/AccountDetailsStore'
import MFPaymentRequest from './MFPaymentRequest'
import env from '../../env/env'
import { useErrorPopup } from '../../hooks/useErrorPopup'

const MFPayment = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { onOpen } = useModal()
  const { showError } = useErrorPopup()
  const userData = JSON.parse(localStorage.getItem('userData'))
  const [upiId, setUpiId] = useState(userData?.upiId || '')
  const [isLoading, setIsLoading] = useState(false)
  const setMessageModalState = useSetRecoilState(messageModalState)
  const { contents: bankDetails } = useRecoilValueLoadable(BankDetails)
  const [showPaymentRequestPopup, setShowPaymentRequestPopup] = useState(false)
  const [selectedOption, setSelectedOption] = useState('UPI')
  const [responseString, setResponseString] = useState('')

  const [src, setSrc] = useState('')
  const handleError = () => {
    setSrc(null)
  }

  let ifscCode = bankDetails?.ifscCode
  const extractedBranch = ifscCode?.match(/^[A-Za-z]{4}/)[0]?.toLowerCase()
  const iconSrc = `${env.BANK_LOGOS}/${extractedBranch}/symbol.svg`
  useEffect(() => {
    setSrc(iconSrc)
  }, [iconSrc])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  const callback = () => {
    navigate('/dashboard/mutual-funds')
  }

  const paymentStatus = async () => {
    try {
      const response = await post('/mutualfundservice/api/bseStar/mfUpload/paymentStatus', {
        clientCode: location?.state?.paymentPayload?.clientCode,
        orderNo: location?.state?.orderNumber,
      })

      if (response.isError) {
        setMessageModalState({
          message: response?.response?.message,
          severity: 'error',
        })
      }

      if (
        !response.isError &&
        response.status === 'SUCCESS' &&
        response.data !== 'PAYMENT NOT INITIATED FOR GIVEN ORDER'
      ) {
        setShowPaymentRequestPopup(false)

        onOpen('openPopup', {
          title: 'UPI Payment Successful',
          message:
            'Payment Successful! 🎉 Your UPI transaction has been completed securely. Thank you for your payment!',
          severity: 'success',
          callback: navigate(-1),
        })
      }

      if (
        !response.isError &&
        response.status === 'SUCCESS' &&
        response.data === 'PAYMENT NOT INITIATED FOR GIVEN ORDER'
      ) {
        setShowPaymentRequestPopup(false)

        showError(
          'UPI Payment Failed',
          'Payment Failed. Unfortunately, your UPI transaction could not be completed. Please try again or use an alternate payment method.',
          () => navigate(-1),
        )
      }
    } catch (error) {
      console.log('🚀 ~ paymentStatus ~ error:', error)
    }
  }

  const oneTimeOrderPayment = async () => {
    try {
      setIsLoading(true)

      const payload = location?.state?.paymentPayload
      payload.upiId = upiId
      payload.modeOfPayment = selectedOption === 'UPI' ? 'UPI' : 'DIRECT'
      payload.loopbackURL =
        selectedOption !== 'UPI'
          ? `${env.LOOPBACK_URL}dashboard/mutual-funds/order-payment/status`
          : ''

      const response = await post('/mutualfundservice/api/bseStar/api/oneTimePayment', payload)

      setIsLoading(false)

      if (response.isError) {
        showError('UPI Payment Failed', response?.response?.data?.responsestring, () => {})
      }

      if (response?.status === 'SUCCESS') {
        // onOpen('openPopup', {
        //   message: response.data.responsestring,
        //   severity: 'success',
        //   callback,
        // })
        setShowPaymentRequestPopup(true)
      }

      if (
        selectedOption !== 'UPI' &&
        payload.modeOfPayment !== 'UPI' &&
        response?.data?.responsestring
      ) {
        setResponseString(response?.data?.responsestring)
        // openBankPaymentResponse(response?.data?.responsestring)
        localStorage.setItem(
          'paymentStatusData',
          JSON.stringify({
            response: response?.data?.responsestring,
            clientCode: location?.state?.paymentPayload?.clientCode,
            orderNo: location?.state?.orderNumber,
          }),
        )
        navigate('/dashboard/mutual-funds/order-payment/status', {
          state: {
            response: response?.data?.responsestring,
            clientCode: location?.state?.paymentPayload?.clientCode,
            orderNo: location?.state?.orderNumber,
          },
        })
      }

      if (response?.status === 'FAILURE') {
        showError('UPI Payment Failed', response.data.responsestring, () => navigate(-1))
        // onOpen('openPopup', {
        //   message: response.data.responsestring,
        //   severity: 'error',
        //   callback,
        // })
        // setMessageModalState({
        //   message: response.data.responsestring,
        //   linkMessage: response.data.responsestring,
        //   severity: 'error',
        // })
      }

      if (response.isError) {
        showError('UPI Payment Failed', response.data.responsestring, () => navigate(-1))
      }
    } catch (error) {
      console.log('🚀 ~ placeOneTimeOrder ~ error:', error)
    }
  }

  return (
    <>
      {responseString ? (
        // <BankPaymentResponse responseString={responseString} />
        // openBankPaymentResponse(responseString)
        <></>
      ) : (
        <>
          {showPaymentRequestPopup ? (
            <MFPaymentRequest
              bankDetails={bankDetails}
              setShowPaymentRequestPopup={setShowPaymentRequestPopup}
              paymentStatus={paymentStatus}
            />
          ) : (
            <div className='bg-white'>
              <div className='max-w-[1128px] mx-auto min-h-screen'>
                <div
                  className='flex gap-x-1 items-center font-semibold cursor-pointer lg:py-2.5 p-4 lg:px-0 bg-white'
                  onClick={() => navigate(-1)}
                >
                  <ChevronLeft className='h-5 w-5' />
                  Complete Payment
                </div>

                <div className='flex items-center justify-center min-h-screen w-full bg-white'>
                  <div className='w-[360px] h-[702px]'>
                    <h1 className='font-dm-sans text-base font-medium leading-6 text-center decoration-slice text-[rgba(45,45,45,1)]'>
                      Total payable amount
                    </h1>
                    <p className='font-dm-sans text-[32px] font-semibold leading-[42px] tracking-[-0.04em] text-center decoration-slice text-[rgba(45,45,45,1)] mt-2 mb-12'>
                      ₹ {location?.state?.paymentPayload?.totalAmount}
                    </p>

                    <div>
                      {/* UPI*/}
                      <div
                        onClick={() => setSelectedOption('UPI')}
                        className={`border p-3 cursor-pointer rounded-[16px] mb-6 transition-all  duration-700 ease-out ${
                          selectedOption === 'UPI'
                            ? 'border-2 border-primary-500 shadow-[0px_4px_24px_0px_rgba(65,85,141,0.12)] bg-[rgba(255,255,255,1)]'
                            : 'border-[rgba(190,190,190,1)]'
                        }`}
                      >
                        <div className='flex justify-between items-center'>
                          <div className='flex items-center space-x-2'>
                            <span>
                              <img
                                src={scanner}
                                alt='Doc_Scanner'
                                className={
                                  selectedOption === 'UPI' ? 'filter-none' : 'filter-grayscale'
                                }
                              />
                            </span>
                            <span
                              className={`font-dm-sans text-[20px] font-semibold leading-[28px] tracking-[-0.04em] text-centre decoration-slice ${
                                selectedOption === 'UPI' ? 'text-primary-500' : 'text-black-500'
                              }`}
                            >
                              UPI
                            </span>
                            <span>
                              <img src={spark} alt='Spark' />
                            </span>
                          </div>
                          <span className='font-dm-sans text-sm font-medium leading-5 text-left text-black-300'>
                            Max. ₹ 2 Lakhs
                          </span>
                        </div>
                        {selectedOption === 'UPI' && (
                          <div className='mt-6 flex flex-col gap-y-6'>
                            <div>
                              {' '}
                              <label className='font-dm-sans text-[14px] font-medium leading-[20px] text-left decoration-slice text-black-500'>
                                Your UPI address
                              </label>
                              <Input
                                value={upiId}
                                onChange={(e) => setUpiId(e.target.value)}
                                type='text'
                                placeholder='address@bank'
                              />
                            </div>

                            <p className='font-dm-sans text-sm font-medium leading-[20px] text-left decoration-slice text-[#2d2d2d]'>
                              Some banks may offer lesser limit, switch to Net Banking if UPI is
                              unsuccessful
                            </p>
                          </div>
                        )}
                      </div>

                      {/* Net Banking Option */}
                      <div
                        onClick={() => setSelectedOption('NetBanking')}
                        className={`border p-3 cursor-pointer rounded-[16px] mb-6 transition-all duration-700 ease-out  ${
                          selectedOption === 'NetBanking'
                            ? 'border-2 border-[#41558d] shadow-[0px_4px_24px_0px_rgba(65,85,141,0.12)] bg-[rgba(255,255,255,1)]'
                            : 'border-[rgba(190,190,190,1)]'
                        }`}
                      >
                        <div className='flex justify-between items-center '>
                          <div className='flex items-center space-x-2 '>
                            <span>
                              <img
                                src={selectedOption === 'NetBanking' ? additionalImage : account}
                                alt='account'
                              />
                            </span>
                            <span
                              className={`font-[DM Sans] text-xl font-medium leading-7 tracking-tight text-left ${
                                selectedOption === 'NetBanking'
                                  ? 'text-[#41558d]'
                                  : 'text-[#727272]'
                              }`}
                            >
                              Net Banking
                            </span>
                          </div>
                          <span className='font-dm-sans text-sm font-medium leading-5 text-left text-[rgba(114,114,114,1)]'>
                            No limit
                          </span>
                        </div>
                        {selectedOption === 'NetBanking' && (
                          <div className='space-y-2 '>
                            <p className='font-dm-sans text-[14px] font-medium leading-[20px] text-left text-[#2d2d2d] mt-6 mb-4'>
                              Select an account to pay on the bank's website
                            </p>
                            <div className='w-[320px] h-[76px] p-4 flex items-center gap-4 rounded-[16px] bg-[#ffffff] hover:bg-[#ecf0f4] border border-transparent hover: group transition-all duration-200 ease-in-out'>
                              <div className='flex-shrink-0'>
                                {src ? (
                                  <img
                                    src={src}
                                    alt='bank logos'
                                    onError={handleError}
                                    height={24}
                                    width={24}
                                  />
                                ) : (
                                  <AccountBalance className='text-primary-200' />
                                )}
                              </div>
                              <div className='flex flex-col'>
                                <span className='text-[#2f3c5a] font-semibold'>
                                  {bankDetails && bankDetails?.accountHolderName}
                                </span>
                                <span className='text-[#2f3c5a] text-xs'>
                                  {bankDetails && bankDetails?.bankName} A/c ending in{' '}
                                  <strong>
                                    {' '}
                                    {bankDetails &&
                                      bankDetails?.accountNo &&
                                      bankDetails?.accountNo.slice(-4)}
                                  </strong>
                                </span>
                              </div>
                              <div className='ml-auto flex-shrink-0 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out'>
                                <div className='w-6 h-6 flex items-center justify-center'>
                                  <img src={tick} alt='tick' />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <Button
                        loading={isLoading}
                        className='w-full py-3 px-4 rounded-[12px] bg-[#41558d] font-dm-sans text-[14px] font-medium leading-[20px] text-center decoration-skip-ink-none text-white'
                        onClick={() => oneTimeOrderPayment()}
                      >
                        Proceed
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default MFPayment
