import React, { useEffect, useState, useMemo, startTransition } from 'react'
import { ProgressFour } from '../../Components/ui/progress'
import { Button } from '../../Components/ui/button'
import { Input } from '../../Components/ui/input'
import { useNavigate } from 'react-router-dom'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CloseIcon from '@mui/icons-material/Close'
import { Checkbox } from '../../Components/ui/checkbox'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../Components/ui/select'
import { Calendar } from '../../Components/ui/calendar'
import CompletionPopup from './CompletionPopup'
import { get, post } from '../../http/restservice'
import { getUserDetails } from '../../utils/AuthUtil'
import clevertap from 'clevertap-web-sdk'
import ReactGA4 from 'react-ga4'
import env from '../../env/env'
import { format } from 'date-fns'
import { cn } from '../../lib/utils'

// const MembersForm = ({ members, setMembers }) => {
//   const [errors, setErrors] = useState([])
//   const [touched, setTouched] = useState(false)

//   const isAlphabetic = (str) => str === '' || /^[A-Za-z\s]+$/.test(str)

//   const validateMember = (member, index) => {
//     const memberErrors = []
//     if (!member.name) memberErrors.push(`Name is required for member ${index + 1}`)
//     else if (!isAlphabetic(member.name))
//       memberErrors.push(`Name should contain only alphabets for member ${index + 1}`)

//     if (!member.relationship) memberErrors.push(`Relationship is required for member ${index + 1}`)
//     else if (!isAlphabetic(member.relationship))
//       memberErrors.push(`Relationship should contain only alphabets for member ${index + 1}`)

//     if (!member.age) memberErrors.push(`Age is required for member ${index + 1}`)
//     return memberErrors
//   }

//   const addMember = () => {
//     setTouched(true)
//     const validationErrors = members.flatMap((member, index) => validateMember(member, index))

//     if (validationErrors.length > 0) {
//       setErrors(validationErrors)
//     } else {
//       setErrors([])

//       setMembers([...members, { name: '', relationship: '', age: '' }])
//       setTouched(false)
//     }
//   }

//   const removeMember = (index) => {
//     if (members.length > 1) {
//       const updatedMembers = members.filter((_, i) => i !== index)
//       setMembers(updatedMembers)

//       const validationErrors = updatedMembers.flatMap((member, i) => validateMember(member, i))
//       setErrors(validationErrors)

//       if (validationErrors.length === 0) {
//         setTouched(false)
//       }
//     }
//   }

//   const handleChange = (index, field, value) => {
//     const updatedMembers = members.map((member, i) =>
//       i === index ? { ...member, [field]: value } : member,
//     )
//     setMembers(updatedMembers)

//     if (touched) {
//       const currentMemberErrors = validateMember(updatedMembers[index], index)
//       setErrors(currentMemberErrors)
//     }
//   }

//   useEffect(() => {
//     window.scrollTo(0, 0)
//   }, [])

//   return (
//     <div className='flex flex-col gap-4 max-h-[320px] overflow-y-scroll'>
//       {members.map((member, index) => (
//         <div key={index} className='flex flex-col mt-7'>
//           <Input
//             required
//             type='text'
//             value={member.name}
//             onChange={(e) => handleChange(index, 'name', e.target.value)}
//             placeholder='Enter name'
//             className={`${
//               touched &&
//               errors.some(
//                 (error) => error.includes(`member ${index + 1}`) && error.includes('Name'),
//               )
//                 ? 'border-red-500'
//                 : ''
//             }`}
//           />
//           <div className='flex gap-4 mt-4'>
//             <Input
//               required
//               type='text'
//               value={member.relationship}
//               onChange={(e) => handleChange(index, 'relationship', e.target.value)}
//               placeholder='Enter relationship'
//               className={`${
//                 touched &&
//                 errors.some(
//                   (error) =>
//                     error.includes(`member ${index + 1}`) && error.includes('Relationship'),
//                 )
//                   ? 'border-red-500'
//                   : ''
//               }`}
//             />
//             <Input
//               required
//               type='number'
//               value={member.age}
//               onChange={(e) => handleChange(index, 'age', e.target.value)}
//               placeholder='Enter age'
//               className={`${
//                 touched &&
//                 errors.some(
//                   (error) => error.includes(`member ${index + 1}`) && error.includes('Age'),
//                 )
//                   ? 'border-red-500'
//                   : ''
//               }`}
//             />
//           </div>
//           <Button
//             variant='outline'
//             className='rounded-[4px] mt-4 bg-white text-red-600 text-sm border border-red-400 flex items-center gap-2 hover:bg-white hover:text-red-400'
//             onClick={() => removeMember(index)}
//           >
//             <RemoveIcon className='h-5 w-5' /> Remove Member
//           </Button>
//         </div>
//       ))}
//       {touched && errors.length > 0 && (
//         <div className='text-red-500 text-sm mt-2'>
//           {errors.map((error, index) => (
//             <p key={index}>{error}</p>
//           ))}
//         </div>
//       )}

//       <div className='flex justify-between'>
//         <Button
//           variant='outline'
//           className='w-[131px] rounded-[4px] bg-white text-primary-600 text-sm border border-[#3B4D80] flex items-center gap-2 hover:bg-white'
//           onClick={addMember}
//         >
//           <AddIcon className='h-5 w-5' /> Add Member
//         </Button>
//       </div>
//     </div>
//   )
// }

const CalendarForm = ({ initialDate, onDateChange }) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  // Updated years array to range from 1950 to 2011
  const years = Array.from({ length: 2011 - 1950 + 1 }, (_, i) => 2011 - i)

  const [date, setDate] = useState(initialDate || new Date(2000, 0, 1))

  useEffect(() => {
    onDateChange(date)
  }, [date, onDateChange])

  const handleMonthChange = (monthIndex) => {
    setDate((prevDate) => {
      const newDate = new Date(prevDate)
      newDate.setMonth(Number.parseInt(monthIndex))
      return newDate
    })
  }

  const handleYearChange = (year) => {
    setDate((prevDate) => {
      const newDate = new Date(prevDate)
      newDate.setFullYear(Number.parseInt(year))
      return newDate
    })
  }

  const [value, setValue] = useState(format(date, 'dd/MM/yyyy'))
  const [error, setError] = useState(null)

  useEffect(() => {
    setValue(format(date, 'dd/MM/yyyy'))
    onDateChange(date)
  }, [date, onDateChange])

  const formatDate = (input) => {
    // Remove any non-digit characters
    const digits = input.replace(/\D/g, '')

    // Format as dd:mm:yyyy
    let formatted = ''
    if (digits.length > 0) {
      formatted += digits.substring(0, Math.min(2, digits.length))
    }
    if (digits.length > 2) {
      formatted += '/' + digits.substring(2, Math.min(4, digits.length))
    }
    if (digits.length > 4) {
      formatted += '/' + digits.substring(4, Math.min(8, digits.length))
    }

    return formatted
  }

  const validateDate = (dateString) => {
    // Check if the format is complete
    if (dateString.length < 10) return null

    const parts = dateString.split('/')
    if (parts.length !== 3) return 'Invalid format. Use dd:mm:yyyy'

    const day = Number.parseInt(parts[0], 10)
    const month = Number.parseInt(parts[1], 10)
    const year = Number.parseInt(parts[2], 10)

    // Basic validation
    if (isNaN(day) || day < 1 || day > 31) return 'Day must be between 1 and 31'
    if (isNaN(month) || month < 1 || month > 12) return 'Month must be between 1 and 12'
    if (isNaN(year) || year < 1950 || year > 2011) {
      return `Year must be between 1950 and 2011`
    }

    // Check for valid dates (e.g., no February 30)
    const date = new Date(year, month - 1, day)
    if (date.getDate() !== day || date.getMonth() !== month - 1 || date.getFullYear() !== year) {
      return 'Invalid date'
    }

    return null
  }

  const handleChange = (e) => {
    const input = e.target.value
    const formatted = formatDate(input)
    setValue(formatted)

    const parts = formatted.split('/').map(Number)
    const day = parts[0]
    const month = parts[1]
    const year = parts[2]

    // Validate day if it's entered
    if (parts.length >= 1 && (isNaN(day) || day < 1 || day > 31)) {
      setError('Day must be between 1 and 31')
      return
    }

    // Validate month if it's entered
    if (parts.length >= 2 && (isNaN(month) || month < 1 || month > 12)) {
      setError('Month must be between 1 and 12')
      return
    }

    // Validate full date only if complete
    if (formatted.length === 10) {
      const errorMsg = validateDate(formatted)
      setError(errorMsg)

      if (!errorMsg) {
        setDate(new Date(year, month - 1, day))
      }
    } else {
      setError(null)
    }
  }

  return (
    <div className='space-y-4 mt-3'>
      <div className='flex flex-col'>
        {/* <Input
          id='selected-date'
          type='text'
          value={format(date, 'dd-MM-yyyy')}
          readOnly
          className='w-full'
        /> */}
        <div className='space-y-2 w-full'>
          <Input
            id='dob'
            type='text'
            placeholder='DD/MM/YYYY'
            value={value}
            onChange={handleChange}
            maxLength={10}
            className={cn(error && 'border-red-500')}
            aria-invalid={!!error}
            aria-describedby={error ? 'dob-error' : undefined}
          />
          {error && (
            <p id='dob-error' className='text-sm text-red-500'>
              {error}
            </p>
          )}
        </div>
      </div>
      <div className='flex gap-x-[14px]'>
        <div className='border rounded-md flex-1'>
          <Select onValueChange={handleMonthChange} value={date.getMonth().toString()}>
            <SelectTrigger id='month-select' className='w-full'>
              <SelectValue placeholder='Month' />
            </SelectTrigger>
            <SelectContent>
              {months.map((month, index) => (
                <SelectItem key={month} value={index.toString()}>
                  {month}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className='border rounded-md flex-1'>
          <Select onValueChange={handleYearChange} value={date.getFullYear().toString()}>
            <SelectTrigger id='year-select' className='w-full'>
              <SelectValue placeholder='Year' />
            </SelectTrigger>
            <SelectContent>
              {years.map((year) => (
                <SelectItem key={year} value={year.toString()}>
                  {year}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className='rounded-md border'>
        <Calendar
          mode='single'
          selected={date}
          onSelect={(newDate) => newDate && setDate(newDate)}
          month={date}
          onMonthChange={(newMonth) => setDate(newMonth)}
          className='rounded-md w-full'
        />
      </div>
    </div>
  )
}

const Questionaire = () => {
  const [progress, setProgress] = useState(10)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [other, setOther] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const navigate = useNavigate()
  // const [userData, setUserData] = useState([])
  const [loginDetails, setLoginDetails] = useState({})

  //User Saved response
  const [savedUserResponse, setSavedUserResponse] = useState([])

  useEffect(() => {
    const fetchSavedResponse = async () => {
      const url = 'https://apimywealth.fabits.com/customerservice/api/firstIdeas'
      const phoneNumber = loginDetails.phoneNumber
      try {
        const response = await get(url, {}, { phoneNumber })
        setSavedUserResponse(response)
        savedUserResponse?.isCompleted && setIsPopupOpen(true)

        if (response && response.userResponse) {
          // Find the index of the last answered question
          const lastAnsweredIndex = response.userResponse.reduce((maxIndex, question, index) => {
            const value = Object.values(question)[0]
            if (
              (Array.isArray(value) && value.length > 0) ||
              (typeof value === 'object' &&
                Object.values(value).some((v) => v !== '' && v !== false)) ||
              (value !== '' && value !== false)
            ) {
              return index
            }
            return maxIndex
          }, -1)

          // Set the current index to the last answered question
          setCurrentIndex(lastAnsweredIndex)
          setProgress(lastAnsweredIndex * 10)

          console.log(response, 'sdfsdsdfsd')

          // location.reload()

          // Populate the state with saved responses
          setState((prevState) => ({
            ...prevState,
            ...response.userResponse.reduce((acc, question) => {
              const [key, value] = Object.entries(question)[0]
              console.log([key, value], 'dsfdsfdsfd')
              return { ...acc, [key.toLowerCase()]: value }
            }, {}),
          }))
        }
      } catch (error) {
        console.log(error, 'error')
      }
    }

    if (loginDetails?.phoneNumber) {
      fetchSavedResponse()
    }
  }, [loginDetails.phoneNumber])

  useEffect(() => {
    setLoginDetails(getUserDetails())
  }, [])

  //Answers
  const [date, setDate] = useState(new Date(2000, 0, 1))

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      dob:
        date instanceof Date && !isNaN(date.getTime())
          ? new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]
          : null,
    }))
  }, [date])

  const submitHandler = async () => {
    const url = 'https://apimywealth.fabits.com/customerservice/api/firstIdeas'

    const data = {
      phoneNumber: loginDetails.phoneNumber,
      userResponse: userResponse,
      isCompleted: userResponse.length === questions.length,
    }

    try {
      await post(url, data)
    } catch (error) {
      console.error(error)
    }
    userResponse.length === questions.length && setIsPopupOpen(true)

    clevertap.event.push('first ideas completed')
    ReactGA4.event({
      action: 'first ideas completed',
    })

    // fbq('track', 'first ideas completed')
  }

  const [state, setState] = React.useState({
    dob: date,
    usergender: '',
    salary: '',
    goals: [],
    fears: [],
    investment: {
      type: [],
      other: '',
      isChecked: false,
    },
    insurancedata: [],
    lumpsum: {
      ans: '',
      amount: '',
    },
    monthlysaving: '',
  })

  const questions = [
    {
      id: 1,
      question: 'Enter your date of birth',
      text: '',
      desc: <CalendarForm initialDate={date} onDateChange={setDate} />,
      image: 'question1',
    },
    {
      id: 2,
      question: 'What is your gender?',
      text: '',
      desc: (
        <div className='grid grid-cols-2 gap-4 mt-6'>
          {['Male', 'Female', 'Prefer not to specify', 'Others'].map((gender, index) => {
            const isSelected = state.usergender === gender
            return (
              <div
                key={index}
                className={`rounded-[4px] cursor-pointer p-2 border font-DMSans font-medium text-sm ${
                  isSelected
                    ? 'border-[#41558D] bg-primary-50 text-primary-500'
                    : 'border-[#C4CADC]'
                }`}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    usergender: gender,
                  }))
                }
              >
                {gender}
              </div>
            )
          })}
        </div>
      ),
      image: 'question2',
    },
    {
      id: 3,
      question: 'What is your monthly take home salary?',
      text: 'Enter the amount you receive after all taxes and deductions.',
      desc: (
        <div className='mt-6'>
          <Input
            required
            type='number'
            value={state.salary}
            placeholder={'Enter here'}
            onKeyDown={(e) => {
              if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                e.preventDefault()
              }
            }}
            onChange={(e) => {
              setState((prevState) => ({
                ...prevState,
                salary: e.target.value,
              }))
            }}
          />
        </div>
      ),
      image: 'question3',
    },
    {
      id: 4,
      question: 'What are some of your financial goals?',
      text: 'Think about what you would like to plan for in the future.',
      desc: (
        <div className='grid grid-cols-2 gap-4 mt-7'>
          {[
            {
              id: 1,
              title: 'Retirement',
            },
            {
              id: 2,
              title: 'Home buying',
            },
            {
              id: 3,
              title: 'Car',
            },
            {
              id: 4,
              title: 'Travel',
            },
            {
              id: 5,
              title: 'Entrepreneurship',
            },
            {
              id: 6,
              title: 'Child education',
            },
            {
              id: 7,
              title: 'Wealth creation',
            },
            {
              id: 8,
              title: 'No goals',
            },
          ].map((goal) => {
            const isSelected = state.goals.includes(goal.title) // Check if the goal is selected
            return (
              <div
                key={goal.id}
                className={`flex cursor-pointer items-center gap-2 p-2 border rounded-[4px] ${
                  isSelected
                    ? 'border-[#41558D] bg-primary-50 text-primary-500'
                    : 'border-[#C4CADC]'
                }`}
                onClick={() =>
                  setState((prevState) => {
                    let updatedGoals = []
                    if (goal.title === 'No goals') {
                      updatedGoals = ['No goals']
                    } else {
                      const currentGoals = prevState.goals.filter((g) => g !== 'No goals')
                      if (currentGoals.includes(goal.title)) {
                        updatedGoals = currentGoals.filter((g) => g !== goal.title)
                      } else {
                        updatedGoals = [...currentGoals, goal.title]
                      }
                    }
                    return {
                      ...prevState,
                      goals: updatedGoals,
                    }
                  })
                }
              >
                {goal.icon}
                <span className='font-DMSans font-medium text-sm'>{goal.title}</span>
              </div>
            )
          })}
        </div>
      ),
      image: 'question4',
    },
    {
      id: 5,
      question: 'What are your financial fears or concerns?',
      text: 'Think of all the current and foreseeable issues in your finances.',
      desc: (
        <div className='grid grid-cols-2 gap-4 mt-7'>
          {[
            {
              id: 1,
              title: 'Emergencies',
            },
            {
              id: 2,
              title: 'Debts',
            },
            {
              id: 3,
              title: 'Medical issues',
            },
            {
              id: 4,
              title: 'Job loss',
            },
            {
              id: 5,
              title: 'Inflation',
            },
            {
              id: 6,
              title: 'No savings',
            },
            {
              id: 7,
              title: 'All of the above',
            },
            {
              id: 8,
              title: 'None',
            },
          ].map((fear) => {
            const isSelected = state.fears.includes(fear.title) // Check if the fear is selected
            return (
              <div
                key={fear.id}
                className={`flex cursor-pointer items-center gap-2 p-2 border rounded-[4px] ${
                  isSelected
                    ? 'border-[#41558D] bg-primary-50 text-primary-500'
                    : 'border-[#C4CADC]'
                }`}
                onClick={() =>
                  setState((prevState) => {
                    let updatedFears = []
                    if (fear.title === 'All of the above') {
                      updatedFears = ['All of the above']
                    } else if (fear.title === 'None') {
                      updatedFears = ['None']
                    } else {
                      const currentFears = prevState.fears.filter(
                        (f) => f !== 'All of the above' && f !== 'None',
                      )
                      const isAlreadySelected = currentFears.includes(fear.title)
                      if (isAlreadySelected) {
                        updatedFears = currentFears.filter((f) => f !== fear.title)
                      } else {
                        updatedFears = [...currentFears, fear.title]
                      }
                    }
                    return {
                      ...prevState,
                      fears: updatedFears,
                    }
                  })
                }
              >
                {fear.icon}
                <span className='font-DMSans font-medium text-sm'>{fear.title}</span>
              </div>
            )
          })}
        </div>
      ),
      image: 'question5',
    },
    // {
    //   id: 6,
    //   question: 'Please provide some information about your family? (Optional)',
    //   text: 'This helps us in personalizing plans for you and your loved ones',
    //   desc: (
    //     <MembersForm
    //       setMembers={(updatedMembers) => {
    //         console.log(updatedMembers, 'Members')
    //         setState((prevState) => ({
    //           ...prevState,
    //           members: updatedMembers,
    //         }))
    //       }}
    //       members={state.members}
    //     />
    //   ),
    //   image: question6,
    // },
    // {
    //   id: 6,
    //   question: 'How many individuals in your family depend on you for financial support?',
    //   text: 'Think of children, elders and spouses. This helps us in personalizing plans for you and your loved ones.',
    //   desc: (
    //     <div className='mt-5 cursor-pointer'>
    //       <Input
    //         type='number'
    //         value={state.members}
    //         step='1'
    //         placeholder={'Enter here'}
    //         onChange={(e) => {
    //           const intValue = parseInt(e.target.value, 10) || ''
    //           setState((prevState) => ({
    //             ...prevState,
    //             members: intValue,
    //           }))
    //         }}
    //       />
    //     </div>
    //   ),
    //   image: 'question6',
    // },
    {
      id: 7,
      question: 'Where have you invested your funds?',
      text: 'This helps us understand where you are so we can take you where you want to go.',
      desc: (
        <>
          <div className='grid grid-cols-2 gap-4 mt-7'>
            {[
              'FD/RD',
              'Mutual Funds',
              'Stocks',
              'PF',
              'PPF',
              'LIC Pension scheme',
              'Not yet',
              'Others',
            ].map((invested, index) => {
              const isSelected =
                state.investment && state.investment.type
                  ? state.investment.type.includes(invested)
                  : false

              return (
                <div
                  key={index}
                  onClick={() => {
                    if (invested === 'Others') {
                      setOther(!other)
                    }

                    setState((prevState) => {
                      let updatedType = []

                      if (invested === 'Not yet') {
                        // If 'Not yet' is selected, it becomes the only option
                        updatedType = ['Not yet']
                        setOther(false)
                      } else {
                        const currentType = prevState.investment.type
                        const isAlreadySelected = currentType.includes(invested)

                        if (isAlreadySelected) {
                          // Remove the option if it's already selected
                          updatedType = currentType.filter((item) => item !== invested)
                        } else {
                          // Add the new option and remove 'Not yet' if it exists
                          updatedType = [
                            ...currentType.filter((item) => item !== 'Not yet'),
                            invested,
                          ]
                        }
                      }

                      return {
                        ...prevState,
                        investment: {
                          ...prevState.investment,
                          type: updatedType,
                        },
                      }
                    })
                  }}
                  className={`border cursor-pointer rounded-[4px] p-2 font-DMSans font-medium text-sm ${
                    isSelected
                      ? 'border-primary-500 bg-primary-50 text-primary-500'
                      : 'border-[#C4CADC]'
                  }`}
                >
                  {invested}
                </div>
              )
            })}
          </div>

          {other && (
            <div className='mt-7'>
              <Input
                value={state.investment.other}
                placeholder='Type here'
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    investment: {
                      ...prevState.investment,
                      other: e.target.value,
                    },
                  }))
                }
              />
            </div>
          )}

          <div className='mt-7 flex gap-2 items-start'>
            <div className='h-5 w-5 flex justify-center mt-1'>
              <Checkbox
                className='border-[#727272] cursor-pointer'
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    investment: {
                      ...prevState.investment,
                      isChecked: !state.investment.isChecked,
                    },
                  }))
                }
              />
            </div>
            <p className='text-black-400 font-DMSans font-normal text-sm'>
              I am willing to provide details of my current investments for a more holistic
              investment experience.
            </p>
          </div>
        </>
      ),
      image: 'question7',
    },
    {
      id: 8,
      question: 'Do you have Insurance?',
      text: 'Selecting the correct insurance for you is crucial to securing your finances.',
      desc: (
        <div className='mt-7 flex flex-col gap-4'>
          {[
            'Private Medical Insurance',
            'Corporate Medical Insurance',
            'Term Insurance',
            'Not yet',
          ].map((insurance, index) => {
            const isSelected = state.insurancedata.includes(insurance)

            return (
              <div
                key={index}
                className={`border cursor-pointer p-2 rounded-[4px] font-DMSans font-medium text-sm ${
                  isSelected
                    ? 'border-primary-500 bg-primary-50 text-primary-500'
                    : 'border-[#C4CADC]'
                }`}
                onClick={() =>
                  setState((prevState) => {
                    let updatedInsuranceData = []

                    if (insurance === 'Not yet') {
                      // If 'Not yet' is selected, it becomes the only option
                      updatedInsuranceData = ['Not yet']
                    } else {
                      const currentInsuranceData = prevState.insurancedata
                      const isAlreadySelected = currentInsuranceData.includes(insurance)

                      if (isAlreadySelected) {
                        // Remove the option if it's already selected
                        updatedInsuranceData = currentInsuranceData.filter(
                          (item) => item !== insurance,
                        )
                      } else {
                        // Add the new option and remove 'Not yet' if it exists
                        updatedInsuranceData = [
                          ...currentInsuranceData.filter((item) => item !== 'Not yet'),
                          insurance,
                        ]
                      }
                    }

                    return {
                      ...prevState,
                      insurancedata: updatedInsuranceData,
                    }
                  })
                }
              >
                {insurance}
              </div>
            )
          })}
        </div>
      ),
      image: 'question8',
    },
    {
      id: 9,
      question: 'Do you have a lump sum to invest now?',
      text: 'We will help you invest this money only after our consultation call',
      desc: (
        <>
          <div className='flex gap-4 mt-7'>
            {['Yes', 'No'].map((lump, index) => {
              const isSelected = state.lumpsum.ans === lump
              return (
                <div
                  key={index}
                  className={`border cursor-pointer flex-1 p-2 rounded-[4px] font-DMSans font-medium text-sm ${
                    isSelected
                      ? 'border-primary-500 bg-primary-50 text-primary-500'
                      : 'border-[#C4CADC]'
                  }`}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      lumpsum: {
                        ...prevState.lumpsum,
                        ans: lump,
                        ...(lump === 'No' && { amount: '' }),
                      },
                    }))
                  }
                >
                  {lump}
                </div>
              )
            })}
          </div>
          <div className='mt-4'>
            <Input
              required
              type='number'
              disabled={state.lumpsum.ans !== 'Yes'}
              placeholder='If yes, please enter the amount'
              value={state.lumpsum.amount}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  lumpsum: {
                    ...prevState.lumpsum,
                    amount: e.target.value,
                  },
                }))
              }
            />
          </div>
        </>
      ),
      image: 'question9',
    },
    {
      id: 10,
      question: 'On average, approximately how much do you save per month?',
      text: 'Our financial experts will curate your investments accordingly.',
      desc: (
        <div className='mt-6'>
          <Input
            required
            type='number'
            value={state.monthlysaving}
            onChange={(e) => {
              const sanitizedValue = e.target.value.replace(/[^0-9]/g, '')
              setState((prevState) => ({
                ...prevState,
                monthlysaving: sanitizedValue,
              }))
            }}
            placeholder={'Enter here'}
          />
        </div>
      ),
      image: 'question10',
    },
  ]

  const isCurrentQuestionAnswered = () => {
    switch (currentIndex) {
      case 0: // Date of birth
        return state.dob !== null && state.dob !== ''
      case 1: // Gender
        return state.usergender !== ''
      case 2: // Salary
        return state.salary !== ''
      case 3: // Goals
        return state.goals.length > 0
      case 4: // Fears
        return state.fears.length > 0
      case 5: // Investments
        return state?.investment?.type?.length > 0 || state?.investment?.other?.length > 0
      case 6: // Insurance
        return state.insurancedata.length > 0
      case 7: // Lump sum
        return (
          state.lumpsum.ans !== '' && (state.lumpsum.ans === 'No' || state.lumpsum.amount !== '')
        )
      case 8: // Monthly savings
        return state.monthlysaving !== ''
      default:
        return false
    }
  }

  const handleNextQuestion = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1)
    setProgress((prev) => prev + 10)
  }

  const handleBackBtn = () => {
    if (currentIndex === 0) {
      navigate(-1)
    }
    setCurrentIndex((prevIndex) => prevIndex - 1)
    setProgress((prev) => prev - 10)
  }

  const currentItem = questions[currentIndex]

  const userResponse = useMemo(() => {
    return Object.entries(state).reduce((acc, [key, value]) => {
      const isEmpty = (val) => {
        if (Array.isArray(val))
          return val.length === 0 || val.every((item) => Object.values(item).every((v) => v === ''))
        if (typeof val === 'object')
          return Object.values(val).every(
            (v) => v === '' || v === false || (Array.isArray(v) && v.length === 0),
          )
        return val === '' || val === false || val === null
      }

      // const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1)

      // For other fields
      if (!isEmpty(value)) {
        acc.push({ [key]: value })
      }

      return acc
    }, [])
  }, [state, currentIndex])

  // useEffect(() => {
  //   setUserData(userResponse)
  // }, [userResponse])

  return (
    <>
      <div
        className={`hidden max-w-[1128px] mx-auto mt-6 mb-8 lg:flex lg:${
          currentItem.id === 1 ? 'justify-end' : 'justify-between'
        } lg:items-center`}
      >
        <div
          className={`${currentItem.id === 1 ? 'hidden' : 'cursor-pointer'}`}
          onClick={handleBackBtn}
        >
          <ChevronLeftIcon sx={{ height: '20px', width: '20px' }} />
        </div>
        <div
          className='cursor-pointer'
          onClick={() => {
            submitHandler()
            startTransition(() => {
              navigate('/dashboard')
            })
          }}
        >
          <CloseIcon sx={{ height: '20px', width: '20px', color: '#d2d2d2' }} />
        </div>
      </div>

      <div className='px-4 py-5 flex flex-col mx-auto lg:border lg:border-grey-200 lg:h-[800px] lg:max-w-[400px] lg:rounded-[12px] lg:relative'>
        <div className='flex justify-between lg:hidden'>
          <div
            onClick={handleBackBtn}
            style={{ visibility: currentItem.id === 1 ? 'hidden' : 'visible' }}
          >
            <ChevronLeftIcon sx={{ height: '20px', width: '20px' }} />
          </div>
          <div className='text-center'>
            <h1 className='text-primary-500 font-DMSans font-semibold text-lg tracking-[-0.04em]'>
              {currentItem.id === 1 ? 'Let’s get started' : 'You are doing great!'}
            </h1>
            <p className='font-DMSSans font-normal text-sm'>It will only take you 5 mins!</p>
          </div>
          <div
            onClick={() => {
              submitHandler()
              startTransition(() => {
                navigate('/dashboard')
              })
            }}
          >
            <CloseIcon sx={{ height: '20px', width: '20px', color: '#d2d2d2' }} />
          </div>
        </div>

        <div className='text-center'>
          <div className='text-center hidden lg:block'>
            <h1 className='text-primary-500 font-DMSans font-semibold text-lg tracking-[-0.04em]'>
              {currentItem.id === 1 ? 'Let’s get started' : 'You are doing great!'}
            </h1>
            <p className='font-DMSSans font-normal text-sm'>It will only take you 5 mins!</p>
          </div>
          <div className='mt-[12px] px-12'>
            <ProgressFour value={progress} className='h-1' />
          </div>
          <div className='flex justify-center my-8'>
            <img
              src={`${env.S3_BASE_URL}/firstTime/${currentItem?.image}.svg`}
              alt={currentItem?.id}
            />
          </div>
        </div>

        <div>
          <div>
            <p className='text-base text-black-600 font-DMSans font-semibold'>
              {currentItem?.question}
            </p>
            <p className='text-black-400 text-sm font-normal font-DMSans'>{currentItem?.text}</p>
          </div>

          <div>{currentItem?.desc}</div>
        </div>
        <div className={`fixed lg:absolute bottom-0 left-0 right-0 p-5 backdrop-blur-sm`}>
          {currentIndex !== questions.length - 1 && (
            <Button
              className={`w-full ${
                !isCurrentQuestionAnswered() ? 'bg-primary-400' : 'bg-[#41558D]'
              } `}
              onClick={handleNextQuestion}
              disabled={!isCurrentQuestionAnswered()}
            >
              Next
            </Button>
          )}

          {currentIndex === questions.length - 1 && (
            <Button
              className={`w-full mt-4 ${
                !isCurrentQuestionAnswered() ? 'bg-primary-400' : 'bg-[#41558D]'
              }`}
              onClick={submitHandler}
              disabled={!isCurrentQuestionAnswered()}
            >
              Submit
            </Button>
          )}
        </div>
        <div className='w-80'>
          <CompletionPopup
            setIsPopupOpen={setIsPopupOpen}
            isOpen={isPopupOpen}
            onClose={() => {
              setIsPopupOpen(false), navigate('/dashboard')
            }}
          />
        </div>
      </div>
    </>
  )
}

export default Questionaire
