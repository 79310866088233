import { Textarea } from '../../Components/ui/textarea'
import { Button } from '../../Components/ui/button'
import { Dialog, DialogContent, DialogFooter } from '../../Components/ui/dialog'
import { Input } from '../../Components/ui/input'
import { Label } from '../../Components/ui/label'
import { useEffect, useState } from 'react'
import { Checkbox } from '../../Components/ui/checkbox'
import { post, get } from '../../http/restservice'
import env from '../../env/env'
import { Toaster, toast } from 'sonner'

const ActionNotification = ({ open, setOpen, data }) => {
  const [formData, setFormData] = useState({
    header: '',
    message: '',
  })

  const formatUid = (id) => id.split('-')[0].toUpperCase()

  const [payloadData, setPayloadData] = useState({
    clientCode: formatUid(data.uid),
    phoneNumber: data.phoneNumber,
    adminId: 'SSL1106',
    sendPushNotification: true,
    notifications: [],
  })

  const fetchData = async () => {
    const url = `${env.API_URL}adminservice/api/actionPlan/notifications`

    const clientCode = formatUid(data.uid)
    try {
      const response = await get(url, {}, { clientCode })

      if (response.code === '200') {
        setPayloadData(response.data)
      } else {
        console.error('Failed to fetch data')
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target
    if (type === 'checkbox') {
      setPayloadData((prevData) => ({
        ...prevData,
        [name]: checked,
      }))
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }))
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const updatedPayload = {
      ...payloadData,
      notifications: [...payloadData.notifications, formData],
    }

    try {
      const url = `${env.API_URL}adminservice/api/actionPlan/notifications`
      const response = await post(url, updatedPayload)

      if (response.status === 'SUCCESS') {
        toast('Message sent successfully !!')
        setFormData({ header: '', message: '' })
        fetchData()
        setOpen(false)
      } else {
        console.error('Failed to send notification')
        toast.error('Failed to send notification')
      }
    } catch (error) {
      console.error('Error sending notification:', error)
    }
  }

  return (
    <div className='flex items-center'>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className='sm:max-w-[425px]' showclose={true}>
          <form onSubmit={handleSubmit}>
            <div className='py-4'>
              <div className='mb-4'>
                <Label htmlFor='header' className='text-right'>
                  Header
                </Label>
                <Input
                  id='header'
                  name='header'
                  value={formData.header}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <Label htmlFor='message' className='text-right'>
                  Message
                </Label>
                <Textarea
                  id='message'
                  name='message'
                  placeholder='Type your message here.'
                  value={formData.message}
                  onChange={handleInputChange}
                />
              </div>
              <div className='flex items-center space-x-2 mt-5'>
                <Checkbox
                  id='sendPushNotification'
                  name='sendPushNotification'
                  checked={payloadData.sendPushNotification}
                  onCheckedChange={(checked) =>
                    handleInputChange({
                      target: {
                        name: 'sendPushNotification',
                        type: 'checkbox',
                        checked,
                      },
                    })
                  }
                />
                <Label htmlFor='sendPushNotification'>Send Push Notification</Label>
              </div>
            </div>
            <DialogFooter>
              <Button type='submit'>Send Notification</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <Toaster />
    </div>
  )
}

export default ActionNotification
