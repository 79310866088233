/* eslint-disable */

import { useEffect, useState } from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../Components/ui/tabs'
import { AutoComplete } from '../../Components/ui/mf-search-input'

import DebtFunds from '../../Components/MutualFunds/DebtFunds'
import EquityFunds from '../../Components/MutualFunds/EquityFunds'
import HybridFunds from '../../Components/MutualFunds/HybridFunds'
import useMediaQuery from '../../hooks/useMediaQuery'
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { get } from '../../http/restservice'
import useDebounce from '../../hooks/useDebounce'
import { messageModalState } from '../../store/Feedback'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { AnimatePresence, motion } from 'framer-motion'
import { FabitsStarFunds, MFRecentlyViewed, MutualFundsHolding } from '../../store/MFStore'
import { toCurrency } from '../../utils/CurrencyUtil'
import MFNavbar from '../../Components/MutualFunds/MFNavbar'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../Components/ui/dialog'
import { Button } from '../../Components/ui/button'
import { Search } from 'lucide-react'
import { MobileSearchInput } from '../../Components/ui/mobile-search-input'
import env from '../../env/env'
import { isDematUser } from '../../utils/AuthUtil'
import { Tab } from '../../Components/ui/Tab'
import { MFBasketHoldings } from '../../store/MFBasketsStore'

const AnimatedTabContent = ({ children }) => (
  <motion.div
    initial={{ x: 20, opacity: 0 }}
    animate={{ x: 0, opacity: 1 }}
    exit={{ x: -20, opacity: 0 }}
    transition={{ duration: 0.1, ease: 'easeInOut' }}
  >
    {children}
  </motion.div>
)

const MutualFunds = () => {
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()
  const [tabValue, setTabValue] = useState(searchParams.get('assetClass') || 'Equity')
  const [isLoading, setIsLoading] = useState(false)

  const [searchLoading, setSearchLoading] = useState(false)
  const [searchData, setSearchData] = useState([])
  const [query, setQuery] = useState('')
  const debouncedSearchTerm = useDebounce(query, 500)

  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const location = useLocation()

  const mfHolding = isDematUser() && useRecoilValueLoadable(MutualFundsHolding)

  const mfBasketHolding = isDematUser() && useRecoilValueLoadable(MFBasketHoldings)

  const paramsObject = Object.fromEntries(searchParams.entries())
  const [mutualFundsData, setMutualFundsData] = useState([])

  const [totalPages, setTotalPages] = useState(0)

  const setMessageModalState = useSetRecoilState(messageModalState)

  const [isOpen, setIsOpen] = useState(false)
  const fabStarFunds = useRecoilValueLoadable(FabitsStarFunds)
  const recentlyViewed = useRecoilValueLoadable(MFRecentlyViewed)

  const handleSearchOpen = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  useEffect(() => {
    const fetchMFData = async () => {
      setIsLoading(true)

      if (paramsObject?.search) {
        delete paramsObject.assetClass
      }

      if (paramsObject.page != 0) {
        paramsObject.page = paramsObject.page - 1
      }

      const response = await get(
        'https://apimywealth.fabits.com/mutualfundservice/api/mfData/allFunds',
        {},
        paramsObject,
      )

      setIsLoading(false)
      if (response.isError) {
        setMutualFundsData([])
        setTotalPages(0)
        setMessageModalState({
          message: response?.response?.message,
          severity: 'error',
        })
      }

      if (!response.isError) {
        setMutualFundsData(response?.funds)
        setTotalPages(response?.totalPages)
      }
    }

    if (location.pathname === '/dashboard/mutual-funds') fetchMFData()
  }, [JSON.stringify(paramsObject)])

  useEffect(() => {
    const searchFunds = async () => {
      setSearchLoading(true)
      const response = await get(
        `/mutualfundservice/api/mfData/allFunds?search=${debouncedSearchTerm}`,
      )
      setSearchLoading(false)

      if (response.isError) {
        setSearchData([])
      }

      if (!response.isError) {
        setSearchData(response?.funds)
      }
    }

    if (debouncedSearchTerm) {
      searchFunds()
    } else {
      setSearchData([])
    }
  }, [debouncedSearchTerm])

  const clearAllFilter = (assetClass) => {
    setSearchParams({
      assetClass: assetClass,
      page: 0,
      size: 10,
      sortBy: 'threeYearRet',
      sortDir: 'desc',
    })
  }

  const hideContentPaths = [
    '/dashboard/mutual-funds/investments',
    '/dashboard/mutual-funds/orders',
    '/dashboard/mutual-funds/sips',
  ] // Update based on your child routes

  const shouldHideParentContent = hideContentPaths.includes(location.pathname)

  const handleSearchCancel = () => {
    setIsOpen(false)
    setQuery('')
  }

  const handleFabStarNavigate = (fund) => {
    setIsOpen(false)

    navigate(`/dashboard/mutual-funds/details`, {
      state: {
        fundDetails: fund,
        schemeCode: fund.schemeCode,
      },
    })
  }

  const getTotalAmount = () => {
    const basketTotal =
      mfBasketHolding?.contents?.holdings?.reduce(
        (acc, order) => acc + Number(order.currentNav * order.netUnits),
        0,
      ) || 0

    const mfIndividualTotal =
      mfHolding?.contents?.mainData?.reduce((sum, item) => sum + item.holding, 0) || 0

    return mfIndividualTotal
  }

  const getTotalInvestedAmount = () => {
    const totalIndividual =
      mfHolding?.contents?.mainData?.reduce((sum, item) => sum + Number(item.netInvestedAmt), 0) ||
      0

    const totalBasket =
      mfBasketHolding?.contents?.holdings?.reduce((acc, order) => {
        return acc + Number(order.netInvestedAmount)
      }, 0) || 0

    return totalIndividual
  }

  const getReturns = (mfHolding) => {
    const total =
      mfHolding?.contents?.mainData?.reduce((sum, item) => sum + item.holding, 0) + getTotalAmount()
    const invested =
      mfHolding?.contents?.mainData?.reduce((sum, item) => sum + item.netInvestedAmt, 0) +
      getTotalInvestedAmount()

    // Calculate returns in percentage
    const returns = ((total - invested) / invested) * 100

    return returns < 0 ? toCurrency(returns) : toCurrency(returns)
  }

  const tabs = ['Debt', 'Equity', 'Hybrid']

  return (
    <>
      {isDesktop ? (
        <>
          <div
            style={{
              background:
                'radial-gradient(83.35% 187.05% at 21.03% 89.97%, #1B243B 0%, #242F4E 40.06%, #3B4D80 100%)',
              isolation: 'isolate',
            }}
            className='py-2'
          >
            {/* <MFNavbar /> */}

            <div className={`max-w-[1128px] mx-auto font-DMSan py-12`}>
              <div className='flex justify-between items-center py-3 max-h-12'>
                {isDematUser() && (
                  <div>
                    <AnimatePresence>
                      <>
                        <Tab to='/dashboard/mutual-funds'>Explore</Tab>
                        <Tab to='/dashboard/mutual-funds/investments'>My Investments</Tab>
                        <Tab to='/dashboard/mutual-funds/sips'>SIPs</Tab>
                        <Tab to='/dashboard/mutual-funds/orders'>Orders</Tab>
                      </>
                    </AnimatePresence>
                  </div>
                )}

                {mfHolding?.state === 'hasValue' &&
                  mfHolding?.contents?.mainData?.length > 0 &&
                  location.pathname != '/dashboard/mutual-funds/sips' &&
                  location.pathname != '/dashboard/mutual-funds/investments' &&
                  location.pathname != '/dashboard/mutual-funds/orders' && (
                    <div>
                      <AutoComplete
                        options={searchData}
                        emptyMessage='No funds available'
                        placeholder='Search for funds'
                        searchLoading={searchLoading}
                        onValueChange={setQuery}
                        value={query}
                        fabStarFunds={fabStarFunds}
                        recentlyViewedFunds={recentlyViewed}
                      />
                    </div>
                  )}
              </div>

              {location.pathname != '/dashboard/mutual-funds/orders' &&
                location.pathname != '/dashboard/mutual-funds/sips' &&
                (mfHolding?.state === 'hasValue' && mfHolding?.contents?.mainData?.length > 0 ? (
                  <>
                    <div className='flex gap-x-12 justify-center items-end mt-12'>
                      <div>
                        <p className='text-center text-white font-semibold'>
                          ₹ <>{toCurrency(getTotalInvestedAmount())}</>
                        </p>
                        <p className='text-primary-100 text-sm text-center'>
                          Total Invested Amount
                        </p>
                      </div>
                      <div>
                        <h3 className='text-center text-white text-[32px] font-AlfaSlabOne tracking-[4%] leading-[42px]'>
                          ₹{' '}
                          {(() => {
                            const totalHolding = getTotalAmount().toFixed(2)
                            const [integerPart, decimalPart] = totalHolding.split('.')

                            return (
                              <>
                                {toCurrency(integerPart)}
                                <span className='text-[20px] align-top'>.{decimalPart}</span>
                              </>
                            )
                          })()}
                        </h3>
                        <p className='text-primary-100 text-sm text-center'>
                          Total Current MF Portfolio
                        </p>
                      </div>
                      <div>
                        <p className={`text-center text-white font-semibold'`}>
                          {getReturns(mfHolding)}%
                        </p>
                        <p className='text-primary-100 text-sm text-center'>Overall Returns</p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <h1 className='font-semibold text-[32px] text-white text-center my-6'>
                      Mutual Funds
                    </h1>
                    <div className='max-w-[552px] mx-auto'>
                      <AutoComplete
                        options={searchData}
                        emptyMessage='No funds available'
                        placeholder='Search for funds'
                        searchLoading={searchLoading}
                        onValueChange={setQuery}
                        value={query}
                        fabStarFunds={fabStarFunds}
                        recentlyViewedFunds={recentlyViewed}
                      />
                    </div>
                  </>
                ))}
            </div>
          </div>

          <Outlet />

          {!shouldHideParentContent && (
            <div className='bg-white'>
              <div className='max-w-[1128px] py-12 mx-auto'>
                <div>
                  <div className='mx-auto w-full flex justify-center items-center'>
                    {tabs.map((tab, index) => (
                      <span
                        key={tab + index}
                        className={`text-primary-200 text-2xl font-light flex flex-col justify-center items-center cursor-pointer px-4 py-2 ${
                          tabValue === tab && 'text-primary-700 font-semibold'
                        }`}
                        onClick={() => {
                          setTabValue(tab)
                          clearAllFilter(tab)
                        }}
                      >
                        {tab} {tabValue === tab && 'Funds'}
                        <span
                          className={`h-2 w-2 rounded-full ${
                            tabValue === tab ? 'bg-primary-700' : 'bg-transparent'
                          }`}
                        />
                      </span>
                    ))}
                  </div>

                  <AnimatePresence mode='wait'>
                    {tabs.map(
                      (tab, index) =>
                        tabValue === tab && (
                          <motion.div
                            key={tab + index}
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.2 }}
                          >
                            {tab === 'Debt' && (
                              <DebtFunds
                                searchValue={''}
                                mutualFundsData={mutualFundsData}
                                totalPages={totalPages}
                                isLoading={isLoading}
                              />
                            )}
                            {tab === 'Equity' && (
                              <EquityFunds
                                searchValue={''}
                                mutualFundsData={mutualFundsData}
                                totalPages={totalPages}
                                isLoading={isLoading}
                              />
                            )}
                            {tab === 'Hybrid' && (
                              <HybridFunds
                                searchValue={''}
                                mutualFundsData={mutualFundsData}
                                totalPages={totalPages}
                                isLoading={isLoading}
                              />
                            )}
                          </motion.div>
                        ),
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div
            className='pt-2'
            style={{
              background:
                'radial-gradient(83.35% 187.05% at 21.03% 89.97%, #1B243B 0%, #242F4E 40.06%, #3B4D80 100%)',
              isolation: 'isolate',
            }}
          >
            <MFNavbar handleSearchOpen={handleSearchOpen} />

            <Dialog
              open={isOpen}
              onOpenChange={handleSearchOpen}
              aria-labelledby='dialog-title'
              aria-describedby='dialog-description'
            >
              <DialogContent className='font-DMSans h-full w-screen md:h-[50vh] duration-300 ease-out flex flex-col justify-start border rounded-none overflow-y-scroll hide-scrollbar'>
                <DialogHeader>
                  <DialogTitle>
                    <div className='flex items-center mb-4'>
                      <MobileSearchInput
                        placeholder='Search for funds'
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        handleSearchCancel={handleSearchCancel}
                      />
                    </div>
                  </DialogTitle>
                </DialogHeader>

                {!debouncedSearchTerm && isDematUser() && (
                  <div className='flex flex-col'>
                    <div className='flex gap-x-2 items-center'>
                      <Button
                        variant='simple'
                        size='simple'
                        onClick={() => {
                          handleSearchCancel(), navigate('/dashboard/mutual-funds/orders')
                        }}
                        className={`rounded-full font-medium text-xs border border-primary-200 px-4 py-2 bg-background text-primary-500`}
                      >
                        Orders
                      </Button>
                      <Button
                        variant='simple'
                        size='simple'
                        onClick={() => {
                          handleSearchCancel(), navigate('/dashboard/mutual-funds/sips')
                        }}
                        className={`rounded-full font-medium text-xs border border-primary-200 px-4 py-2 bg-background text-primary-500`}
                      >
                        SIPs
                      </Button>
                      <Button
                        variant='simple'
                        size='simple'
                        onClick={() => {
                          handleSearchCancel(), navigate('/dashboard/mutual-funds/investments')
                        }}
                        className={`rounded-full font-medium text-xs border border-primary-200 px-4 py-2 bg-background text-primary-500`}
                      >
                        Investments
                      </Button>
                    </div>
                  </div>
                )}

                {!debouncedSearchTerm && recentlyViewed?.state != 'hasValue' && (
                  <div className='mt-5'>
                    <h2 className='font-semibold text-primary-700 leading-6 tracking-[-4%]'>
                      Fabstar funds
                    </h2>
                    <div>
                      {fabStarFunds.state === 'hasValue' &&
                        fabStarFunds?.contents?.data?.map((fund) => (
                          <div
                            className='cursor-pointer flex gap-x-2 items-center py-4 border-b-[1px] border-primary-50'
                            key={fund?.amcCode}
                            onClick={() => handleFabStarNavigate(fund)}
                          >
                            <img
                              src={`${env.MF_LOGOS_URL}/${fund?.amcCode}.png`}
                              className='h-9 w-9 rounded-sm'
                            />

                            <div className='w-full'>
                              <div className='flex justify-between'>
                                <h2 className='font-semibold leading-5 text-sm text-black-500 text-nowrap'>
                                  {fund?.legalName
                                    ? fund?.legalName?.slice(0, 25)
                                    : fund?.sName?.slice(0, 25)}
                                  ...
                                </h2>

                                <p
                                  className={`font-semibold leading-5 text-sm text-secondary-600 text-nowrap`}
                                >
                                  {fund?.threeYrRet.toFixed(2)}%{' '}
                                  <span className='text-xs'>(3Y)</span>
                                </p>
                              </div>
                              <p className='text-xs text-black-400 font-medium leading-4'>
                                Starts at{' '}
                                <span className='text-xs text-black-500 font-semibold'>
                                  ₹ {toCurrency(fund?.minInvt)}
                                </span>
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}

                {!debouncedSearchTerm && (
                  <div className='mt-5'>
                    <h2 className='font-semibold text-primary-700 leading-6 tracking-[-4%]'>
                      Recently viewed funds
                    </h2>
                    <div>
                      {fabStarFunds.state === 'hasValue' &&
                        fabStarFunds?.contents?.data?.map((fund) => (
                          <div
                            className='cursor-pointer flex gap-x-2 items-center py-4 border-b-[1px] border-primary-50'
                            key={fund?.amcCode}
                            onClick={() => handleFabStarNavigate(fund)}
                          >
                            <img
                              src={`${env.MF_LOGOS_URL}/${fund?.amcCode}.png`}
                              className='h-9 w-9 rounded-sm'
                            />

                            <div className='w-full'>
                              <div className='flex justify-between'>
                                <h2 className='font-semibold leading-5 text-sm text-black-500 text-nowrap'>
                                  {fund?.legalName
                                    ? fund?.legalName?.slice(0, 25)
                                    : fund?.sName?.slice(0, 25)}
                                  ...
                                </h2>

                                <p
                                  className={`font-semibold leading-5 text-sm text-secondary-600 text-nowrap`}
                                >
                                  {fund?.threeYrRet.toFixed(2)}%{' '}
                                  <span className='text-xs'>(3Y)</span>
                                </p>
                              </div>
                              <p className='text-xs text-black-400 font-medium leading-4'>
                                Starts at{' '}
                                <span className='text-xs text-black-500 font-semibold'>
                                  ₹ {toCurrency(fund?.minInvt)}
                                </span>
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}

                {searchLoading && (
                  <div className='max-h-[50vh] flex justify-center items-center text-primary-500'>
                    Loading...
                  </div>
                )}

                {debouncedSearchTerm && !searchLoading && searchData.length === 0 && (
                  <div className='max-h-[50vh] flex justify-center items-center text-primary-500'>
                    No funds available
                  </div>
                )}

                {debouncedSearchTerm && (
                  <div className='max-h-[70vh] overflow-y-scroll'>
                    {searchData.map((fund) => (
                      <div
                        className='cursor-pointer flex gap-x-2 items-center py-4 border-b-[1px] border-primary-50'
                        key={fund?.amcCode}
                        onClick={() => handleFabStarNavigate(fund)}
                      >
                        <img
                          src={`${env.MF_LOGOS_URL}/${fund?.amcCode}.png`}
                          className='h-9 w-9 rounded-sm'
                        />

                        <div className='w-full'>
                          <div className='flex justify-between'>
                            <h2 className='font-semibold leading-5 text-sm text-black-500 text-nowrap'>
                              {fund?.legalName
                                ? fund?.legalName?.slice(0, 25)
                                : fund?.sName?.slice(0, 25)}
                              ...
                            </h2>

                            <p
                              className={`font-semibold leading-5 text-sm text-secondary-600 text-nowrap`}
                            >
                              {fund?.threeYrRet.toFixed(2)}% <span className='text-xs'>(3Y)</span>
                            </p>
                          </div>
                          <p className='text-xs text-black-400 font-medium leading-4'>
                            Starts at{' '}
                            <span className='text-xs text-black-500 font-semibold'>
                              ₹ {toCurrency(fund?.minInvt)}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </DialogContent>
            </Dialog>

            <div className='h-auto p-5 mx-auto w-full'>
              {isDematUser() && (
                <div className='flex items-center gap-x-2 overflow-x-scroll hide-scrollbar w-full'>
                  <AnimatePresence className='overflow-x-scroll'>
                    <>
                      <Tab to='/dashboard/mutual-funds'>Explore</Tab>
                      <Tab to='/dashboard/mutual-funds/investments'>My Investments</Tab>
                      <Tab to='/dashboard/mutual-funds/sips'>SIPs</Tab>
                      <Tab to='/dashboard/mutual-funds/orders'>Orders</Tab>
                    </>
                  </AnimatePresence>
                </div>
              )}

              {location.pathname != '/dashboard/mutual-funds/orders' &&
                (mfHolding?.state === 'hasValue' && mfHolding?.contents?.mainData?.length > 0 ? (
                  <>
                    <div className='flex flex-col gap-y-6 justify-center items-center mt-12'>
                      <div>
                        <h3 className='text-center text-white text-[32px] font-AlfaSlabOne tracking-[4%] leading-[42px]'>
                          ₹{' '}
                          {(() => {
                            const totalHolding = (
                              mfHolding?.contents?.mainData?.reduce(
                                (sum, item) => sum + item.holding,
                                0,
                              ) || 0
                            ).toFixed(2)
                            const [integerPart, decimalPart] = totalHolding.split('.')

                            return (
                              <>
                                {toCurrency(integerPart)}
                                <span className='text-[20px] align-top'>.{decimalPart}</span>
                              </>
                            )
                          })()}
                        </h3>
                        <p className='text-primary-100 text-sm text-center font-medium'>
                          Total Current MF Portfolio
                        </p>
                      </div>

                      <div className='flex justify-between w-full items-center'>
                        <div>
                          <p className='text-start text-white font-semibold'>
                            ₹{' '}
                            {toCurrency(
                              mfHolding?.contents?.mainData
                                ?.reduce((sum, item) => sum + item.netInvestedAmt, 0)
                                ?.toFixed(2),
                            )}
                          </p>
                          <p className='text-primary-100 text-sm text-start font-medium'>
                            Total Invested Amount
                          </p>
                        </div>
                        <div>
                          <p className={`text-right text-white font-semibold'`}>
                            {getReturns(mfHolding)}%
                          </p>
                          <p className='text-primary-100 text-sm text-right font-medium'>Returns</p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <h1 className='font-semibold text-[32px] text-white text-center my-6'>
                      Mutual Funds
                    </h1>
                    <div className='max-w-[552px] mx-auto'>
                      <div
                        className='relative flex items-center rounded-full bg-primary-700 shadow-sm'
                        onClick={() => setIsOpen(true)}
                      >
                        <Search className='absolute left-4 h-[14px] w-[14px] text-primary-200' />

                        <input
                          placeholder='Search for funds'
                          className='pl-12 pr-24 flex h-10 w-full rounded-full border-0 border-primary-50 bg-transparent text-sm font-normal ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-primary-200 placeholder:font-normal placeholder:text-sm placeholder:leading-5 focus-visible:outline-none focus-visible:ring-0 disabled:cursor-not-allowed disabled:opacity-50'
                        />
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </div>

          <Outlet />

          {!shouldHideParentContent && (
            <div className='bg-white'>
              <div className='max-w-[1128px] py-12 mx-auto'>
                <div>
                  <Tabs defaultValue='Debt' value={tabValue} onValueChange={setTabValue}>
                    <TabsList className='mx-auto w-full'>
                      <TabsTrigger
                        className={`text-primary-200 text-2xl font-light flex flex-col ${
                          tabValue === 'Debt' && 'text-primary-700 font-semibold'
                        }`}
                        value='Debt'
                        onClick={() => clearAllFilter('Debt')}
                      >
                        Debt {tabValue === 'Debt' && 'Funds'}
                        <span
                          className={`h-2 w-2 rounded-full ${
                            tabValue === 'Debt' ? 'bg-primary-700' : 'bg-transparent'
                          }`}
                        ></span>
                      </TabsTrigger>
                      <TabsTrigger
                        className={`text-primary-200 text-2xl font-light flex flex-col ${
                          tabValue === 'Equity' && 'text-primary-700 font-semibold'
                        }`}
                        value='Equity'
                        onClick={() => clearAllFilter('Equity')}
                      >
                        Equity {tabValue === 'Equity' && 'Funds'}
                        <span
                          className={`h-2 w-2 rounded-full  ${
                            tabValue === 'Equity' ? 'bg-primary-700' : 'bg-transparent'
                          }`}
                        ></span>
                      </TabsTrigger>
                      <TabsTrigger
                        className={`text-primary-200 text-2xl font-light flex flex-col ${
                          tabValue === 'Hybrid' && 'text-primary-700 font-semibold'
                        }`}
                        value='Hybrid'
                        onClick={() => clearAllFilter('Hybrid')}
                      >
                        Hybrid {tabValue === 'Hybrid' && 'Funds'}
                        <span
                          className={`h-2 w-2 rounded-full  ${
                            tabValue === 'Hybrid' ? 'bg-primary-700' : 'bg-transparent'
                          }`}
                        ></span>
                      </TabsTrigger>
                    </TabsList>

                    <AnimatePresence mode='wait'>
                      <TabsContent value='Debt'>
                        <AnimatedTabContent>
                          <DebtFunds
                            searchValue={''}
                            mutualFundsData={mutualFundsData}
                            totalPages={totalPages}
                            isLoading={isLoading}
                          />
                        </AnimatedTabContent>
                      </TabsContent>
                    </AnimatePresence>

                    <AnimatePresence mode='wait'>
                      <TabsContent value='Equity'>
                        <AnimatedTabContent>
                          <EquityFunds
                            searchValue={''}
                            mutualFundsData={mutualFundsData}
                            totalPages={totalPages}
                            isLoading={isLoading}
                          />
                        </AnimatedTabContent>
                      </TabsContent>
                    </AnimatePresence>

                    <AnimatePresence mode='wait'>
                      <TabsContent value='Hybrid'>
                        <AnimatedTabContent>
                          <HybridFunds
                            searchValue={''}
                            mutualFundsData={mutualFundsData}
                            totalPages={totalPages}
                            isLoading={isLoading}
                          />
                        </AnimatedTabContent>
                      </TabsContent>
                    </AnimatePresence>
                  </Tabs>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default MutualFunds
