import { create } from 'zustand'

export const useErrorPopupStore = create((set) => ({
  isOpen: false,
  title: '',
  description: '',
  showErrorPopup: (title, description, onClose) =>
    set({ isOpen: true, title, description, onClose }),
  hideErrorPopup: () => set({ isOpen: false, title: '', description: '' }),
}))
