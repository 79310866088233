import { selector } from 'recoil'
import { get } from '../http/restservice'

const MFTimeBasedBaskets = selector({
  key: 'MFTimeBasedBaskets',
  get: async () => {
    const response = await get(`/mutualfundservice/api/basket`)

    if (response.isError) {
      return []
    }

    return response
  },
})

const MFBasketOrders = selector({
  key: 'MFBasketOrders',
  get: async () => {
    const response = await get(`/mutualfundservice/api/basket/orderHistory`)

    if (response.isError) {
      return []
    }

    return response
  },
})

const MFBasketHoldings = selector({
  key: 'MFBasketHoldings',
  get: async () => {
    const response = await get(`/mutualfundservice/api/basket/holdings`)

    if (response.isError) {
      return []
    }

    return response
  },
})

const MFUserBaskets = selector({
  key: 'MFUserBaskets',
  get: async () => {
    const response = await get(`/mutualfundservice/api/basket/getAllUserCreatedBasket`)

    if (response.isError) {
      return []
    }

    return response
  },
})

export { MFTimeBasedBaskets, MFBasketOrders, MFBasketHoldings, MFUserBaskets }
