// import { useRecoilValueLoadable } from 'recoil'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '../../../Components/ui/carousel'
import { useNavigate } from 'react-router-dom'
// import { InvestedPlansSelector } from '../../../store/InvestedPlansStore'
import { ChevronRight } from 'lucide-react'
import { useState } from 'react'
import actionCarouselData from './ActionCarouselData'

const ActionCarousel = ({ plans, mfData, receivedData }) => {
  const navigate = useNavigate()
  // const { state: investedPlansState, contents: investedPlans } =
  //   useRecoilValueLoadable(InvestedPlansSelector)

  const [isHover, setIsHover] = useState(null)

  // const getInvestedPlanCount = (type) => {
  //   if (investedPlansState === 'hasValue' && investedPlans && investedPlans.length > 0) {
  //     const filteredPlans = investedPlans.filter((plan) => plan.planType === type)
  //     return filteredPlans.length + 1
  //   } else {
  //     return 1
  //   }
  // }

  return (
    <Carousel
      opts={{
        align: 'start',
        slideStep: 4,
      }}
    >
      <CarouselContent className='mt-8'>
        {actionCarouselData.map((el, index) => (
          <CarouselItem
            key={index}
            className='basis-1/2 md:basis-1/4 lg:basis-1/6 2xl:basis-1/7'
            onClick={() =>
              navigate(el.link, {
                state: { plans, mfData, receivedData },
              })
            }
          >
            <div
              className='cursor-pointer rounded-2xl flex justify-center items-center p-4 w-40 transition-shadow duration-300'
              style={{
                boxShadow: `${isHover === index ? '0px 4px 16px 0px #242F4E1F' : ''}`,
                outline: `${isHover === index ? '1px solid #D3DBEC' : 'none'}`,
              }}
              onMouseEnter={() => setIsHover(index)}
              onMouseLeave={() => setIsHover(null)}
            >
              <div className='rounded-2xl max-w-36 border-none'>
                <div className='aspect-square grid gap-4'>
                  <div className='flex justify-between'>
                    <img src={el.icon} className='rounded-full w-12 h-12' />
                    {isHover === index && <ChevronRight className='text-black-300 h-6 w-6' />}
                  </div>
                  <div>
                    <span className='font-semibold text-black-500 tracking-[-4%] text-base font-DMSans'>
                      {el.title}
                    </span>
                    <p className='text-black-400 text-sm text-wrap font-normal font-DMSans'>
                      {el.text}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext />
    </Carousel>
  )
}

export default ActionCarousel
