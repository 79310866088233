import { useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { MutualFundsSIPs } from '../../store/MFStore'
import { useEffect, useState } from 'react'
import { Button } from '../../Components/ui/button'
import MFOrdersSkeleton from '../../Components/SkeletonLoaders/MFOrdersSkeleton'
import NoBond from '../../assets/images/nodata.svg'
import { useNavigate } from 'react-router-dom'
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from '../../Components/ui/accordian'
import env from '../../env/env'
import { toCurrency } from '../../utils/CurrencyUtil'
import {
  addNumberSuffix,
  capitalizeWords,
  FinancialGoalsReversed,
  monthShortNames,
} from '../../utils/Util'
import { Card, CardContent, CardHeader, CardTitle } from '../../Components/ui/card'
import IconsSelector from '../../store/IconsStore'

const BasketSIPs = () => {
  const sips = useRecoilValueLoadable(MutualFundsSIPs)
  const [goals, setGoals] = useState(null)

  const iconsList = useRecoilValue(IconsSelector)

  useEffect(() => {
    if (sips?.state === 'hasValue' && sips?.contents?.data?.length > 0) {
      const groupedSIPs = sips?.contents?.data?.reduce((acc, sip) => {
        if (sip.customerBasketInvestmentId) {
          acc[sip.customerBasketInvestmentId] = acc[sip.customerBasketInvestmentId] || []
          acc[sip.customerBasketInvestmentId].push(sip)
        }
        return acc
      }, {})
      console.log(groupedSIPs)
      setGoals(groupedSIPs)
    }
  }, [sips])

  const navigate = useNavigate()
  //   const refreshSIPs = useRecoilRefresher_UNSTABLE(MutualFundsSIPs)

  //   useEffect(() => refreshSIPs(), [])

  const calculateParcentage = (part, total) => {
    const result = (part / total) * 100
    return result ? result.toFixed(2) : 0
  }

  const getTotalAmount = (sips) => {
    const sipTotal = sips.reduce((acc, sip) => acc + Number(sip.installmentAmt), 0) || 0

    return sipTotal
  }

  const getDate = (dateString) => {
    if (!dateString) return null // Handle null/undefined cases

    // Determine the correct delimiter
    const delimiter = dateString.includes('/') ? '/' : '-'

    return dateString.split(delimiter)[0]
  }

  const getMonth = (dateString) => {
    if (!dateString) return null // Handle null/undefined cases

    // Determine the correct delimiter
    const delimiter = dateString.includes('/') ? '/' : '-'

    return dateString.split(delimiter)[1]
  }

  const getYear = (dateString) => {
    if (!dateString) return null // Handle null/undefined cases

    // Determine the correct delimiter
    const delimiter = dateString.includes('/') ? '/' : '-'

    return dateString.split(delimiter)[2]
  }

  if (sips.contents.length === 0) {
    return (
      <div className='bg-white flex items-center justify-center'>
        <Card className='w-full max-w-sm text-center border-none shadow-none font-DMSans'>
          <CardHeader className='flex flex-col gap-y-6 justify-center items-center'>
            <img
              className='h-14 w-14 mx-auto'
              src={`${env.SVGS}/error-icon.svg`}
              alt='Error-Icon'
            />
            <CardTitle className='font-semibold text-base'>Oops! Something Went Wrong</CardTitle>
          </CardHeader>
          <CardContent className='flex flex-col gap-y-6'>
            <p className='text-sm leading-5 text-black-400 mb-6'>
              We're sorry, but it seems there was an issue connecting to our server. Please check
              your internet connection or try refreshing the page. If the problem persists, try
              again later.
            </p>

            {/* <div className='flex gap-x-2 justify-between'>
              <Button
                className='w-full'
                variant='fabitsWhite'
                onClick={() => window.location.reload()}
              >
                <Refresh className='mr-2 h-4 w-4 text-black-300' /> Refresh
              </Button>

              <Link className='flex-1' to='/dashboard/mutual-funds'>
                <Button className='w-full'>
                  View Plans
                  <MoveRight className='ml-2 h-4 w-4' />
                </Button>
              </Link>
            </div> */}
          </CardContent>
        </Card>
      </div>
    )
  }

  switch (sips.state) {
    case 'loading':
      return (
        <div className='max-w-[1128px] mx-auto'>
          <MFOrdersSkeleton />
        </div>
      )

    case 'hasValue':
      return (
        <div className='bg-white min-h-screen'>
          {sips?.contents?.message?.toLowerCase() === 'no sip found for the specified user.' ? (
            <div
              className='py-12'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '12px',
              }}
            >
              <img src={NoBond} width={400} alt='No Bond' />

              <div
                style={{
                  // width: '454px',
                  gap: '4px',
                  textAlign: 'center',
                }}
              >
                <p
                  style={{
                    fontSize: '20px',
                    fontWeight: 600,
                    lineHeight: '28px',
                    letterSpacing: '-0.04em',
                    fontFamily: 'DM Sans',
                    marginTop: '30px',
                    marginBottom: '4px',
                    color: '#2D2D2D',
                  }}
                >
                  It seems you have no SIPs
                </p>

                <p
                  style={{
                    fontFamily: 'DM Sans',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    marginBottom: '12px',
                    color: 'black-300',
                  }}
                >
                  Get started with investing today. You can either invest in a plan or explore our
                  custom funds{' '}
                </p>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '34px',
                    justifyContent: 'center',
                    gap: '16px',
                  }}
                >
                  <div
                    style={{
                      width: '149px',
                      height: '42px',
                      padding: '10px 11px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',

                      gap: '8px',
                      borderRadius: '8px',
                      border: '1px solid #D3DBEC',
                      boxShadow: '0px 4px 4px 0px #41558D0A', // Added box shadow
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/dashboard/plans')}
                  >
                    <Button
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontFamily: 'DM Sans',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        textAlign: 'center',
                        color: '#41558D',
                        background: 'transparent',
                        boxShadow: 'none',
                        padding: 0,
                        textTransform: 'none',
                      }}
                    >
                      Plan a Goal
                    </Button>
                  </div>
                  <div onClick={() => navigate('/dashboard/mutual-funds')}>
                    <Button
                      sx={{
                        // width: '149px',
                        height: '42px',
                        padding: '5px 18px',
                        gap: '8px',
                        borderRadius: '8px',
                        background: '#41558D',
                        fontFamily: 'DM Sans',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        textAlign: 'center',
                        color: '#FFFFFF',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: '#37457A',
                        },
                      }}
                    >
                      Discover Top Funds
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='max-w-[1128px] px-4 lg:px-0 md:pt-12 mx-auto'>
              <div className='hidden md:flex justify-between bg-[#F4F7FE] rounded-lg pl-14'>
                <div className='text-primary-400 text-sm font-medium py-3'>Goal Name</div>

                <div className='text-primary-400 text-sm font-medium py-3 px-4'>Amount</div>
              </div>
              {goals && Object.keys(goals).length > 0 && (
                <Accordion type='single' collapsible className='w-full'>
                  {Object.entries(goals).map(([basketId, sips]) => (
                    <AccordionItem value={basketId} key={basketId}>
                      <AccordionTrigger className='hover:no-underline'>
                        <div className='flex justify-between items-center w-full'>
                          <div className='w-full'>
                            <div className='w-full flex md:justify-between items-start md:items-center gap-x-2'>
                              <img
                                src={
                                  iconsList['light' + FinancialGoalsReversed[sips[0]?.planType]]
                                    ? iconsList['light' + FinancialGoalsReversed[sips[0]?.planType]]
                                    : `${env.SVGS}/basket.svg`
                                }
                                className='h-12 w-12 rounded-[8px] mt-1'
                              />
                              <div className='w-full'>
                                <div className='flex justify-between gap-x-4 items-center'>
                                  <p className='font-semibold text-gray-900 text-wrap leading-6 flex items-center gap-x-2'>
                                    <span className='truncate whitespace-nowrap overflow-hidden max-w-[200px] md:max-w-max'>
                                      {' '}
                                      {sips[0]?.customerBasketName}
                                    </span>
                                    <span
                                      className={`text-sm ${
                                        capitalizeWords(sips[0]?.sipStatus) === 'Active'
                                          ? 'text-secondary-500 bg-secondary-50 rounded-full px-2'
                                          : capitalizeWords(sips[0]?.sipStatus) === 'Cancelled'
                                          ? 'text-error-500 bg-error-50 rounded-full px-2'
                                          : 'text-warning-500 bg-warning-50 rounded-full px-2'
                                      }`}
                                    >
                                      {capitalizeWords(sips[0]?.sipStatus)}
                                    </span>
                                  </p>
                                </div>

                                {/* <p className='text-xs text-black-400'>
                                  Mandate Id: {sips[0]?.mandateId}
                                </p> */}

                                <p className='text-sm text-black-400'>
                                  Repeats on the{' '}
                                  <strong>{addNumberSuffix(getDate(sips[0]?.sipStartDate))}</strong>{' '}
                                  of every month
                                </p>

                                <div className='flex justify-between items-center'>
                                  <div className='flex gap-x-1 text-xs items-center text-black-400'>
                                    Start Date -
                                    <p className='flex justify-start text-xs items-center gap-x-1 text-center font-DMSans'>
                                      <span className='font-semibold'>
                                        {getDate(sips[0]?.sipStartDate)}
                                      </span>
                                      <span className='font-semibold '>
                                        {monthShortNames[getMonth(sips[0]?.sipStartDate) - 1]}
                                      </span>
                                      <span className='font-semibold '>
                                        {getYear(sips[0]?.sipStartDate)}
                                      </span>
                                    </p>
                                  </div>

                                  <p className='text-nowrap pr-2 md:hidden'>
                                    ₹ {toCurrency(getTotalAmount(sips))}
                                  </p>
                                </div>
                              </div>

                              <div className='hidden md:flex'>
                                <p className='text-nowrap pr-2'>
                                  ₹ {toCurrency(getTotalAmount(sips))}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent>
                        <div>
                          {sips?.map((sip, index) => {
                            return (
                              <div
                                className={`p-4 flex items-center lg:items-start w-full`}
                                key={index}
                              >
                                <div className='flex justify-between w-full'>
                                  <div className='flex justify-between items-center w-full'>
                                    <div className='w-full flex justify-between items-center gap-x-2'>
                                      <div className='flex gap-x-4 items-center md:w-[50%]'>
                                        <img
                                          src={`${env.MF_LOGOS_URL}/${sip?.amcCode}.png`}
                                          className='h-12 w-12 rounded-[8px] mt-1'
                                        />
                                        <div className='w-full md:w-auto'>
                                          <p className='font-semibold text-gray-900  md:max-w-full text-wrap leading-6'>
                                            {sip?.sname ? sip?.sname : sip?.amfiName}
                                          </p>
                                        </div>
                                      </div>

                                      {/* {sip?.installmentAmt && (
                                        <p className='text-sm font-medium text-black-400'>
                                          ₹ {toCurrency(sip.installmentAmt)}
                                        </p>
                                      )} */}

                                      {sip?.installmentAmt && (
                                        <p className='text-nowrap text-sm font-medium text-black-400'>
                                          {calculateParcentage(
                                            sip.installmentAmt,
                                            getTotalAmount(sips),
                                          )}{' '}
                                          %
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  ))}
                </Accordion>
              )}
            </div>
          )}
        </div>
      )

    case 'hasError':
      return (
        <div className='bg-white flex items-center justify-center'>
          <Card className='w-full max-w-3xl text-center border-none shadow-none font-DMSans'>
            <CardHeader className='flex flex-col gap-y-6 justify-center items-center'>
              <img className='h-14 w-14' src={`${env.SVGS}/error-icon.svg`} alt='Error-Icon' />
              <CardTitle className='font-semibold text-base'>Oops! Something Went Wrong</CardTitle>
            </CardHeader>
            <CardContent className='flex flex-col gap-y-6'>
              <p className='text-sm leading-5 text-black-400 mb-6'>
                We're sorry, but it seems there was an issue connecting to our server. Please check
                your internet connection or try refreshing the page. If the problem persists, try
                again later.
              </p>
            </CardContent>
          </Card>
        </div>
      )

    default:
      return null
  }
}

export default BasketSIPs
