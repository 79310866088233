/* eslint-disable */
import { MoveRight, Plus } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Button } from '../../Components/ui/button'
import { Info, KeyboardArrowDown, KeyboardArrowUp, Refresh } from '@mui/icons-material'
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { Card, CardContent, CardHeader, CardTitle } from '../..//Components/ui/card'
import env from '../../env/env'
import { toCurrency } from '../../utils/CurrencyUtil'
import { Link } from 'react-router-dom'
import { MFBasketOrders } from '../../store/MFBasketsStore'
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../Components/ui/tooltip'
import { Popover, PopoverContent, PopoverTrigger } from '../../Components/ui/popover'
import { capitalizeWords, FinancialGoalsReversed } from '../../utils/Util'
import MFOrdersSkeleton from '../../Components/SkeletonLoaders/MFOrdersSkeleton'
import IconsSelector from '../../store/IconsStore'
import NoBond from '../../assets/images/nodata.svg'

const MFBasketsOrders = ({ showFailedOrders }) => {
  const orders = useRecoilValueLoadable(MFBasketOrders)
  console.log('🚀 ~ MFBasketsOrders ~ orders:', orders)
  const [groupedOrders, setGroupedOrders] = useState({})
  const [failedBasketOrders, setFailedBasketOrders] = useState({})
  const [expandedIndex, setExpandedIndex] = useState(null)
  const [activeIndex, setActiveIndex] = useState(null)
  const refreshOrder = useRecoilRefresher_UNSTABLE(MFBasketOrders)
  const iconsList = useRecoilValue(IconsSelector)

  useEffect(() => {
    refreshOrder()
  }, [])

  const groupByBasketId = (orders) =>
    orders.reduce((acc, { customerBasketInvestmentId, basketStatus, ...order }) => {
      // if (
      //   showFailedOrders ||
      //   basketStatus === 'COMPLETED' ||
      //   basketStatus === 'PARTIAL' ||
      //   basketStatus === 'IN PROGRESS'
      // ) {
      if (!acc[customerBasketInvestmentId]) {
        acc[customerBasketInvestmentId] = []
      }
      acc[customerBasketInvestmentId].push({ basketStatus, ...order })
      // }
      return acc
    }, {})

  const groupByBasketIdFailed = (orders) =>
    orders.reduce((acc, { customerBasketInvestmentId, basketStatus, ...order }) => {
      if (!acc[customerBasketInvestmentId]) {
        acc[customerBasketInvestmentId] = []
      }

      if (basketStatus === 'FAILED') {
        acc[customerBasketInvestmentId].push({ basketStatus, ...order })
      }
      return acc
    }, {})

  useEffect(() => {
    if (orders.state === 'hasValue' && orders?.contents?.orders?.length > 0) {
      setGroupedOrders(groupByBasketId(orders.contents.orders))
      setFailedBasketOrders(groupByBasketIdFailed(orders.contents.orders))
    } else if (orders.state === 'hasError') {
      console.error('Error fetching orders:', orders.contents)
    }
  }, [orders, showFailedOrders])

  const getTotalUnits = (basketOrders) => {
    return basketOrders.reduce((acc, order) => {
      return acc + Number(order.allotedUnits ? order.allotedUnits : 0)
    }, 0)
  }

  const getTotalAmount = (basketOrders) => {
    return basketOrders.reduce((acc, order) => {
      return acc + Number(order.allotedAmt ? order.allotedAmt : 0)
    }, 0)
  }

  const getStatus = (currentStatus) => {
    const statusMapping = {
      allotment: 'Allotted',
      partial: 'Partial',
      failed: 'Failed',
      processed: 'In Progress',
      placed: 'In Progress',
    }

    // Convert currentStatus to lowercase for case-insensitive matching
    const lowerCaseStatus = currentStatus.toLowerCase()

    // Iterate over the keys in statusMapping
    for (const keyword in statusMapping) {
      if (lowerCaseStatus.includes(keyword)) {
        return statusMapping[keyword]
      }
    }

    // Default return value if no keywords are matched
    return 'Unknown Status'
  }

  switch (orders.state) {
    case 'loading':
      return <MFOrdersSkeleton />
    case 'hasValue':
      return (
        <>
          <div className='bg-white min-h-screen py-6'>
            {orders?.contents?.status === 'SUCCESS' && orders?.contents?.orders?.length > 0 && (
              <>
                <div className='lg:mx-auto overflow-hidden'>
                  {orders?.contents?.orders?.length > 0 ? (
                    Object.entries(groupedOrders).map(([key, basketOrders], index) => {
                      let sortedOrders = [...basketOrders].sort(
                        (a, b) => b.customerBasketOrderNo - a.customerBasketOrderNo,
                      )

                      // // IN PROGRESS, FAILED , COMPLETED , PARTIAL

                      // const partialOrders =
                      //   basketOrders?.filter((order) => order.basketStatus === 'PARTIAL') || []

                      // const failedOrders =
                      //   basketOrders?.filter((order) => order.basketStatus === 'FAILED') || []

                      return (
                        <div key={key} className='cursor-pointer w-full'>
                          <div className='py-2 text-sm font-medium text-black-400 mb-4 mt-12 leading-5 px-4 lg:px-0'>
                            <div
                              className='flex justify-between items-center w-full'
                              onClick={() => {
                                setExpandedIndex((prev) => (prev === index ? null : index))
                              }}
                            >
                              <div className='w-full md:w-auto'>
                                <div className='flex md:justify-between items-start md:items-center gap-x-2'>
                                  <img
                                    src={
                                      iconsList[
                                        'light' + FinancialGoalsReversed[basketOrders[0]?.planType]
                                      ]
                                        ? iconsList[
                                            'light' +
                                              FinancialGoalsReversed[basketOrders[0]?.planType]
                                          ]
                                        : `${env.SVGS}/basket.svg`
                                    }
                                    className='h-12 w-12 rounded-[8px] mt-1'
                                  />

                                  <div className='w-full'>
                                    <div className='flex justify-between gap-x-4 items-center'>
                                      <p className='font-semibold text-gray-900  md:max-w-full text-wrap leading-6'>
                                        {basketOrders[0]?.customerBasketName}
                                      </p>

                                      {basketOrders[0]?.buySell && (
                                        <p
                                          className={`hidden md:block text-sm leading-4 px-3 py-1 rounded-full ${
                                            basketOrders[0]?.buySell === 'P'
                                              ? 'bg-secondary-50 text-secondary-600'
                                              : 'bg-error-50 text-error-600'
                                          }`}
                                        >
                                          {basketOrders[0]?.buySell === 'P' ? 'Buy' : 'Sell'}
                                        </p>
                                      )}

                                      <span className='flex-shrink-0 text-black-200 md:hidden'>
                                        {expandedIndex === index ? (
                                          <KeyboardArrowUp
                                            className='flex-shrink-0 text-black-200'
                                            sx={{ fontSize: '24px' }}
                                          />
                                        ) : (
                                          <KeyboardArrowDown
                                            className='flex-shrink-0 text-black-200'
                                            sx={{ fontSize: '24px' }}
                                          />
                                        )}
                                      </span>
                                    </div>

                                    <div className='flex justify-between md:justify-normal gap-x-5'>
                                      <p
                                        className={`text-sm font-medium ${
                                          basketOrders[0]?.basketStatus.toLowerCase() ===
                                          'completed'
                                            ? 'text-secondary-600'
                                            : basketOrders[0]?.basketStatus.toLowerCase() ===
                                              'in progress'
                                            ? 'text-warning-600'
                                            : 'text-error-600'
                                        }`}
                                      >
                                        {/* {failedBasketOrders[key]?.length > 0
                                          ? 'Basket needs attention'
                                          : partialOrders?.length === 0 &&
                                            failedBasketOrders[key]?.length === 0
                                          ? 'Allotted'
                                          : partialOrders?.length > 0
                                          ? 'In Progress'
                                          : failedBasketOrders[key]?.length === basketOrders?.length
                                          ? 'Failed'
                                          : ''} */}
                                        {capitalizeWords(basketOrders[0]?.basketStatus)}
                                      </p>

                                      {/* <p className='hidden md:block text-sm text-black-400 leading-5 font-medium'>
                                        Basket Order ID : {basketOrders[0]?.customerBasketOrderNo}
                                      </p> */}

                                      {basketOrders[0]?.buySell && (
                                        <p
                                          className={`md:hidden text-sm leading-4 px-3 py-1 rounded-full ${
                                            basketOrders[0]?.buySell === 'P'
                                              ? 'bg-secondary-50 text-secondary-600'
                                              : 'bg-error-50 text-error-600'
                                          }`}
                                        >
                                          {basketOrders[0]?.buySell === 'P' ? 'Buy' : 'Sell'}
                                        </p>
                                      )}
                                    </div>

                                    {basketOrders[0]?.basketStatus.toLowerCase() != 'failed' && (
                                      <div className='flex justify-between mt-1 md:hidden'>
                                        {/* <p className='text-sm text-black-400 leading-5 font-medium'>
                                        Basket Order ID : {basketOrders[0]?.customerBasketOrderNo}
                                      </p> */}

                                        {basketOrders[0]?.basketStatus.toLowerCase() ===
                                        'completed' ? (
                                          <p className='text-sm font-medium text-black-400'>
                                            {toCurrency(getTotalUnits(basketOrders))} Units
                                          </p>
                                        ) : (
                                          <>
                                            <Popover>
                                              <PopoverTrigger
                                                className='text-black-400'
                                                onClick={(e) => e.stopPropagation()} // Prevent parent click
                                              >
                                                In progress{' '}
                                                <Info fontSize='10px' className='text-black-100' />
                                              </PopoverTrigger>
                                              <PopoverContent className='bg-black-500 text-center'>
                                                <p className='text-white'>
                                                  Investments could take up to 4 days to reflect in
                                                  your account
                                                </p>
                                              </PopoverContent>
                                            </Popover>
                                          </>
                                        )}

                                        {basketOrders[0]?.basketStatus.toLowerCase() ===
                                        'completed' ? (
                                          <p className='text-sm font-semibold text-black-500'>
                                            ₹ {toCurrency(getTotalAmount(basketOrders))}
                                          </p>
                                        ) : (
                                          <Popover>
                                            <PopoverTrigger
                                              className='text-black-400'
                                              onClick={(e) => e.stopPropagation()} // Prevent parent click
                                            >
                                              In progress{' '}
                                              <Info fontSize='10px' className='text-black-100' />
                                            </PopoverTrigger>
                                            <PopoverContent className='bg-black-500 text-center'>
                                              <p className='text-white'>
                                                Investments could take up to 4 days to reflect in
                                                your account
                                              </p>
                                            </PopoverContent>
                                          </Popover>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className='flex items-center gap-x-4'>
                                {
                                  <div className='hidden md:flex leading-5 flex-shrink-0 text-right w-[100px]'>
                                    {basketOrders[0]?.basketStatus.toLowerCase() === 'completed' ? (
                                      <p className='text-sm font-medium text-black-400'>
                                        {toCurrency(getTotalUnits(basketOrders))} Units
                                      </p>
                                    ) : (
                                      <>
                                        {' '}
                                        {basketOrders[0]?.basketStatus.toLowerCase() !=
                                          'failed' && (
                                          <TooltipProvider delayDuration={0}>
                                            <Tooltip>
                                              <TooltipTrigger className='text-black-400'>
                                                In progress{' '}
                                                <Info fontSize='10px' className='text-black-100' />
                                              </TooltipTrigger>
                                              <TooltipContent className='bg-black-500 max-w-[275px] border-0'>
                                                <p className='text-white'>
                                                  Investments could take up to 4 days to reflect in
                                                  your account
                                                </p>
                                                <TooltipArrow />
                                              </TooltipContent>
                                            </Tooltip>
                                          </TooltipProvider>
                                        )}
                                      </>
                                    )}
                                  </div>
                                }

                                <div className='hidden md:flex justify-end items-center gap-x-1 min-w-[150px]'>
                                  {basketOrders[0]?.basketStatus.toLowerCase() === 'completed' ? (
                                    <p className='font-semibold leading-6 text-black-500'>
                                      ₹ {toCurrency(getTotalAmount(basketOrders))}
                                    </p>
                                  ) : (
                                    <>
                                      {basketOrders[0]?.basketStatus.toLowerCase() != 'failed' && (
                                        <TooltipProvider delayDuration={0}>
                                          <Tooltip>
                                            <TooltipTrigger className='text-black-400'>
                                              In progress{' '}
                                              <Info fontSize='10px' className='text-black-100' />
                                            </TooltipTrigger>
                                            <TooltipContent className='bg-black-500 max-w-[275px] border-0'>
                                              <p className='text-white'>
                                                Investments could take up to 4 days to reflect in
                                                your account
                                              </p>
                                              <TooltipArrow />
                                            </TooltipContent>
                                          </Tooltip>
                                        </TooltipProvider>
                                      )}
                                    </>
                                  )}

                                  {expandedIndex === index ? (
                                    <KeyboardArrowUp
                                      className='flex-shrink-0 text-black-200'
                                      sx={{ fontSize: '24px' }}
                                    />
                                  ) : (
                                    <KeyboardArrowDown
                                      className='flex-shrink-0 text-black-200'
                                      sx={{ fontSize: '24px' }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              className={`text-sm md:pl-6 flex-col justify-between items-start mt-4 border-b text-gray-500 overflow-hidden transition-all duration-300 ease-in-out ${
                                expandedIndex === index
                                  ? 'max-h-max opacity-100 pt-4'
                                  : 'max-h-0 opacity-0'
                              }`}
                            >
                              {sortedOrders?.map((order, index) => {
                                return (
                                  <div
                                    className={`p-4 flex items-center lg:items-start w-full`}
                                    key={index}
                                  >
                                    <div className='flex justify-between w-full'>
                                      <div className='flex justify-between items-center w-full'>
                                        <div className='w-full md:w-auto'>
                                          {(index === 0 ||
                                            order.customerBasketOrderNo !==
                                              sortedOrders[index - 1].customerBasketOrderNo) && (
                                            <p className='text-black-400 text-sm mb-1'>
                                              Order #{order.customerBasketOrderNo}
                                            </p>
                                          )}

                                          <div className='flex justify-between items-start md:items-center gap-x-2'>
                                            <img
                                              src={`${env.MF_LOGOS_URL}/${order?.amcCode}.png`}
                                              className='h-12 w-12 rounded-[8px] mt-1'
                                            />
                                            <div className='w-full md:w-auto'>
                                              <p className='font-semibold text-gray-900  md:max-w-full text-wrap leading-6'>
                                                {order?.legalName
                                                  ? order?.legalName
                                                  : order?.schemeName}
                                              </p>

                                              <div className='flex justify-between md:justify-normal w-full gap-x-5'>
                                                <p
                                                  className={`text-sm font-medium ${
                                                    getStatus(order.currentStatus) === 'Failed'
                                                      ? 'text-error-600'
                                                      : getStatus(order.currentStatus) ===
                                                        'Allotted'
                                                      ? 'text-secondary-600'
                                                      : 'text-warning-600'
                                                  }`}
                                                >
                                                  {getStatus(order.currentStatus)}
                                                </p>

                                                <p className='hidden md:block text-sm text-black-400 leading-5 font-medium'>
                                                  Order ID : {order?.orderNumber}
                                                </p>

                                                {order?.buySell && (
                                                  <p
                                                    className={`md:hidden text-sm leading-4 px-3 py-1 rounded-full ${
                                                      order?.buySell === 'P'
                                                        ? 'bg-secondary-50 text-secondary-600'
                                                        : 'bg-error-50 text-error-600'
                                                    }`}
                                                  >
                                                    {order?.buySell === 'P' ? 'Buy' : 'Sell'}
                                                  </p>
                                                )}
                                              </div>

                                              <div className='flex md:hidden justify-between items-center gap-x-2'>
                                                {order.allotedUnits && (
                                                  <p className='text-sm font-medium text-black-400'>
                                                    {order.allotedUnits
                                                      ? toCurrency(order.allotedUnits)
                                                      : null}{' '}
                                                    Units
                                                  </p>
                                                )}

                                                {order.allotedAmt && (
                                                  <p className='font-semibold leading-6 text-black-500'>
                                                    ₹{' '}
                                                    {order.allotedAmt
                                                      ? toCurrency(order.allotedAmt)
                                                      : null}
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className='flex items-center gap-x-2'>
                                          <div className='hidden md:flex justify-center leading-5 flex-shrink-0 text-right min-w-[100px] pr-2'>
                                            {order.allotedUnits && (
                                              <p className='text-sm font-medium text-black-400'>
                                                {order.allotedUnits
                                                  ? toCurrency(order.allotedUnits)
                                                  : null}{' '}
                                                Units
                                              </p>
                                            )}
                                          </div>

                                          <div className='hidden md:flex justify-end items-center gap-x-1 min-w-[150px] pr-4'>
                                            {order.allotedAmt && (
                                              <p className='font-semibold leading-6 text-black-500'>
                                                ₹{' '}
                                                {order.allotedAmt
                                                  ? toCurrency(order.allotedAmt)
                                                  : null}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <Boilerplate
                      state={'You have no orders in progress at the moment.'}
                      desc={
                        'It seems like you haven’t started any new orders yet. Once you place an order, it will appear here as it progresses through the system.'
                      }
                    />
                  )}
                </div>
              </>
            )}

            {orders?.state === 'hasValue' && orders?.contents?.length === 0 && (
              <div className='bg-white flex items-center justify-center'>
                <Card className='w-full max-w-2xl text-center border-none shadow-none'>
                  <CardHeader className='flex flex-col justify-center items-center space-y-2'>
                    <img src={NoBond} width={400} alt='No Bond' />
                    <CardTitle className='text-xl'>You haven’t set any goals yet</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className='text-muted-foreground mb-6'>
                      Take the first step towards your financial future by setting up a goal today.
                    </p>
                    <Link to='/dashboard/plans'>
                      <Button className='w-full'>
                        <Plus className='mr-2 h-4 w-4' /> Create Goal
                      </Button>
                    </Link>
                  </CardContent>
                </Card>
              </div>
            )}

            {orders?.contents?.status === 'FAILURE' && (
              <div className='bg-white flex items-center justify-center'>
                <Card className='w-full max-w-sm text-center border-none shadow-none font-DMSans'>
                  <CardHeader className='flex flex-col gap-y-6 justify-center items-center'>
                    <img
                      className='h-14 w-14 mx-auto'
                      src={`${env.SVGS}/error-icon.svg`}
                      alt='Error-Icon'
                    />
                    <CardTitle className='font-semibold text-base'>
                      Oops! Something Went Wrong
                    </CardTitle>
                  </CardHeader>
                  <CardContent className='flex flex-col gap-y-6'>
                    <p className='text-sm leading-5 text-black-400 mb-6'>
                      We're sorry, but it seems there was an issue connecting to our server. Please
                      check your internet connection or try refreshing the page. If the problem
                      persists, try again later.
                    </p>

                    <div className='flex gap-x-2 justify-between'>
                      <Button
                        className='w-full'
                        variant='fabitsWhite'
                        onClick={() => window.location.reload()}
                      >
                        <Refresh className='mr-2 h-4 w-4 text-black-300' /> Refresh
                      </Button>

                      <Link className='flex-1' to='/dashboard/mutual-funds'>
                        <Button className='w-full'>
                          View Funds
                          <MoveRight className='ml-2 h-4 w-4' />
                        </Button>
                      </Link>
                    </div>
                  </CardContent>
                </Card>
              </div>
            )}
          </div>
        </>
      )

    case 'hasError':
      return (
        <div className='bg-white flex items-center justify-center'>
          <Card className='w-full max-w-3xl text-center border-none shadow-none font-DMSans'>
            <CardHeader className='flex flex-col gap-y-6 justify-center items-center'>
              <img className='h-14 w-14' src={`${env.SVGS}/error-icon.svg`} alt='Error-Icon' />
              <CardTitle className='font-semibold text-base'>Oops! Something Went Wrong</CardTitle>
            </CardHeader>
            <CardContent className='flex flex-col gap-y-6'>
              <p className='text-sm leading-5 text-black-400 mb-6'>
                We're sorry, but it seems there was an issue connecting to our server. Please check
                your internet connection or try refreshing the page. If the problem persists, try
                again later.
              </p>
            </CardContent>
          </Card>
        </div>
      )

    default:
      return null
  }
}

export const Boilerplate = ({ state, desc }) => {
  return (
    <div className='h-3/5 flex items-center justify-center p-4'>
      <Card className='w-full max-w-sm text-center border-none shadow-none'>
        <CardHeader>
          <img src={`${env.SVGS}/error-icon.svg`} className='mx-auto' />
          <CardTitle className='text-xl'>{state}</CardTitle>
        </CardHeader>
        <CardContent>
          <p className='text-muted-foreground mb-6'>{desc}</p>
          {/* <Link to='/dashboard/mutual-funds'>
            <Button className='w-full'>
              <Plus className='mr-2 h-4 w-4' /> Create SIP
            </Button>
          </Link> */}
        </CardContent>
      </Card>
    </div>
  )
}

export default MFBasketsOrders
