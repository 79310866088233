import ActionCarousel from './actionCarousel/ActionCarousel'
import { useEffect, useState } from 'react'
import { Grid, Box, Typography, useTheme } from '@mui/material'
import { InvestedPlansSelector } from '../../store/InvestedPlansStore'
import { useRecoilRefresher_UNSTABLE, useSetRecoilState, useRecoilValue } from 'recoil'
import { useThemeMode } from '../../Provider/ThemeContextProvider'
import { toCurrency } from '../../utils/CurrencyUtil'
import { useLocation } from 'react-router-dom'
import { messageModalState } from '../../store/Feedback'
import { deleteReq, get } from '../../http/restservice'
import IconsSelector from '../../store/IconsStore'
import { EllipsisVertical } from 'lucide-react'
import { Separator } from '../../Components/ui/separator'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../Components/ui/alert-dialog'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '../../Components/ui/menubar'
import ActionNotification from './ActionNotification'
import env from '../../env/env'

const AddPlan = () => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const { darkMode } = useThemeMode()
  // const { state, contents: investedPlans } = useRecoilValueLoadable(InvestedPlansSelector)
  const [plans, setPlans] = useState()
  const refreshPlans = useRecoilRefresher_UNSTABLE(InvestedPlansSelector)
  const theme = useTheme()
  const [planToDelete, setPlanToDelete] = useState({})
  const iconsList = useRecoilValue(IconsSelector)
  // const navigate = useNavigate()
  // const [showFilters, setShowFilters] = useState(false)
  // const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const receivedData = location.state?.data
  const [mfData, setMFData] = useState([])

  const fetchAllPlans = async () => {
    const url = `${env.API_URL}adminservice/api/actionPlan/getPlans`
    let customerId = receivedData?.id
    try {
      const response = await get(url, {}, { customerId })
      setPlans(response?.data)
    } catch (error) {
      console.log(error, 'dsdfdsd')
    }
  }

  const userMFBasket = async () => {
    const url = `${env.API_URL}adminservice/api/actionPlan/getUserMFBaskets`
    let clientCode = receivedData?.uid?.split('-')[0]?.toUpperCase()
    try {
      const response = await get(url, {}, { clientCode })
      setMFData(response?.data?.data)
      // console.log(response, 'ALL USER MF BASKETS')
    } catch (error) {
      console.log(error, 'fgdgfdfg')
    }
  }

  useEffect(() => {
    fetchAllPlans()
    userMFBasket()
  }, [])

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false)
  }
  const setMessageModalState = useSetRecoilState(messageModalState)
  // const [planStatus, setPlanStatus] = useState('All')
  // const [sortPlans, setSortPlans] = useState('none')

  // useEffect(() => {
  //   refreshPlans()
  // }, [plans])

  // useEffect(() => {
  //   if (investedPlans && investedPlans.length > 0) {
  //     setPlans(investedPlans)
  //   }
  // }, [investedPlans])

  // const viewEditPlan = (item) => {
  //   if (item.planType === 'CHILDEDUCATION') {
  //     navigate('/educationplan', { state: { item, edit: item.status === 'DRAFT' } })
  //   } else if (item.planType === 'RETIREMENT') {
  //     navigate('/retirementplan', {
  //       state: { item, edit: item.status === 'DRAFT' },
  //     })
  //   } else if (item.planType === 'MARRIAGE') {
  //     navigate('/weddingplan', { state: { item, edit: item.status === 'DRAFT' } })
  //   } else if (
  //     item.planType === 'PURCHASE' ||
  //     item.planType === 'PASSION' ||
  //     item.planType === 'JEWELLERY' ||
  //     item.planType === 'VEHICLE'
  //   ) {
  //     navigate('/purchaseplan', {
  //       state: { item, edit: item.status === 'DRAFT', planName: item.planType },
  //     })
  //   } else if (item.planType === 'HOLIDAY') {
  //     navigate('/vacationplan', { state: { item, edit: item.status === 'DRAFT' } })
  //   } else if (item.planType === 'EMERGENCY') {
  //     navigate('/emergencyplan', { state: { item, edit: item.status === 'DRAFT' } })
  //   } else if (item.planType === 'HOME') {
  //     navigate('/homepurchase', { state: { item, edit: item.status === 'DRAFT' } })
  //   } else if (item.planType === 'POSTRETIREMENTVACATION') {
  //     navigate('/postretirementvacationplan', {
  //       state: { item, edit: item.status === 'DRAFT' },
  //     })
  //   } else if (item.planType === 'PROPERTY') {
  //     navigate('/propertypurchase', {
  //       state: { item, edit: item.status === 'DRAFT', planName: item.planType },
  //     })
  //   }
  // }

  //   const getInvestedPlanCount = (type) => {
  //     if (state === 'hasValue' && investedPlans && investedPlans.length > 0) {
  //       const filteredPlans = investedPlans.filter((plan) => plan.planType === type)
  //       return filteredPlans.length + 1
  //     } else {
  //       return 1
  //     }
  //   }

  //   const statusClickHandler = (item) => {
  //     if (!isDematUser()) {
  //       setMessageModalState({
  //         message: 'Your KYC is incomplete.',
  //         linkMessage:
  //           'Click here to complete your KYC and to enable hassle free, one-click investing.',
  //         severity: 'info',
  //       })
  //       return
  //     }
  //     if (item.status === 'INVESTED') {
  //       //pass plan id
  //       navigate('/portfolio')
  //     } else {
  //       navigate('/invest', {
  //         state: {
  //           plan: item,
  //         },
  //       })
  //     }
  //   }

  //   const planClickHandler = (plan) => {
  //     console.log(plan)
  //     navigate('/holdings', {
  //       state: {
  //         selectedPortfolio: plan,
  //       },
  //     })
  //   }

  const deletePlan = async (plan) => {
    try {
      const response = await deleteReq(`/planservice/api/plan/${plan.id}`)

      const url = `${env.API_URL}adminservice/api/actionPlan/deleteUserMFBasket/${plan.customerMfBasketInvestmentId}`
      const responseTwo = await deleteReq(url)

      console.log({ responseTwo, response }, 'RESPONSE TWO')
      if (response.status === 204) {
        fetchAllPlans()
        refreshPlans()
        setMessageModalState({
          message: 'Plan deleted successfully',
          linkMessage: '',
          severity: 'info',
        })
        // window.location.reload()
      }
    } catch (e) {
      console.log(e)
    }
  }

  // useEffect(() => {
  //   if (planStatus && investedPlans) {
  //     if (planStatus === 'All') {
  //       setPlans(investedPlans)
  //     } else {
  //       const filteredPlans = investedPlans.filter((plan) => plan.status === planStatus)
  //       setPlans(filteredPlans)
  //     }
  //   }
  // }, [planStatus])

  // const sortPlansList = (plansList, sortType) => {
  //   const parseDate = (dateString) => {
  //     const [day, month, year] = dateString.split('/')
  //     return new Date(year, month - 1, day)
  //   }

  //   if (sortType === 'asc') {
  //     return plansList.sort((a, b) => parseDate(a.createdDate) - parseDate(b.createdDate))
  //   } else if (sortType === 'desc') {
  //     return plansList.sort((a, b) => parseDate(b.createdDate) - parseDate(a.createdDate))
  //   } else {
  //     return refreshPlans()
  //   }
  // }
  // useEffect(() => {
  //   if (plans?.length > 0) {
  //     const sortedPlans = sortPlansList([...plans], sortPlans)

  //     setPlans(sortedPlans)
  //   }
  // }, [sortPlans])

  //   useEffect(() => {
  //     clevertap.event.push('Page Viewed', {
  //       Page_name: 'Plans Page',
  //     })
  //   }, [])

  // if (state === 'loading') {
  //   return (
  //     <div className='flex flex-col justify-between h-screen w-screen'>
  //       <Skeleton width='90%' height='20%' animation='wave' />
  //       <Skeleton width='90%' height='20%' animation='wave' />
  //       <Skeleton width='90%' height='20%' animation='wave' />
  //       <Skeleton width='90%' height='20%' animation='wave' />
  //     </div>
  //   )
  // }
  return (
    <>
      <>
        <div className='bg-white w-[320px] mx-auto md:w-[1128px] h-56 box-border rounded-3xl border mt-5'>
          <div className='mb-12 px-8'>
            <ActionCarousel receivedData={receivedData} plans={plans} mfData={mfData} />
          </div>

          <div className='flex justify-between my-3'>
            <p className='underline cursor-pointer' onClick={() => setOpen(true)}>
              Send notification
            </p>
          </div>

          <div className='my-5 bg-white md:rounded-3xl'>
            <div
              className='flex flex-col justify-between md:mb-1 md:mt-0 '
              // style={{ paddingBottom: '0px', paddingLeft: '40px' }}
            >
              <h1
                className='text-xl md:text-2xl font-semibold'
                style={{ letterSpacing: '-0.096px', marginBottom: '10px' }}
              >
                User All Plans
              </h1>
            </div>

            <div className={`justify-center lg:justify-start flex gap-x-3`}>
              <div className={`lg:grid-cols-3 grid grid-cols-1 md:grid-cols-2 gap-6`}>
                {plans &&
                  plans.length > 0 &&
                  plans.map((item, index) => {
                    const table1 = item.results.table
                    let totalInvestementPlanned = 0
                    let totalExpenses = 0
                    let totalPlannedContribution = 0
                    // eslint-disable-next-line no-unused-vars
                    Object.entries(table1).forEach(([key, value]) => {
                      totalExpenses += value.projectedExpenses
                      totalPlannedContribution += value.plannedContribution
                    })

                    if (item.planType === 'RETIREMENT') {
                      totalExpenses = table1['1'].corpus
                    }
                    if (item.planType === 'HOLIDAY' || item.planType === 'CHILDEDUCATION') {
                      totalInvestementPlanned = totalExpenses - totalPlannedContribution
                    } else {
                      totalInvestementPlanned = totalExpenses - (table1['1'].fvOfCurrentValue || 0)
                    }
                    if (totalInvestementPlanned <= 0) {
                      totalInvestementPlanned = 0
                    }

                    const mFStatus = (item) => {
                      return mfData?.find((status) => status.customerBasketInvestmentId === item)
                    }

                    return (
                      <Box
                        key={`investedPlans-${index}`}
                        sx={{
                          stroke: '#D3DBEC',
                          borderRadius: '24px',
                          border: theme.palette.border.primary,
                          fontWeight: '500',
                          background: theme.palette.secondary.main,
                          padding: '24px',
                          transition: 'box-shadow',
                          '&:hover': {
                            boxShadow: '0px 6px 24px 0px rgba(65, 85, 141, 0.12)',
                          },
                        }}
                      >
                        <Box className='flex justify-between'>
                          <div
                            className='w-full'
                            // onClick={() => {
                            //   viewEditPlan(item)
                            // }}
                          >
                            <Box className='flex gap-x-3 items-center'>
                              <div className='h-16 w-16 rounded-full'>
                                <img
                                  className='rounded-md w-16'
                                  src={
                                    darkMode
                                      ? iconsList['dark' + item.planType]
                                      : iconsList['light' + item.planType]
                                  }
                                />
                              </div>

                              <Typography
                                component='label'
                                sx={{
                                  fontSize: '20px',
                                  fontWeight: 600,
                                  letterSpacing: '-0.8px',
                                }}
                                className={`${'cursor-pointer'}`}
                              >
                                {item.planName}
                                {item.createdSource === 'ACTION PLAN' && (
                                  <div className='text-xs p-2 flex justify-center items-center text-green-500 rounded-full bg-green-100'>
                                    Admin Created
                                  </div>
                                )}
                              </Typography>
                            </Box>

                            <Separator className='my-6' />
                          </div>

                          <Menubar>
                            <MenubarMenu className='bg-transparent'>
                              <MenubarTrigger className=' p-1 h-6 w-6 rounded-full hover:bg-[#D3DBEC]'>
                                <EllipsisVertical
                                  fill='#5D5D5D'
                                  className='cursor-pointer hover:text-primary-500'
                                />
                              </MenubarTrigger>

                              <MenubarContent className='rounded-[10px] flex flex-col gap-y-1'>
                                {/* <MenubarItem
                                    onClick={() => {
                                      viewEditPlan(item)
                                    }}
                                    className='py-2 pl-3 text-sm text-black-500 cursor-pointer  hover:bg-blue-100'
                                  >
                                    View Details
                                  </MenubarItem> */}

                                {/* {item.status === 'DRAFT' && (
                                    <MenubarItem
                                      onClick={() => {
                                        viewEditPlan(item)
                                      }}
                                      className='py-2 pl-3 text-sm text-black-500 cursor-pointer hover:bg-blue-100 '
                                    >
                                      Edit Plan
                                    </MenubarItem>
                                  )} */}

                                {item.status === 'DRAFT' && (
                                  <MenubarItem
                                    onClick={() => {
                                      setPlanToDelete(item)
                                      setOpenDeleteDialog(true)
                                    }}
                                    className='py-2 pl-3 text-sm cursor-pointer hover:bg-red-100 hover:text-red-500'
                                  >
                                    Delete
                                  </MenubarItem>
                                )}
                              </MenubarContent>
                            </MenubarMenu>
                          </Menubar>
                        </Box>

                        <Grid
                          container
                          rowGap={4}
                          // onClick={() => {
                          //   viewEditPlan(item)
                          // }}
                          sx={{ cursor: 'pointer' }}
                        >
                          <Grid item xs={6} md={6}>
                            <Typography
                              component='label'
                              sx={{ color: '#727272', fontSize: '14px' }}
                            >
                              Monthly SIP
                            </Typography>
                            <Typography
                              component='div'
                              fontWeight={600}
                              style={{ color: '#2D2D2D' }}
                            >
                              <Typography component='span' className='custom-font'>
                                &#8377;
                              </Typography>
                              {toCurrency(item.monthlySIP)}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <Typography
                              component='label'
                              sx={{ color: '#727272', fontSize: '14px' }}
                            >
                              Goal Amount
                            </Typography>
                            <Typography
                              component='div'
                              fontWeight={600}
                              style={{ color: '#2D2D2D' }}
                            >
                              <Typography component='span' className='custom-font'>
                                &#8377;
                              </Typography>
                              {toCurrency(totalInvestementPlanned)}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <Typography
                              component='label'
                              sx={{ color: '#727272', fontSize: '14px' }}
                            >
                              Goal Year
                            </Typography>
                            <Typography
                              component='div'
                              fontWeight={600}
                              style={{ color: '#2D2D2D' }}
                            >
                              {item.results.targetYear}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            md={6}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'start',
                              alignItems: 'start',
                            }}
                          >
                            <Typography
                              component='label'
                              sx={{ color: '#727272', fontSize: '14px' }}
                              fontWeight={600}
                            >
                              Status
                            </Typography>
                            <Typography
                              component='p'
                              sx={{
                                display: 'inline-block',
                                borderRadius: '24px',
                                color:
                                  item.status === 'INVESTED'
                                    ? '#5B8350'
                                    : item.status === 'PARTIALLY_INVESTED'
                                    ? '#74A766'
                                    : '#D99E16',
                                fontWeight: '600',
                              }}
                            >
                              {item.customerMfBasketInvestmentId !== null &&
                              item.customerMfBasketInvestmentId > 0 ? (
                                mFStatus(item.customerMfBasketInvestmentId) ? (
                                  mFStatus(item.customerMfBasketInvestmentId)?.basketStatus
                                ) : (
                                  'DRAFT'
                                )
                              ) : (
                                <>
                                  {item.status === 'INVESTED'
                                    ? 'Invested'
                                    : item.status === 'PARTIALLY_INVESTED'
                                    ? 'Partially-Invested'
                                    : 'DRAFT'}
                                </>
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <Typography
                              component='label'
                              sx={{ color: '#727272', fontSize: '14px' }}
                            >
                              One Time Investment
                            </Typography>
                            <Typography
                              component='div'
                              fontWeight={600}
                              style={{ color: '#2D2D2D' }}
                            >
                              <Typography component='span' className='custom-font'>
                                &#8377;
                              </Typography>{' '}
                              {item?.lumpsum ? item?.lumpsum : 0}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>

        <AlertDialog open={openDeleteDialog} onOpenChange={handleDeleteDialogClose}>
          <AlertDialogContent className='py-16'>
            <AlertDialogHeader>
              <AlertDialogTitle className='text-black-500 font-DMSans font-semibold'>
                Are you absolutely sure?
              </AlertDialogTitle>
              <AlertDialogDescription className='text-black-400 font-DMSans'>
                Do you want to delete this {planToDelete.planName} ?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel
                onClick={() => {
                  handleDeleteDialogClose()
                }}
              >
                No
              </AlertDialogCancel>
              <AlertDialogAction
                onClick={() => {
                  deletePlan(planToDelete)
                  setPlanToDelete({})
                  setOpenDeleteDialog(false)
                }}
              >
                Yes
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>

      {open && <ActionNotification open={open} setOpen={setOpen} data={receivedData} />}
    </>
  )
}

export default AddPlan
