/* eslint-disable */
import { useState } from 'react'
import { Table, TableHeader, TableRow, TableBody, TableCell, TableHead } from '../ui/table'
import { ArrowDown, ArrowRight, ArrowUp, ChevronRight, PiggyBank, Plus } from 'lucide-react'
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '../ui/card'
import { Button } from '../ui/button'
import { Link, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import env from '../../env/env'
import { useModal } from '../../hooks/use-modal-store'
import { toCurrency } from '../../utils/CurrencyUtil'
import { get } from '../../http/restservice'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../ui/alert-dialog'
import { Separator } from '../ui/separator'
import { sanitizeName } from '../../utils/Util'
import NoBond from '../../assets/images/nodata.svg'

export default function MFIndividualInvestmentsTable({ holdings }) {
  const { onOpen } = useModal()
  const [isHovered, setIsHovered] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const navigate = useNavigate()
  const [alertIsOpen, setAlertIsOpen] = useState(false)

  const handleMFRedirect = async (fundId) => {
    const response = await get(`/mutualfundservice/api/mfData/generalInfo/${fundId}`)

    if (response.status === 'SUCCESS') {
      navigate(`/dashboard/mutual-funds/details`, {
        state: {
          fundDetails: response?.data,
          schemeCode: fundId,
        },
      })
    }
  }

  const handleMFRedeem = async (fund) => {
    const response = await get(`/mutualfundservice/api/mfData/generalInfo/${fund?.schemeCode}`)

    if (response.status === 'SUCCESS') {
      navigate('/dashboard/mutual-funds/redeem/place-order', {
        state: {
          fundDetails: response?.data,
          fund,
        },
      })
    }
  }

  const getReturns = (mfHolding) => {
    const total = mfHolding?.mainData?.reduce((sum, item) => sum + item.holding, 0)
    const invested = mfHolding?.mainData?.reduce((sum, item) => sum + item.netInvestedAmt, 0)

    // Calculate returns in percentage
    const returns = ((total - invested) / invested) * 100

    return returns < 0 ? toCurrency(returns) : toCurrency(returns)
  }

  const calculateParcentage = (invested, returns) => {
    const result = ((returns - invested) / invested) * 100
    return result
  }

  return (
    <>
      {/* Investment Details  */}
      <div className='px-2'>
        <div className='mt-4'>
          {holdings?.mainData?.length > 0 && (
            <div className='w-full lg:max-w-2xl mx-auto px-6 py-4 rounded-xl md:rounded-3xl border text-nowrap bg-[#F4F7FE]'>
              <div className='flex justify-between items-center space-x-6'>
                <div className='space-y-1 flex-1'>
                  <p className='font-semibold text-primary-500 text-lg'>
                    ₹{' '}
                    {toCurrency(
                      holdings?.mainData?.reduce((sum, item) => sum + item.netInvestedAmt, 0) || 0,
                    )}
                  </p>

                  <p className='text-black-400 text-sm'>Invested</p>
                </div>
                <Separator orientation='vertical' className='h-11' />
                <div className='space-y-1 flex-1'>
                  <p className='font-semibold text-lg text-primary-500'>
                    ₹{' '}
                    {toCurrency(
                      holdings?.mainData?.reduce((sum, item) => sum + item.holding, 0) || 0,
                    )}
                  </p>
                  <p className='text-sm text-black-400 flex gap-x-1 items-center'>
                    Current{' '}
                    <span
                      className={`${
                        getReturns(holdings) < 0
                          ? 'bg-error-600 text-error-600'
                          : 'bg-secondary-600 text-secondary-600'
                      } font-semibold  rounded-[50%] h-4 w-4 flex justify-center items-center mr-1 sm:hidden`}
                    >
                      {getReturns(holdings) < 0 ? (
                        <ArrowDown className='h-3 w-2 text-white' />
                      ) : (
                        <ArrowUp className='h-3 w-2 text-white' />
                      )}
                    </span>
                    <span className='text-secondary-600 font-semibold sm:hidden'>
                      {' '}
                      {getReturns(holdings)} %
                    </span>
                  </p>
                </div>

                <Separator orientation='vertical' className='h-11 hidden sm:block' />
                <div className='space-y-1 hidden sm:block flex-1'>
                  <p className='font-semibold text-lg'>
                    ₹{' '}
                    {toCurrency(
                      (holdings?.mainData?.reduce((sum, item) => sum + item.holding, 0) || 0) -
                        (holdings?.mainData?.reduce((sum, item) => sum + item.netInvestedAmt, 0) ||
                          0),
                    )}
                  </p>
                  <div className='flex gap-x-1 items-center text-sm text-black-400'>
                    Returns{' '}
                    <p className='font-semibold text-secondary-600 flex items-center'>
                      <span
                        className={`${
                          getReturns(holdings) < 0
                            ? 'bg-error-600 text-error-600'
                            : 'bg-secondary-600 text-secondary-600'
                        } rounded-[50%] h-5 w-5 flex justify-center items-center text-white mr-1`}
                      >
                        {getReturns(holdings) < 0 ? (
                          <ArrowDown className='h-4 w-3 text-white' />
                        ) : (
                          <ArrowUp className='h-4 w-3 text-white' />
                        )}
                      </span>
                      <span
                        className={`${
                          getReturns(holdings) < 0 ? 'text-error-600' : 'text-secondary-600'
                        } `}
                      >
                        {getReturns(holdings)} %
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='my-6 max-w-[1128px] mx-auto hidden md:block'>
        {holdings?.mainData?.length > 0 ? (
          <Table className='border-collapse rounded-lg shadow-lg'>
            <TableHeader className='bg-[#F4F7FE]'>
              <TableRow className='border-none'>
                <TableHead className='text-primary-400 py-3 h-0 w-10 rounded-l-xl'></TableHead>
                <TableHead className='text-primary-400 py-3 h-0 w-[60%]'>Fund Name</TableHead>
                <TableHead className='text-primary-400 py-3 h-0 px-4 text-right'>
                  Invested
                </TableHead>
                <TableHead className='text-primary-400 py-3 h-0 px-4 text-right'>Current</TableHead>
                <TableHead className='text-primary-400 py-3 pr-16 h-0 text-right rounded-r-xl'>
                  Returns
                </TableHead>
              </TableRow>
            </TableHeader>

            <TableBody>
              {holdings?.mainData
                ?.filter((item) => Number(item.balance) > 0)
                ?.sort(
                  (a, b) =>
                    calculateParcentage(b.netInvestedAmt, b.holding) -
                    calculateParcentage(a.netInvestedAmt, a.holding),
                )
                ?.map((fund, index) => (
                  <TableRow
                    key={index}
                    className='cursor-pointer hover:shadow-[0px_24px_24px_-20px_rgba(0,0,0,0.08)]'
                    onMouseEnter={() => {
                      setIsHovered(true), setCurrentIndex(index)
                    }}
                    onMouseLeave={() => {
                      setIsHovered(false), setCurrentIndex(null)
                    }}
                  >
                    <TableCell className='p-0 w-12 pl-2'>
                      <img
                        src={`${env.MF_LOGOS_URL}/${fund.amcCode}.png`}
                        alt={fund.amcCode}
                        className='h-10 w-10 rounded-md m-auto'
                      />
                    </TableCell>
                    <TableCell>
                      <div className='w-full' onClick={() => handleMFRedirect(fund?.schemeCode)}>
                        <h3 className='font-semibold text-base leading-6'>{sanitizeName(fund)}</h3>
                        <div className='text-xs leading-4 text-black-400 flex gap-x-2 items-center'>
                          <span> NAV ₹{fund?.closerate} </span>
                          <span>(Qty. {toCurrency(fund?.balance)})</span>

                          {fund?.holdingType && (
                            <span
                              className='text-[10px] px-2 
                              m-0.5 text-white bg-secondary-500 rounded-full'
                            >
                              {fund?.holdingType}
                            </span>
                          )}
                        </div>
                      </div>
                    </TableCell>

                    <TableCell className='px-4 py-4 text-base leading-6 font-semibold text-nowrap text-right'>
                      ₹ {toCurrency(fund?.netInvestedAmt)}
                    </TableCell>

                    <TableCell className='px-4 py-4 text-base leading-6 font-medium text-nowrap text-right'>
                      ₹ {toCurrency(fund?.holding)}
                    </TableCell>

                    <TableCell className='pr-16 py-4 font-semibold flex justify-end min-w-40'>
                      <button>
                        <motion.span
                          className='inline-block'
                          animate={{ x: isHovered && currentIndex === index ? -5 : 0 }}
                          transition={{ type: 'tween', duration: 0.2 }}
                        >
                          <div className='flex flex-col justify-end items-end'>
                            {/* Only show the currency value when NOT hovering */}
                            {!(isHovered && currentIndex === index) ? (
                              <>
                                <span className='text-base font-medium leading-6 text-nowrap'>
                                  ₹ {toCurrency(fund?.holding - fund?.netInvestedAmt)}
                                </span>
                                <span
                                  className={`${
                                    calculateParcentage(fund.netInvestedAmt, fund.holding) === 0
                                      ? 'text-gray-600'
                                      : calculateParcentage(fund.netInvestedAmt, fund.holding) > 0
                                      ? 'text-green-600'
                                      : 'text-red-600'
                                  } font-semibold text-xs leading-4`}
                                >
                                  {calculateParcentage(fund.netInvestedAmt, fund.holding).toFixed(
                                    2,
                                  ) > 0 && '+'}
                                  {calculateParcentage(fund.netInvestedAmt, fund.holding).toFixed(
                                    2,
                                  )}
                                  %
                                </span>{' '}
                              </>
                            ) : (
                              <span
                                className={`font-semibold border px-4 py-2 rounded-lg text-error-600 border-error-600`}
                                onClick={() =>
                                  fund?.holdingType
                                    ? setAlertIsOpen((prev) => !prev)
                                    : handleMFRedeem(fund)
                                }
                              >
                                Redeem
                              </span>
                            )}

                            {/* Always show the percentage */}
                          </div>
                        </motion.span>
                      </button>

                      <AlertDialog open={alertIsOpen} onOpenChange={setAlertIsOpen}>
                        <AlertDialogContent className='font-DMSans'>
                          <AlertDialogHeader>
                            <AlertDialogTitle>Redemption</AlertDialogTitle>
                            <AlertDialogDescription>
                              Please contact your Relationship Manager (RM) to proceed with the
                              redemption process.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Close</AlertDialogCancel>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ) : (
          <div className='bg-background flex items-center justify-center p-4'>
            <Card className='w-full max-w-2xl text-center border-none shadow-none'>
              <CardHeader className='flex flex-col justify-center items-center space-y-2'>
                <img src={NoBond} width={400} alt='No Bond' />
                <CardTitle className='text-xl'>You have not made an investment yet</CardTitle>
              </CardHeader>
              <CardContent>
                <p className='text-muted-foreground mb-4'>
                  Start building your investment portfolio by placing your first mutual fund order.
                </p>
                <Link to='/dashboard/mutual-funds'>
                  <Button className='w-full'>
                    <Plus className='mr-2 h-4 w-4' /> Invest Now
                  </Button>
                </Link>
              </CardContent>
            </Card>
          </div>
        )}
      </div>

      <div className='mt-12 max-w-[1128px] mx-auto px-2 md:hidden'>
        {holdings?.mainData?.length > 0 ? (
          <Table className='w-full table-auto border-collapse rounded-lg shadow-lg'>
            <TableBody>
              {holdings?.mainData
                ?.filter((item) => Number(item.balance) > 0)
                ?.sort(
                  (a, b) =>
                    calculateParcentage(b.netInvestedAmt, b.holding) -
                    calculateParcentage(a.netInvestedAmt, a.holding),
                )
                .map((fund, index) => (
                  <TableRow
                    key={index}
                    className='cursor-pointer hover:shadow-[0px_24px_24px_-20px_rgba(0,0,0,0.08)]'
                    onMouseEnter={() => {
                      setIsHovered(true), setCurrentIndex(index)
                    }}
                    onMouseLeave={() => {
                      setIsHovered(false), setCurrentIndex(null)
                    }}
                    onClick={() => onOpen('openSellRequestForm', { fund, handleMFRedeem })}
                  >
                    <TableCell className='p-0 w-10'>
                      <img
                        src={`${env.MF_LOGOS_URL}/${fund.amcCode}.png`}
                        alt={`logos`}
                        className='h-10 w-10'
                      />
                    </TableCell>
                    <TableCell className='px-4 py-4 flex items-center'>
                      <div className='w-full'>
                        <div className='flex justify-between gap-x-2'>
                          <div>
                            <h3
                              className='font-semibold'
                              onClick={(e) => {
                                e.stopPropagation() // Prevents the TableRow's onClick from triggering
                                handleMFRedirect(fund?.schemeCode)
                              }}
                            >
                              <span> {sanitizeName(fund)}</span>

                              {fund?.holdingType && (
                                <span
                                  className='text-xs px-2 
                              m-0.5 text-white bg-secondary-500 rounded-full'
                                >
                                  {fund?.holdingType}
                                </span>
                              )}
                            </h3>
                          </div>
                          <ChevronRight className='h-5 w-5 text-black-200 md:hidden' />
                        </div>

                        <div className='flex justify-between items-center w-full text-xs space-x-2 text-black-400'>
                          <p className='space-x-2'>
                            <span>NAV ₹{toCurrency(fund?.closerate)}</span>
                            <span>(Qty. ₹{toCurrency(fund.balance)})</span>
                          </p>

                          <span
                            className={`${
                              calculateParcentage(fund.netInvestedAmt, fund.holding) === 0
                                ? 'text-gray-600'
                                : calculateParcentage(fund.netInvestedAmt, fund.holding) > 0
                                ? 'text-green-600'
                                : 'text-red-600'
                            } font-semibold text-xs leading-4`}
                          >
                            (
                            {calculateParcentage(fund.netInvestedAmt, fund.holding).toFixed(2) >
                              0 && '+'}
                            {calculateParcentage(fund.netInvestedAmt, fund.holding).toFixed(2)}
                            %)
                          </span>
                        </div>

                        <div className='flex justify-between items-center lg:block mt-[2px]'>
                          <div className='w-full flex gap-x-2 justify-between items-center md:hidden'>
                            <span className='font-semibold text-nowrap'>
                              ₹ {toCurrency(fund.netInvestedAmt)}
                            </span>
                            <p className='font-semibold space-x-2 text-nowrap'>
                              <span> ₹ {toCurrency(fund.holding)}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ) : (
          <div className='bg-background flex items-center justify-center'>
            <Card className='w-full max-w-2xl text-center border-none shadow-none'>
              <CardHeader className='flex flex-col justify-center items-center space-y-2'>
                <img src={NoBond} width={400} alt='No Bond' />
                <CardTitle className='text-xl'>You have not made an investment yet</CardTitle>
              </CardHeader>
              <CardContent>
                <p className='text-muted-foreground mb-4'>
                  Start building your investment portfolio by placing your first mutual fund order.
                </p>
                <Link to='/dashboard/mutual-funds'>
                  <Button className='w-full'>
                    <Plus className='mr-2 h-4 w-4' /> Invest Now
                  </Button>
                </Link>
              </CardContent>
            </Card>
          </div>
        )}
      </div>
    </>
  )
}
