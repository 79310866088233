import { SidebarProvider, SidebarTrigger } from '../../Components/ui/sidebar'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import ActionSidebar from './Sidebar'
import { Separator } from '../../Components/ui/separator'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '../../Components/ui/breadcrumb'

import { LogOut } from 'lucide-react'

const Index = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const pathSegments = location.pathname.split('/').filter(Boolean).slice(1) // Remove empty segments
  const user =
    JSON.parse(sessionStorage.getItem('actionPlanUser')) &&
    JSON.parse(sessionStorage.getItem('actionPlanUser')).data
  const hideSidebar =
    location.pathname ===
    '/6fb2a4ac30ddccb8b418e27864a778dee5409a7da4c55660f49f21b8543289b4/admin/actionPlan'

  const handleLogout = () => {
    sessionStorage.removeItem('actionPlanUser')
    navigate('/6fb2a4ac30ddccb8b418e27864a778dee5409a7da4c55660f49f21b8543289b4/admin/actionPlan')
  }

  return (
    <SidebarProvider>
      <div className='flex h-screen w-screen'>
        {!hideSidebar && <ActionSidebar user={user} />}

        <main className='flex-1'>
          {!hideSidebar && (
            <header className='flex h-16 shrink-0 items-center gap-2 border-b px-4'>
              <SidebarTrigger className='h-5 w-5 text-gray-400' />
              <Separator orientation='vertical' className='mr-2 h-4' />

              <Breadcrumb className='flex w-full justify-between items-center'>
                <BreadcrumbList>
                  {pathSegments.map((segment, index) => {
                    const url = `/6fb2a4ac30ddccb8b418e27864a778dee5409a7da4c55660f49f21b8543289b4/${pathSegments
                      .slice(0, index + 1)
                      .join('/')}`
                    const isLast = index === pathSegments.length - 1

                    return (
                      <BreadcrumbItem key={url}>
                        {isLast ? (
                          <BreadcrumbPage>{decodeURIComponent(segment)}</BreadcrumbPage>
                        ) : (
                          <>
                            <Link to={url}>{decodeURIComponent(segment)}</Link>
                            <BreadcrumbSeparator />
                          </>
                        )}
                      </BreadcrumbItem>
                    )
                  })}
                </BreadcrumbList>
                {user && (
                  <div className='flex items-center space-x-2'>
                    <p className='font-semibold'>{user?.firstName + ' ' + user?.lastName}</p>

                    <span onClick={handleLogout} className='cursor-pointer'>
                      <LogOut className='text-error-500 h-5' />
                    </span>
                  </div>
                )}
              </Breadcrumb>
            </header>
          )}

          <div className='p-4'>
            <Outlet />
          </div>
        </main>
      </div>
    </SidebarProvider>
  )
}

export default Index
