import { useEffect, useState } from 'react'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
// import WavingHandOutlinedIcon from '@mui/icons-material/WavingHandOutlined'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useNavigate } from 'react-router-dom'
import { AccountDetailsNewSelector } from '../../store/AccountDetailsStore'
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'
import { MutualFundsHolding } from '../../store/MFStore'
import { toCurrency } from '../../utils/CurrencyUtil'
import { MFUserBaskets } from '../../store/MFBasketStore'
import { get } from '../../http/restservice'
import { getUserDetails } from '../../utils/AuthUtil'
import { Grid } from '@mui/material'
import PlansCarousel from '../../Components/Home/PlansCarousel'
import env from '../../env/env'

// const WavingIcon = () => {
//   return (
//     <svg width='24' height='24'>
//       <defs>
//         <linearGradient id='gradient' x1='0%' y1='0%' x2='100%' y2='0%'>
//           <stop offset='100%' stopColor='#60a5fa' />
//           {/* <stop offset='50%' stopColor='#f472b6' />
//           <stop offset='100%' stopColor='#f9a8d4' /> */}
//         </linearGradient>
//       </defs>
//       <WavingHandOutlinedIcon style={{ fill: 'url(#gradient)', fontSize: 24 }} />
//     </svg>
//   )
// }

const RightIcon = () => {
  return (
    <svg width='24' height='24'>
      <defs>
        <linearGradient id='gradient' x1='0%' y1='0%' x2='100%' y2='0%'>
          {/* <stop offset='100%' stopColor='#60a5fa' /> */}
          <stop offset='100%' stopColor='#f472b6' />
          {/* <stop offset='100%' stopColor='#f9a8d4' /> */}
        </linearGradient>
      </defs>
      <ChevronRightIcon style={{ fill: 'url(#gradient)', fontSize: 24 }} />
    </svg>
  )
}

const DematHomepage = () => {
  const [adminPlans, setAdminPlans] = useState([])
  const navigate = useNavigate()
  // const etfHolding = useRecoilValueLoadable(AccountDetailsNewSelector)
  const mfHolding = useRecoilValueLoadable(MutualFundsHolding)
  const { contents: accountDetails } = useRecoilValueLoadable(AccountDetailsNewSelector)
  const refreshSummary = useRecoilRefresher_UNSTABLE(AccountDetailsNewSelector)
  const { state, contents: investedPlans } = useRecoilValueLoadable(MFUserBaskets)
  const refreshPlans = useRecoilRefresher_UNSTABLE(MFUserBaskets)
  const [isFICompleted, setIsFICompleted] = useState(false)
  const [loginDetails, setLoginDetails] = useState({})
  const [contentCarouselData, setContentCarouselData] = useState([])

  useEffect(() => {
    const fetchCarouselContent = async () => {
      try {
        const res = await get(`${env.API_URL}customerservice/api/carouselContent`)
        if (!res.isError) {
          setContentCarouselData(res)
        }
      } catch (error) {
        console.log('[CONERENT_CAROUSEL_ERROR]', error)
      }
    }

    fetchCarouselContent()
  }, [])

  const isQuestionaireCompleted = async () => {
    const url = 'https://apimywealth.fabits.com/customerservice/api/firstIdeas'
    const phoneNumber = loginDetails?.phoneNumber

    try {
      const response = await get(url, {}, { phoneNumber })
      // console.log(response?.isCompleted)
      setIsFICompleted(response?.isCompleted)
    } catch (error) {
      console.log(error, 'sddfsdfns')
    }
  }

  useEffect(() => {
    setLoginDetails(getUserDetails())

    if (loginDetails?.phoneNumber) {
      isQuestionaireCompleted()
    }
  }, [loginDetails?.phoneNumber])

  useEffect(() => refreshPlans(), [])

  useEffect(() => {
    if (state === 'hasValue' && Array.isArray(investedPlans?.data)) {
      const adminCuratedPlans = investedPlans?.data?.filter(
        (plan) => plan.createdSource === 'ACTION PLAN',
      )
      // console.log(adminCuratedPlans)
      setAdminPlans(adminCuratedPlans)
    }
  }, [state, investedPlans?.data])

  useEffect(() => refreshSummary(), [])

  const getCurrentInvestedTotal = () => {
    const investment =
      mfHolding.state === 'hasValue' && mfHolding?.contents?.mainData?.length > 0
        ? mfHolding?.contents?.mainData?.reduce((sum, item) => sum + Number(item.netInvestedAmt), 0)
        : 0

    const etf = Object.keys(accountDetails).length > 0 ? +accountDetails.totalInvestment : 0

    const total = investment + etf

    return total.toFixed(2)
  }

  const getCurrentInvestmentTotal = () => {
    const investment =
      mfHolding.state === 'hasValue' && mfHolding?.contents?.mainData?.length > 0
        ? mfHolding?.contents?.mainData?.reduce((sum, item) => sum + Number(item.holding), 0)
        : 0

    const etf = Object.keys(accountDetails).length > 0 ? +accountDetails.currentInvestment : 0

    const total = investment + etf

    return total.toFixed(2)
  }

  const calculateParcentage = (invested, returns) => {
    const result = ((returns - invested) / invested) * 100
    return result.toFixed(2)
  }

  const redirect = (id) => {
    if (id === 1) {
      navigate('/dashboard/firstTime/questionnaire')
    } else if (id === 2) {
      return
    } else if (id === 3) {
      navigate('/dashboard/firstTime/actionPlanUser', {
        state: adminPlans,
      })
    } else if (id === 4) {
      navigate('/dashboard/plans')
    } else {
      navigate('')
    }
  }

  const cards = [
    {
      id: 1,
      icon: '',
      title: 'Start Here',
      description: 'Discover your perfect fund in a few taps',
      shouldShow: !isFICompleted && adminPlans.length === 0,
    },
    {
      id: 2,
      icon: '',
      title: 'Curating your plans',
      description: 'Discover your perfect fund in a few taps',
      shouldShow: isFICompleted && adminPlans.length === 0,
    },
    {
      id: 3,
      icon: '',
      title: 'Action Center',
      description: 'Discover your perfect fund in a few taps',
      shouldShow: adminPlans.length > 0,
    },
    {
      id: 4,
      icon: <TrackChangesIcon />,
      title: 'Goal Planning',
      description: 'Stay on course with personalized goal tracking',
      shouldShow: true,
    },
    {
      id: 5,
      icon: <LocalMallOutlinedIcon />,
      title: 'Mutual Fund Basket',
      description: 'Expert fund combinations for smarter investing',
      shouldShow: true,
    },
  ]

  const visibleCards = cards.filter((card) => card.shouldShow)

  return (
    <div className='m-4 lg:max-w-[1128px] md:mx-[20px] lg:my-12'>
      {/* PORTFOLIO DETAILS */}
      <div className='flex flex-col lg:flex-row lg:items-end gap-6 lg:gap-x-12'>
        <div>
          <p className='font-DMSans font-normal text-sm text-center lg:text-start text-black-300'>
            Your total portfolio is currently worth
          </p>
          <div className='text-center lg:flex lg:gap-x-3 lg:items-end'>
            <p className='font-normal text-[32px] leading-[36px] mt-2'>
              <span className='font-Platypi'>&#8377;</span>
              <span className='font-OrelegaOne'> {toCurrency(getCurrentInvestmentTotal())}</span>
            </p>
            {/* <p className='font-DMSans flex justify-center items-center text-sm text-secondary-600'>
              <span className='font-semibold flex items-center gap-x-1'>
                <ArrowCircleRightIcon className='-rotate-90' /> + 20.18%
              </span>
              <span className='font-normal'> today</span>
            </p> */}
          </div>
        </div>
        <div className='flex justify-between lg:gap-x-12'>
          <div>
            <p className='font-DMSans font-medium text-xs text-black-300 tracking-[2%]'>
              INVESTED AMOUNT
            </p>
            <p className='font-DMSans font-semibold text-base mt-1'>
              &#8377; {toCurrency(getCurrentInvestedTotal() || 0)}
            </p>
          </div>
          <div>
            <p className='font-DMSans font-medium text-xs text-black-300 tracking-[2%]'>
              TOTAL RETURNS
            </p>
            <div className='flex gap-x-3 items-end mt-1'>
              <span className='font-DMSans font-semibold text-base'>
                &#8377;{' '}
                {toCurrency(Math.floor(getCurrentInvestmentTotal() - getCurrentInvestedTotal()))}
              </span>
              <p
                className={`hidden lg:block font-DMSans text-sm ${
                  calculateParcentage(getCurrentInvestedTotal(), getCurrentInvestmentTotal()) > 0
                    ? 'text-secondary-600'
                    : 'text-error-600'
                }`}
              >
                <span className='font-semibold flex gap-[6px]'>
                  {calculateParcentage(getCurrentInvestedTotal(), getCurrentInvestmentTotal()) >
                  0 ? (
                    <ArrowCircleRightIcon className={`h-4 w-3 -rotate-90`} />
                  ) : (
                    <ArrowCircleRightIcon className='h-4 w-3 rotate-90' />
                  )}
                  <span
                    className={`font-semibold ${
                      calculateParcentage(getCurrentInvestedTotal(), getCurrentInvestmentTotal()) >
                      0
                        ? 'text-secondary-600'
                        : 'text-error-600'
                    }`}
                  >
                    {calculateParcentage(getCurrentInvestedTotal(), getCurrentInvestmentTotal()) ||
                      0}{' '}
                    %
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* CARDS */}
      <div className='grid grid-cols-2 lg:grid-cols-3 gap-6 mt-12'>
        {visibleCards.map((card) => {
          const { id, icon, title, description } = card
          return (
            <div
              key={id}
              className={`rounded-lg cursor-pointer shadow-md ${
                id === 1 || id === 2 || id === 3 ? 'col-span-2 lg:col-span-1' : ''
              }`}
              style={{
                border: id === 1 || id === 2 || id === 3 ? '1px solid transparent' : '',
                borderRadius: '16px',
                background:
                  id === 1 || id === 2 || id === 3
                    ? 'linear-gradient(white, white) padding-box, linear-gradient(93.32deg, rgba(31, 160, 230, 0.8) 0%, rgba(236, 41, 197, 0.8) 49.96%, rgba(236, 127, 38, 0.8) 99.91%) border-box'
                    : '#F4F7FE',
              }}
            >
              <div
                className='p-4 h-full'
                style={{
                  background:
                    id === 1 || id === 2 || id === 3
                      ? 'linear-gradient(92.56deg, rgba(31, 160, 230, 0.08) 3.63%, rgba(236, 41, 197, 0.08) 47.06%, rgba(236, 127, 38, 0.08) 90.49%), #FFFFFF'
                      : '#F4F7FE',
                  boxShadow: '0px 6px 16px rgba(239, 149, 80, 0.12)',
                  borderRadius: '16px',
                }}
                onClick={() => redirect(id)}
              >
                <div
                  className={`${
                    id !== 1 || id !== 2 || id !== 3
                      ? ''
                      : 'bg-gradient-to-r from-blue-400 via-pink-400 to-pink-300 bg-clip-text text-transparent'
                  } flex justify-between items-start mb-2`}
                >
                  <div
                    className={`${(id === 1 || id === 2 || id === 3) && 'flex'} lg:flex lg:gap-2`}
                  >
                    {icon}
                    <p
                      className={`font-medium text-lg lg:text-xl tracking-[-4%] lg:mt-0 ${
                        id === 1 ||
                        id === 2 ||
                        (id === 3 &&
                          'bg-gradient-to-r from-blue-400 via-pink-400 to-pink-300 bg-clip-text text-transparent')
                      }`}
                    >
                      {title}
                    </p>
                  </div>
                  <div>
                    {id === 1 || id === 2 || id === 3 ? (
                      <RightIcon />
                    ) : (
                      <ChevronRightIcon className={`${id !== 1 ? 'text-black-200' : ''}`} />
                    )}
                  </div>
                </div>
                <p
                  className={`${
                    id !== 1 || id !== 2 || id !== 3
                      ? 'hidden lg:block text-black-400'
                      : 'bg-gradient-to-r from-blue-400 via-pink-400 to-pink-300 bg-clip-text text-transparent'
                  } text-sm font-DMSans font-normal`}
                >
                  {description}
                </p>
              </div>
            </div>
          )
        })}
      </div>

      {/*BOTTOM CAROUSEL*/}
      <div className='lg:max-w-[1128px] mt-10'>
        <h1 className='font-semibold text-lg'>Understand your Money</h1>

        <Grid mt={3}>
          <div className='flex flex-col gap-y-5'>
            <PlansCarousel newPage contentCarouselData={contentCarouselData} />
          </div>
        </Grid>
      </div>
    </div>
  )
}

export default DematHomepage
