/* eslint-disable */

import blub from '../../assets/SVGS/blub.svg'

import { Button } from '../../Components/ui/button'
import { useEffect, useRef, useState } from 'react'
import PaymentGif from '../../assets/gifs/https___lottiefiles.com_animations_flow-tWbpYsXrBz.gif'
import { useLocation, useNavigate } from 'react-router-dom'
import { AccountBalance } from '@mui/icons-material'
import env from '../../env/env'
import { Progress } from '../../Components/ui/progress'
import { usePaymentProcessingTimer } from '../../hooks/useProcessingTimer'
import { post } from '../../http/restservice'
import { useModal } from '../../hooks/use-modal-store'
import { useErrorPopup } from '../../hooks/useErrorPopup'
import { UserDetails } from '../../store/AccountDetailsStore'
import { useRecoilValueLoadable } from 'recoil'

const ActionPlanMandatePage = () => {
  const location = useLocation()
  const [timeLeft, setTimeLeft] = useState(120)
  const [src, setSrc] = useState('')
  const handleError = () => {
    setSrc(null)
  }
  const hasOpened = useRef(false)

  const { state, contents: bankDetails } = useRecoilValueLoadable(UserDetails)
  const { progress } = usePaymentProcessingTimer(500) // 5 minutes
  const [dots, setDots] = useState('')
  const [urlGenerated, setUrlGenerated] = useState('')
  const { onOpen } = useModal()
  const { showError } = useErrorPopup()
  const [mandateStatus, setMandateStatus] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    const handlePolling = async () => {
      if (location?.state?.mandateId) {
        // Check if it's time to call the eMandateAuthURL
        if (timeLeft % 10 === 0 || timeLeft === 0) {
          try {
            const eMandateResponse = await getEMandateAuthURL(location?.state?.mandateId)

            if (eMandateResponse.Status == 100) {
              setTimeLeft(-10)
              handleSuccessfulEMandate(eMandateResponse)
              return
            }

            if (timeLeft < 0 && eMandateResponse.Status != 100) {
              handleFailedEMandate()
            }
          } catch (error) {
            console.error('Error in eMandateAuthURL:', error)
            handleFailedEMandate()
          }
        }

        // Countdown logic
        if (timeLeft > 0) {
          const timerId = setTimeout(() => {
            setTimeLeft((prevTime) => prevTime - 1)
          }, 1000)

          // Cleanup timeout
          return () => clearTimeout(timerId)
        }
      }
    }

    handlePolling() // Call the polling function
  }, [timeLeft])

  // useEffect(() => {
  //   onOpen('openActionPlanPopup', {
  //     severity: 'sipsuccess',
  //     title: 'SIP Order Placed Successfully',
  //     message:
  //       'Your SIP order has been successfully placed. The transaction has been processed, and your investment will be scheduled accordingly.',
  //     redirectTo: '/dashboard/firstTime/confirmPayment',
  //     onClose: '/dashboard/firstTime/confirmPayment',
  //     from: 'mandate-page',
  //   })
  // }, [])

  useEffect(() => {
    const startPolling = () => {
      const pollingDuration = 600 // Total duration in seconds (10 minutes)
      const pollingInterval = 10 // Interval in seconds
      let timeLeft = pollingDuration

      // Start the polling
      const intervalId = setInterval(async () => {
        if (timeLeft <= 0) {
          clearInterval(intervalId) // Stop polling after 10 minutes
          return
        }

        // Execute polling logic at each 10-second interval
        try {
          const response = await getMandateDeatils(location?.state?.mandateId)

          if (response?.MandateDetails[0]?.status) {
            setMandateStatus(response?.MandateDetails[0]?.status)
          }
          if (response.isError) {
            setShowMfSipMandatePopup(false)
            setMessageModalState({
              message: 'Error occurred while fetching mandate details or processing e-Mandate',
              severity: 'error',
            })
            clearInterval(intervalId) // Stop polling on error
            return
          }

          if (!response.isError && response.MandateDetails[0].status === 'UNDER PROCESSING') {
            setUrlGenerated('')
            const sipPlansData = JSON.parse(sessionStorage.getItem('actionPlanSipAndOneTime'))

            const handleSipRegistration =
              sipPlansData?.sip?.length && (await handleXSipRegistration(sipPlansData.sip))

            if (sipPlansData?.sip?.length && handleSipRegistration.status === 'SUCCESS') {
              // console.log('🚀 ~ intervalId ~ sipPlansData:', sipPlansData)
              onOpen('openActionPlanPopup', {
                severity: 'sipsuccess',
                title: '',
                message:
                  'Your SIP order has been successfully placed. The transaction has been processed, and your investment will be scheduled accordingly.',
                redirectTo: '/dashboard/firstTime/confirmPayment',
              })
            }

            clearInterval(intervalId) // Stop polling on success
            return
          }

          timeLeft -= pollingInterval
        } catch (error) {
          console.error('Error in eMandateAuthURL:', error)
          handleFailedEMandate()
          clearInterval(intervalId) // Stop polling on error
          return
        }

        // Decrease time left
        timeLeft -= pollingInterval
      }, pollingInterval * 1000) // Convert interval to milliseconds

      return intervalId // Return interval ID for cleanup
    }

    let intervalId
    if (urlGenerated) {
      intervalId = startPolling(urlGenerated)
    }

    // Cleanup function to clear interval on unmount
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
        console.log('Interval cleared on unmount')
      }
    }
  }, [urlGenerated])

  const handleSuccessfulEMandate = (eMandateResponse) => {
    if (!urlGenerated && !hasOpened.current) {
      setUrlGenerated(eMandateResponse.ResponseString)
      // window.location.href = eMandateResponse.ResponseString
      window.open(eMandateResponse.ResponseString)
    }
  }

  // Helper function to handle failed eMandate response
  const handleFailedEMandate = () => {
    showError(
      'Mandate Processing Error',
      'Mandate registration failed or mandate details are currently unavailable. Please try again later or contact support.',
      () => {},
    )
  }

  const getEMandateAuthURL = async () => {
    const eMandatePayload = {
      clientCode: location?.state?.clientCode,
      mandateId: location?.state?.mandateId,
      loopBackUrl: `${env.LOOPBACK_URL}dashboard/actionplan/mandate`,
    }

    const response = await post(
      'mutualfundservice/api/bseStar/mfWebService/eMandateAuthURL',
      eMandatePayload,
    )

    if (response.isError) {
      showError(
        'Mandate Processing Error',
        'Mandate registration failed or mandate details are currently unavailable. Please try again later or contact support.',
        () => {},
      )

      return
    }

    return response
  }

  const getMandateDeatils = async () => {
    const today = new Date()
    const formattedDate = today.toLocaleDateString('en-GB').replace(/\//g, '/')

    const payload = {
      fromDate: '01/10/2024',
      toDate: formattedDate,
      clientCode: location?.state?.clientCode,
      mandateId: location?.state?.mandateId,
    }

    const response = await post(
      'mutualfundservice/api/bseStar/mfWebService/mandateDetails',
      payload,
    )

    if (response?.isError) {
      showError(
        'Mandate Processing Error',
        'An unexpected error occurred while processing your mandate. Please try again later or reach out to support.',
        () => {},
      )
    }

    return response
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots === '...') return '.'
        return prevDots + '.'
      })
    }, 500) // Change dot every 500ms

    return () => clearInterval(interval)
  }, [])

  let ifscCode = bankDetails?.ifscCode
  const extractedBranch = ifscCode?.match(/^[A-Za-z]{4}/)[0]?.toLowerCase()
  const iconSrc = `${env.BANK_LOGOS}/${extractedBranch}/symbol.svg`

  useEffect(() => {
    setSrc(iconSrc)
  }, [iconSrc])

  const handleXSipRegistration = async (payload) => {
    try {
      const xSIPRegistration = await post(
        'mutualfundservice/api/illusion/multiBasketSipRegistrationOrder',
        payload,
      )

      if (xSIPRegistration?.isError) {
        showError(
          'SIPs Registration Failed',
          'We encountered an issue while registering your SIP. Please try again or contact support for assistance.',
          () => {},
        )
        return
      }

      if (xSIPRegistration.status === 'SUCCESS') {
        return xSIPRegistration
      }
    } catch (error) {
      console.log('Error registering XSIP:', error)
    }
  }

  // const placeOneTimeOrder = async (payload) => {
  //   try {
  //     const response = await post('mutualfundservice/api/illusion/multiBasketOneTimeOrder', payload)

  //     if (response.isError) {
  //       showError(
  //         'Mandate Processing Error',
  //         'Mandate registration failed or mandate details are currently unavailable. Please try again later or contact support.',
  //         () => {},
  //       )
  //       return
  //     }

  //     if (response.status === 'SUCCESS') {
  //       return response
  //     } else {
  //       setMessageModalState({
  //         message: response.data.responsestring,
  //         linkMessage: response.data.responsestring,
  //         severity: 'error',
  //       })
  //     }
  //   } catch (error) {
  //     console.log('🚀 ~ placeOneTimeOrder ~ error:', error)
  //   }
  // }

  return (
    <div className='bg-white fixed top-0 left-0 right-0 bottom-0 z-[10000] overflow-y-scroll lg:py-4'>
      <div className='ease-in-out h-screen md:max-w-md m-auto flex flex-col justify-center gap-y-4 px-4 py-2 md:py-0'>
        <div className='flex flex-col gap-y-6'>
          <div>
            <h2 className='text-center text-xl md:text-2xl text-black-500 font-semibold'>
              Taking you to create mandate on your bank’s website
            </h2>
            <p className='text-center text-sm text-black-400 mt-2'>
              The amount is transferred directly to <strong>BSE</strong>
            </p>
          </div>
          <div className='relative flex justify-between items-center px-4 bg-primary-50 py-2 rounded-2xl h-12 md:h-16'>
            <img src={`${env.SVGS}/logo.svg`} alt='UPI Icon' />
            <img
              src={PaymentGif}
              alt='Moving GIF'
              className='absolute left-[25.51%] move-left-right rotate-90 z-2'
            />
            {/* Add the bank icon here */}
            {src ? (
              <img src={src} alt='bank logos' onError={handleError} height={24} width={24} />
            ) : (
              <AccountBalance className='text-primary-600' />
            )}
          </div>

          <div className='relative'>
            <iframe
              src={'https://d3lgi69rk7563.cloudfront.net/ProductVideos/Mandate.mp4'}
              className='w-full aspect-video rounded-2xl'
              allowFullScreen
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            ></iframe>
          </div>

          <div className='bg-[#fdf7e8] rounded-lg p-4'>
            <div className='space-y-4 text-warning-600'>
              <div className='flex gap-x-2 items-center'>
                <img src={blub} alt={'Blub'} className='h-6 w-6 md:h-auto md:w-auto' />
                <h2 className='font-semibold text-sm md:text-base'>What is a mandate?</h2>
              </div>

              <ol className='space-y-4 list-disc text-xs md:text-sm px-5'>
                <li>
                  <strong>Maximum amount </strong>
                  that BSE is permitted to deduct from your account
                </li>
                <li>
                  The mandate amount may be more than your desired SIP amount, to account for future
                  changes in your investment plans.
                </li>
              </ol>
            </div>
          </div>
        </div>

        {(mandateStatus != 'RECEIVED BY EXCHANGE' || mandateStatus != 'UNDER PROCESSING') && (
          <div className='flex justify-between gap-x-4'>
            <Button
              className='flex-1 border border-error-100 bg-error-50 hover:bg-error-50 text-error-500 rounded-lg'
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              disabled={!urlGenerated}
              onClick={() => window.open(urlGenerated)}
              className='flex-1 rounded-lg'
            >
              {!urlGenerated ? `Processing${dots}` : 'Continue'}
            </Button>
          </div>
        )}

        {/* {(mandateStatus == 'RECEIVED BY EXCHANGE' || mandateStatus == 'UNDER PROCESSING') && ( */}
        {urlGenerated && (
          <div className='flex flex-col gap-y-2'>
            <Progress value={progress} className='h-2 bg-primary-100' />
            <p className='text-sm text-center'>
              Please wait while we process your mandate. This may take a few moments. Kindly avoid
              closing the app or navigating back during this time.
            </p>
          </div>
        )}
        {/* )} */}
      </div>
    </div>
  )
}

export default ActionPlanMandatePage
