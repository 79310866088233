/* eslint-disable */
import MFBasketsOrders from '../MutualFundsBaskets/MFBasketsOrders'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../Components/ui/tabs'
import MFIndividualOrders from './MFIndividualOrders'

const MFOrders = () => {
  return (
    <>
      <div className='max-w-[1128px] pt-12 mx-auto'>
        <Tabs defaultValue='Goals' className='relative mr-auto w-full'>
          <TabsList className='w-full justify-start rounded-none border-b bg-transparent p-0'>
            <TabsTrigger
              value='Goals'
              className='relative rounded-none border-b-2 border-b-transparent bg-transparent px-4 pb-3 pt-2 font-semibold text-muted-foreground shadow-none transition-none focus-visible:ring-0 data-[state=active]:border-b-primary data-[state=active]:text-foreground data-[state=active]:shadow-none '
            >
              Goals
            </TabsTrigger>

            <TabsTrigger
              value='Mutual Funds'
              className='relative rounded-none border-b-2 border-b-transparent bg-transparent px-4 pb-3 pt-2 font-semibold text-muted-foreground shadow-none transition-none focus-visible:ring-0 data-[state=active]:border-b-primary data-[state=active]:text-foreground data-[state=active]:shadow-none '
            >
              Mutual Funds
            </TabsTrigger>
          </TabsList>

          <TabsContent value='Mutual Funds'>
            <MFIndividualOrders />
          </TabsContent>

          <TabsContent value='Goals'>
            <MFBasketsOrders />
          </TabsContent>
        </Tabs>
      </div>
    </>
  )
}

export default MFOrders
