// src/state/mutualFundsState.js

import { atom, selector, selectorFamily } from 'recoil'
import { get } from '../http/restservice'

// Define atom to store the state of mutual funds data
const MutualFundsState = atom({
  key: 'MutualFundsState',
  default: [],
})

//To get chart Data
const MutualFundChartData = selectorFamily({
  key: 'MutualFundChartData',
  get: (fundId) => async () => {
    const response = await get(`/mutualfundservice/api/mfData/chartData/${fundId}`)
    if (response.isError) {
      return []
    }
    return response?.data
  },
})

//To get search Data
const MutualFundSearch = selectorFamily({
  key: 'MutualFundSearch',
  get: (query) => async () => {
    const response = await get(`/mutualfundservice/api/mfData/allFunds?search=${query}`)
    if (response.isError) {
      return []
    }
    return response?.data
  },
})

// Define selector to fetch mutual funds data
const MutualFundsSelector = selectorFamily({
  key: 'MutualFundsSelector',
  get: (params) => async () => {
    const response = await get('/mutualfundservice/api/mfData/allFunds', {}, params)
    if (response.isError) {
      return []
    }
    return response
  },
})

const MutualFundsSelectorDetails = selectorFamily({
  key: 'MutualFundsSelectorDetails',
  get: (fundId) => async () => {
    const response = await get(`/mutualfundservice/api/mfData/mainInfo/${fundId}`)
    if (response.isError) {
      return []
    }
    return response?.data
  },
})

const MutualFundsSelectorDates = selectorFamily({
  key: 'MutualFundsSelectorDates',
  get: (fundId) => async () => {
    const response = await get(`/mutualfundservice/api/mfData/bseSIPData/${fundId}`)
    if (response.isError) {
      return []
    }
    return response?.data
  },
})

const MutualFundsSIPs = selector({
  key: 'MutualFundsSIPs',
  get: async () => {
    const response = await get(`/mutualfundservice/api/mutualFund/activeSIPs`)

    if (response.isError) {
      return []
    }

    return response
  },
})

const MutualFundsHolding = selector({
  key: 'MutualFundsHolding',
  get: async () => {
    const response = await get(`/mutualfundservice/api/mutualFund/holdings`)

    if (response.isError) {
      return []
    }

    return response
  },
})

const MutualFundsOrders = selector({
  key: 'MutualFundsOrders',
  get: async () => {
    const response = await get(`/mutualfundservice/api/mutualFund/orderHistory`)
    if (response.isError) {
      return response
    }
    return response
  },
})

const FabitsStarFunds = selector({
  key: 'FabitsStarFunds',
  get: async () => {
    const response = await get(`/mutualfundservice/api/mfData/fabStarFunds`)
    if (response.isError) {
      return []
    }
    return response
  },
})

const MFSingleFund = selectorFamily({
  key: 'MFSingleFund',
  get: (fundId) => async () => {
    const response = await get(`/mutualfundservice/api/mfData/generalInfo/${fundId}`)
    if (response.isError) {
      return []
    }
    return response?.data
  },
})

const MFRecentlyViewed = selector({
  key: 'MFRecentlyViewed',
  get: async () => {
    const response = await get(`/mutualfundservice/api/mfData/recentlyViewed`)

    if (response.isError) {
      return []
    }
    return response
  },
})

export {
  MFRecentlyViewed,
  MutualFundSearch,
  MutualFundsSelector,
  MutualFundsState,
  MutualFundsSelectorDetails,
  MutualFundChartData,
  MutualFundsSelectorDates,
  MutualFundsSIPs,
  MutualFundsHolding,
  MutualFundsOrders,
  FabitsStarFunds,
  MFSingleFund,
}
