/* eslint-disable */

import blub from '../../assets/SVGS/blub.svg'

import { Button } from '../../Components/ui/button'
import { useEffect, useState } from 'react'
import PaymentGif from '../../assets/gifs/https___lottiefiles.com_animations_flow-tWbpYsXrBz.gif'
import { useLocation } from 'react-router-dom'
import { AccountBalance } from '@mui/icons-material'
import env from '../../env/env'
import { Progress } from '../../Components/ui/progress'
import { usePaymentProcessingTimer } from '../../hooks/useProcessingTimer'
import clevertap from 'clevertap-web-sdk'

const MFSipMandate = ({
  bankDetails,
  mandateStatus,
  redirectURL,
  isSIPLoading,
  setIsSIPLoading,
  setShowMfSipMandatePopup,
  setRedirectURL,
}) => {
  const [src, setSrc] = useState('')
  const handleError = () => {
    setSrc(null)
  }

  const { progress } = usePaymentProcessingTimer(500) // 3 minutes

  const [dots, setDots] = useState('')

  useEffect(() => {
    if (redirectURL) {
      window.open(redirectURL)
    }
  }, [redirectURL])

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots === '...') return '.'
        return prevDots + '.'
      })
    }, 500) // Change dot every 500ms

    return () => clearInterval(interval)
  }, [])

  let ifscCode = bankDetails?.ifscCode
  const extractedBranch = ifscCode?.match(/^[A-Za-z]{4}/)[0]?.toLowerCase()
  const iconSrc = `${env.BANK_LOGOS}/${extractedBranch}/symbol.svg`

  useEffect(() => {
    setSrc(iconSrc)
  }, [iconSrc])

  return (
    <div className='bg-white fixed top-0 left-0 right-0 bottom-0 z-[10000] overflow-y-scroll lg:py-12'>
      <div className='ease-in-out h-screen md:max-w-md m-auto flex flex-col justify-center gap-y-4 px-4 py-2 md:py-0'>
        <div className='flex flex-col gap-y-6'>
          <div>
            <h2 className='text-center text-xl md:text-2xl text-black-500 font-semibold'>
              Taking you to create mandate on your bank’s website
            </h2>
            <p className='text-center text-sm text-black-400 mt-2'>
              The amount is transferred directly to <strong>BSE</strong>
            </p>
          </div>
          <div className='relative flex justify-between items-center px-4 bg-primary-50 py-2 rounded-2xl h-12 md:h-16'>
            <img src={`${env.SVGS}/logo.svg`} alt='UPI Icon' />
            <img
              src={PaymentGif}
              alt='Moving GIF'
              className='absolute left-[25.51%] move-left-right rotate-90 z-2'
            />
            {/* Add the bank icon here */}
            {src ? (
              <img src={src} alt='bank logos' onError={handleError} height={24} width={24} />
            ) : (
              <AccountBalance className='text-primary-600' />
            )}
          </div>

          <div className='relative'>
            <iframe
              src={'https://d3lgi69rk7563.cloudfront.net/ProductVideos/Mandate.mp4'}
              className='w-full aspect-video rounded-2xl'
              allowFullScreen
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            ></iframe>
          </div>

          <div className='bg-[#fdf7e8] rounded-lg p-4'>
            <div className='space-y-4 text-warning-600'>
              <div className='flex gap-x-2 items-center'>
                <img src={blub} alt={'Blub'} className='h-6 w-6 md:h-auto md:w-auto' />
                <h2 className='font-semibold text-sm md:text-base'>What is a mandate?</h2>
              </div>

              <ol className='space-y-4 list-disc text-xs md:text-sm px-5'>
                <li>
                  <strong>Maximum amount </strong>
                  that BSE is permitted to deduct from your account
                </li>
                <li>
                  The mandate amount may be more than your desired SIP amount, to account for future
                  changes in your investment plans.
                </li>
              </ol>
            </div>
          </div>
        </div>

        {(mandateStatus != 'RECEIVED BY EXCHANGE' || mandateStatus != 'UNDER PROCESSING') && (
          <div className='flex justify-between gap-x-4'>
            <Button
              className='flex-1 border border-error-100 bg-error-50 hover:bg-error-50 text-error-500 rounded-lg'
              onClick={() => {
                setShowMfSipMandatePopup(false),
                  setIsSIPLoading(false),
                  setRedirectURL(''),
                  clevertap.event.push('Clicked Button', {
                    Action: 'Cancel MF Mandate',
                    Page_name: 'MF SIP Mandate',
                  })
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!redirectURL}
              onClick={() => window.open(redirectURL)}
              className='flex-1 rounded-lg'
            >
              {!redirectURL ? `Processing${dots}` : 'Continue'}
            </Button>
          </div>
        )}

        {/* {(mandateStatus == 'RECEIVED BY EXCHANGE' || mandateStatus == 'UNDER PROCESSING') && ( */}
        {redirectURL && (
          <div className='flex flex-col gap-y-2'>
            <Progress value={progress} className='h-2 bg-primary-100' />
            <p className='text-sm text-center'>
              Please wait while we process your mandate. This may take a few moments. Kindly avoid
              closing the app or navigating back during this time.
            </p>
          </div>
        )}
        {/* )} */}
      </div>
    </div>
  )
}

export default MFSipMandate
