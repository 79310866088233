'use client'

import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'

import { cn } from '../../lib/utils'

const Tabs = TabsPrimitive.Root

const TabsList = React.forwardRef(({ className, ...props }, ref) => (
  <div className='relative w-full'>
    <TabsPrimitive.List
      ref={ref}
      className={cn(
        'inline-flex h-[42px] items-center justify-center rounded-md p-0 text-muted-foreground w-full overflow-x-auto',
        className,
      )}
      style={{
        scrollbarWidth: 'none' /* Firefox */,
        msOverflowStyle: 'none' /* IE and Edge */,
      }}
      {...props}
    />
  </div>
))
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef(({ className, onClick, ...props }, ref) => {
  // Create a ref we can use to scroll this element into view
  const triggerRef = React.useRef(null)

  // Combine the external ref with our local ref
  const handleRef = (element) => {
    // Update our local ref
    triggerRef.current = element

    // Forward the ref if it exists
    if (typeof ref === 'function') {
      ref(element)
    } else if (ref) {
      ref.current = element
    }
  }

  // Handle click to scroll into view
  const handleClick = (e) => {
    // Call the original onClick if it exists
    if (onClick) {
      onClick(e)
    }

    // Scroll the tab into view with a slight delay to ensure state changes have occurred
    setTimeout(() => {
      if (triggerRef.current) {
        triggerRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        })
      }
    }, 10)
  }

  return (
    <TabsPrimitive.Trigger
      ref={handleRef}
      className={cn(
        'inline-flex items-center justify-center whitespace-nowrap px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:text-foreground data-[state=active]:border-b-2 data-[state=active]:border-primary-600',
        className,
      )}
      onClick={handleClick}
      {...props}
    />
  )
})
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
      className,
    )}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent }
