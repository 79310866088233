/* eslint-disable*/
import { Box, Grid, styled, SwipeableDrawer, Typography, useTheme } from '@mui/material'
import { Outlet, useLocation } from 'react-router-dom'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Carousel } from 'react-responsive-carousel'
import logo from '../assets/85A6933F.png'
import LoginScreen1 from '../assets/images/signup asset.png'
import LoginScreen2 from '../assets/images/signup page asset 2.png'
import LoginScreen3 from '../assets/images/Signup page asset.png'
import darkLogo from '../assets/DarkThemeFabLogo.png'
import { useThemeMode } from '../Provider/ThemeContextProvider'
import { Drawer, DrawerContent, DrawerHeader } from '../Components/ui/drawer'
import { Button } from '../Components/ui/button'
import { useEffect, useState } from 'react'
import { AuthCarouselDesktop, AuthCarouselMobile } from '../Components/Auth/AuthCarousel'
import useWindowHeight from '../hooks/useWindowHeight'
import { Sheet, SheetContent } from '../Components/ui/sheet'
import clevertap from 'clevertap-web-sdk'
import env from '../env/env'

const LoginContainer = () => {
  // const navigate = useNavigate()
  const theme = useTheme()
  const { darkMode } = useThemeMode()
  const location = useLocation()
  const [open, setOpen] = useState(false)

  return (
    <>
      <div className='lg:hidden h-screen bg-primary-900 relative'>
        <div className='absolute top-[2%] w-full z-[1000]  flex justify-center'>
          <img src={logo} alt='fabits' className='h-6 mt-6' />
        </div>
        <AuthCarouselMobile />

        <div
          className={`${
            open && 'hidden'
          } bg-white fixed bottom-0 w-full rounded-t-3xl px-6 pb-6 flex flex-col items-center justify-center h-[20%]`}
          onClick={() => {
            setOpen(!open)
          }}
        >
          <h2 className='text-black-500 text-[28px] kerning-[36px] -tracking-[1.12px] font-semibold text-center'>
            Get started
          </h2>

          <p className='text-[14px] kerning-[20px] -tracking-[0.56px] font-medium text-black-400'>
            Tap here to begin your journey
          </p>
        </div>

        {/* <Drawer open={open} onOpenChange={setOpen} className='fixed bottom-0'>
          <DrawerContent
            side='bottom'
            className={`flex flex-col justify-end !h-min min-h-[50vh] max-h-[85vh] items-center bottom-0
              pb-0 outline-none`}
          >
            <div className='pb-6 w-full px-6 h-full overflow-auto mt-8' data-vaul-no-drag>
              <Outlet />
              <div className='mt-6'>
                <p className='text-black-300 text-[10px] text-center'>
                  Shareway Securities Limited (SEBI Reg. no. INZ000208134) NSE Member Code 13093 |
                  CDSL - SEBI Reg. No. IN-DP-610-2021 Registered with NSE India Limited with
                  approved segments Capital Market and F&O
                </p>
                <div className='text-[10px] flex gap-x-2 justify-center text-primary-500 mt-1'>
                  <a
                    href='https://fabits.com/Termsandconditions'
                    target='_blank'
                    rel='noreferrer'
                    style={{
                      textDecoration: 'none',
                      display: 'inline-block',
                      borderBottom: `2px dashed ${theme.palette.primary.main}`,
                    }}
                    className='font-semibold'
                  >
                    Terms & Conditions
                  </a>
                  <a
                    href='https://fabits.com/privacypolicy'
                    target='_blank'
                    rel='noreferrer'
                    style={{
                      textDecoration: 'none',
                      display: 'inline-block',
                      borderBottom: `2px dashed ${theme.palette.primary.main}`,
                    }}
                    className='font-semibold'
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </DrawerContent>
        </Drawer> */}
        <SwipeableDrawer
          anchor='bottom'
          open={open}
          onOpen={setOpen}
          onClose={() => setOpen(false)}
          sx={{
            '& .MuiPaper-root': {
              borderTopRightRadius: '24px',
              borderTopLeftRadius: '24px',
            },
          }}
        >
          <div className='pb-6 w-full px-6 h-full overflow-auto mt-8'>
            <div className='bg-primary-200 w-6 h-1 rounded-[10px] absolute top-4 left-1/2  -translate-x-1/2' />
            <Outlet />
            <div className='mt-6'>
              <p className='text-black-300 text-[10px] text-center'>
                Shareway Securities Private Limited (SEBI Reg. no. INZ000208134) NSE Member Code
                13093 | CDSL - SEBI Reg. No. IN-DP-610-2021 | BSE Member ID 61731 | ARN - 310082
                Registered with NSE India Limited with approved segments Capital Market and F&O
              </p>
              <div className='text-[10px] flex gap-x-2 justify-center text-primary-500 mt-1'>
                <a
                  href='https://fabits.com/Termsandconditions'
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    textDecoration: 'none',
                    display: 'inline-block',
                    borderBottom: `2px dashed ${theme.palette.primary.main}`,
                  }}
                  className='font-semibold'
                >
                  Terms & Conditions
                </a>
                <a
                  href='https://fabits.com/privacypolicy'
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    textDecoration: 'none',
                    display: 'inline-block',
                    borderBottom: `2px dashed ${theme.palette.primary.main}`,
                  }}
                  className='font-semibold'
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </SwipeableDrawer>
      </div>

      <div className='hidden lg:flex justify-between items-stretch max-h-screen h-screen max-w-screen-2xl mx-auto'>
        <div className='flex-1 flex flex-col justify-center items-center w-1/2'>
          <AuthCarouselDesktop />
        </div>
        <div className='flex-1 w-1/2 p-12 flex flex-col justify-between relative'>
          <div className='flex justify-end my-3'>
            <img src={`${env.SVGS}/logo.svg`} alt='Fabits' className='h-8' />
          </div>
          <Outlet />

          <div className='mt-2'>
            <p className='text-black-300 text-[10px] text-right font-medium leading-3'>
              <span className='font-semibold'>Shareway Securities Private Limited</span> <br /> SEBI
              Reg. No. INZ000208134 | NSE Member Code 13093 | CDSL - SEBI Reg. No. IN-DP-610-2021 |
              BSE Member ID 61731 | ARN - 310082 <br /> Registered with NSE India Limited with
              approved segments Capital Market and F&O
            </p>
            <div className='text-[10px] flex gap-x-6 justify-end text-primary-500 mt-1'>
              <a
                href='https://fabits.com/Termsandconditions'
                target='_blank'
                rel='noreferrer'
                style={{
                  textDecoration: 'none',
                  display: 'inline-block',
                  borderBottom: `2px dashed ${theme.palette.primary.main}`,
                }}
                className='font-semibold'
              >
                Terms & Conditions
              </a>
              <a
                href='https://fabits.com/privacypolicy'
                target='_blank'
                rel='noreferrer'
                style={{
                  textDecoration: 'none',
                  display: 'inline-block',
                  borderBottom: `2px dashed ${theme.palette.primary.main}`,
                }}
                className='font-semibold'
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginContainer
