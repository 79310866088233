import retret from '../../../assets/images/Retirement.svg'
import emergency from '../../../assets/images/Emergency.svg'
import vacation from '../../../assets/images/Vacation.svg'
import education from '../../../assets/images/Education.svg'
import home from '../../../assets/images/House.svg'
import jewellery from '../../../assets/images/Jewellery.svg'
import passion from '../../../assets/images/Passion.svg'
import vehicle from '../../../assets/images/Vehicle.svg'
import wedding from '../../../assets/images/Wedding.svg'
import purchase from '../../../assets/images/Purchase.svg'
import general from '../../../assets/images/General.svg'

const actionCarouselData = [
  {
    id: 1,
    icon: general,
    title: 'General Savings',
    text: 'Big buys, no more EMIs!',
    tag: '',
    svg: '',
    link: '/6fb2a4ac30ddccb8b418e27864a778dee5409a7da4c55660f49f21b8543289b4/admin/generalsavingplan',
  },
  {
    id: 2,
    icon: education,
    title: 'Education',
    text: 'Build your career, tension-free',
    tag: '',
    svg: '',
    link: '/6fb2a4ac30ddccb8b418e27864a778dee5409a7da4c55660f49f21b8543289b4/admin/educationplan',
  },
  {
    id: 3,
    icon: retret,
    title: 'Retirement',
    text: 'Your old age, taken care of',
    tag: 'Popular',
    svg: '/Plans/popular.svg',
    link: '/6fb2a4ac30ddccb8b418e27864a778dee5409a7da4c55660f49f21b8543289b4/admin/retirementplan',
  },
  {
    id: 4,
    icon: emergency,
    title: 'Emergency',
    text: 'Be prepared at all times',
    tag: 'Must have',
    svg: '/Plans/must_have.svg',
    link: '/6fb2a4ac30ddccb8b418e27864a778dee5409a7da4c55660f49f21b8543289b4/admin/emergencyplan',
  },
  {
    id: 5,
    icon: vacation,
    title: 'Vacation',
    text: 'Beaches or mountains?',
    tag: '',
    svg: '',
    link: '/6fb2a4ac30ddccb8b418e27864a778dee5409a7da4c55660f49f21b8543289b4/admin/vacationplan',
  },
  {
    id: 6,
    icon: home,
    title: 'Home Buying',
    text: 'Save to buy your forever home',
    tag: '',
    svg: '',
    link: '/6fb2a4ac30ddccb8b418e27864a778dee5409a7da4c55660f49f21b8543289b4/admin/homepurchaseplan',
  },
  {
    id: 7,
    icon: jewellery,
    title: 'Jewellery',
    text: 'Buy jewellery affordably.',
    tag: '',
    svg: '',
    link: '/6fb2a4ac30ddccb8b418e27864a778dee5409a7da4c55660f49f21b8543289b4/admin/jewelleryplan',
  },
  {
    id: 8,
    icon: passion,
    title: 'Passion',
    text: 'Set aside for your passion project ',
    tag: '',
    svg: '',
    link: '/6fb2a4ac30ddccb8b418e27864a778dee5409a7da4c55660f49f21b8543289b4/admin/passionplan',
  },
  {
    id: 9,
    icon: wedding,
    title: 'Wedding',
    text: 'Save money for your happiest day ',
    tag: '',
    svg: '',
    link: '/6fb2a4ac30ddccb8b418e27864a778dee5409a7da4c55660f49f21b8543289b4/admin/weddingplan',
  },
  {
    id: 10,
    icon: vehicle,
    title: 'Vehicle',
    text: 'Save to drive your dream car',
    tag: '',
    svg: '',
    link: '/6fb2a4ac30ddccb8b418e27864a778dee5409a7da4c55660f49f21b8543289b4/admin/vehicleplan',
  },
  {
    id: 11,
    icon: purchase,
    title: 'Purchase',
    text: 'Big buys, no more EMIs!',
    tag: '',
    svg: '',
    link: '/6fb2a4ac30ddccb8b418e27864a778dee5409a7da4c55660f49f21b8543289b4/admin/purchaseplan',
  },
]

export default actionCarouselData
