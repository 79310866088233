/* eslint-disable */

import { ChevronDown, ChevronRight } from 'lucide-react'
import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarTrigger } from './ui/menubar'
import { isDematUser, removeToken } from '../utils/AuthUtil'
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar'
import avatar from '../assets/Navbar/Group 146.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userDetailsState } from '../Pages/LoginState'
import NavigationStore from '../store/NavigationStore'
import { useEffect, useState } from 'react'
import { Button } from './ui/button'
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
} from './ui/navigation-menu'
import {
  AccountBalanceWalletOutlined,
  ChevronLeft,
  CurrencyExchangeOutlined,
  CurrencyRupeeSharp,
  EmojiEventsOutlined,
  LocalMallOutlined,
  // Instagram,
  // LinkedIn,
  Logout,
  NotificationsNoneOutlined,
  PersonOutlineOutlined,
  SignalCellularAlt,
  SupportAgentOutlined,
} from '@mui/icons-material'
import useMediaQuery from '../hooks/useMediaQuery'
import twitter from '../assets/Twitter.svg'
import YouTube from '../assets/Youtube.svg'
import LinkedIn from '../assets/Linkedin.svg'
import Instagram from '../assets/Instagram.svg'
import env from '../env/env'

const socials = [
  {
    id: 1,
    icon: YouTube,
    linkTo: 'https://www.youtube.com/@fabits.social',
  },
  {
    id: 2,
    icon: LinkedIn,
    linkTo: 'https://www.linkedin.com/company/fabits/',
  },
  {
    id: 3,
    icon: twitter,
    linkTo: 'https://x.com/fabitssocial',
  },
  {
    id: 4,
    icon: Instagram,
    linkTo: 'https://www.instagram.com/fabits.social',
  },
]

const Navbar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [userDetails] = useRecoilState(userDetailsState)
  const navState = useRecoilValue(NavigationStore)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (showMenu && !isDesktop) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [showMenu, isDesktop])

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY
      setScrollPosition(position)
      setIsTransitioning(position > 300 && position < 404)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const isMFPage = location.pathname.includes('mutual-funds')

  const handleLogout = () => {
    removeToken()
  }

  const getNavbarStyle = () => {
    if (!isMFPage)
      return 'backdrop-blur-md bg-black bg-opacity-30 border-b border-gray-200 border-opacity-25'
    if (isMFPage && scrollPosition < 400) return 'bg-mf-gradient'
    if (isMFPage && scrollPosition >= 504)
      return 'backdrop-blur-md bg-black bg-opacity-30 border-b border-gray-200 border-opacity-25'
    return 'bg-gradient-to-b from-[#eceef4] to-white'
  }

  const getTextStyle = () => {
    if (!isMFPage) return 'text-black-400'
    if (scrollPosition < 300) return 'text-primary-100'
    if (scrollPosition >= 404) return 'text-black-400'
    return `text-primary-100 transition-colors duration-300 ${
      isTransitioning ? 'text-opacity-50' : ''
    }`
  }

  return (
    <>
      <nav
        className={`hidden lg:block w-full h-[52px] fixed top-0 left-0 z-[1000] transition-all duration-200 ${getNavbarStyle()}`}
      >
        <div className='max-w-[1128px] z-10 flex justify-between items-center mx-auto h-full'>
          <div onClick={() => navigate('/dashboard')} className='cursor-pointer'>
            <img
              src={
                isMFPage && scrollPosition < 404 ? `${env.SVGS}/logo2.svg` : `${env.SVGS}/logo.svg`
              }
              alt='Fabits'
            />
          </div>

          <div className='flex gap-x-5 h-full'>
            {navState.slice(0, 3).map((link) => (
              <Link
                key={link.pathName}
                to={link.linkPath}
                className={`${
                  link.highlightUrls.includes(location.pathname)
                    ? isMFPage && scrollPosition < 404
                      ? 'text-white font-semibold'
                      : 'text-primary-500'
                    : getTextStyle()
                } flex items-center font-medium text-sm no-underline transition-colors duration-300`}
                onClick={() => setIsOpen(false)}
              >
                {link.linkName}
              </Link>
            ))}

            {/* More dropdown remains the same */}
            <NavigationMenu>
              <NavigationMenuList>
                <NavigationMenuItem>
                  <div
                    onClick={() => setIsOpen(!isOpen)}
                    className={`cursor-pointer p-0 bg-transparent group inline-flex h-10 w-max items-center justify-center rounded-md text-sm font-medium transition-colors focus:outline-none disabled:pointer-events-none disabled:opacity-50 ${
                      isMFPage && scrollPosition < 404
                        ? getTextStyle()
                        : 'text-black-400 hover:text-black-300'
                    } transition duration-200`}
                  >
                    More{' '}
                    <ChevronDown
                      className={`relative top-[1px] ml-1 h-3 w-3 transition duration-200 ${
                        isOpen ? 'rotate-180' : ''
                      }`}
                      aria-hidden='true'
                    />
                  </div>
                  {isOpen && (
                    <div className='bg-white border border-black-50 rounded-lg p-3 flex top-[50px] flex-col gap-y-2 md:w-[150px] w-full data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out data-[motion=from-end]:slide-in-from-right-52 data-[motion=from-start]:slide-in-from-left-52 data-[motion=to-end]:slide-out-to-right-52 data-[motion=to-start]:slide-out-to-left-52 md:absolute'>
                      {navState.slice(3, navState.length).map((link) => (
                        <NavigationMenuLink className='no-underline' key={link.pathName}>
                          <Link
                            to={link.linkPath}
                            className={`${
                              link.highlightUrls.includes(location.pathname)
                                ? ' text-primary-500'
                                : 'text-black-400 hover:text-primary-400'
                            } flex justify-between items-center font-medium text-sm no-underline`}
                            onClick={() => setIsOpen(false)}
                          >
                            {link.linkName}

                            {/* <ChevronRight className='h-4 w-4' /> */}
                          </Link>
                        </NavigationMenuLink>
                      ))}
                    </div>
                  )}
                </NavigationMenuItem>
              </NavigationMenuList>
            </NavigationMenu>
          </div>

          <div className='flex items-center'>
            {/* Notification icon */}
            {/* <div className='flex items-center mr-6 cursor-pointer'>
              <NotificationsNoneOutlined
                id='web-inbox-placeholder'
                sx={{
                  color: isMFPage && scrollPosition < 404 ? 'white' : '#575757',
                  width: '24px',
                  height: '24px',
                  transition: 'color 300ms ease-in-out',
                }}
              />
            </div> */}

            {/* User name */}
            <p
              className={`capitalize text-sm font-semibold mr-4 ${
                isMFPage && scrollPosition < 404 ? 'text-white' : 'text-black-400'
              } transition-colors duration-300`}
            >
              {userDetails.firstName} {userDetails.lastName}
            </p>

            {/* Avatar and menu remain the same */}
            <Menubar>
              <MenubarMenu className='bg-transparent'>
                <MenubarTrigger onClick={() => setShowMenu(true)}>
                  <Avatar className='cursor-pointer h-7 w-7'>
                    {/* <AvatarImage src={avatar} alt={userDetails.firstName} /> */}
                    <AvatarFallback className='bg-primary-50 text-black-400'>
                      {userDetails?.firstName?.slice(0, 1)}
                    </AvatarFallback>
                  </Avatar>
                </MenubarTrigger>

                {isDematUser() ? (
                  showMenu && !isDesktop ? (
                    <MenubarContent className='relative top-[-65px] w-[100vw] h-[100vh] z-[9999] bg-white flex flex-col gap-y-[2px] p-0'>
                      <MenubarItem className='flex justify-between py-4 px-5'>
                        <p onClick={() => setShowMenu(false)}>
                          <ChevronLeft /> Profile
                        </p>
                        <div className='flex lg:hidden justify-center items-center gap-x-4'>
                          {/* {!isDesktop && (
                            <NotificationsNoneOutlined
                              onClick={() => setShowMenu(false)}
                              id='web-inbox-placeholder'
                              sx={{
                                color: '#575757',
                                width: '24px',
                                height: '24px',
                                transition: 'color 300ms ease-in-out',
                              }}
                            />
                          )} */}
                        </div>
                      </MenubarItem>
                      <MenubarItem className='flex justify-center py-4 px-5'>
                        <div className='grid justify-items-center'>
                          <div className='h-[72px] w-[72px] aspect-square bg-primary-200 rounded-full text-white flex justify-center items-center text-xl'>
                            {userDetails?.firstName?.slice(0, 1)}
                          </div>
                          <div className='text-black-500 font-DMSans font-semibold mt-2 text-xl'>
                            {userDetails.firstName} {userDetails.lastName}
                          </div>
                        </div>
                      </MenubarItem>

                      <div className='px-5 grid gap-4 my-4 text-sm'>
                        <div>
                          <p className='text-black-200 font-DMSans font-medium text-[10px] leading-3'>
                            ACCOUNT
                          </p>

                          <Link className='no-underline' to='/dashboard/profile'>
                            <MenubarItem
                              className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                              onClick={() => setShowMenu(false)}
                            >
                              <PersonOutlineOutlined
                                sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                              />

                              <p>Account Details</p>
                            </MenubarItem>
                          </Link>

                          <Link className='no-underline text-black-500' to='/dashboard/refer'>
                            <MenubarItem
                              className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                              onClick={() => setShowMenu((prev) => !prev)}
                            >
                              <EmojiEventsOutlined
                                sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                              />

                              <p>Refer & Rewards</p>
                            </MenubarItem>
                          </Link>

                          <Link className='no-underline' to='/dashboard/funds'>
                            <MenubarItem
                              className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                              onClick={() => setShowMenu(false)}
                            >
                              <AccountBalanceWalletOutlined
                                sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                              />
                              <p>Wallet</p>
                            </MenubarItem>
                          </Link>
                        </div>
                        <div>
                          <p className='text-black-200 font-medium text-[10px] leading-3'>
                            INVESTMENTS
                          </p>

                          <Link className='no-underline' to='/dashboard/sips'>
                            <MenubarItem
                              className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                              onClick={() => setShowMenu(false)}
                            >
                              <CurrencyExchangeOutlined
                                sx={{ fontSize: '18px', lineHeight: '28px', color: '#2d2d2d' }}
                              />

                              <p>SIPs</p>
                            </MenubarItem>
                          </Link>

                          <Link className='no-underline' to='/dashboard/portfolio'>
                            <MenubarItem
                              className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                              onClick={() => setShowMenu(false)}
                            >
                              <LocalMallOutlined
                                sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                              />

                              <p>My Portfolio</p>
                            </MenubarItem>
                          </Link>

                          <Link className='no-underline' to='/dashboard/theme'>
                            <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                              <SignalCellularAlt
                                sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                              />

                              <p>Exchange Traded Funds (ETFs)</p>
                            </MenubarItem>
                          </Link>
                        </div>
                        <div>
                          <p className='text-black-200 font-medium text-[10px] leading-3'>
                            CONNECT
                          </p>

                          <Link className='no-underline' to='/dashboard/support'>
                            <MenubarItem
                              className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                              onClick={() => setShowMenu(false)}
                            >
                              <SupportAgentOutlined
                                sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                              />

                              <p>Support</p>
                            </MenubarItem>
                          </Link>
                        </div>
                        <div className='flex justify-center'>
                          <MenubarItem className='justify-between w-44 p-0'>
                            {socials.map((social, index) => {
                              return (
                                <div key={social.id || index}>
                                  <a href={social.linkTo} target='_blank' rel='noopener noreferrer'>
                                    <img src={social.icon} alt={social.id} />
                                  </a>
                                </div>
                              )
                            })}
                          </MenubarItem>
                        </div>
                      </div>

                      <div
                        className='cursor-pointer px-5 my-4  flex justify-center'
                        onClick={() => handleLogout()}
                      >
                        <div className='px-5 py-3 font-DMSans rounded-md font-medium text-sm text-error-500'>
                          Logout
                        </div>
                      </div>
                    </MenubarContent>
                  ) : (
                    <MenubarContent
                      className='rounded-[20px] min-w-60 flex flex-col gap-y-[2px] p-0'
                      align='center'
                    >
                      <MenubarItem className='justify-between bg-[#F4F7FE] py-4 px-5'>
                        <div className='flex gap-x-4 items-center'>
                          <Avatar className='h-7 w-7'>
                            {/* <AvatarImage src={avatar} alt={userDetails.firstName} /> */}
                            <AvatarFallback className='bg-primary-100 text-black-400'>
                              {userDetails?.firstName?.slice(0, 1)}
                            </AvatarFallback>
                          </Avatar>

                          <div className='text-black-500 font-DMSans font-semibold text-base'>
                            {userDetails.firstName}
                          </div>
                        </div>
                        <div className='cursor-pointer'>
                          <Logout onClick={handleLogout} sx={{ color: '#ff6666', fontSize: 20 }} />
                        </div>
                      </MenubarItem>

                      <div className='px-5 grid gap-4 my-4 text-sm'>
                        <div>
                          <p className='text-black-200 font-medium text-[10px] leading-3'>
                            ACCOUNT
                          </p>

                          <Link className='no-underline' to='/dashboard/profile'>
                            <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                              <PersonOutlineOutlined
                                sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                              />

                              <p>Account Details</p>
                            </MenubarItem>
                          </Link>

                          <Link className='no-underline text-black-500' to='/dashboard/refer'>
                            <MenubarItem
                              className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                              onClick={() => setShowMenu((prev) => !prev)}
                            >
                              <EmojiEventsOutlined
                                sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                              />

                              <p>Refer & Rewards</p>
                            </MenubarItem>
                          </Link>

                          <Link className='no-underline' to='/dashboard/funds'>
                            <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                              <AccountBalanceWalletOutlined
                                sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                              />

                              <p>Wallet</p>
                            </MenubarItem>
                          </Link>
                        </div>
                        <div>
                          <p className='text-black-200 font-medium text-[10px] leading-3'>
                            INVESTMENTS
                          </p>

                          <Link className='no-underline' to='/dashboard/sips'>
                            <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                              <CurrencyExchangeOutlined
                                sx={{ fontSize: '18px', lineHeight: '28px', color: '#2d2d2d' }}
                              />
                              <p>SIPs</p>
                            </MenubarItem>
                          </Link>

                          <Link className='no-underline' to='/dashboard/portfolio'>
                            <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                              <LocalMallOutlined
                                sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                              />

                              <p>My Portfolio</p>
                            </MenubarItem>
                          </Link>

                          <Link className='no-underline' to='/dashboard/theme'>
                            <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                              <SignalCellularAlt
                                sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                              />

                              <p>Exchange Traded Funds (ETFs)</p>
                            </MenubarItem>
                          </Link>
                        </div>
                        <div>
                          <p className='text-black-200 font-medium text-[10px] leading-3'>
                            CONNECT
                          </p>

                          <Link className='no-underline' to='/dashboard/support'>
                            <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                              <SupportAgentOutlined
                                sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                              />

                              <p>Support</p>
                            </MenubarItem>
                          </Link>
                        </div>
                        <div className='flex justify-center'>
                          <MenubarItem className='justify-between w-36 p-0'>
                            {socials.map((social, index) => {
                              return (
                                <div
                                  key={social.id || index}
                                  className='h-6 w-6 rounded-full flex justify-center items-center cursor-pointer'
                                >
                                  <a href={social.linkTo} target='_blank' rel='noopener noreferrer'>
                                    <img src={social.icon} alt={social.id} />
                                  </a>
                                </div>
                              )
                            })}
                          </MenubarItem>
                        </div>
                      </div>
                    </MenubarContent>
                  )
                ) : showMenu && !isDesktop ? (
                  <MenubarContent className='relative top-[-65px] w-[100vw] h-[100vh] z-[9999] bg-white flex flex-col gap-y-[2px] p-0'>
                    <MenubarItem className='flex justify-between py-4 px-5'>
                      <p onClick={() => setShowMenu(false)}>
                        <ChevronLeft /> Profile
                      </p>
                      <div className='flex lg:hidden justify-center items-center gap-x-4'>
                        {/* {!isDesktop && (
                          <NotificationsNoneOutlined
                            onClick={() => setShowMenu(false)}
                            id='web-inbox-placeholder'
                            sx={{
                              color: '#575757',
                              width: '24px',
                              height: '24px',
                              transition: 'color 300ms ease-in-out',
                            }}
                          />
                        )} */}
                      </div>
                    </MenubarItem>
                    <MenubarItem className='flex justify-center py-4 px-5'>
                      <div className='grid justify-items-center'>
                        <div className='h-[72px] w-[72px] aspect-square bg-primary-200 rounded-full text-white flex justify-center items-center text-xl'>
                          {userDetails?.firstName?.slice(0, 1)}
                        </div>
                        <div className='text-black-500 font-DMSans font-semibold mt-2 text-xl'>
                          {userDetails.firstName} {userDetails.lastName}
                        </div>
                      </div>
                    </MenubarItem>
                    {/* <Link className='no-underline' to='/dashboard/profile'>
                        <MenubarItem className='flex justify-between cursor-pointer'>
                          <p>{userDetails.firstName + ' ' + userDetails.lastName}</p>
                          <ChevronRight className='h-5 w-5' />
                        </MenubarItem>
                      </Link> */}
                    <div className='px-5 grid gap-4 my-4 no-underline'>
                      <Link className='no-underline text-black-500' to='/dashboard/refer'>
                        <MenubarItem
                          className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                          onClick={() => setShowMenu((prev) => !prev)}
                        >
                          <EmojiEventsOutlined
                            sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                          />
                          {/* <span class='material-symbols-outlined text-xl'>emoji_events</span> */}
                          <p>Refer & Rewards</p>
                        </MenubarItem>
                      </Link>

                      <div>
                        <p className='text-black-200 font-medium text-[10px] leading-3'>CONNECT</p>

                        <Link className='no-underline' to='/dashboard/support'>
                          <MenubarItem
                            className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                            onClick={() => setShowMenu(false)}
                          >
                            <SupportAgentOutlined
                              sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                            />
                            {/* <span className='material-symbols-outlined text-xl text-black-500'>
                              support_agent
                            </span> */}
                            <p>Support</p>
                          </MenubarItem>
                        </Link>
                      </div>
                      <div className='flex justify-center'>
                        <MenubarItem className='justify-between w-44 p-0'>
                          {socials.map((social, index) => {
                            return (
                              <div
                                key={social.id || index}
                                // className='bg-black-500 h-8 w-8 rounded-full flex justify-center items-center cursor-pointer'
                                // onClick={() => setShowMenu(false)}
                              >
                                <a href={social.linkTo} target='_blank' rel='noopener noreferrer'>
                                  <img src={social.icon} alt={social.id} />
                                </a>
                              </div>
                            )
                          })}
                        </MenubarItem>
                      </div>
                    </div>

                    <div
                      className='cursor-pointer px-5 my-4  flex justify-center'
                      onClick={() => handleLogout()}
                    >
                      <div className='px-5 py-3 font-DMSans rounded-md font-medium text-sm text-error-500'>
                        Logout
                      </div>
                    </div>
                  </MenubarContent>
                ) : (
                  <MenubarContent
                    className='rounded-[20px] flex flex-col gap-y-[2px] w-[280px] p-0'
                    align='center'
                  >
                    <MenubarItem className='justify-between bg-[#F4F7FE] py-4 px-5'>
                      <div className='flex gap-x-4 items-center'>
                        <Avatar className='h-7 w-7'>
                          {/* <AvatarImage src={avatar} alt={userDetails.firstName} /> */}
                          <AvatarFallback className='bg-primary-100 text-black-400'>
                            {userDetails?.firstName?.slice(0, 1)}
                          </AvatarFallback>
                        </Avatar>

                        <div className='text-black-500 font-DMSans font-semibold text-base'>
                          {userDetails.firstName}
                        </div>
                      </div>
                      <div className='cursor-pointer'>
                        <Logout onClick={handleLogout} sx={{ color: '#ff6666', fontSize: 20 }} />
                      </div>
                    </MenubarItem>
                    {/* <Link className='no-underline' to='/dashboard/profile'>
                        <MenubarItem className='flex justify-between cursor-pointer'>
                          <p>{userDetails.firstName + ' ' + userDetails.lastName}</p>
                          <ChevronRight className='h-5 w-5' />
                        </MenubarItem>
                      </Link> */}
                    <div className='px-5 grid gap-4 my-4 no-underline'>
                      <Link className='no-underline text-black-500' to='/dashboard/refer'>
                        <MenubarItem
                          className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                          onClick={() => setShowMenu((prev) => !prev)}
                        >
                          <EmojiEventsOutlined
                            sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                          />
                          {/* <span class='material-symbols-outlined text-xl'>emoji_events</span> */}
                          <p>Refer & Rewards</p>
                        </MenubarItem>
                      </Link>

                      <div>
                        <p className='text-black-200 font-medium text-[10px] leading-3'>CONNECT</p>

                        <Link className='no-underline' to='/dashboard/support'>
                          <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                            <SupportAgentOutlined
                              sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                            />
                            {/* <span className='material-symbols-outlined text-xl text-black-500'>
                              support_agent
                            </span> */}
                            <p>Support</p>
                          </MenubarItem>
                        </Link>
                      </div>
                      <div className='flex justify-center'>
                        <MenubarItem className='justify-between w-36 p-0'>
                          {socials.map((social, index) => {
                            return (
                              <div
                                key={social.id || index}
                                className=' h-6 w-6 rounded-full flex justify-center items-center cursor-pointer'
                              >
                                <a href={social.linkTo} target='_blank' rel='noopener noreferrer'>
                                  <img src={social.icon} alt={social.id} />
                                </a>
                              </div>
                            )
                          })}
                        </MenubarItem>
                      </div>
                    </div>
                  </MenubarContent>
                )}
              </MenubarMenu>
            </Menubar>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar
