/* eslint-disable */
import { useEffect, useState } from 'react'
import MFIndividualInvestmentsTable from '../../Components/MutualFunds/MFIndividualInvestmentsTable'
import { useRecoilValueLoadable } from 'recoil'
import { MutualFundsHolding } from '../../store/MFStore'
import { MFBasketHoldings } from '../../store/MFBasketsStore'
import MFInvestmentSkeleton from '../../Components/SkeletonLoaders/MFInvestmentSkeleton'
import env from '../../env/env'
import { Card, CardContent, CardHeader, CardTitle } from '../../Components/ui/card'
import { Button } from '../../Components/ui/button'
import { Link } from 'react-router-dom'
import { Refresh } from '@mui/icons-material'
import { MoveRight } from 'lucide-react'

const MFIndividualInvestments = () => {
  const mfHolding = useRecoilValueLoadable(MutualFundsHolding)
  const orders = useRecoilValueLoadable(MFBasketHoldings)

  const [invidualHoldings, setInvidualHoldings] = useState({})

  const groupByBasketId = (orders) => {
    const groupedOrders = {} // Define groupedOrders inside function

    orders.forEach((order) => {
      if (!groupedOrders[order?.bseSchemeCode.toLowerCase()]) {
        groupedOrders[order?.bseSchemeCode.toLowerCase()] = {
          basketNetInvestedAmount: +order?.netInvestedAmount || 0,
          basketCurrentNav: +order?.currentNav || 0,
          basketNetUnits: +order?.netUnits || 0,
        }
      } else {
        groupedOrders[order?.bseSchemeCode.toLowerCase()].basketNetInvestedAmount +=
          order?.netInvestedAmount || 0
        groupedOrders[order?.bseSchemeCode.toLowerCase()].basketNetUnits += order?.netUnits || 0
      }
    })

    return groupedOrders
  }

  useEffect(() => {
    if (
      mfHolding?.state === 'hasValue' &&
      orders.state === 'hasValue' &&
      orders?.contents?.holdings?.length > 0
    ) {
      const groupedOrders = groupByBasketId(orders.contents.holdings)

      const holdings =
        mfHolding?.contents?.mainData
          ?.map((item) => {
            if (
              item?.holdingType !== 'PHYSICAL' &&
              groupedOrders[item?.bseSchemeCode?.toLowerCase()]
            ) {
              return {
                ...item, // Spread existing properties to avoid mutation
                netInvestedAmt:
                  (item?.netInvestedAmt || 0) -
                  (groupedOrders[item.bseSchemeCode.toLowerCase()]?.basketNetInvestedAmount || 0),
                balance:
                  (item?.balance || 0) -
                  (groupedOrders[item.bseSchemeCode.toLowerCase()]?.basketNetUnits || 0),
                holding:
                  (item?.holding || 0) -
                  groupedOrders[item?.bseSchemeCode.toLowerCase()].basketCurrentNav *
                    groupedOrders[item?.bseSchemeCode.toLowerCase()].basketNetUnits,
              }
            }

            return item
          })
          ?.filter((item) => Number(item.balance) > 0) || {}

      setInvidualHoldings({ mainData: holdings })
    } else if (orders.state === 'hasError') {
      console.error('Error fetching orders:', orders.contents)
    }
  }, [orders, mfHolding])

  switch (mfHolding.state) {
    case 'loading':
      return <MFInvestmentSkeleton />

    case 'hasValue':
      return (
        <>
          {mfHolding.state === 'hasValue' && (
            <MFIndividualInvestmentsTable
              holdings={invidualHoldings?.mainData ? invidualHoldings : mfHolding.contents}
            />
          )}
        </>
      )

    case 'hasError':
      return (
        <div className='bg-white flex items-center justify-center'>
          <Card className='w-full max-w-3xl text-center border-none shadow-none font-DMSans'>
            <CardHeader className='flex flex-col gap-y-6 justify-center items-center'>
              <img className='h-14 w-14' src={`${env.SVGS}/error-icon.svg`} alt='Error-Icon' />
              <CardTitle className='font-semibold text-base'>Oops! Something Went Wrong</CardTitle>
            </CardHeader>
            <CardContent className='flex flex-col gap-y-6'>
              <p className='text-sm leading-5 text-black-400 mb-6'>
                We're sorry, but it seems there was an issue connecting to our server. Please check
                your internet connection or try refreshing the page. If the problem persists, try
                again later.
              </p>

              {/* <div className='flex gap-x-2 justify-between'>
                <Button
                  className='w-full'
                  variant='fabitsWhite'
                  onClick={() => window.location.reload()}
                >
                  <Refresh className='mr-2 h-4 w-4 text-black-300' /> Refresh
                </Button>

                <Link className='flex-1' to='/dashboard/mutual-funds'>
                  <Button className='w-full'>
                    View Funds
                    <MoveRight className='ml-2 h-4 w-4' />
                  </Button>
                </Link>
              </div> */}
            </CardContent>
          </Card>
        </div>
      )

    default:
      return null
  }
}

export default MFIndividualInvestments
