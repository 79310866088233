import Loader from './Loader/Loader'

const LoadingSkeleton = () => {
  return (
    <div className='min-h-screen flex flex-col justify-center items-center'>
      <Loader />
    </div>
  )
}

export default LoadingSkeleton
