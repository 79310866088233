import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '../../Components/ui/dialog'
import { Button } from '../../Components/ui/button'
import { useSetRecoilState } from 'recoil'
import { messageModalState } from '../../store/Feedback'
import { useNavigate } from 'react-router-dom'
import env from '../../env/env'
import { isDematUser } from '../../utils/AuthUtil'

const CompletionPopup = ({ isOpen, onClose, setIsPopupOpen }) => {
  const setMessageModalState = useSetRecoilState(messageModalState)
  const navigate = useNavigate()

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className='px-4 max-w-80 rounded-[12px]'>
        <DialogHeader>
          <DialogTitle className='flex justify-center mb-9'>
            <img src={`${env.S3_BASE_URL}/firstTime/hurray.svg`} alt='hurray' />
          </DialogTitle>
          <DialogDescription>
            <h1 className='font-DMSans font-semibold text-2xl text-primary-600'>Hurray!</h1>
            <p className='font-DMSans font-normal text-sm mt-[9px]'>
              Thank you for your time. <br /> We will contact within{' '}
              <span className='font-bold'>24</span> to <span className='font-bold'>48</span> hours
            </p>
          </DialogDescription>
        </DialogHeader>
        {isDematUser() ? (
          <Button
            onClick={() => {
              setIsPopupOpen(false)
              navigate('/dashboard')
            }}
            className='mt-[32px]'
          >
            Close
          </Button>
        ) : (
          <Button
            onClick={() => {
              setIsPopupOpen(false)
              navigate('/dashboard')
              setMessageModalState({
                message: 'Your KYC is incomplete.',
                linkMessage:
                  'Click here to complete your KYC and to enable hassle free, one-click investing.',
                severity: 'info',
              })
            }}
            className='mt-[32px]'
          >
            Complete your KYC
          </Button>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default CompletionPopup
