import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../Components/ui/dialog'
import { Button } from '../../Components/ui/button'
import useMediaQuery from '../../hooks/useMediaQuery'
import IntroVideoMobile from './IntroVideoMobile'

const IntroVideo = ({ showDialog, setShowDialog, handleVideoEnd }) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  return (
    <>
      {isDesktop ? (
        <Dialog open={showDialog} onOpenChange={() => setShowDialog(true)}>
          <DialogContent className='sm:max-w-[586px] max-w-[334px] sm:rounded-[16px] rounded-[10px]'>
            <DialogHeader>
              <DialogTitle className='font-DMSans font-semibold text-2xl'>Why Fabits ?</DialogTitle>
              <DialogDescription className='font-DMSans font-normal text-base text-black-400'>
                While technology has consumed every facet of our lives, we believe that it can’t
                truly replace the human connect.
              </DialogDescription>
            </DialogHeader>
            <div className='flex justify-center'>
              <video width='300' autoPlay controls>
                <source
                  src={'https://d3lgi69rk7563.cloudfront.net/ProductVideos/introvideo.mp4'}
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <DialogFooter className='sm:justify-start flex-row flex sm:gap-0 gap-[10px] h-[66px]'>
              <DialogClose asChild>
                <Button
                  type='button'
                  className='flex-1 bg-white text-[#A8A8A8] hover:text-white hover:bg-black-100'
                  style={{ border: '1.5px solid #F0F0F0', boxShadow: '0px 6px 6px 0px #41558D0A' }}
                  onClick={handleVideoEnd}
                >
                  Skip
                </Button>
              </DialogClose>
              <Button onClick={() => setShowDialog(false)} type='button' className='flex-1'>
                <span>Get Started</span>
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      ) : (
        <IntroVideoMobile
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          handleVideoEnd={handleVideoEnd}
        />
      )}
    </>
  )
}

export default IntroVideo
