/* eslint-disable */
import {
  IconButton,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  SliderThumb,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme,
  useMediaQuery,
  Tooltip,
} from '@mui/material'
import Retirement from '../../assets/images/Retirement.svg'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import CancelIcon from '@mui/icons-material/Cancel'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { useEffect, useState } from 'react'
import { indian__currency__rounder, toCurrencyNoDecimal } from '../../utils/CurrencyUtil'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import { useFormik } from 'formik'
import { object, string, number } from 'yup'
import { getUserDetails, isDematUser } from '../../utils/AuthUtil'
import { useLocation, useNavigate } from 'react-router-dom'
import { patch, post } from '../../http/restservice'
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { messageModalState } from '../../store/Feedback'
import { MFTimeBasedBaskets } from '../../store/MFBasketsStore'
import { InvestedPlansSelector } from '../../store/InvestedPlansStore'
import { userDetailsState } from '../LoginState'
import { useErrorPopup } from '../../hooks/useErrorPopup'

const ThumbComponent = (props) => {
  const { children, ...other } = props
  return (
    <SliderThumb {...other}>
      {children}
      <span className='thumb-bar' />
      <span className='thumb-bar' />
    </SliderThumb>
  )
}

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))((props) => ({
  color: '#fefefe',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    borderBottomLeftRadius: props.isDesktop ? '24px' : 0,
    borderBottomRightRadius: props.isDesktop ? '24px' : 0,
  },
}))

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
  backgroundColor: '#ECEEF4',
  color: '#41558D',
  padding: '20px 32px 20px 32px',
  border: '1px solid #D3DBEC',
  borderTop: 'none',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.secondary.text,
  },
  '& .MuiAccordionSummary-content': {
    margin: '12px 0',
    alignItems: 'center',
  },
}))

const AccordionDetails = styled((props) => <MuiAccordionDetails {...props} />)((props) => ({
  padding: 0,
  color: '#fefefe',
  backgroundColor: '#fff',
  border: '1px solid #D3DBEC',
  borderTop: 'none',
  borderBottomLeftRadius: props.isDesktop ? '24px' : 0,
  borderBottomRightRadius: props.isDesktop ? '24px' : 0,
}))

function ValueLabelComponent(props) {
  const { children, open, value } = props

  return (
    <Tooltip open={open} enterTouchDelay={0} placement='top' title={value}>
      {children}
    </Tooltip>
  )
}

const SliderWithTooltip = (props) => {
  const { value, ...otherProps } = props
  return (
    <CustomisedSlider
      {...otherProps}
      components={{
        ValueLabelComponent: ValueLabelComponent,
      }}
      valueLabelDisplay='auto'
    />
  )
}

const CustomisedSlider = styled(Slider)(() => {
  const theme = useTheme()
  return {
    color: theme.palette.primary.main,
    height: 12,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 44,
      width: 16,
      borderRadius: '8px',
      boxShadow: '0px 2px 6px 0px #41558D3D',
      backgroundColor: '#fff',
      '&::before': {
        display: 'none',
      },
      '& .thumb-bar': {
        height: 10,
        width: 1,
        backgroundColor: 'currentColor',
        marginLeft: 1,
        marginRight: 1,
      },
    },
    '& .MuiSlider-rail': {
      backgroundColor: '#ccc',
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 40,
      height: 40,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: theme.palette.primary.main,
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&::before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
    '& .MuiSlider-markLabel[data-index="1"]': {
      transform: 'translateX(-100%)',
    },
    '& .MuiSlider-markLabelActive[data-index="0"]': {
      transform: 'none',
    },
  }
})

const validationInvestmentDetails = object({
  amount: number().required('Required field').positive('Enter valid Amount'),
  type: string().required('Required field'),
})

const RetirementCalculator = () => {
  const location = useLocation()
  const plan = location.state ? location.state.item : null

  const baskets = useRecoilValueLoadable(MFTimeBasedBaskets)
  const { state, contents: investedPlans } = useRecoilValueLoadable(InvestedPlansSelector)

  const [userDetails] = useRecoilState(userDetailsState)

  const clientCode = userDetails?.uid?.split('-')[0]
  const { showError } = useErrorPopup()

  const isAllInputDisabled =
    plan?.status === 'INVESTED' || plan?.status === 'PARTIALLY_INVESTED' ? true : false
  const [age, setAge] = useState(plan?.age ? plan?.age : 25)

  const [desiredAge, setDesiredAge] = useState(plan?.retireAt ? plan?.retireAt : 60)

  const [monthlyExpenses, setMonthlyExpenses] = useState(
    plan?.monthlyExpenses ? plan.monthlyExpenses : 30000,
  )
  const [sipGrowth, setSIPGrowth] = useState(plan?.sipGrowth ? plan.sipGrowth : 0)
  const [lumpsum, setLumpsum] = useState(plan?.lumpsum ? plan?.lumpsum : 0)
  const navigate = useNavigate()

  const [sip, setSIP] = useState(plan?.results?.monthlySIP)
  const [goalAmt, setGoalAmt] = useState(plan?.results?.table['1'].corpus)
  const [dateStr, setDateStr] = useState(plan?.targetYear ? 'January ' + plan?.targetYear : null)
  const [currentInvestments, setCurrentInvestments] = useState(
    plan?.currentInvestment ? plan?.currentInvestment : [],
  )
  const [planName, setPlanName] = useState()
  const [updatedPlanName, setUpdatedPlanName] = useState()
  const [editPlanName, setEditPlanName] = useState(false)
  const [customSIP, setCustomeSIP] = useState(plan?.monthlySIP)
  const [results, setResults] = useState(plan?.results)
  const isDesktop = useMediaQuery('(min-width:900px)')
  const setMessageModalState = useSetRecoilState(messageModalState)
  const [error, setError] = useState({
    desiredAgeError: '',
    lumpsumError: '',
    currentExpenseError: '',
  })

  const validateForm = () => {
    let newError = { ...error }

    if (desiredAge < age) {
      newError.desiredAgeError = 'Desired retirement age cannot be less than the current age'
    } else {
      newError.desiredAgeError = ''
    }

    if (monthlyExpenses < 1000) {
      newError.currentExpenseError = 'Monthly expense cannot be less than ₹ 1000.'
    } else {
      newError.currentExpenseError = ''
    }

    if (lumpsum < 0) {
      newError.lumpsumError = 'Lumpsum cannot be negative'
    } else {
      newError.lumpsumError = ''
    }

    setError(newError)
  }

  useEffect(() => {
    validateForm()
  }, [age, desiredAge, monthlyExpenses, lumpsum])

  const DEFAULT_INFLATION = 8
  const PORTFOLIO_RETURN = 10
  const RISK_FREE_RETURN = 7
  const LIFEEXPECTATION = 90

  const onAgeSliderChange = (event, newValue) => {
    console.log(newValue)
    setAge(newValue)
  }

  const formikForInvestments = useFormik({
    initialValues: {
      type: '',
      amount: '',
    },
    validationSchema: validationInvestmentDetails,
    onSubmit: () => {},
  })

  useEffect(() => {
    calculateValues()
    const details = getUserDetails()
    const plans =
      state === 'hasValue' && investedPlans
        ? investedPlans?.filter((plan) => plan.basketId === 12)
        : []
    setPlanName(
      plan?.planName
        ? plan.planName
        : details?.firstName + `'s Retirement Plan ${plans.length > 0 ? plans.length : ''}`,
    )
    setUpdatedPlanName(
      plan?.planName
        ? plan.planName
        : details?.firstName + `'s Retirement Plan ${plans.length > 0 ? plans.length : ''}`,
    )
  }, [])

  const onAgeBlur = (event) => {
    console.log(event.target.value)
    setAge(event.target.value)
    calculateValues(event)
  }

  const onDesiredAgeSliderChange = (event, newValue) => {
    setDesiredAge(newValue)
  }

  const onDesiredAgeBlur = (event) => {
    console.log(event.target.value)
    setDesiredAge(event.target.value)
    calculateValues(event)
  }

  const onSIPGrowthBlur = (event) => {
    setSIPGrowth(event.target.value)
    calculateValues(event)
  }

  const onMonthlyExpensesSliderChange = (event, newValue) => {
    setMonthlyExpenses(newValue)
  }

  const onMonthlyExpensesAgeBlur = (event) => {
    setMonthlyExpenses(event.target.value)
    calculateValues(event)
  }

  const onSIPGrowthSliderChange = (event, newValue) => {
    setSIPGrowth(newValue)
  }

  const onLumpsumSliderChange = (event, newValue) => {
    console.log(newValue)
    setLumpsum(newValue)
  }

  const onLumpsumOnBlur = (event) => {
    console.log(event.target.value)
    setLumpsum(event.target.value)
    calculateValues(event)
  }

  const formatMonthYear = (date) => {
    let options = { year: 'numeric', month: 'long' }
    return date.toLocaleDateString('en-US', options)
  }

  const addCurrentInvestment = () => {
    let plans = [...currentInvestments]
    console.log(formikForInvestments?.values?.type)
    console.log(formikForInvestments?.values?.amount)
    plans.push({
      type: formikForInvestments?.values?.type,
      amount: formikForInvestments?.values?.amount,
    })
    formikForInvestments.resetForm()
    setCurrentInvestments(plans)
  }

  useEffect(() => {
    calculateValues()
  }, [currentInvestments, lumpsum, monthlyExpenses, age, desiredAge])

  useEffect(() => {
    clevertap.event.push('Page Viewed', {
      Page_name: 'RetirementCalculatorNew Page',
    })
  }, [])

  // this.name = name;
  // 	this.spouseName = spouseName;
  // 	this.age = age;
  // 	this.retireAt = retireAt;
  // 	this.lifeExpectation = lifeExpectation;
  // 	this.monthlyExpenses = monthlyExpenses;
  // 	this.inflation = inflation;
  // 	this.lifeStyleInflation = lifeStyleInflation;
  // 	this.sipGrowth = sipGrowth;
  // 	this.lumpsum = lumpsum;
  // 	this.currentInvestment = currentInvestment;

  const savePlan = async () => {
    const basketData = baskets.contents.data
    const sipDuration = desiredAge - age
    const investmentType = 'RETIREMENT'
    const lumpsumAmount = lumpsum ? Number(lumpsum) : 0
    const customSIPAmount = Number(customSIP)

    const userBasket = filterBasket(
      basketData,
      sipDuration,
      investmentType,
      lumpsumAmount,
      customSIPAmount,
    )

    const educationConstituentsWeightage = {
      earg: 20, // Nippon Large Cap Fund
      'pp001rg-gr': 25, // Parag Parikh Flexi Cap Fund
      dfg: 25, // ICICI Prudential Value Discovery Fund
      'edutrg-gr': 10, // Edelweiss US Tech Fund
      'edmarg-gr': 20, // Edelweiss Multi Asset Allocation Fund
    }

    const retirementConstituentsWeightageSIP = {
      dynmic: 30, // ICICI Pru multi asset
      'edutrg-gr': 20, // Edelweiss US Tech Fund
      'rmfscpgp-gr': 10, // Nippon Small Cap
      'hdfcmcog-gr': 10, // HDFC Mid-Cap Opportunities Reg
      'edmarg-gr': 10, // Edelweiss Multi Asset Allocation Fund
      'qumogp-gr': 20, // Quant Momentum Fund
    }

    const retirementConstituentsWeightageOneTime = {
      dynmic: 35, // ICICI Pru multi asset
      'edutrg-gr': 20, // Edelweiss US Tech Fund
      'rmfscpgp-gr': 0, // Nippon Small Cap
      'hdfcmcog-gr': 10, // HDFC Mid-Cap Opportunities Reg
      'edmarg-gr': 10, // Edelweiss Multi Asset Allocation Fund
      'qumogp-gr': 25, // Quant Momentum Fund
    }

    const oneToThreeYrConstituentsWeightage = {
      'km35-gr': 50, // Kotak Equity Savings
      'pp001rg-gr': 50, // Parag Parikh Flexi Cap Fund
    }

    const oneYrConstituentsWeightage = {
      'edmarg-gr': 50, // Edelweiss Multi Asset Allocation Fund
      'icici1477-gr': 50, // ICICI Pru Corporate Debt Fund
    }

    const anytimeConstituentsWeightage = {
      'rmfgdgp-gr': 30, // Nippon Gold Fund
      'km35-gr': 70, // Kotak Equity Savings
    }

    const basketName = userBasket[0]?.universalBasketName?.toLowerCase()

    const filteredConstituents =
      baskets?.state === 'hasValue'
        ? baskets?.contents?.data
            ?.filter((fund) => fund?.universalBasketId === userBasket[0].universalBasketId)
            .map((fund) => ({
              ...fund,

              sipWeightage:
                customSIP >= 5000 && basketName.includes('retirement')
                  ? retirementConstituentsWeightageSIP[`${fund.bseSchemeCode.toLowerCase()}`]
                  : null,
              weightage: basketName.includes('retirement')
                ? retirementConstituentsWeightageOneTime[`${fund.bseSchemeCode.toLowerCase()}`]
                : basketName.includes('education')
                ? educationConstituentsWeightage[`${fund.bseSchemeCode.toLowerCase()}`]
                : basketName.includes('1-3')
                ? oneToThreeYrConstituentsWeightage[`${fund.bseSchemeCode.toLowerCase()}`]
                : basketName.includes('1')
                ? oneYrConstituentsWeightage[`${fund.bseSchemeCode.toLowerCase()}`]
                : anytimeConstituentsWeightage[`${fund.bseSchemeCode.toLowerCase()}`],
            }))
        : []

    const calculateParcentageAmount = (parcentage, totalAmount) => {
      const result = totalAmount * (parcentage / 100)
      return Math.ceil(result)
    }

    const investmentBreakdownOneTime =
      lumpsum > 0
        ? filteredConstituents
            ?.map((fund) => ({
              ...fund,
              investmentAmount: calculateParcentageAmount(fund?.weightage, lumpsum),
            }))
            .filter((fund) => {
              console.log(fund)
              return basketName.includes('retirement')
                ? fund.bseSchemeCode.toLowerCase() !== 'rmfscpgp-gr'
                : true
            })
        : []

    const investmentBreakdownSIP =
      customSIP > 0
        ? filteredConstituents?.map((fund) => ({
            ...fund,
            investmentAmount: calculateParcentageAmount(fund?.weightage, customSIP),
          }))
        : []

    const basketPayload = {
      universalBasketId: userBasket[0].universalBasketId,
      versionNumber: userBasket[0].versionNumber,
      clientCode: clientCode?.toUpperCase(),
      customerBasketName: planName,
      investmentAmount: +lumpsum,
      oneTimeInvestmentAmount: +lumpsum,
      oneTimeInvestmentBreakdown: investmentBreakdownOneTime,
      sipInvestmentAmount: +customSIP,
      sipInvestmentBreakdown: investmentBreakdownSIP,
      category: 'RETIREMENT',
    }

    if (plan?.customerMfBasketInvestmentId) {
      basketPayload.customerBasketInvestmentId = plan?.customerMfBasketInvestmentId
    }

    const createUserBasket = await post(`/mutualfundservice/api/basket/create`, basketPayload)

    if (createUserBasket.isError) {
      showError(
        'Plan Creation Failed',
        'We encountered an issue while creating your plan. Please try again later.',
        () => {},
      )
      return
    }

    const request = {
      planName: planName,
      age: +age,
      retireAt: +desiredAge,
      lifeExpectation: LIFEEXPECTATION,
      monthlyExpenses,
      inflation: DEFAULT_INFLATION,
      lifeStyleInflation: 0,
      sipGrowth: +sipGrowth,
      lumpsum: +lumpsum,
      currentInvestment: currentInvestments,
      actualSIP: parseInt(customSIP),
      customerMfBasketInvestmentId: createUserBasket?.data?.customerBasketInvestmentId,
    }
    const payload = {
      request,
      results,
    }

    const planId = plan && plan.id
    let response
    if (planId) {
      response = await patch('/planservice/api/plan/' + planId + '/v2', payload)
    } else {
      response = await post('/planservice/api/plan/v2', payload, {
        'plan-type': 'RETIREMENT',
      })
    }
    if (response.isError) {
      return
    }

    if (!isDematUser()) {
      setMessageModalState({
        message: 'Your KYC is incomplete.',
        linkMessage:
          'Click here to complete your KYC and to enable hassle free, one-click investing.',
        severity: 'info',
      })
      return
    }
    navigate('/dashboard/place-order', {
      state: {
        basket: createUserBasket,
        constituents: filteredConstituents,
        plan: response,
        sipAmount: +customSIP,
        lumpsum: lumpsum ? lumpsum : 0,
        sipOrOneTime: 'SIP',
      },
    })
  }

  const filterBasket = (baskets, sipDuration, name, oneTime, sip) => {
    let results = []

    results = baskets.filter((basket) => {
      const basketName = basket?.universalBasketName?.toLowerCase() || ''

      if (sipDuration > 6 && lumpsum >= 50000 && customSIP >= 5000) {
        return basketName.includes('retirement')
      }

      if (sipDuration > 6 && lumpsum >= 50000 && customSIP == 0) {
        return basketName.includes('retirement')
      }

      if (sipDuration > 6 && (!lumpsum || lumpsum == 0) && customSIP >= 5000) {
        return basketName.includes('retirement')
      }

      if (sipDuration > 3 && sipDuration <= 6 && lumpsum >= 5000 && customSIP >= 5000) {
        return basketName.includes('education')
      }

      if (sipDuration > 3 && sipDuration <= 6 && (!lumpsum || lumpsum == 0) && customSIP >= 5000) {
        return basketName.includes('education')
      }

      if (
        sipDuration > 3 &&
        sipDuration <= 6 &&
        lumpsum >= 5000 &&
        (!customSIP || customSIP == 0)
      ) {
        return basketName.includes('education')
      }

      if (sipDuration > 1 && sipDuration <= 3 && lumpsum >= 5000 && customSIP >= 5000) {
        return basketName.includes('1-3')
      }

      if (sipDuration > 1 && sipDuration <= 3 && (!lumpsum || lumpsum == 0) && customSIP >= 5000) {
        return basketName.includes('1-3')
      }

      if (
        sipDuration > 1 &&
        sipDuration <= 3 &&
        lumpsum >= 5000 &&
        (!customSIP || customSIP == 0)
      ) {
        return basketName.includes('1-3')
      }

      if (sipDuration <= 1 && lumpsum >= 200 && customSIP >= 200) {
        return basketName.includes('1')
      }

      if (sipDuration <= 1 && lumpsum >= 200 && (!customSIP || customSIP == 0)) {
        return basketName.includes('1')
      }

      if (sipDuration <= 1 && (!lumpsum || lumpsum == 0) && customSIP >= 200) {
        return basketName.includes('1')
      }

      return basketName.includes('anytime')
    })

    return results
  }

  const calculateValues = () => {
    const currentMonth = new Date().getMonth()
    const sipYearsLeft = parseFloat(
      (desiredAge - age - 1 + (1 - (currentMonth + 1) / 12)).toFixed(3),
    )

    const monthlyInflation = (1 + DEFAULT_INFLATION / 100) ** (1.0 / 12) - 1
    const finalInflation = DEFAULT_INFLATION
    // eslint-disable-next-line no-unused-vars
    // const inflation = DEFAULT_INFLATION / 100
    const multiplier = (1 + finalInflation / 100) ** (desiredAge - age)
    // console.log(multiplier)
    const projectedExpenses = Math.round(monthlyExpenses * multiplier)

    const adjustedRate = (1 + PORTFOLIO_RETURN / 100) / (1 + DEFAULT_INFLATION / 100) - 1
    const arg = (1 / (1 + adjustedRate)) ** (LIFEEXPECTATION - desiredAge)
    const multi2 = (1 + adjustedRate) * ((1 - arg) / adjustedRate)
    const corpus = Math.round(multi2 * projectedExpenses * 12)

    let currentInvestment = 0
    if (currentInvestments && currentInvestments.length > 0) {
      currentInvestment = currentInvestments.reduce(
        (accumulator, currentValue) => accumulator + parseInt(currentValue.amount),
        0,
      )
    }

    const fvOfCurrentInv = Math.round(
      currentInvestment * (1 + RISK_FREE_RETURN / 100) ** sipYearsLeft,
    )

    const plannedContribution = fvOfCurrentInv >= corpus ? corpus : fvOfCurrentInv
    const amountTobePlanned = Math.round(fvOfCurrentInv >= corpus ? 0 : corpus - fvOfCurrentInv)
    console.log(amountTobePlanned)
    const mult3 = (1 + PORTFOLIO_RETURN / 100) ** sipYearsLeft
    const lumpsum1 = lumpsum ? lumpsum : 0
    const fvOfLumpsum = lumpsum1 * mult3

    const amountTobePlannedNew = Math.round(
      fvOfLumpsum >= amountTobePlanned ? 0 : amountTobePlanned - fvOfLumpsum,
    )

    const currentYear = new Date().getFullYear()

    const years = desiredAge - age
    const targetYear = Number(currentYear) + Number(years)
    let date1 = new Date(targetYear, 0, 1)

    setDateStr(formatMonthYear(date1))

    if (amountTobePlannedNew <= 0) {
      setSIP(0)
      setGoalAmt(corpus < 0 ? 0 : corpus)
      setCustomeSIP(0)
      return
    }

    // const sipGrowth = 5

    const arg1 = amountTobePlannedNew * (PORTFOLIO_RETURN / 100 - sipGrowth / 100 + 0.00001 / 100)
    const arg2 = 1 + PORTFOLIO_RETURN / 100 + 0.00001 / 100
    const arg4 = arg2 ** sipYearsLeft
    const arg5 = 1 + sipGrowth / 100
    const arg7 = arg5 ** sipYearsLeft
    const val = 12 * (arg4 - arg7)
    const sip = Math.round(arg1 / val + 99)
    const rSIP = Math.round(sip / 100) * 100
    setSIP(rSIP < 0 ? 0 : rSIP)
    setGoalAmt(corpus < 0 ? 0 : corpus)
    setCustomeSIP(rSIP < 0 ? 0 : rSIP)
    if (plan?.monthlySIP) {
      setCustomeSIP(plan.monthlySIP)
    }
    setResults({
      planName,
      monthlySIP: rSIP,
      targetYear: targetYear,
      table: {
        1: {
          plannedContribution: plannedContribution,
          projectedExpenses: projectedExpenses,
          amountToBePlanned: amountTobePlanned,
          fvOfCurrentValue: fvOfCurrentInv,
          corpus: corpus,
          sip: rSIP,
          fvOfLumpsum: fvOfLumpsum,
          balanceAmountToBePlanned: amountTobePlannedNew,
        },
      },
    })
  }

  const deleteRecord = (rowData) => {
    let plans = [...currentInvestments]
    for (var i = 0; i < plans.length; i++) {
      if (plans[i].amount === rowData.amount && plans[i].type === rowData.type) {
        plans.splice(i, 1)
        setCurrentInvestments(plans)
        return
      }
    }
  }

  const MIN_ANYTIME_AMOUNT = 350
  const MIN_1Y_AMOUNT = 200

  const PLAN_YEARS = desiredAge - age

  const isContinueDisable =
    Object.values(error).some((errMsg) => errMsg !== '') ||
    (lumpsum > 0 && lumpsum < MIN_ANYTIME_AMOUNT && PLAN_YEARS > 1) ||
    (lumpsum > 0 && lumpsum < MIN_1Y_AMOUNT && PLAN_YEARS <= 1) ||
    (customSIP > 0 && customSIP < MIN_ANYTIME_AMOUNT && PLAN_YEARS > 1) ||
    (customSIP > 0 && customSIP < MIN_1Y_AMOUNT && PLAN_YEARS <= 1)

  let currYear = new Date().getFullYear()

  return (
    <div className='md:p-4 lg:p-0'>
      <Box sx={{ my: 3, pl: { xs: 2, md: 0 } }}>
        <i
          className='fa-solid fa-chevron-left cursor-pointer'
          onClick={() => navigate(-1)}
          style={{ color: '#2D2D2D' }}
        ></i>
        <Typography
          variant='span'
          sx={{
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '28px',
            color: '#2D2D2D',
            pl: '12px',
          }}
        >
          Plan Your Retirement
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={12} md={8} sx={{ pr: { xs: 0, md: '12px' }, mb: { xs: 1, md: 2 } }}>
          <Box
            sx={{
              borderTopLeftRadius: { xs: 0, md: '24px' },
              borderTopRightRadius: { xs: 0, md: '24px' },
              background: '#fff',
              border: '1px solid #D3DBEC',
              borderBottom: 'none',
            }}
          >
            <Grid container sx={{ p: { xs: 2, md: 4 }, pb: { xs: 0, md: 0 } }}>
              <Stack
                width={'100%'}
                alignItems={'center'}
                sx={{ mb: { xs: 4, md: 3 }, flexDirection: { md: 'row', xs: 'columns' } }}
              >
                <img
                  src={Retirement}
                  style={{
                    width: '64px',
                    borderRadius: '60px',
                  }}
                />
                {editPlanName ? (
                  <Stack flexDirection={'row'} alignItems={'center'}>
                    <FormControl
                      variant='outlined'
                      sx={{
                        fontSize: { xs: '14px', md: '24px' },
                        fontWeight: 600,
                        lineHeight: { xs: '20px', md: '32px' },
                        color: '#2D2D2D',
                        pl: 3,
                        pt: { xs: '8px', md: 0 },
                      }}
                    >
                      {/* <TextField
                        id='planName'
                        variant='outlined'
                        label='Plan Name'
                        autoComplete='off'
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderRadius: '8px',
                              borderColor: '#D3DBEC',
                            },
                            '&:hover fieldset': {
                              borderColor: '#D3DBEC',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#D3DBEC',
                            },
                            '& input': {
                              color: '#41558D',
                            },
                            '& .MuiInputAdornment-root .MuiTypography-root': {
                              color: '#808DB3',
                            },
                          },
                        }}
                        onChange={(event) => setUpdatedPlanName(event.target.value)}
                        value={updatedPlanName}
                      /> */}
                      <TextField
                        variant='standard'
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            '& input': {
                              borderBottom: '1px dashed #2D2D2D',
                            },
                          },
                        }}
                        sx={{
                          fontSize: { xs: '14px', md: '24px' },
                          fontWeight: 600,
                          lineHeight: { xs: '20px', md: '32px' },
                          color: '#2D2D2D',
                          ml: 3,
                          pt: { xs: '4px', md: 0 },
                        }}
                        onChange={(event) => setUpdatedPlanName(event.target.value)}
                        value={updatedPlanName}
                        disabled={isAllInputDisabled}
                      />
                    </FormControl>
                    <i
                      className='fa-solid fa-check cursor-pointer'
                      onClick={() => {
                        setPlanName(updatedPlanName)
                        setEditPlanName(false)
                      }}
                      style={{ color: '#2D2D2D', fontSize: '24px', paddingLeft: '8px' }}
                    ></i>
                  </Stack>
                ) : (
                  <Typography
                    variant='subtitle2'
                    sx={{
                      fontSize: { xs: '14px', md: '24px' },
                      fontWeight: 600,
                      lineHeight: { xs: '20px', md: '32px' },
                      color: '#2D2D2D',
                      ml: 3,
                      pt: { xs: '4px', md: 0 },
                      // borderBottom: '1px dashed #2D2D2D',
                    }}
                  >
                    {planName}
                    <i
                      className='fa-solid fa-pen cursor-pointer'
                      onClick={() => setEditPlanName(true)}
                      style={{ color: '#2D2D2D', fontSize: '18px', paddingLeft: '8px' }}
                    ></i>
                  </Typography>
                )}
              </Stack>

              <Grid item xs={6} md={12} sx={{ mb: '12px', alignContent: 'center' }}>
                <Typography
                  variant='subtitle2'
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    color: '#2D2D2D',
                  }}
                >
                  Current age
                </Typography>
              </Grid>
              <Grid
                sx={{ display: { xs: 'block', md: 'none' }, mb: '12px', alignContent: 'center' }}
                item
                xs={6}
              >
                <FormControl variant='outlined'>
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderRadius: '8px',
                          borderColor: '#D3DBEC',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D3DBEC',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D3DBEC',
                        },
                        '& input': {
                          color: '#41558D',
                        },
                        '& .MuiInputAdornment-root .MuiTypography-root': {
                          color: '#808DB3',
                        },
                      },
                    }}
                    fullWidth
                    id='age'
                    variant='outlined'
                    autoComplete='off'
                    type='number'
                    onChange={(event) => setAge(event.target.value)}
                    onBlur={onAgeBlur}
                    value={age}
                    disabled={isAllInputDisabled}
                    size='small'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' className='custom-font'>
                          Years
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item md={8} xs={12} sx={{ mb: { xs: 0, md: 3 } }}>
                <SliderWithTooltip
                  value={age}
                  onChange={onAgeSliderChange}
                  step={1}
                  defaultValue={age}
                  disabled={isAllInputDisabled}
                  onChangeCommitted={calculateValues}
                  min={18}
                  max={100}
                  aria-label='age-slider'
                  valueLabelDisplay='off'
                  slots={{ thumb: ThumbComponent }}
                />
              </Grid>
              <Grid
                sx={{
                  display: { xs: 'none', md: 'block' },
                  pl: 2,
                  mb: 3,
                }}
                item
                xs={4}
              >
                <FormControl variant='outlined'>
                  <TextField
                    sx={{
                      minWidth: '100px',
                      width: '70%',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderRadius: '8px',
                          borderColor: '#D3DBEC',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D3DBEC',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D3DBEC',
                        },
                        '& input': {
                          color: '#41558D',
                        },
                        '& .MuiInputAdornment-root .MuiTypography-root': {
                          color: '#808DB3',
                        },
                      },
                    }}
                    id='age'
                    type='number'
                    variant='outlined'
                    autoComplete='off'
                    onChange={(event) => setAge(event.target.value)}
                    onBlur={onAgeBlur}
                    value={age}
                    disabled={isAllInputDisabled}
                    size='small'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' className='custom-font'>
                          Years
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={12} sx={{ mb: '12px', alignContent: 'center' }}>
                <Typography
                  variant='subtitle2'
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    color: '#2D2D2D',
                  }}
                >
                  Desired Retirement Age
                </Typography>
              </Grid>
              <Grid
                sx={{ display: { xs: 'block', md: 'none' }, mb: '12px', alignContent: 'center' }}
                item
                xs={6}
              >
                <FormControl variant='outlined'>
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderRadius: '8px',
                          borderColor: '#D3DBEC',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D3DBEC',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D3DBEC',
                        },
                        '& input': {
                          color: '#41558D',
                        },
                        '& .MuiInputAdornment-root .MuiTypography-root': {
                          color: '#808DB3',
                        },
                      },
                    }}
                    fullWidth
                    type='number'
                    id='desiredAge'
                    variant='outlined'
                    autoComplete='off'
                    onChange={(event) => setDesiredAge(event.target.value)}
                    onBlur={onDesiredAgeBlur}
                    value={desiredAge}
                    disabled={isAllInputDisabled}
                    size='small'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' className='custom-font'>
                          Years
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item md={8} xs={12} sx={{ mb: { xs: 0, md: 3 } }}>
                <SliderWithTooltip
                  value={desiredAge}
                  onChange={onDesiredAgeSliderChange}
                  step={1}
                  defaultValue={desiredAge}
                  disabled={isAllInputDisabled}
                  onChangeCommitted={calculateValues}
                  min={18}
                  max={90}
                  aria-label='dage-slider'
                  valueLabelDisplay='off'
                  slots={{ thumb: ThumbComponent }}
                />
              </Grid>
              <Grid
                sx={{
                  display: { xs: 'none', md: 'block' },
                  pl: 2,
                  mb: 3,
                }}
                item
                xs={4}
              >
                <FormControl variant='outlined'>
                  <TextField
                    sx={{
                      minWidth: '100px',
                      width: '70%',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderRadius: '8px',
                          borderColor: '#D3DBEC',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D3DBEC',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D3DBEC',
                        },
                        '& input': {
                          color: '#41558D',
                        },
                        '& .MuiInputAdornment-root .MuiTypography-root': {
                          color: '#808DB3',
                        },
                      },
                    }}
                    id='desiredAge'
                    type='number'
                    variant='outlined'
                    autoComplete='off'
                    onChange={(event) => setDesiredAge(event.target.value)}
                    onBlur={onDesiredAgeBlur}
                    value={desiredAge}
                    disabled={isAllInputDisabled}
                    size='small'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' className='custom-font'>
                          Years
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>

              {error.desiredAgeError != null && (
                <Grid md={12} sx={{ mb: '12px' }}>
                  <p className='text-red-500 text-sm'>{error.desiredAgeError}</p>
                </Grid>
              )}

              <Grid item xs={12} md={12} sx={{ mb: '12px', alignContent: 'center' }}>
                <Typography
                  variant='subtitle2'
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    color: '#2D2D2D',
                  }}
                >
                  Current Monthly Expenses
                </Typography>
              </Grid>
              <Grid
                sx={{ display: { xs: 'block', md: 'none' }, mb: '12px', alignContent: 'center' }}
                item
                xs={12}
              >
                <FormControl variant='outlined'>
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderRadius: '8px',
                          borderColor: '#D3DBEC',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D3DBEC',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D3DBEC',
                        },
                        '& input': {
                          color: '#41558D',
                        },
                        '& .MuiInputAdornment-root .MuiTypography-root': {
                          color: '#808DB3',
                        },
                      },
                    }}
                    fullWidth
                    type='number'
                    id='monthlyExpenses'
                    variant='outlined'
                    autoComplete='off'
                    onChange={(event) => setMonthlyExpenses(event.target.value)}
                    onBlur={onMonthlyExpensesAgeBlur}
                    value={monthlyExpenses}
                    disabled={isAllInputDisabled}
                    size='small'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start' className='custom-font'>
                          &#8377;
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item md={8} xs={12} sx={{ mb: { xs: 0, md: 3 } }}>
                <CustomisedSlider
                  value={monthlyExpenses}
                  onChange={onMonthlyExpensesSliderChange}
                  step={1000}
                  defaultValue={monthlyExpenses}
                  disabled={isAllInputDisabled}
                  onChangeCommitted={calculateValues}
                  min={1000}
                  max={1000000}
                  aria-label='me-slider'
                  valueLabelDisplay='off'
                  slots={{ thumb: ThumbComponent }}
                />
              </Grid>
              <Grid
                sx={{
                  display: { xs: 'none', md: 'block' },
                  pl: 2,
                  mb: 3,
                }}
                item
                xs={4}
              >
                <FormControl variant='outlined'>
                  <TextField
                    sx={{
                      minWidth: '100px',
                      width: '80%',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderRadius: '8px',
                          borderColor: '#D3DBEC',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D3DBEC',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D3DBEC',
                        },
                        '& input': {
                          color: '#41558D',
                        },
                        '& .MuiInputAdornment-root .MuiTypography-root': {
                          color: '#808DB3',
                        },
                      },
                    }}
                    fullWidth
                    id='monthlyExpenses'
                    type='number'
                    variant='outlined'
                    autoComplete='off'
                    onChange={(event) => setMonthlyExpenses(event.target.value)}
                    onBlur={onMonthlyExpensesAgeBlur}
                    value={monthlyExpenses}
                    disabled={isAllInputDisabled}
                    size='small'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start' className='custom-font'>
                          &#8377;
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>

              {error.currentExpenseError != null && (
                <Grid md={12} sx={{ mb: '12px' }}>
                  <p className='text-red-500 text-sm'>{error.currentExpenseError}</p>
                </Grid>
              )}
            </Grid>

            <Grid container item sx={{ p: '0px 32px 16px 32px' }}>
              <Typography sx={{ fontSize: '14px' }}>
                <span style={{ fontWeight: 'bolder' }}>*</span>Assuming 8% Inflation and&nbsp;
              </Typography>
              <Typography sx={{ fontSize: '14px' }}>
                Planning till the year <span>{currYear + 90 - age}</span>
              </Typography>
            </Grid>
          </Box>
          <Accordion isDesktop={isDesktop}>
            <AccordionSummary
              expandIcon={<KeyboardArrowUp sx={{ fill: '#41558D' }} />}
              aria-controls='panel1-content'
              id='panel1-header'
            >
              <Typography
                variant='subtitle2'
                sx={{
                  color: '#41558D',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
              >
                Optional Information
              </Typography>
            </AccordionSummary>
            <AccordionDetails isDesktop={isDesktop}>
              <Grid
                container
                sx={{
                  px: { xs: 2, md: 4 },
                  py: '12px',
                  borderBottomLeftRadius: { xs: 0, md: '24px' },
                  borderBottomRightRadius: { xs: 0, md: '24px' },
                }}
              >
                {/* <Grid item xs={8} md={12} sx={{ mb: '12px', alignContent: 'center' }}>
                  <Typography
                    variant='subtitle2'
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '20px',
                      color: '#2D2D2D',
                    }}
                  >
                    SIP Increment year-on-year
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    display: { xs: 'block', md: 'none' },
                    mb: '12px',
                    alignContent: 'center',
                  }}
                  item
                  xs={4}
                >
                  <FormControl variant='outlined'>
                    <TextField
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderRadius: '8px',
                            borderColor: '#D3DBEC',
                          },
                          '&:hover fieldset': {
                            borderColor: '#D3DBEC',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#D3DBEC',
                          },
                          '& input': {
                            color: '#41558D',
                          },
                          '& .MuiInputAdornment-root .MuiTypography-root': {
                            color: '#808DB3',
                          },
                        },
                      }}
                      fullWidth
                      type='number'
                      id='sipGrowth'
                      variant='outlined'
                      autoComplete='off'
                      onChange={(event) => setSIPGrowth(event.target.value)}
                      onBlur={onSIPGrowthBlur}
                      value={sipGrowth}
                      disabled={isAllInputDisabled}
                      size='small'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end' className='custom-font'>
                            %
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={8} xs={12} sx={{ mb: { xs: 0, md: 3 } }}>
                  <SliderWithTooltip
                    value={sipGrowth}
                    onChange={onSIPGrowthSliderChange}
                    step={1}
                    defaultValue={sipGrowth}
                    disabled={isAllInputDisabled}
                    onChangeCommitted={calculateValues}
                    min={1}
                    max={20}
                    aria-label='sipgrowth-slider'
                    valueLabelDisplay='off'
                    slots={{ thumb: ThumbComponent }}
                  />
                </Grid>
                <Grid
                  sx={{
                    display: { xs: 'none', md: 'block' },
                    pl: 2,
                    mb: 3,
                  }}
                  item
                  xs={4}
                >
                  <FormControl variant='outlined'>
                    <TextField
                      sx={{
                        minWidth: '100px',
                        width: '80%',

                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderRadius: '8px',
                            borderColor: '#D3DBEC',
                          },
                          '&:hover fieldset': {
                            borderColor: '#D3DBEC',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#D3DBEC',
                          },
                          '& input': {
                            color: '#41558D',
                          },
                          '& .MuiInputAdornment-root .MuiTypography-root': {
                            color: '#808DB3',
                          },
                        },
                      }}
                      type='number'
                      id='sipGrowth'
                      variant='outlined'
                      autoComplete='off'
                      onChange={(event) => setSIPGrowth(event.target.value)}
                      onBlur={onSIPGrowthBlur}
                      value={sipGrowth}
                      disabled={isAllInputDisabled}
                      size='small'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end' className='custom-font'>
                            %
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid> */}

                <Grid item xs={8} md={12} sx={{ mb: '12px', alignContent: 'center' }}>
                  <Typography
                    variant='subtitle2'
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '20px',
                      color: '#2D2D2D',
                    }}
                  >
                    One-time Lumpsum Investment
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    display: { xs: 'block', md: 'none' },
                    mb: '12px',
                    alignContent: 'center',
                  }}
                  item
                  xs={4}
                >
                  <FormControl variant='outlined'>
                    <TextField
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderRadius: '8px',
                            borderColor: '#D3DBEC',
                          },
                          '&:hover fieldset': {
                            borderColor: '#D3DBEC',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#D3DBEC',
                          },
                          '& input': {
                            color: '#41558D',
                          },
                          '& .MuiInputAdornment-root .MuiTypography-root': {
                            color: '#808DB3',
                          },
                        },
                      }}
                      fullWidth
                      type='number'
                      id='lumpsum'
                      variant='outlined'
                      autoComplete='off'
                      onChange={(event) => setLumpsum(event.target.value)}
                      onBlur={onLumpsumOnBlur}
                      value={lumpsum}
                      disabled={isAllInputDisabled}
                      size='small'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start' className='custom-font'>
                            &#8377;
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={8} xs={12} sx={{ mb: { xs: 0, md: 3 } }}>
                  <CustomisedSlider
                    value={lumpsum}
                    onChange={onLumpsumSliderChange}
                    step={1}
                    defaultValue={lumpsum}
                    disabled={isAllInputDisabled}
                    onChangeCommitted={calculateValues}
                    min={0}
                    max={1000000}
                    aria-label='mi-slider'
                    valueLabelDisplay='off'
                    slots={{ thumb: ThumbComponent }}
                  />
                </Grid>
                <Grid
                  sx={{
                    display: { xs: 'none', md: 'block' },
                    pl: 2,
                    mb: 3,
                  }}
                  item
                  xs={4}
                >
                  <FormControl variant='outlined'>
                    <TextField
                      sx={{
                        minWidth: '100px',
                        width: '80%',

                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderRadius: '8px',
                            borderColor: '#D3DBEC',
                          },
                          '&:hover fieldset': {
                            borderColor: '#D3DBEC',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#D3DBEC',
                          },
                          '& input': {
                            color: '#41558D',
                          },
                          '& .MuiInputAdornment-root .MuiTypography-root': {
                            color: '#808DB3',
                          },
                        },
                      }}
                      type='number'
                      id='lumpsum'
                      variant='outlined'
                      autoComplete='off'
                      onChange={(event) => setLumpsum(event.target.value)}
                      onBlur={onLumpsumOnBlur}
                      value={lumpsum}
                      disabled={isAllInputDisabled}
                      size='small'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start' className='custom-font'>
                            &#8377;
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={8} md={12} sx={{ mb: '12px', alignContent: 'center' }}>
                  <Typography
                    variant='subtitle2'
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '20px',
                      color: '#2D2D2D',
                    }}
                  >
                    Add Existing Savings
                  </Typography>
                </Grid>

                {currentInvestments &&
                  currentInvestments.length > 0 &&
                  currentInvestments.map((investment, index) => (
                    <>
                      <Grid item md={8} xs={9} sx={{ mb: { xs: 2, md: 3 } }}>
                        <Grid container>
                          <Grid item xs={6} md={6}>
                            <FormControl fullWidth size='small'>
                              <InputLabel id='type-label'>Select</InputLabel>
                              <Select
                                labelId='type-label'
                                id='Type'
                                size='small'
                                value={investment.type}
                                label='Type'
                                disabled
                                variant='outlined'
                                fullWidth
                                sx={{
                                  '&.MuiOutlinedInput-root': {
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      borderColor: '#D3DBEC',
                                      borderTopLeftRadius: '8px',
                                      borderTopRightRadius: 0,
                                      borderBottomLeftRadius: '8px',
                                      borderBottomRightRadius: 0,
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                      borderColor: '#D3DBEC',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                      borderColor: '#D3DBEC',
                                    },
                                  },
                                  '& .MuiInputLabel-root': {
                                    color: '#6E6E6E',
                                  },
                                  '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#6E6E6E',
                                  },
                                }}
                              >
                                <MenuItem value='MF'>MF</MenuItem>
                                <MenuItem value='PF'>PF</MenuItem>
                                <MenuItem value='FD'>FD</MenuItem>
                                <MenuItem value='Stocks'>Stocks</MenuItem>
                                <MenuItem value='Others'>Others</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <FormControl variant='outlined' fullWidth>
                              <TextField
                                fullWidth
                                type='number'
                                id='amount'
                                variant='outlined'
                                label='Amount'
                                value={investment.amount}
                                size='small'
                                disabled
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <IconButton edge='end'>
                                        <CancelIcon
                                          onClick={() => deleteRecord(investment, index)}
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      borderColor: '#D3DBEC',
                                      borderTopRighttRadius: '8px',
                                      borderTopLeftRadius: 0,
                                      borderBottomRightRadius: '8px',
                                      borderBottomLeftRadius: 0,
                                    },
                                    '&:hover fieldset': {
                                      borderColor: '#D3DBEC',
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#D3DBEC',
                                    },
                                  },
                                  '& .MuiInputLabel-root': {
                                    color: '#6E6E6E',
                                  },
                                  '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#6E6E6E',
                                  },
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ))}
                <Grid item md={8} xs={9} sx={{ mb: { xs: 0, md: 3 } }}>
                  <Grid container>
                    <Grid item xs={6} md={6}>
                      <FormControl fullWidth size='small'>
                        <InputLabel id='type-label'>Select</InputLabel>
                        <Select
                          labelId='type-label'
                          id='Type'
                          size='small'
                          value={formikForInvestments.values?.type}
                          disabled={isAllInputDisabled}
                          label='Type'
                          onChange={(e) => {
                            formikForInvestments.setFieldValue('type', e.target.value)
                          }}
                          variant='outlined'
                          fullWidth
                          sx={{
                            '&.MuiOutlinedInput-root': {
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#D3DBEC',
                                borderTopLeftRadius: '8px',
                                borderTopRightRadius: 0,
                                borderBottomLeftRadius: '8px',
                                borderBottomRightRadius: 0,
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#D3DBEC',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#D3DBEC',
                              },
                            },
                            '& .MuiInputLabel-root': {
                              color: '#6E6E6E',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                              color: '#6E6E6E',
                            },
                          }}
                        >
                          <MenuItem value='MF'>MF</MenuItem>
                          <MenuItem value='PF'>PF</MenuItem>
                          <MenuItem value='FD'>FD</MenuItem>
                          <MenuItem value='Stocks'>Stocks</MenuItem>
                          <MenuItem value='Others'>Others</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <FormControl variant='outlined' fullWidth>
                        <TextField
                          fullWidth
                          type='number'
                          id='amount'
                          variant='outlined'
                          label='Amount'
                          onChange={formikForInvestments.handleChange}
                          value={formikForInvestments?.values?.amount}
                          disabled={isAllInputDisabled}
                          onBlur={formikForInvestments.handleBlur}
                          size='small'
                          autoComplete='off'
                          // error={
                          //   formikForInvestments.touched.amount &&
                          //   Boolean(formikForInvestments.errors.amount)
                          // }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start' className='custom-font'>
                                &#8377;
                              </InputAdornment>
                            ),
                          }}
                          // helperText={
                          //   formikForInvestments.touched.amount && formikForInvestments.errors.amount
                          // }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#D3DBEC',
                                borderTopRighttRadius: '8px',
                                borderTopLeftRadius: 0,
                                borderBottomRightRadius: '8px',
                                borderBottomLeftRadius: 0,
                              },
                              '&:hover fieldset': {
                                borderColor: '#D3DBEC',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#D3DBEC',
                              },
                            },
                            '& .MuiInputLabel-root': {
                              color: '#6E6E6E',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                              color: '#6E6E6E',
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sx={{ pl: { xs: 1, md: 3 } }} xs={3} md={4}>
                  <Button
                    sx={{
                      borderRadius: '8px',
                      fontSize: '16px',
                      lineHeight: '24px',
                      fontWeight: 500,
                      p: '8px 24px',
                    }}
                    color='primary'
                    disabled={
                      !(formikForInvestments.dirty && formikForInvestments.isValid) ||
                      isAllInputDisabled
                    }
                    variant='contained'
                    onClick={addCurrentInvestment}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={4} sx={{ pl: { xs: 0, md: '12px' }, mb: 2 }}>
          <Box
            sx={{
              borderRadius: { xs: 0, md: '24px' },
              background: '#fff',
              border: '1px solid #D3DBEC',
              py: '24px',
              px: { xs: '16px', md: '24px' },
            }}
          >
            <Box mb={1} sx={{ textAlign: 'center', borderBottom: '1px dashed #D3DBEC' }}>
              <Typography
                variant='h4'
                sx={{
                  fontSize: '28px',
                  fontWeight: 600,
                  lineHeight: '36px',
                  mb: '4px',
                  color: '#2D2D2D',
                }}
              >
                ₹{toCurrencyNoDecimal(sip)}
              </Typography>
              <Typography
                component='p'
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  mb: 3,
                  color: '#575757',
                }}
              >
                Recommended Monthly SIP
              </Typography>
              <Box
                sx={{
                  mt: 1,
                  mb: 3,
                  padding: '6px 16px 6px 12px',
                  borderRadius: '6px',
                  border: '1px solid #FAE6B7',
                  background: '#FDF7E8',
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                }}
              >
                <TipsAndUpdatesIcon sx={{ color: '#D99E16', fontSize: '20px' }} />
                <Typography sx={{ color: '#D99E16', fontSize: '14px', lineHeight: '20px', p: 0 }}>
                  Invest lumpsum now to lower this amount
                </Typography>
              </Box>
            </Box>
            <Box mt={3}>
              <Stack width={'100%'} flexDirection={'row'} justifyContent={'space-between'}>
                <Typography
                  variant='subtitle2'
                  sx={{
                    fontSize: { xs: '14px', md: '16px' },
                    fontWeight: 500,
                    lineHeight: { xs: '20px', md: '24px' },
                    color: '#575757',
                  }}
                >
                  Goal Amount
                </Typography>
                <Typography
                  variant='subtitle2'
                  sx={{
                    fontSize: { xs: '14px', md: '16px' },
                    fontWeight: 600,
                    lineHeight: { xs: '20px', md: '24px' },
                    color: '#2D2D2D',
                  }}
                >
                  ₹{toCurrencyNoDecimal(goalAmt)}
                </Typography>
              </Stack>
              <Stack
                sx={{ mt: 1.5 }}
                width={'100%'}
                flexDirection={'row'}
                justifyContent={'space-between'}
              >
                <Typography
                  variant='subtitle2'
                  sx={{
                    fontSize: { xs: '14px', md: '16px' },
                    fontWeight: 500,
                    lineHeight: { xs: '20px', md: '24px' },
                    color: '#575757',
                  }}
                >
                  Goal Date
                </Typography>
                <Typography
                  variant='subtitle2'
                  sx={{
                    fontSize: { xs: '14px', md: '16px' },
                    fontWeight: 600,
                    lineHeight: { xs: '20px', md: '24px' },
                    color: '#2D2D2D',
                  }}
                >
                  {dateStr}
                </Typography>
              </Stack>

              <Stack
                width={'100%'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={{ mt: '16px' }}
              >
                <Typography
                  variant='subtitle2'
                  sx={{
                    fontSize: { xs: '14px', md: '16px' },
                    fontWeight: 500,
                    lineHeight: { xs: '20px', md: '24px' },
                    color: '#575757',
                  }}
                >
                  One-Time Investment
                </Typography>

                <TextField
                  type='number'
                  id='lumpsum'
                  variant='outlined'
                  autoComplete='off'
                  sx={{
                    minWidth: '100px',
                    width: '40%',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderRadius: '8px',
                        borderColor: '#D3DBEC',
                      },
                      '&:hover fieldset': {
                        borderColor: '#D3DBEC',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D3DBEC',
                      },
                      '& input': {
                        color: '#41558D',
                      },
                      '& .MuiInputAdornment-root .MuiTypography-root': {
                        color: '#808DB3',
                      },
                    },
                  }}
                  onChange={(event) => setLumpsum(event.target.value)}
                  onBlur={onLumpsumOnBlur}
                  value={lumpsum}
                  disabled={isAllInputDisabled}
                  size='small'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start' className='custom-font'>
                        &#8377;
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>

              {lumpsum > 0 && lumpsum < MIN_ANYTIME_AMOUNT && PLAN_YEARS > 1 && (
                <p className='text-error-600 text-sm my-2 text-center'>
                  Minimum One-time amount is ₹ {MIN_ANYTIME_AMOUNT}
                </p>
              )}

              {lumpsum > 0 && lumpsum < MIN_ANYTIME_AMOUNT && PLAN_YEARS <= 1 && (
                <p className='text-error-600 text-sm my-2 text-center'>
                  Minimum One-time amount is ₹ {MIN_1Y_AMOUNT}
                </p>
              )}

              <Stack
                width={'100%'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={{ mt: '16px' }}
              >
                <Typography
                  variant='subtitle2'
                  sx={{
                    fontSize: { xs: '14px', md: '16px' },
                    fontWeight: 500,
                    lineHeight: { xs: '20px', md: '24px' },
                    color: '#575757',
                  }}
                >
                  Your monthly SIP
                </Typography>
                <TextField
                  type='number'
                  id='customSIP'
                  variant='outlined'
                  autoComplete='off'
                  sx={{
                    minWidth: '100px',
                    width: '40%',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderRadius: '8px',
                        borderColor: '#D3DBEC',
                      },
                      '&:hover fieldset': {
                        borderColor: '#D3DBEC',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D3DBEC',
                      },
                      '& input': {
                        color: '#41558D',
                      },
                      '& .MuiInputAdornment-root .MuiTypography-root': {
                        color: '#808DB3',
                      },
                    },
                  }}
                  onChange={(event) => setCustomeSIP(event.target.value)}
                  value={customSIP}
                  disabled={isAllInputDisabled}
                  size='small'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start' className='custom-font'>
                        &#8377;
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>

              {customSIP > 0 && customSIP < MIN_ANYTIME_AMOUNT && PLAN_YEARS > 1 && (
                <p className='text-error-600 text-sm my-2 text-center'>
                  Minimum SIP amount is ₹ {MIN_ANYTIME_AMOUNT}
                </p>
              )}

              {customSIP > 0 && customSIP < MIN_ANYTIME_AMOUNT && PLAN_YEARS <= 1 && (
                <p className='text-error-600 text-sm my-2 text-center'>
                  Minimum SIP amount is ₹ {MIN_1Y_AMOUNT}
                </p>
              )}

              {/* <Stack
                width={'100%'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={{ mt: '16px' }}
              >
                <Typography
                  variant='subtitle2'
                  sx={{
                    fontSize: { xs: '14px', md: '16px' },
                    fontWeight: 500,
                    lineHeight: { xs: '20px', md: '24px' },
                    color: '#575757',
                  }}
                >
                  Increase Amount By
                </Typography>
                <TextField
                  type='number'
                  sx={{
                    minWidth: '100px',
                    width: '40%',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderRadius: '8px',
                        borderColor: '#D3DBEC',
                      },
                      '&:hover fieldset': {
                        borderColor: '#D3DBEC',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D3DBEC',
                      },
                      '& input': {
                        color: '#41558D',
                      },
                      '& .MuiInputAdornment-root .MuiTypography-root': {
                        color: '#808DB3',
                      },
                    },
                  }}
                  id='sipGrowth'
                  variant='outlined'
                  autoComplete='off'
                  onChange={(event) => setSIPGrowth(event.target.value)}
                  onBlur={onSIPGrowthBlur}
                  value={sipGrowth}
                  disabled={isAllInputDisabled}
                  size='small'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Typography variant='caption'> % yearly</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack> */}

              <Stack
                sx={{ mt: '24px' }}
                width={'100%'}
                flexDirection={'row'}
                justifyContent={'space-between'}
              >
                <Button
                  onClick={savePlan}
                  fullWidth
                  sx={{
                    borderRadius: '8px',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: 500,
                    p: '8px 24px',
                  }}
                  variant='contained'
                  disabled={isContinueDisable || plan?.status === 'INVESTED'}
                >
                  Continue
                </Button>
              </Stack>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default RetirementCalculator
