/* eslint-disable */

import { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarTrigger } from '../ui/menubar'
import useMediaQuery from '../../hooks/useMediaQuery'
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '../ui/sheet'
import { Separator } from '../ui/separator'
import { toCurrency } from '../../utils/CurrencyUtil'
import { addNumberSuffix, capitalizeWords, monthShortNames, sanitizeName } from '../../utils/Util'
import env from '../../env/env'
import { post } from '../../http/restservice'
import { userDetailsState } from '../../Pages/LoginState'
import { useRecoilState } from 'recoil'
import { useModal } from '../../hooks/use-modal-store'
import { ChevronRight } from 'lucide-react'
import { Button } from '../ui/button'

const SipTable = ({ sips }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [hoveredRow, setHoveredRow] = useState(null)
  const [detailsOpen, setDetailsOpen] = useState(false)
  const [userDetails] = useRecoilState(userDetailsState)
  const { onOpen } = useModal()
  const clientCode = userDetails?.uid?.split('-')[0]
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  const getDate = (dateString) => {
    if (!dateString) return null // Handle null/undefined cases

    // Determine the correct delimiter
    const delimiter = dateString.includes('/') ? '/' : '-'

    return dateString.split(delimiter)[0]
  }

  const getMonth = (dateString) => {
    if (!dateString) return null // Handle null/undefined cases

    // Determine the correct delimiter
    const delimiter = dateString.includes('/') ? '/' : '-'

    return dateString.split(delimiter)[1]
  }

  const getYear = (dateString) => {
    if (!dateString) return null // Handle null/undefined cases

    // Determine the correct delimiter
    const delimiter = dateString.includes('/') ? '/' : '-'

    return dateString.split(delimiter)[2]
  }

  const handlePauseSIP = async (regNo) => {
    try {
      const payload = {
        clientCode: clientCode,
        regNo: regNo,
      }
      const response = await post(
        `${env.API_URL}mutualfundservice/api/bseStar/api/XSIPCancellation`,
        payload,
      )

      if (
        response?.status === 'SUCCESS' &&
        response?.data?.BSERemarks?.includes('CANCELLATION INITIATED. KINDLY CONFIRM')
      ) {
        onOpen('openPopup', {
          title: 'Cancellation Initiated!',
          message:
            'Your cancellation request has been initiated. Please check your email and follow the instructions to complete the cancellation process.',
          severity: 'info',
        })
      }

      console.log('🚀 ~ handlePauseSIP ~ response:', response)
    } catch (error) {
      console.error('Request failed:', error)
      return []
    }
  }

  if (sips?.length === 0) {
    return (
      <div
        className='py-12'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        <img src={NoBond} width={400} alt='No Bond' />

        <div
          style={{
            // width: '454px',
            gap: '4px',
            textAlign: 'center',
          }}
        >
          <p
            style={{
              fontSize: '20px',
              fontWeight: 600,
              lineHeight: '28px',
              letterSpacing: '-0.04em',
              fontFamily: 'DM Sans',
              marginTop: '30px',
              marginBottom: '4px',
              color: '#2D2D2D',
            }}
          >
            It seems you have no SIPs
          </p>

          <p
            style={{
              fontFamily: 'DM Sans',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
              marginBottom: '12px',
              color: 'black-300',
            }}
          >
            Get started with investing today. You can either invest in a plan or explore our custom
            funds{' '}
          </p>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '34px',
              justifyContent: 'center',
              gap: '16px',
            }}
          >
            <div
              style={{
                width: '149px',
                height: '42px',
                padding: '10px 11px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                gap: '8px',
                borderRadius: '8px',
                border: '1px solid #D3DBEC',
                boxShadow: '0px 4px 4px 0px #41558D0A', // Added box shadow
                cursor: 'pointer',
              }}
              onClick={() => navigate('/dashboard/plans')}
            >
              <Button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: 'DM Sans',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  textAlign: 'center',
                  color: '#41558D',
                  background: 'transparent',
                  boxShadow: 'none',
                  padding: 0,
                  textTransform: 'none',
                }}
              >
                Plan a Goal
              </Button>
            </div>
            <div onClick={() => navigate('/dashboard/mutual-funds')}>
              <Button
                sx={{
                  // width: '149px',
                  height: '42px',
                  padding: '5px 18px',
                  gap: '8px',
                  borderRadius: '8px',
                  background: '#41558D',
                  fontFamily: 'DM Sans',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  textAlign: 'center',
                  color: '#FFFFFF',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#37457A',
                  },
                }}
              >
                Discover Top Funds
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {isDesktop ? (
        <Table>
          <TableHeader className='bg-[#F4F7FE]'>
            <TableRow className='border-none'>
              <TableHead className='rounded-l-xl text-center font-DMSans font-medium text-sm text-primary-400 w-18 px-0'></TableHead>
              <TableHead className='font-DMSans font-medium text-sm text-primary-400'>
                Investment Name
              </TableHead>
              {/* <TableHead className='text-right'>Annual Step Up</TableHead> */}
              <TableHead className='text-right font-DMSans font-medium text-sm text-primary-400'>
                Investment amount
              </TableHead>
              <TableHead className='text-right rounded-r-xl'></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sips
              ?.filter((sip) => sip.customerBasketInvestmentId == null)
              ?.sort((a, b) => {
                const dateA = +getDate(a.sipStartDate)
                const dateB = +getDate(b.sipStartDate)
                return dateA - dateB
              })
              ?.map((sips, index) => (
                <TableRow
                  key={sips.date}
                  onMouseEnter={() => {
                    setIsHovered(true)
                    setHoveredRow(index)
                  }}
                  onMouseLeave={() => {
                    setIsHovered(false)
                    setHoveredRow(null)
                  }}
                  style={{
                    boxShadow:
                      isHovered &&
                      hoveredRow === index &&
                      '0px 24px 24px -20px rgba(0, 0, 0, 0.08)',
                  }}
                >
                  <TableCell className='font-medium p-0 m-0'>
                    <div className='flex justify-center items-center'>
                      <img
                        src={`${env.MF_LOGOS_URL}/${sips?.amcCode}.png`}
                        className='h-12 w-12 rounded-[8px]'
                      />
                    </div>
                  </TableCell>

                  <TableCell>
                    <p className='font-DMSans font-semibold text-base text-black-500 flex items-center flex-nowrap gap-x-2'>
                      <span> {sanitizeName(sips)}</span>

                      <span
                        className={`text-sm ${
                          capitalizeWords(sips?.sipStatus) === 'Active'
                            ? 'text-secondary-500 bg-secondary-50 rounded-full px-2'
                            : capitalizeWords(sips?.sipStatus) === 'Cancelled'
                            ? 'text-error-500 bg-error-50 rounded-full px-2'
                            : 'text-warning-500 bg-warning-50 rounded-full px-2'
                        }`}
                      >
                        {capitalizeWords(sips?.sipStatus)}
                      </span>
                    </p>
                    <p className='text-sm text-black-400'>
                      Repeats on the <strong>{addNumberSuffix(getDate(sips?.sipStartDate))}</strong>{' '}
                      of every month
                    </p>
                    {/* <p className='text-xs'>MandateID - {sips?.mandateId}</p> */}
                    <div className='flex gap-x-1 text-xs items-center text-black-400'>
                      Start Date -
                      <p className='flex justify-start text-xs items-center gap-x-1 text-center font-DMSans'>
                        <span className='font-semibold'>{getDate(sips?.sipStartDate)}</span>
                        <span className='font-semibold '>
                          {monthShortNames[getMonth(sips?.sipStartDate) - 1]}
                        </span>
                        <span className='font-semibold '>{getYear(sips?.sipStartDate)}</span>
                      </p>
                    </div>
                  </TableCell>
                  {/* <TableCell className='text-right'>+{sips?.Annual_Step_Up}%</TableCell> */}
                  <TableCell className='text-right font-DMSans font-semibold text-base'>
                    ₹ {toCurrency(sips?.installmentAmt)}
                  </TableCell>
                  {capitalizeWords(sips?.sipStatus) === 'Active' && (
                    <TableCell className='text-right'>
                      <Menubar>
                        <MenubarMenu className='bg-transparent'>
                          <MenubarTrigger className='hover:bg-[#F4F7FE] px-1 py-[10px] rounded-full h-10 w-7 flex justify-center items-center cursor-pointer'>
                            <MoreVertIcon sx={{ color: '#41558D' }} />
                          </MenubarTrigger>

                          <MenubarContent className='rounded-[10px] flex flex-col gap-y-1'>
                            {/* <MenubarItem
                            onClick={() => setOpen(true)}
                            className='py-2 pl-3 text-sm text-black-500 cursor-pointer  hover:bg-blue-100'
                          >
                            Modify
                          </MenubarItem> */}

                            <MenubarItem
                              className='py-2 pl-3 text-sm text-black-500 cursor-pointer hover:bg-blue-100'
                              onClick={() => handlePauseSIP(sips?.sipRegId)}
                            >
                              Cancel
                            </MenubarItem>
                            {/* 
                          <MenubarItem
                            onClick={() => {
                              setOpen(false)
                            }}
                            className='py-2 pl-3 text-sm cursor-pointer hover:bg-red-100 hover:text-red-500'
                          >
                            Cancel
                          </MenubarItem> */}
                          </MenubarContent>
                        </MenubarMenu>
                      </Menubar>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      ) : (
        <>
          <div className='px-4 flex flex-col gap-y-4'>
            {sips
              ?.filter((sip) => sip.customerBasketInvestmentId == null)
              ?.sort((a, b) => {
                const dateA = +getDate(a.sipStartDate)
                const dateB = +getDate(b.sipStartDate)
                return dateA - dateB
              })
              .map((sips, index) => (
                <div
                  key={sips.date}
                  onMouseEnter={() => {
                    setIsHovered(true)
                    setHoveredRow(index)
                  }}
                  onMouseLeave={() => {
                    setIsHovered(false)
                    setHoveredRow(null)
                  }}
                  style={{
                    boxShadow:
                      isHovered &&
                      hoveredRow === index &&
                      '0px 24px 24px -20px rgba(0, 0, 0, 0.08)',
                  }}
                  className='flex gap-x-4'
                >
                  <div className='font-medium text-center'>
                    {/* <div className='h-12 w-12 bg-primary-50 rounded-lg flex justify-center items-center'>
                      <div className='text-center font-DMSans text-primary-500'>
                        <p className='font-semibold text-[18px] leading-[26px]'>
                          {getDate(sips?.sipStartDate)}
                        </p>
                        <p className='font-medium text-[10px] leading-3'>
                          {monthShortNames[getMonth(sips?.sipStartDate) - 1]}
                        </p>
                      </div>
                    </div> */}
                    <img
                      src={`${env.MF_LOGOS_URL}/${sips?.amcCode}.png`}
                      className='h-10 w-10 mt-1 rounded-[8px]'
                    />
                  </div>

                  <div className='w-full'>
                    <div className='w-full flex justify-between'>
                      <div>
                        <div className='flex items-center'>
                          <p className='font-DMSans font-semibold text-base truncate whitespace-nowrap overflow-hidden max-w-[200px] md:max-w-max'>
                            {sanitizeName(sips)}
                          </p>

                          <span
                            className={`text-sm ${
                              capitalizeWords(sips?.sipStatus) === 'Active'
                                ? 'text-secondary-500 bg-secondary-50 rounded-full px-2'
                                : capitalizeWords(sips?.sipStatus) === 'Cancelled'
                                ? 'text-error-500 bg-error-50 rounded-full px-2'
                                : 'text-warning-500 bg-warning-50 rounded-full px-2'
                            }`}
                          >
                            {capitalizeWords(sips?.sipStatus)}
                          </span>
                        </div>
                        <p className='text-sm text-black-400'>
                          Repeats on the{' '}
                          <strong className='text-black-500'>
                            {addNumberSuffix(getDate(sips?.sipStartDate))}
                          </strong>{' '}
                          of every month
                        </p>
                      </div>

                      {capitalizeWords(sips?.sipStatus) === 'Active' && (
                        <Sheet>
                          <SheetTrigger className='px-1 py-[10px] rounded-full h-6 w-6 flex justify-center items-center'>
                            <ChevronRight className='h-4 w-4' />
                          </SheetTrigger>
                          <SheetContent side='bottom' className='rounded-t-3xl'>
                            <SheetHeader>
                              <SheetTitle>
                                <div className='flex items-center gap-x-1'>
                                  <div className='flex justify-center items-center'>
                                    <img
                                      src={`${env.MF_LOGOS_URL}/${sips?.amcCode}.png`}
                                      className='h-10 w-10 rounded-[8px]'
                                    />
                                  </div>
                                  <div className='text-start'>
                                    <p className='font-DMSans font-semibold text-base text-black-500 mb-1'>
                                      {sips?.sname}
                                    </p>
                                    <span className='font-DMSans font-medium text-sm text-black-400 flex justify-between'>
                                      <p> Mandate ID - {sips?.mandateId}</p>
                                      <p className='text-black-500 font-semibold'>
                                        ₹ {sips?.installmentAmt}
                                      </p>
                                    </span>
                                  </div>
                                </div>
                              </SheetTitle>
                            </SheetHeader>
                            <Separator className='my-2' />
                            <div>
                              {/* <div
                              onClick={handleModify}
                              className='flex  items-center gap-x-4 hover:bg-[#F4F7FE] p-[10px] rounded-lg'
                            >
                              <EditIcon sx={{ height: '16px', width: '16px' }} />
                              <p className='font-DMSans font-medium text-sm'>Modify</p>
                            </div>
                            <div className='flex items-center gap-x-4  hover:bg-[#F4F7FE] p-[10px] rounded-lg'>
                              <PauseRoundedIcon sx={{ height: '16px', width: '16px' }} />
                              <p className='font-DMSans font-medium text-sm'>Pause</p>
                            </div> */}
                              <Button
                                onClick={() => handlePauseSIP(sips?.sipRegId)}
                                className='flex items-center text-center gap-x-4 bg-error-600  hover:bg-red-500 p-[10px] rounded-lg w-full'
                              >
                                <CancelRoundedIcon sx={{ height: '16px', width: '16px' }} />
                                <p className='font-DMSans font-medium text-sm'>Cancel</p>
                              </Button>
                            </div>
                          </SheetContent>
                        </Sheet>
                      )}
                    </div>
                    <div className='w-full flex justify-between items-center font-DMSans font-semibold'>
                      <p className='text-xs'>MandateID - {sips?.mandateId}</p>
                      <p className='text-base'>₹ {toCurrency(sips?.installmentAmt)}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  )
}

export default SipTable
