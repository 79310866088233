import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'
import { MutualFundsSIPs } from '../../store/MFStore'
import { useEffect } from 'react'
import { Button } from '../../Components/ui/button'
import SipTable from '../../Components/MutualFunds/SipTable'
import MFOrdersSkeleton from '../../Components/SkeletonLoaders/MFOrdersSkeleton'
import NoBond from '../../assets/images/nodata.svg'
import { useNavigate } from 'react-router-dom'
import { Card, CardContent, CardHeader, CardTitle } from '../../Components/ui/card'
import env from '../../env/env'

const MFSips = () => {
  const sips = useRecoilValueLoadable(MutualFundsSIPs)
  const navigate = useNavigate()
  const refreshSIPs = useRecoilRefresher_UNSTABLE(MutualFundsSIPs)

  useEffect(() => refreshSIPs(), [])

  if (sips.contents.length === 0) {
    return (
      <div className='bg-white flex items-center justify-center'>
        <Card className='w-full max-w-sm text-center border-none shadow-none font-DMSans'>
          <CardHeader className='flex flex-col gap-y-6 justify-center items-center'>
            <img
              className='h-14 w-14 mx-auto'
              src={`${env.SVGS}/error-icon.svg`}
              alt='Error-Icon'
            />
            <CardTitle className='font-semibold text-base'>Oops! Something Went Wrong</CardTitle>
          </CardHeader>
          <CardContent className='flex flex-col gap-y-6'>
            <p className='text-sm leading-5 text-black-400 mb-6'>
              We're sorry, but it seems there was an issue connecting to our server. Please check
              your internet connection or try refreshing the page. If the problem persists, try
              again later.
            </p>

            {/* <div className='flex gap-x-2 justify-between'>
              <Button
                className='w-full'
                variant='fabitsWhite'
                onClick={() => window.location.reload()}
              >
                <Refresh className='mr-2 h-4 w-4 text-black-300' /> Refresh
              </Button>

              <Link className='flex-1' to='/dashboard/mutual-funds'>
                <Button className='w-full'>
                  View Plans
                  <MoveRight className='ml-2 h-4 w-4' />
                </Button>
              </Link>
            </div> */}
          </CardContent>
        </Card>
      </div>
    )
  }

  switch (sips.state) {
    case 'loading':
      return (
        <div className='max-w-[1128px] mx-auto'>
          <MFOrdersSkeleton />
        </div>
      )

    case 'hasValue':
      return (
        <div className='bg-white min-h-screen'>
          {sips?.contents?.message?.toLowerCase() === 'no sip found for the specified user.' ? (
            <div
              className='py-12'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '12px',
              }}
            >
              <img src={NoBond} width={400} alt='No Bond' />

              <div
                style={{
                  // width: '454px',
                  gap: '4px',
                  textAlign: 'center',
                }}
              >
                <p
                  style={{
                    fontSize: '20px',
                    fontWeight: 600,
                    lineHeight: '28px',
                    letterSpacing: '-0.04em',
                    fontFamily: 'DM Sans',
                    marginTop: '30px',
                    marginBottom: '4px',
                    color: '#2D2D2D',
                  }}
                >
                  It seems you have no SIPs
                </p>

                <p
                  style={{
                    fontFamily: 'DM Sans',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    marginBottom: '12px',
                    color: 'black-300',
                  }}
                >
                  Get started with investing today. You can either invest in a plan or explore our
                  custom funds{' '}
                </p>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '34px',
                    justifyContent: 'center',
                    gap: '16px',
                  }}
                >
                  <div
                    style={{
                      width: '149px',
                      height: '42px',
                      padding: '10px 11px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',

                      gap: '8px',
                      borderRadius: '8px',
                      border: '1px solid #D3DBEC',
                      boxShadow: '0px 4px 4px 0px #41558D0A', // Added box shadow
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/dashboard/plans')}
                  >
                    <Button
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontFamily: 'DM Sans',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        textAlign: 'center',
                        color: '#41558D',
                        background: 'transparent',
                        boxShadow: 'none',
                        padding: 0,
                        textTransform: 'none',
                      }}
                    >
                      Plan a Goal
                    </Button>
                  </div>
                  <div onClick={() => navigate('/dashboard/mutual-funds')}>
                    <Button
                      sx={{
                        // width: '149px',
                        height: '42px',
                        padding: '5px 18px',
                        gap: '8px',
                        borderRadius: '8px',
                        background: '#41558D',
                        fontFamily: 'DM Sans',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        textAlign: 'center',
                        color: '#FFFFFF',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: '#37457A',
                        },
                      }}
                    >
                      Discover Top Funds
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='max-w-[1128px] pt-12 mx-auto'>
              <SipTable sips={sips?.contents?.data} />
            </div>
          )}
        </div>
      )

    case 'hasError':
      return (
        <div className='bg-white flex items-center justify-center'>
          <Card className='w-full max-w-3xl text-center border-none shadow-none font-DMSans'>
            <CardHeader className='flex flex-col gap-y-6 justify-center items-center'>
              <img className='h-14 w-14' src={`${env.SVGS}/error-icon.svg`} alt='Error-Icon' />
              <CardTitle className='font-semibold text-base'>Oops! Something Went Wrong</CardTitle>
            </CardHeader>
            <CardContent className='flex flex-col gap-y-6'>
              <p className='text-sm leading-5 text-black-400 mb-6'>
                We're sorry, but it seems there was an issue connecting to our server. Please check
                your internet connection or try refreshing the page. If the problem persists, try
                again later.
              </p>
            </CardContent>
          </Card>
        </div>
      )

    default:
      return null
  }
}

export default MFSips
