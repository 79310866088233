/* eslint-disable no-undef */

let API_URL = 'https://apimywealth.fabits.com/'
let LOOPBACK_URL = 'https://mywealth.fabits.com/'
let AUTH_API_URL = 'https://apimywealth.fabits.com/'
let MF_LOGOS_URL = 'https://d3lgi69rk7563.cloudfront.net/AMC_LOGOS'
let BANK_LOGOS = 'https://d3lgi69rk7563.cloudfront.net/indian-banks/logos'
let SVGS = 'https://d3lgi69rk7563.cloudfront.net/svgs'
let S3_BASE_URL = 'https://d3lgi69rk7563.cloudfront.net'

let E_MANDATE_URL = 'https://emandate.hdfcbank.com/Emandate.aspx'
let CRM_URL = 'https://crm.zoho.in/crm/WebToLeadForm'
let GA_KEY = 'G-3NX9B1P41T'
let CLEVER_TAP_KEY = '466-Z65-WW7Z'
const PLANSERVICE_NAME = 'planservice'
const CUSTOMERSERVICE_NAME = 'customerservice'
const AUTHSERVER = 'authserver'
const DO_NOT_VALIDATE_401 = ['/customerservice/api/customer/login']
const FRESHDESK_SUPPORT_PORTAL_URL =
  'https://fabits.myfreshworks.com/login/auth/1676624556972?client_id=451980303908285122&redirect_uri=https%3A%2F%2Ffabits.freshdesk.com%2Ffreshid%2Fcustomer_authorize_callback%3Fhd%3Dfabits.freshdesk.com'
const FRESHDESK_REDIRECT_URL = 'https://fabits.myfreshworks.com/login/auth/1676624556972'
const SCHEDULE_CALL_URL = 'https://calendly.com/fabits'
let QUICKO_URL = 'https://it.quicko.com'
const FABITS = 'https://fabits.com'

if (process.env.NODE_ENV === 'production') {
  API_URL = process.env.REACT_APP_API_URL
  AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL
  E_MANDATE_URL = process.env.REACT_APP_E_MANDATE_URL
  GA_KEY = process.env.REACT_APP_GA_KEY
  CLEVER_TAP_KEY = process.env.REACT_APP_CLEVER_TAP_KEY
  QUICKO_URL = process.env.REACT_APP_QUICKO_URL ? process.env.REACT_APP_QUICKO_URL : QUICKO_URL
}

const env = {
  API_URL,
  LOOPBACK_URL,
  AUTH_API_URL,
  PLANSERVICE_NAME,
  AUTHSERVER,
  CUSTOMERSERVICE_NAME,
  DO_NOT_VALIDATE_401,
  E_MANDATE_URL,
  FRESHDESK_SUPPORT_PORTAL_URL,
  FRESHDESK_REDIRECT_URL,
  CRM_URL,
  SCHEDULE_CALL_URL,
  GA_KEY,
  CLEVER_TAP_KEY,
  QUICKO_URL,
  MF_LOGOS_URL,
  BANK_LOGOS,
  SVGS,
  FABITS,
  S3_BASE_URL,
}

export default env
