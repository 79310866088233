/* eslint-disable */
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button } from '../../Components/ui/button'
import { Input } from '../../Components/ui/input'
import { Label } from '../../Components/ui/label'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../Components/ui/dialog'
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '../../Components/ui/sheet'

import { ChevronLeft, CircleCheck } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { post } from '../../http/restservice'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userDetailsState } from '../LoginState'
import { useEffect, useState } from 'react'
import useMediaQuery from '../../hooks/useMediaQuery'
import { Toaster } from '../../Components/ui/toaster'
import { useToast } from '../../Components/ui/use-toast'
import { getUserDetails } from '../../utils/AuthUtil'
import clevertap from 'clevertap-web-sdk'
import { toCurrency } from '../../utils/CurrencyUtil'

export const TaxFiling = () => {
  const navigate = useNavigate()
  const [userDetails, setUserDetails] = useRecoilState(userDetailsState)
  const [selectedPlan, setSelectedPlan] = useState({})
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const { toast } = useToast()

  useEffect(() => {
    clevertap.event.push('Page Viewed', { Page_name: 'Tax Filing' }) //Very nice! :thumbs_up:
    setUserDetails(getUserDetails)
  }, [])

  useEffect(() => {
    clevertap.event.push('Page Viewed', {
      Page_name: 'TaxFiling Page',
    })
  }, [])
  const services = [
    { id: 1, title: 'Personalised tax review & suggestions' },
    { id: 2, title: 'Dedicated expert to assist you' },
    { id: 3, title: 'Optimisations to reduce tax liability' },
    { id: 4, title: 'Post-filing support & reports' },
  ]

  const plans = [
    {
      id: 1,
      level: 'Basic',
      for: isDesktop
        ? 'For income via salary < 20 LPA, interest, or rent.'
        : 'For professionals with income lesser than 20 LPA',
      props: [
        'Salaried professionals',
        'Non-salaried professionals',
        '2 or lesser income streams',
        'Rental or interest income',
      ],
      price: 2599,
      discount: 40,
      actualPrice: 4599,
      icon: '/Tax/basic.svg',
      href: 'https://wa.me/+919035189836?text=I%20want%20to%20know%20more%20about%20the%20Basic%20ITR%20filing%20service%20on%20Fabits',
      svg: '/Tax/basic_circle.svg',
      isAdvance: true,
    },
    {
      id: 2,
      level: 'Advanced',
      for: isDesktop
        ? 'For income via salary < 50 LPA, interest, or rent.'
        : 'For professionals with income lesser than 50 LPA',
      props: [
        'Capital gains/losses from MFs, stocks, property, lottery, etc',
        'Long-term investors',
        'Traders',
        'More than 2 income streams',
      ],
      price: 5999,
      discount: 60,
      actualPrice: 9999,
      icon: '/Tax/advanced.svg',
      href: 'https://wa.me/+919035189836?text=I%20want%20to%20know%20more%20about%20the%20Advanced%20ITR%20filing%20service%20on%20Fabits',
      svg: '/Tax/advanced_circle.svg',
      isAdvance: false,
    },
    {
      id: 3,
      level: 'Pro',
      for: 'For salary above 50 LPA, ESOPs/RSUs, plus all in Advanced',
      props: [
        'For income above 50 LPA',
        'For ESOPs/RSU holders',
        'For directors, and CXOs',
        'PSU employees',
      ],
      price: 9999,
      discount: 60,
      actualPrice: 16599,
      icon: '/Tax/pro.svg',
      href: 'https://wa.me/+919035189836?text=I%20want%20to%20know%20more%20about%20the%20Pro%20ITR%20filing%20service%20on%20Fabits',
      svg: '/Tax/pro_circle.svg',
      isAdvance: false,
    },
  ]

  const formik = useFormik({
    initialValues: {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      phoneNumber: userDetails.phoneNumber?.replace(/^\+91/, ''),
      email: '',
      terms: true,
    },
    enableReinitialize: true,

    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'First name can only contain alphabets')
        .required('First name is required'),
      lastName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Last name can only contain alphabets')
        .required('Last name is required'),
      phoneNumber: Yup.string()
        .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits')
        .required('Phone number is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      terms: Yup.boolean().oneOf([true], '').default(true),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      clevertap.event.push('Clicked Button', {
        Action: 'Tax Filing Submitted',
        Plan: selectedPlan.level,
      })

      clevertap.profile.push({
        Site: {
          Name: values.firstName + ' ' + values.lastName,
          Email: values.email,
          Phone: values.phoneNumber,
          'MSG-email': true, // Disable email notifications
          'MSG-push': true, // Enable push notifications
          'MSG-sms': true, // Enable sms notifications
          'MSG-whatsapp': true, // Enable whatsapp notifications
        },
      })

      const payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: '+91' + values.phoneNumber,
        email: values.email,
        planSelected: selectedPlan.level,
        planAmount: selectedPlan.price,
      }

      const response = await post(`/customerservice/api/taxFiling`, payload)

      if (response.isError) {
        setSubmitting(false)

        toast({
          variant: 'destructive',
          title: 'Uh-oh',
          description: 'Something went wrong. Try again later.',
        })
      } else {
        setSubmitting(false)
        toast({
          variant: 'success',
          title: 'Good to go!',
          description: 'We will contact you soon regarding your tax filing needs',
        })

        isDesktop
          ? document.getElementById('closeDialogButton').click()
          : document.getElementById('closeSheet').click()
      }
    },
  })

  return (
    <>
      <div className='w-full relative'>
        <div className='sticky top-0 left-0 pt-safe w-full p-[10px] md:px-[192px] bg-inherit z-[1000] bg-[rgba(255, 255, 255, 0.2)] z-100 backdrop-blur-[48px]'>
          <Button
            className='flex items-center bg-transparent hover:bg-transparent gap-x-1 text-[#2D2D2D] font-semibold text-base md:text-xl z-10 cursor-pointer border-none'
            onClick={() => navigate('/dashboard')}
            variant='outline'
          >
            <ChevronLeft className='h-6 w-6' /> Tax Planning
          </Button>
        </div>
        <div className='relative text-center px-[16px] py-[72px] md:px-[192px] md:py-[70px]'>
          <div
            className='absolute top-[-72px] inset-0 mix-blend-multiply opacity-75 -z-10'
            style={{
              background: 'url(/Tax/Herobg.svg)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          ></div>

          <h1 className='text-4xl md:text-[3.4rem] font-semibold leading-[64px] tracking-[-2.24px]'>
            <span className='bg-gradient-to-r from-[#A91FCB] via-[#A91FCB] to-[#08BABA] bg-clip-text text-transparent'>
              Filing taxes
            </span>
            <br />
            <span className='text-[#2D2D2D]'>shouldn’t be taxing</span>
          </h1>
          <p className='text-base text-[#2D2D2D] md:text-[#575757] mt-[8px]'>
            Grab a coffee, and chill! <br className='md:hidden ml-1' />
            Let our experts do the hard work.
          </p>
          <div className='flex justify-center items-center gap-x-4 mx-auto mt-[50px]'>
            <button
              className='rounded-3xl w-full lg:w-auto text-[#2D2D2D] py-[12px] lg:px-[48px] lg:py-[10px] border border-[#2D2D2D]'
              variant='outline'
              onClick={() =>
                window.open(
                  'https://wa.me/+919035189836?text=I%20want%20to%20know%20more%20about%20the%20ITR%20filing%20service%20on%20Fabits.',
                )
              }
            >
              Talk to us
            </button>
            <a href='#plans' className='w-full lg:w-auto'>
              <button className='rounded-3xl w-full lg:w-auto text-white  py-[12px] lg:px-[48px] lg:py-[10px] bg-[#2D2D2D]'>
                Explore Plans
              </button>
            </a>
          </div>
        </div>

        <div className='flex md:hidden items-center p-4'>
          <div className='md:text-center'>
            <h2 className='text-2xl font-semibold'>Services we offer</h2>
            <p className='text-[#427C7C] text-sm'>
              Get a dedicated expert to review & file ITR for you{' '}
            </p>

            <ul className='flex flex-col gap-y-[8px] mt-4'>
              {services.map((service) => (
                <li
                  key={service.id}
                  className='flex justify-start items-start gap-x-2 text-[#2D2D2D]'
                >
                  <img src='/Tax/services_circle.svg' alt='services' className='mt-[2px]' />
                  {service.title}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className='md:px-[6vw] lg:px-[12.7vw] md:py-[72px]'>
          <div className='mx-3 my-5 md:m-5 md:text-center'>
            <h3 className='text-2xl md:text-[2rem] font-semibold text-[#0A4545] leading-[2.625rem]'>
              Plans for everyone
            </h3>
            <p className='text-[#427C7C]'>Reduce liability, maximise income!</p>
          </div>
          <div
            className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[1.5rem] p-4 lg:p-0'
            id='plans'
          >
            {plans.map((plan) => (
              <div
                key={plan.id}
                className={`${
                  plan.level === 'Basic'
                    ? 'border border-[#CDDEFF]'
                    : plan.level === 'Advanced'
                    ? 'border border-[#ECD0ED]'
                    : 'border border-[#E4C7B7]'
                } rounded-[20px] pb-2 flex-1 flex flex-col justify-between max-h-[500px]'`}
                style={{
                  background:
                    plan.level === 'Basic'
                      ? 'radial-gradient(77.04% 68.72% at 16.46% 11.89%, rgba(69, 133, 254, 0.12) 0%, rgba(69, 133, 254, 0.00) 100%)'
                      : plan.level === 'Advanced'
                      ? 'radial-gradient(77.04% 68.72% at 16.46% 11.89%, rgba(214, 108, 216, 0.12) 0%, rgba(214, 108, 216, 0.00) 100%)'
                      : 'radial-gradient(77.04% 68.72% at 16.46% 11.89%, rgba(189, 106, 60, 0.12) 0%, rgba(189, 106, 60, 0.00) 100%)',
                }}
              >
                <div className='flex justify-start items-start px-2'>
                  <img src={plan.icon} alt={plan.level} />
                  <div>
                    <h3
                      className={`${
                        plan.level === 'Basic'
                          ? 'text-[#0D337B]'
                          : plan.level === 'Advanced'
                          ? 'text-[#8A108C]'
                          : 'text-[#832F00]'
                      } text-xl font-semibold mt-5`}
                    >
                      {plan.level}
                    </h3>
                    <p
                      className={`${
                        plan.level === 'Basic'
                          ? 'text-[#4263A1]'
                          : plan.level === 'Advanced'
                          ? 'text-[#D66CD8]'
                          : 'text-[#BD6A3C]'
                      } text-sm md:text-base md:font-medium`}
                    >
                      {plan.for}
                    </p>
                  </div>
                </div>
                <div className='px-4 py-3'>
                  <p
                    className={`${
                      plan.level === 'Basic'
                        ? 'text-[#0D337B]'
                        : plan.level === 'Advanced'
                        ? 'text-[#8A108C]'
                        : 'text-[#832F00]'
                    } text-base md:text-xl font-semibold`}
                  >
                    This plan is perfect for
                  </p>
                  <ul className='flex flex-col gap-y-2 my-2 py-2'>
                    {plan.props.map((prop, i) => (
                      <li
                        key={prop + i}
                        className='flex justify-start items-start gap-x-2 font-medium'
                      >
                        <img src={plan.svg} alt={plan.level} className='mt-[2px]' />
                        {prop}
                      </li>
                    ))}
                  </ul>

                  <div className='flex gap-x-3 items-end mt-5'>
                    <h1
                      className={`${
                        plan.level === 'Basic'
                          ? 'text-[#0D337B]'
                          : plan.level === 'Advanced'
                          ? 'text-[#8A108C]'
                          : 'text-[#832F00]'
                      } text-4xl custom-font leading-[3rem] font-semibold`}
                    >
                      &#8377;
                      {toCurrency(plan.price)}
                    </h1>
                    <span
                      className={`${
                        plan.level === 'Basic'
                          ? 'text-[#0D337B]'
                          : plan.level === 'Advanced'
                          ? 'text-[#8A108C]'
                          : 'text-[#832F00]'
                      } relative custom-font`}
                    >
                      &#8377; {toCurrency(plan.actualPrice)}
                      <img
                        className='absolute top-3 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
                        src='/Tax/cross.svg'
                      />
                    </span>
                    <span className='text-red-500 font-semibold'>(-{plan.discount}%)</span>
                  </div>
                  <p
                    className={`${
                      plan.level === 'Basic'
                        ? 'text-[#4263A1]'
                        : plan.level === 'Advanced'
                        ? 'text-[#D66CD8]'
                        : 'text-[#BD6A3C]'
                    } text-xs`}
                  >
                    One time service fee, inclusive of GST
                  </p>

                  <p
                    className={`${
                      plan.level === 'Basic'
                        ? 'text-[#4263A1]'
                        : plan.level === 'Advanced'
                        ? 'text-[#D66CD8]'
                        : 'text-[#BD6A3C]'
                    } text-sm`}
                  >
                    In case of advance tax filing, the service fee is ₹ 2,000
                  </p>

                  {isDesktop ? (
                    <Dialog>
                      <DialogTrigger
                        className={`${
                          plan.level === 'Basic'
                            ? 'bg-[#0D337B]'
                            : plan.level === 'Advanced'
                            ? 'bg-[#8A108C]'
                            : 'bg-[#832F00]'
                        } w-full rounded-xl mt-5 text-white px-[24px] py-[10px] hidden md:block`}
                        variant='outline'
                        onClick={() => setSelectedPlan(plan)}
                      >
                        Select {plan.level} Plan
                      </DialogTrigger>
                      <DialogContent className='hidden md:block'>
                        <DialogHeader>
                          <DialogTitle className='text-start'>{plan.level}</DialogTitle>
                          <DialogDescription className='text-start'>{plan.for}</DialogDescription>
                        </DialogHeader>
                        <div className='border-b py-2' />

                        <form onSubmit={formik.handleSubmit}>
                          <div className='grid gap-4 py-4'>
                            <div className='flex gap-x-2'>
                              <div className='flex flex-col gap-y-2 justify-start items-start w-full'>
                                <Label htmlFor='firstName' className='text-start'>
                                  First name
                                </Label>
                                <Input
                                  id='firstName'
                                  name='firstName'
                                  type='text'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.firstName}
                                  placeholder='eg. Dhruv'
                                />
                                {formik.touched.firstName && formik.errors.firstName ? (
                                  <div className='text-red-500'>{formik.errors.firstName}</div>
                                ) : null}
                              </div>
                              <div className='flex flex-col gap-y-2 justify-start items-start w-full'>
                                <Label htmlFor='lastName' className='text-start'>
                                  Last name
                                </Label>
                                <Input
                                  id='lastName'
                                  name='lastName'
                                  type='text'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.lastName}
                                  placeholder='eg. Sharma'
                                />
                                {formik.touched.lastName && formik.errors.lastName ? (
                                  <div className='text-red-500'>{formik.errors.lastName}</div>
                                ) : null}
                              </div>
                            </div>

                            <div className='flex gap-x-2'>
                              <div className='flex flex-col gap-y-2 justify-start items-start w-full'>
                                <Label htmlFor='phoneNumber' className='text-left'>
                                  Phone number
                                </Label>
                                <Input
                                  id='phoneNumber'
                                  name='phoneNumber'
                                  type='text'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.phoneNumber}
                                  placeholder='eg. 9788990099'
                                />
                                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                  <div className='text-red-500'>{formik.errors.phoneNumber}</div>
                                ) : null}
                              </div>

                              <div className='flex flex-col gap-y-2 justify-start items-start w-full'>
                                <Label htmlFor='email' className='text-left'>
                                  Email address
                                </Label>
                                <Input
                                  id='email'
                                  name='email'
                                  type='email'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.email}
                                  placeholder='eg. dhruv.sharma@gmail.com'
                                />
                                {formik.touched.email && formik.errors.email ? (
                                  <div className='text-red-500'>{formik.errors.email}</div>
                                ) : null}
                              </div>
                            </div>

                            <div className='flex items-center px-1 space-x-2'>
                              {/* <input
                                className='h-4 w-4'
                                type='checkbox'
                                id='terms'
                                name='terms'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.terms}
                              />
                              <label
                                htmlFor='terms'
                                className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                              >
                                I wish to receive communication from Fabits
                              </label> */}
                              <div className='checkbox-wrapper-46'>
                                <input
                                  type='checkbox'
                                  name='terms'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  checked={formik.values.terms}
                                  id='cbx-46'
                                  className='inp-cbx'
                                />
                                <label for='cbx-46' className='cbx'>
                                  <span>
                                    <svg viewBox='0 0 12 10' height='10px' width='12px'>
                                      <polyline points='1.5 6 4.5 9 10.5 1'></polyline>
                                    </svg>
                                  </span>
                                  <span className='text-sm font-medium'>
                                    I wish to receive communication from Fabits
                                  </span>
                                </label>
                              </div>
                              {formik.touched.terms && formik.errors.terms ? (
                                <div className='text-red-500'>{formik.errors.terms}</div>
                              ) : null}
                            </div>
                          </div>

                          <div className='border-b py-2' />

                          <div className='flex justify-between items-center w-full mt-3'>
                            <Button
                              className='mt-2'
                              variant='link'
                              onClick={() => window.open(plan.href)}
                            >
                              Get in touch
                            </Button>
                            <div className='w-full flex justify-end gap-x-5'>
                              <DialogClose asChild>
                                <Button
                                  variant='outline'
                                  className='py-[10px] px-[24px]'
                                  id='closeDialogButton'
                                >
                                  Close
                                </Button>
                              </DialogClose>
                              <Button
                                type='submit'
                                className='py-[10px] px-[24px]'
                                disabled={!formik.values.terms || formik.isSubmitting}
                              >
                                {formik.isSubmitting ? 'Submitting...' : 'Request callback'}
                              </Button>
                            </div>
                          </div>
                        </form>
                      </DialogContent>
                    </Dialog>
                  ) : (
                    <Sheet>
                      <SheetTrigger asChild>
                        <Button
                          className={`${
                            plan.level === 'Basic'
                              ? 'bg-[#0D337B]'
                              : plan.level === 'Advanced'
                              ? 'bg-[#8A108C]'
                              : 'bg-[#832F00]'
                          } w-full rounded-xl mt-5 text-white block lg:hidden`}
                          variant='outline'
                          onClick={() => setSelectedPlan(plan)}
                        >
                          Select {plan.level} Plan
                        </Button>
                      </SheetTrigger>
                      <SheetContent side='bottom' className='rounded-3xl z-[100] lg:hidden'>
                        <SheetHeader>
                          <SheetTitle>{plan.level}</SheetTitle>
                          <SheetDescription>{plan.for} </SheetDescription>
                        </SheetHeader>
                        <form onSubmit={formik.handleSubmit}>
                          <div className='grid gap-4 py-4'>
                            <div className='flex gap-x-2 justify-between items-start'>
                              <div className='w-full'>
                                <Label htmlFor='firstName' className='text-left'>
                                  First name
                                </Label>
                                <Input
                                  id='firstName'
                                  name='firstName'
                                  type='text'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.firstName}
                                  placeholder='eg. Dhruv'
                                />
                                {formik.touched.firstName && formik.errors.firstName ? (
                                  <div className='text-red-500'>{formik.errors.firstName}</div>
                                ) : null}
                              </div>
                              <div className='w-full'>
                                <Label htmlFor='lastName' className='text-left'>
                                  Last name
                                </Label>
                                <Input
                                  id='lastName'
                                  name='lastName'
                                  type='text'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.lastName}
                                  placeholder='eg. Sharma'
                                />
                                {formik.touched.lastName && formik.errors.lastName ? (
                                  <div className='text-red-500'>{formik.errors.lastName}</div>
                                ) : null}
                              </div>
                            </div>

                            <div className='flex flex-col gap-y-2 justify-start items-start'>
                              <Label htmlFor='phoneNumber' className='text-left'>
                                Phone number
                              </Label>
                              <Input
                                id='phoneNumber'
                                name='phoneNumber'
                                type='text'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phoneNumber}
                                placeholder='eg. 9788990099'
                              />
                              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                <div className='text-red-500'>{formik.errors.phoneNumber}</div>
                              ) : null}
                            </div>

                            <div className='flex flex-col gap-y-2 justify-start items-start'>
                              <Label htmlFor='email' className='text-left'>
                                Email address
                              </Label>
                              <Input
                                id='email'
                                name='email'
                                type='email'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                placeholder='eg. dhruv.sharma@gmail.com'
                              />
                              {formik.touched.email && formik.errors.email ? (
                                <div className='text-red-500'>{formik.errors.email}</div>
                              ) : null}
                            </div>

                            <div className='flex items-center px-1 space-x-2'>
                              <input
                                className='h-4 w-4'
                                type='checkbox'
                                id='terms'
                                name='terms'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.terms}
                              />
                              <label
                                htmlFor='terms'
                                className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                              >
                                I wish to receive communication from Fabits
                              </label>
                              {formik.touched.terms && formik.errors.terms ? (
                                <div className='text-red-500'>{formik.errors.terms}</div>
                              ) : null}
                            </div>
                          </div>
                          <SheetFooter>
                            <Button
                              type='submit'
                              disabled={!formik.values.terms || formik.isSubmitting}
                            >
                              {formik.isSubmitting ? 'Submitting...' : 'Request callback'}
                            </Button>
                          </SheetFooter>
                        </form>
                        <div className='flex justify-center items-center w-full'>
                          <Button
                            className='mt-2'
                            variant='link'
                            onClick={() => window.open(plan.href)}
                          >
                            Get in touch
                          </Button>
                        </div>
                      </SheetContent>
                    </Sheet>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='p-5 md:p-10 hidden md:flex md:justify-center md:items-center'>
          <div className='md:text-center'>
            <h2 className='text-2xl font-semibold'>Services we offer</h2>
            <p>Get a dedicated expert to review & file ITR for you </p>

            <ul className='grid grid-cols-1 gap-2 md:grid-cols-2 my-5'>
              {services.map((service) => (
                <li key={service.id} className='flex gap-x-2'>
                  <img src='/Tax/services_circle.svg' alt='services' className='mt-[2px]' />
                  {service.title}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className='text-center border-t border-[#AEC695] py-[48px] px-[20px]'
          style={{
            background:
              'radial-gradient(77.04% 68.72% at 16.46% 11.89%, rgba(102, 148, 56, 0.12) 0%, rgba(102, 148, 56, 0.00) 100%)',
          }}
        >
          <h2 className='text-[#1D3A00] text-2xl font-semibold'>Confused which plan to pick?</h2>
          <p className='text-[#608B34]'>
            Get in touch with us for
            <br />
            complete, dedicated assistance
          </p>
          <button
            className='bg-[#1D3A00] w-[90%] md:w-[15%] mx-auto rounded-3xl mt-6 text-white cursor-pointer px-[48px] py-[10px]'
            onClick={() =>
              window.open(
                'https://wa.me/+919035189836?text=I%20want%20to%20know%20more%20about%20the%20ITR%20filing%20service%20on%20Fabits.',
              )
            }
          >
            Talk to us
          </button>
        </div>
      </div>
      <Toaster />
    </>
  )
}
