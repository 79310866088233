import { useEffect, useRef, useState } from 'react'
import { Button } from '../../Components/ui/button'
import { useNavigate } from 'react-router-dom'
import { get } from '../../http/restservice'
import { getUserDetails } from '../../utils/AuthUtil'
import env from '../../env/env'
import { ChevronLeft, Star } from 'lucide-react'

const analyticsCards = [
  { id: 1, title: 'Fabits Short term fund', analytic: '17.62' },
  { id: 2, title: 'Fabits Mid term fund', analytic: '19.36' },
  { id: 3, title: 'Fabits Long term fund', analytic: '22.11' },
  { id: 4, title: 'NIFTY 50', analytic: '8.81' },
]

const Returns = () => {
  const navigate = useNavigate()
  const [Questions, setQuestions] = useState([])
  const [loginDetails, setLoginDetails] = useState({})
  const videoRef = useRef(null)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.5 // Adjust the speed (0.5 means half-speed)
    }
  }, [])

  useEffect(() => {
    setLoginDetails(getUserDetails())
  }, [])

  useEffect(() => {
    const fetchSavedResponse = async () => {
      if (!loginDetails.phoneNumber) return
      const url = `https://apimywealth.fabits.com/customerservice/api/firstIdeas`
      const phoneNumber = loginDetails.phoneNumber
      try {
        const response = await get(url, {}, { phoneNumber })
        console.log(response, 'sdfdsfd')
        setQuestions(response)
      } catch (error) {
        console.log(error, 'error')
      }
    }

    fetchSavedResponse()
  }, [loginDetails.phoneNumber])

  return (
    <>
      {/* Desktop mode code */}
      <div className='bg-white'>
        <div className='hidden h-screen lg:block max-w-[1128px] mx-auto'>
          <div className='flex justify-between w-full pt-6'>
            <div>
              <h1 className='font-DMSans font-bold text-xl text-black-400'>
                2024 Performance Report
              </h1>
              <p className='font-DMSans font-normal text-base text-primary-600'>
                Don't miss out on an opportunity to invest in our diversified baskets
              </p>
            </div>
            <div className='flex items-end w-[348px]'>
              {(Questions?.isCompleted === false || Questions.length <= 0) && (
                <Button
                  className='w-full'
                  onClick={() => navigate('/dashboard/firstTime/questionnaire')}
                >
                  Get Started
                </Button>
              )}
            </div>
          </div>

          {/* <a
            href={`${env.S3_BASE_URL}/firstTime/Performance_Report.pdf`}
            download
            target='_blank'
            rel='noreferrer'
          >
            <div className='my-10 flex gap-4 items-center border border-[#D3DBEC] py-2 pr-10 pl-3 max-w-[306px]'>
              <img src={`${env.S3_BASE_URL}/firstTime/pdf.svg`} alt='pdf icon' />
              <p className='font-DMSans font-normal text-sm text-black-400'>Download PDF</p>
            </div>
          </a> */}

          <div>
            <h1 className='font-DMSans font-semibold text-xl text-black-500'>
              Fabits basket performance
            </h1>

            <div className='flex gap-5 mt-2'>
              <div className='max-w-[909px]'>
                <video ref={videoRef} autoPlay muted loop>
                  <source src={`${env.S3_BASE_URL}/firstTime/BearMarketPerformance.mp4`} />
                </video>

                <p className='mt-3'>
                  Our curated baskets performed better than Nifty 50 in 2024 by diversifying your
                  investments and achieving the most optimal risk-adjusted-returns for your goals.
                </p>
              </div>

              <div className='w-[199px] flex flex-col gap-4'>
                {analyticsCards.map((details) => {
                  const { id, title, analytic } = details
                  return (
                    <div key={id} className='bg-[#F4F7FE] p-3 rounded-[16px]'>
                      <p
                        className={`font-DMSans flex items-center justify-between font-medium text-[28px] ${
                          id === 4 ? 'text-red-400' : 'text-secondary-600'
                        } `}
                      >
                        <span>{analytic} %</span>
                        {id != 4 && (
                          <span className='flex items-center justify-center pr-2'>
                            <Star
                              className='w-8 h-8 text-yellow-500 drop-shadow-lg'
                              fill='currentColor'
                            />
                          </span>
                        )}
                      </p>
                      <p className='font-DMSans font-semibold text-base text-black-400'>{title}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile and tablet mode code */}
      <div className='lg:hidden py-6 px-4 bg-white flex flex-col gap-6'>
        <div
          className='flex items-center gap-x-1 font-bold text-black-400 lg:hidden'
          onClick={() => {
            navigate('/dashboard')
          }}
        >
          <ChevronLeft className='h-5 w-5' />
          2024 Fabits Returns
        </div>

        {/* <a
          href={`${env.S3_BASE_URL}/firstTime/Performance_Report.pdf`}
          download
          target='_blank'
          rel='noreferrer'
        >
          <div className='py-2 px-3 flex gap-4 rounded-[4px] font-DMSans items-center border border-[#D3DBEC]'>
            <img src={`${env.S3_BASE_URL}/firstTime/pdf.svg`} alt='pdf icon' />
            <p className='text-black-400'>Download PDF</p>
          </div>
        </a> */}

        <div>
          <h1 className='font-semibold text-lg font-DMSans'>2024 Performance Report</h1>

          <div className='gap-5'>
            <div className='max-w-[909px]'>
              <video autoPlay muted loop>
                <source src={`${env.S3_BASE_URL}/firstTime/BearMarketPerformance.mp4`} />
              </video>

              <p className='mt-6 text-sm'>
                Our curated baskets performed better than Nifty 50 in 2024 by diversifying your
                investments and achieving the most optimal risk-adjusted-returns for your goals.
              </p>
            </div>

            <div className='grid grid-cols-2 gap-4 mt-10 pb-10'>
              {analyticsCards.map((details) => {
                const { id, title, analytic } = details
                return (
                  <div key={id} className='bg-[#F4F7FE] p-3 rounded-[16px]'>
                    <p
                      className={`font-DMSans flex items-center justify-between font-medium text-[28px] ${
                        id === 4 ? 'text-red-400' : 'text-secondary-600'
                      } `}
                    >
                      <span>{analytic} %</span>
                      {id != 4 && (
                        <span className='flex items-center justify-center'>
                          <Star
                            className='w-8 h-8 text-yellow-500 drop-shadow-lg'
                            fill='currentColor'
                          />
                        </span>
                      )}
                    </p>

                    <p className='font-DMSans font-semibold text-base text-black-400'>{title}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className='fixed bottom-1 left-0 right-0 px-4 pb-safe'>
          {(Questions?.isCompleted === false || Questions.length <= 0) && (
            <Button
              className='w-full'
              onClick={() => navigate('/dashboard/firstTime/questionnaire')}
            >
              Get started
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

export default Returns
