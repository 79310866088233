/* eslint-disable */
import UPI from '../../assets/SVGS/upi.svg'
import CircleUPI from '../../assets/SVGS/circle-upi.svg'
import CircleRight from '../../assets/SVGS/circle-right.svg'
import { Separator } from '../../Components/ui/separator'
import { Button } from '../../Components/ui/button'
import { useEffect, useMemo, useState } from 'react'
import PaymentGif from '../../assets/gifs/https___lottiefiles.com_animations_flow-tWbpYsXrBz.gif'
import bse from '../../assets/SVGS/bse.svg'
import { useNavigate } from 'react-router-dom'
import env from '../../env/env'
import { useTimer } from '../../hooks/useTimer'
import { useErrorPopup } from '../../hooks/useErrorPopup'
import { useModal } from '../../hooks/use-modal-store'

const ActionPlanUpiRequest = ({ bankDetails, setShowPaymentRequestPopup, paymentStatus }) => {
  const [timeLeft, setTimeLeft] = useState(300) // 5 minutes = 300 seconds
  const { time, isActive, startTimer, pauseTimer, resetTimer } = useTimer(5) // 5 minutes timer
  const navigate = useNavigate()
  const { onOpen } = useModal()
  const { showError } = useErrorPopup()

  const parsedData = useMemo(() => {
    try {
      return JSON.parse(sessionStorage.getItem('oneTimeBasketOrders')) || {}
    } catch {
      return {}
    }
  }, [])

  useEffect(() => {
    startTimer()
  }, [])

  useEffect(() => {
    const pollingInterval = 10 // Interval in seconds

    const intervalId = setInterval(async () => {
      if (timeLeft <= 0) {
        setShowPaymentRequestPopup(false)
        clearInterval(intervalId) // Stop polling after 10 minutes
        // showError(
        //   'Payment Status Pending',
        //   'We are unable to retrieve the payment status at the moment. If the amount has been deducted, don’t worry—your payment status will be updated shortly..',
        //   () => navigate(-1),
        // )

        onOpen('openActionPlanPopup', {
          title: 'Payment Status Pending',
          message:
            'We are unable to retrieve the payment status at the moment. If the amount has been deducted, don’t worry—your payment status will be updated shortly.',
          severity: 'info',
          redirectTo: -1,
        })

        // For the testing purpose

        // onOpen('openActionPlanPopup', {
        //   severity: 'success',
        //   title: '',
        //   message:
        //     'Your UPI payment has been successfully processed. The amount has been debited from your account and credited to the recipient.',
        //   severity: 'success',
        //   redirectTo: -1,
        // })
        return
      }

      console.log(`Time left: ${timeLeft} seconds`)
      paymentStatus(parsedData)

      // Decrease time left using the setter
      setTimeLeft((prevTime) => prevTime - pollingInterval)
    }, pollingInterval * 1000) // Convert interval to milliseconds

    // Cleanup on unmount
    return () => clearInterval(intervalId)
  }, [timeLeft]) // Add dependencies to useEffect

  // Convert the remaining time to minutes and seconds
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
  }

  const onClose = () => {
    setShowPaymentRequestPopup(false)
    navigate(-1)
  }

  return (
    <div className='bg-white absolute top-0 left-0 right-0 bottom-0 z-[10000] overflow-y-scroll'>
      <div className='ease-in-out h-[95vh] md:max-w-[360px] m-auto flex flex-col justify-center gap-y-12 pt-12 pb-16 px-4 lg:pb-0 lg:pt-0 lg:px-0'>
        <div className='flex flex-col gap-y-12'>
          <div>
            <h1 className='text-center text-2xl text-black-500 font-semibold'>Payment Pending</h1>
            <p className='text-center text-sm text-black-400'>Approve payment request on UPI app</p>
          </div>
          <div className='relative flex justify-between items-center px-4 bg-primary-50 py-2 rounded-2xl h-[64px]'>
            <img src={`${env.SVGS}/logo.svg`} alt='Fabits' />
            <img
              src={PaymentGif}
              alt='Moving GIF'
              className='absolute left-[25.51%] move-left-right rotate-90 z-2'
            />
            <img src={UPI} alt='UPI Icon' />
          </div>

          <div>
            <p className='text-center text-sm text-black-400 pb-4'>
              Follow these steps to complete your payment
            </p>
            <div>
              <div className='flex gap-x-2'>
                <img src={CircleRight} alt='Right SVG' />
                <div>
                  <p className='text-sm font-semibold text-black-500'>
                    Request sent to your UPI app
                  </p>
                  <p className='text-sm text-black-500'>
                    Bank A/c ending {bankDetails?.accountNo.slice(-4)}
                  </p>
                </div>
              </div>
              <Separator className='h-6 w-[1px] mx-4 my-2' />
              <div className='flex items-center gap-x-2'>
                <img src={CircleUPI} alt='Right SVG' />
                <p className='text-sm text-black-500 font-semibold'>Open your UPI app</p>
              </div>
              <Separator className='h-6 w-[1px] mx-4 my-2' />
              <div className='flex gap-x-2'>
                <img src={bse} alt='Right SVG' />
                <div>
                  <p className='text-sm text-black-500 font-semibold'>
                    Approve the payment request
                  </p>
                  <p className='text-sm text-black-500'>
                    Request would be sent by <strong>Indian Clearing Corporation</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className='text-black-400 text-center text-sm'>
          Request expires in{' '}
          <b>
            {time.minutes}:{time.seconds.toString().padStart(2, '0')}
          </b>
        </p>
        <Button className='bg-error-50 text-error-500 hover:bg-error-100' onClick={onClose}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default ActionPlanUpiRequest
