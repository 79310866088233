import { useNavigate } from 'react-router-dom'
import { Button } from '../../Components/ui/button'
import React from 'react'

const NewUser = () => {
  const navigate = useNavigate()
  const clickHandler = () => {
    navigate('/dashboard/firstTime')
  }
  return (
    <div>
      <h1>NewUser</h1>
      <Button onClick={clickHandler}>Continue</Button>
    </div>
  )
}

export default NewUser
