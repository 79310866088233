/* eslint-disable */
import { useModal } from '../../hooks/use-modal-store'
import { Button } from '../../Components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../Components/ui/dialog'

import useMediaQuery from '../../hooks/useMediaQuery'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '../../Components/ui/sheet'
import {
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '../../Components/ui/table'
import rocket from '../../assets/SVGS/rocket.svg'
import env from '../../env/env'
import { toCurrency } from '../../utils/CurrencyUtil'

const funds = [
  {
    icon: rocket,
    name: 'Parag Parikh Flexi Cap Fund Direct Growth',
    returns: '47.28%',
    minAmount: '₹ 5,000',
    type: 'Debt',
  },
  {
    icon: rocket,
    name: 'SBI PSU Direct Plan Growth',
    returns: '41.16%',
    minAmount: '₹ 1,000',
    type: 'Equity',
  },
  {
    icon: rocket,
    name: 'Motilal Oswal Midcap Fund Direct Growth',
    returns: '38.73%',
    minAmount: '₹ 2,000',
    type: 'Equity',
  },
]

const OneTimeBreakdown = ({ isOpen, onClose, type, data }) => {
  const isModalOpen = isOpen && type === 'oneTimeBreakdown'
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  // const constituents = data?.map((fund)=>)
  const totalAmount =
    data && data.constituents.reduce((sum, item) => sum + item.investmentAmount, 0)

  const calculateParcentageAmount = (parcentage, totalAmount) => {
    const result = totalAmount * (parcentage / 100)
    return Math.ceil(result)
  }

  return (
    <>
      {isDesktop ? (
        <Dialog open={isModalOpen} onOpenChange={() => onClose()}>
          <DialogContent className='sm:max-w-[736px] font-DMSans p-6' showclose={true}>
            <DialogHeader>
              <DialogTitle className='text-start font-semibold text-xl text-[#2D2D2D]'>
                One Time Investment Breakdown
              </DialogTitle>
              <DialogDescription className='text-start text-black-400 text-sm font-DMSans font-medium'>
                This is how your investment of{' '}
                <b className='text-black-500'>₹ {toCurrency(totalAmount)}</b> gets divided
              </DialogDescription>
            </DialogHeader>
            <div
            // className='max-h-[450px] overflow-y-scroll hide-scrollbar'
            >
              <Table>
                <TableHeader className='bg-[#F4F7FE]'>
                  <TableRow className='border-none'>
                    <TableHead className='text-primary-400 w-[50%] pl-12 rounded-l-xl'>
                      Fund Name
                    </TableHead>
                    <TableHead className='text-primary-400 text-right rounded-r-xl'>
                      Allocation
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data?.constituents?.map((fund, index) => (
                    <TableRow key={index}>
                      <TableCell className='font-medium flex gap-x-2 max-w-[432px]'>
                        <img
                          src={`${env.MF_LOGOS_URL}/${fund?.amcCode}.png`}
                          alt={`${fund.amcCode} icon`}
                          className='rounded-md h-12 w-12'
                        />
                        <div>
                          <p className='text-clip font-semibold text-base text-nowrap truncate'>
                            {fund.legalName ? fund.legalName : fund.schemeName}
                          </p>
                          <span className='font-medium text-xs text-black-400'>
                            {fund.assetClass}
                          </span>
                        </div>
                      </TableCell>

                      <TableCell className='font-semibold text-right'>
                        ₹ {toCurrency(calculateParcentageAmount(fund.weightage, totalAmount))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <DialogFooter>
              <Button
                className='border rounded-lg bg-white hover:bg-white text-primary-500'
                onClick={onClose}
              >
                Okay
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      ) : (
        <Sheet open={isModalOpen} onOpenChange={() => onClose()}>
          <SheetContent
            className={`font-DMSans p-0 py-5 rounded-t-[24px] 
              ${data?.displayNavbar ? 'bottom-[80px]' : 'bottom-0'}
              `}
            showClose={true}
            side='bottom'
          >
            <SheetHeader className='px-4'>
              <SheetTitle className='text-start font-semibold text-[#2D2D2D]'>
                One Time Investment Breakdown
              </SheetTitle>
              <SheetDescription className='text-start text-black-400 font-medium text-sm'>
                This is how your investment of{' '}
                <b className='text-black-500'>₹ {toCurrency(totalAmount)}</b> gets divided
              </SheetDescription>
            </SheetHeader>

            <Table className='mt-6'>
              <TableBody>
                {data?.constituents?.map((fund, index) => (
                  <TableRow
                    key={index}
                    className={`${index + 1 === funds.length && 'border-none'}`}
                  >
                    <TableCell className='font-medium flex gap-x-2 px-4'>
                      <img
                        src={`${env.MF_LOGOS_URL}/${fund?.amcCode}.png`}
                        alt={`${fund.amcCode} icon`}
                        className='rounded-md h-12 w-12'
                      />
                      <div className='w-full'>
                        <p className='font-semibold text-sm text-nowrap truncate max-w-[284px]'>
                          {fund.legalName ? fund.legalName : fund.schemeName}
                        </p>

                        <div className='flex justify-between'>
                          <span className='font-medium text-xs text-black-400'>
                            {fund.assetClass}
                          </span>
                          <span className='font-semibold text-right text-sm'>
                            ₹ {toCurrency(calculateParcentageAmount(fund.weightage, totalAmount))}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </SheetContent>
        </Sheet>
      )}
    </>
  )
}

const SIPBreakdown = ({ isOpen, onClose, type, data }) => {
  const isModalOpen = isOpen && type === 'sipBreakdown'
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  // const constituents = data?.map((fund)=>)
  const totalAmount =
    data && data.constituents.reduce((sum, item) => sum + item.investmentAmount, 0)

  const calculateParcentageAmount = (parcentage, totalAmount) => {
    const result = totalAmount * (parcentage / 100)
    return result
  }

  return (
    <>
      {isDesktop ? (
        <Dialog open={isModalOpen} onOpenChange={() => onClose()}>
          <DialogContent className='sm:max-w-[736px] font-DMSans p-6' showclose={true}>
            <DialogHeader>
              <DialogTitle className='text-start font-semibold text-xl text-[#2D2D2D]'>
                SIP Investment Breakdown
              </DialogTitle>
              <DialogDescription className='text-start text-black-400 text-sm font-DMSans font-medium'>
                This is how your investment of{' '}
                <b className='text-black-500'>₹ {toCurrency(totalAmount)}</b> gets divided
              </DialogDescription>
            </DialogHeader>
            <div
            // className='max-h-[450px] overflow-y-scroll hide-scrollbar'
            >
              <Table>
                <TableHeader className='bg-[#F4F7FE]'>
                  <TableRow className='border-none'>
                    <TableHead className='text-primary-400 w-[50%] pl-12 rounded-l-xl'>
                      Fund Name
                    </TableHead>
                    <TableHead className='text-primary-400 text-right rounded-r-xl'>
                      Allocation
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data?.constituents?.map((fund, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell className='font-medium flex gap-x-2 max-w-[432px]'>
                          <img
                            src={`${env.MF_LOGOS_URL}/${fund?.amcCode}.png`}
                            alt={`${fund.amcCode} icon`}
                            className='rounded-md h-12 w-12'
                          />
                          <div>
                            <p className='text-clip font-semibold text-base text-nowrap truncate'>
                              {fund.legalName ? fund.legalName : fund.schemeName}
                            </p>
                            <span className='font-medium text-xs text-black-400'>
                              {fund.assetClass}
                            </span>
                          </div>
                        </TableCell>

                        <TableCell className='font-semibold text-right'>
                          ₹ {toCurrency(calculateParcentageAmount(fund.weightage, totalAmount))}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </div>
            <DialogFooter>
              <Button
                className='border rounded-lg bg-white hover:bg-white text-primary-500'
                onClick={onClose}
              >
                Okay
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      ) : (
        <Sheet open={isModalOpen} onOpenChange={() => onClose()}>
          <SheetContent
            className={`font-DMSans p-0 py-5 rounded-t-[24px] 
              ${data?.displayNavbar ? 'bottom-[80px]' : 'bottom-0'}
              `}
            showClose={true}
            side='bottom'
          >
            <SheetHeader className='px-4'>
              <SheetTitle className='text-start font-semibold text-[#2D2D2D]'>
                SIP Investment Breakdown
              </SheetTitle>
              <SheetDescription className='text-start text-black-400 font-medium text-sm'>
                This is how your investment of{' '}
                <b className='text-black-500'>₹ {toCurrency(totalAmount)}</b> gets divided
              </SheetDescription>
            </SheetHeader>

            <Table className='mt-6'>
              <TableBody>
                {data?.constituents?.map((fund, index) => (
                  <TableRow
                    key={index}
                    className={`${index + 1 === funds.length && 'border-none'}`}
                  >
                    <TableCell className='font-medium flex gap-x-2 px-4'>
                      <img
                        src={`${env.MF_LOGOS_URL}/${fund?.amcCode}.png`}
                        alt={`${fund.amcCode} icon`}
                        className='rounded-md h-12 w-12'
                      />
                      <div className='w-full'>
                        <p className='font-semibold text-sm text-nowrap truncate max-w-[284px]'>
                          {fund.legalName ? fund.legalName : fund.schemeName}
                        </p>

                        <div className='flex justify-between'>
                          <span className='font-medium text-xs text-black-400'>
                            {fund.assetClass}
                          </span>
                          <span className='font-semibold text-right text-sm'>
                            ₹ {toCurrency(calculateParcentageAmount(fund.weightage, totalAmount))}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </SheetContent>
        </Sheet>
      )}
    </>
  )
}

const PaymentBreakdown = () => {
  const { isOpen, onClose, type, data, onOpen } = useModal()

  if (type === 'oneTimeBreakdown') {
    return <OneTimeBreakdown isOpen={isOpen} onClose={onClose} data={data} type={type} />
  } else if (type === 'sipBreakdown') {
    return <SIPBreakdown isOpen={isOpen} onClose={onClose} data={data} type={type} />
  }

  return null
}

export default PaymentBreakdown
