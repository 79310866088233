import { useEffect, useState } from 'react'
import { AlertCircle } from 'lucide-react'
import { Dialog, DialogContent, DialogHeader } from '../../Components/ui/dialog'
import { Sheet, SheetContent, SheetHeader } from '../../Components/ui/sheet'
import { Button } from '../../Components/ui/button'
import { useErrorPopupStore } from '../../store/useErrorPopupStore'

export function ErrorPopup() {
  const { isOpen, title, description, onClose, hideErrorPopup } = useErrorPopupStore()

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkIfMobile = () => setIsMobile(window.innerWidth < 768)
    checkIfMobile()
    window.addEventListener('resize', checkIfMobile)
    return () => window.removeEventListener('resize', checkIfMobile)
  }, [])

  const handleClose = () => {
    hideErrorPopup()
    if (onClose) onClose()
  }

  const ErrorContent = () => (
    <>
      <div className='flex justify-center items-center text-error-600 mb-2'>
        <AlertCircle className='w-6 h-6 mr-2' />
        <h2 className='text-lg font-semibold'>{title}</h2>
      </div>
      <p className='text-black-400 text-sm text-center'>{description}</p>
      <div className='flex justify-center pt-2'>
        <Button
          className='bg-error-600 hover:bg-error-500 text-white px-6'
          size='sm'
          onClick={handleClose}
        >
          Close
        </Button>
      </div>
    </>
  )

  if (isMobile) {
    return (
      <Sheet open={isOpen} onOpenChange={handleClose}>
        <SheetContent side='bottom' className='rounded-t-3xl font-DMSans'>
          <SheetHeader>
            <ErrorContent />
          </SheetHeader>
        </SheetContent>
      </Sheet>
    )
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className='sm:max-w-[425px] font-DMSans rounded-none'>
        <DialogHeader>
          <ErrorContent />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
