import * as React from 'react'

import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
} from '../../Components/ui/sidebar'
import { Link } from 'react-router-dom'

// This is sample data.
const data = {
  navMain: [
    {
      title: 'Getting Started',
      url: '#',
      items: [
        {
          title: 'Create User Plan',
          url: '/6fb2a4ac30ddccb8b418e27864a778dee5409a7da4c55660f49f21b8543289b4/admin/actionPlan',
          isActive: true,
        },
      ],
    },
  ],
}

const ActionSidebar = ({ user, ...props }) => {
  return (
    <Sidebar {...props}>
      <SidebarHeader>
        <div className='py-3 px-2'>
          <img src='/logo.svg' alt='fabits' className='h-6' />
        </div>
      </SidebarHeader>
      <SidebarContent>
        {user && (
          <div className='flex flex-col items-start space-y-2 px-4'>
            {/* <p className='font-semibold'>{user?.firstName + ' ' + user?.lastName}</p> */}
            <p className='font-medium text-sm'>Role - {user?.role}</p>
            <p className='font-medium text-sm'>Status - {user?.status}</p>
            <p className='font-medium text-sm'>Email - {user?.email}</p>
          </div>
        )}

        {/* We create a SidebarGroup for each parent. */}
        {data.navMain.map((item) => (
          <SidebarGroup key={item.title}>
            <SidebarGroupLabel>{item.title}</SidebarGroupLabel>
            <SidebarGroupContent>
              <SidebarMenu>
                {item.items.map((item) => (
                  <SidebarMenuItem key={item.title}>
                    <SidebarMenuButton
                      asChild
                      isActive={item.isActive}
                      className={`${item.isActive && 'bg-primary-50'}`}
                    >
                      <Link to={item.url}>{item.title}</Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                ))}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        ))}
      </SidebarContent>
      <SidebarRail />
    </Sidebar>
  )
}

export default ActionSidebar
