import { Card, CardContent } from '../ui/card'
import { Skeleton } from '../ui/skeleton'

const MFInvestmentSkeleton = () => {
  return (
    <div className='w-full lg:max-w-[1128px] mx-auto p-4 space-y-8 mt-12'>
      {/* Stats Card */}
      <Card className='lg:w-1/2 mx-auto'>
        <CardContent className='flex justify-between items-center p-6'>
          <div className='space-y-2'>
            <Skeleton className='h-7 w-24 md:w-32' />
            <Skeleton className='h-4 w-16' />
          </div>
          <div className='space-y-2'>
            <Skeleton className='h-7 w-24 md:w-32' />
            <Skeleton className='h-4 w-16' />
          </div>
          <div className='space-y-2'>
            <Skeleton className='h-7 w-24 md:w-32' />
            <Skeleton className='h-4 w-16' />
          </div>
        </CardContent>
      </Card>

      {/* Table Header */}
      <div className='grid grid-cols-4 gap-4 py-4 px-1'>
        <Skeleton className='h-10 w-27' />
        <Skeleton className='h-10 w-27' />
        <Skeleton className='h-10 w-27' />
        <Skeleton className='h-10 w-27' />
      </div>

      {/* Fund List */}
      {[1, 2, 3, 4, 5].map((index) => (
        <div key={index} className='grid grid-cols-4 gap-4 items-center p-4 rounded-lg border'>
          <div className='flex items-center gap-3'>
            <Skeleton className='h-10 w-10 rounded-full' />
            <div className='space-y-2'>
              <Skeleton className='h-4 w-40' />
              <Skeleton className='h-3 w-24' />
            </div>
          </div>
          <div />
          <Skeleton className='h-6 w-24' />
          <div className='flex justify-between items-center'>
            <Skeleton className='h-6 w-16' />
            <Skeleton className='h-9 w-24' />
          </div>
        </div>
      ))}
    </div>
  )
}

export default MFInvestmentSkeleton
