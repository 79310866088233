import { useState } from 'react'
import { Label } from '../../Components/ui/label'
import { Input } from '../../Components/ui/input'
import { Button } from '../../Components/ui/button'
import { post } from '../../http/restservice'
import { useNavigate } from 'react-router-dom'
import env from '../../env/env'
import { toast } from 'sonner'

const fetchCustomerValidation = async (data) => {
  const url = `${env.API_URL}adminservice/api/actionPlan/customerValidation`

  try {
    const response = await post(url, data)
    sessionStorage.setItem('actionPlanUser', JSON.stringify(response))
    return response
  } catch (error) {
    console.log(error, 'Error Occured')
  }
}

const ActionPlan = () => {
  const [data, setData] = useState({
    uccId: '',
    phoneNumber: '',
  })

  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target
    setData((prevData) => ({
      ...prevData,
      [name]:
        name === 'phoneNumber'
          ? value.startsWith('+91')
            ? value
            : `+91${value.replace(/^\+91/, '')}`
          : value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const res = await fetchCustomerValidation(data)

    if (res.code === '200' && res.status === 'SUCCESS') {
      toast.success('Login Successfull')
      navigate('/6fb2a4ac30ddccb8b418e27864a778dee5409a7da4c55660f49f21b8543289b4/admin/addPlan', {
        state: {
          data: res.data,
        },
      })
    } else {
      toast.error('Login Unsuccessfull')
    }
  }

  return (
    <div className='w-full h-screen flex justify-center items-center'>
      <form onSubmit={handleSubmit} className='w-1/2'>
        <div>
          <Label htmlFor='uccId'>Client Code</Label>
          <Input id='uccId' name='uccId' value={data.uccId} onChange={handleChange} />
        </div>
        <div className='mt-10'>
          <Label htmlFor='phoneNumber'>Client Phone Number</Label>
          <Input
            id='phoneNumber'
            name='phoneNumber'
            value={data.phoneNumber}
            onChange={handleChange}
          />
        </div>

        <Button type='submit' className='mt-12 w-full py-6 text-lg'>
          SUBMIT
        </Button>
      </form>
    </div>
  )
}

export default ActionPlan
