import { useRef, useState } from 'react'
import { Button } from '../../Components/ui/button'
import { Dialog, DialogClose, DialogContent, DialogFooter } from '../../Components/ui/dialog'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'

const IntroVideoMobile = ({ showDialog, setShowDialog, handleVideoEnd }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const videoRef = useRef(null)

  const handleSound = () => {
    if (videoRef.current) {
      videoRef.current.muted = false // Enable sound
      videoRef.current.play()
      setIsPlaying(true)
    }
  }

  const handleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = true // Enable sound
      videoRef.current.play()
      setIsPlaying(false)
    }
  }

  return (
    <Dialog open={showDialog} onOpenChange={() => setShowDialog(true)}>
      <DialogContent className='max-w-[586px] bg-black-900 border-none shadow-none p-0 h-full'>
        <div className='flex justify-center relative'>
          <video ref={videoRef} autoPlay loop muted={!isPlaying} playsInline className='w-full'>
            <source
              src='https://d3lgi69rk7563.cloudfront.net/ProductVideos/introvideo.mp4'
              type='video/mp4'
            />
            Your browser does not support the video tag.
          </video>

          {!isPlaying ? (
            <Button
              onClick={handleSound}
              className='absolute p-2 top-20 left-5 flex items-center justify-center bg-black border-none'
            >
              <VolumeOffIcon />
            </Button>
          ) : (
            <Button
              onClick={handleMute}
              className='absolute p-2 top-20 left-5 flex items-center justify-center bg-black border-none'
            >
              <VolumeUpIcon />
            </Button>
          )}
        </div>
        <DialogFooter className='sm:justify-start flex-row flex sm:gap-0 gap-[10px] fixed lg:absolute bottom-14 left-0 right-0 p-5'>
          <DialogClose asChild>
            <Button
              type='button'
              className='flex-1 bg-white text-[#A8A8A8] hover:text-white hover:bg-black-100'
              style={{ border: '1.5px solid #F0F0F0', boxShadow: '0px 6px 6px 0px #41558D0A' }}
              onClick={handleVideoEnd}
            >
              Skip
            </Button>
          </DialogClose>
          <Button onClick={() => setShowDialog(false)} type='button' className='flex-1'>
            <span>Get Started</span>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default IntroVideoMobile
