/* eslint-disable */

import { useEffect, useState } from 'react'
import { Edit, ChevronDown, Check } from 'lucide-react'
import { cn } from '../../lib/utils'
import { Button } from '../../Components/ui/button'
import { Input } from '../../Components/ui/input'
import { Popover, PopoverContent, PopoverTrigger } from '../../Components/ui/popover'
import { Card, CardContent } from '../../Components/ui/card'
import { Slider } from '../../Components/ui/custom-slider'
import GENERALSAVING from '../../assets/images/General saving.svg'
import { toCurrency } from '../../utils/CurrencyUtil'
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { MFTimeBasedBaskets } from '../../store/MFBasketsStore'
import { InvestedPlansSelector } from '../../store/InvestedPlansStore'
import { patch, post } from '../../http/restservice'
import { getUserDetails, isDematUser } from '../../utils/AuthUtil'
import { userDetailsState } from '../LoginState'
import { useLocation, useNavigate } from 'react-router-dom'
import BackButton from '../../Components/BackButton'
import useMediaQuery from '../../hooks/useMediaQuery'
import { messageModalState } from '../../store/Feedback'
// import { formatIndianCurrency } from '../../utils/CurrencyUtil'

const GeneralSavingNew = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const plan = location.state ? location.state.item : null
  const [currentCost, setCurrentCost] = useState(0)
  const [currentInvestments, setCurrentInvestments] = useState(
    plan?.currentInvestment ? plan?.currentInvestment : [],
  )
  const [results, setResults] = useState(plan?.results)

  const [selectedYear, setSelectedYear] = useState(5)
  const [sliderValue, setSliderValue] = useState([10000])
  const [oneTimeInvestment, setOneTimeInvestment] = useState(0)
  const [selectedPercentage, setSelectedPercentage] = useState(0)
  const [isYearPopoverOpen, setIsYearPopoverOpen] = useState(false)
  const [planName, setPlanName] = useState(``)
  const [isEditingPlanName, setIsEditingPlanName] = useState(false)
  const [tempPlanName, setTempPlanName] = useState(``)
  const baskets = useRecoilValueLoadable(MFTimeBasedBaskets)
  const { state, contents: investedPlans } = useRecoilValueLoadable(InvestedPlansSelector)
  const [result, setResult] = useState({})
  const [userDetails] = useRecoilState(userDetailsState)
  const clientCode = userDetails?.uid?.split('-')[0]
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const setMessageModalState = useSetRecoilState(messageModalState)

  const DEFAULT_INFLATION = 0
  const RETURN_RATE = 10

  const yearOptions = [
    {
      label: '1 year',
      value: calculateSavings(
        1,
        sliderValue[0],
        oneTimeInvestment,
        selectedPercentage,
        DEFAULT_INFLATION,
        RETURN_RATE,
      ).finalValue,
    },
    {
      label: '3 years',
      value: calculateSavings(
        3,
        sliderValue[0],
        oneTimeInvestment,
        selectedPercentage,
        DEFAULT_INFLATION,
        RETURN_RATE,
      ).finalValue,
    },
    {
      label: '5 years',
      value: calculateSavings(
        5,
        sliderValue[0],
        oneTimeInvestment,
        selectedPercentage,
        DEFAULT_INFLATION,
        RETURN_RATE,
      ).finalValue,
    },
    {
      label: '10 years',
      value: calculateSavings(
        10,
        sliderValue[0],
        oneTimeInvestment,
        selectedPercentage,
        DEFAULT_INFLATION,
        RETURN_RATE,
      ).finalValue,
    },
    {
      label: '15 years',
      value: calculateSavings(
        15,
        sliderValue[0],
        oneTimeInvestment,
        selectedPercentage,
        DEFAULT_INFLATION,
        RETURN_RATE,
      ).finalValue,
    },
  ]

  // General Savings Plan
  useEffect(() => {
    const details = getUserDetails()
    setPlanName(plan?.planName ? plan.planName : details?.firstName + `'s General Savings Plan`)
    setTempPlanName(plan?.planName ? plan.planName : details?.firstName + `'s General Savings Plan`)
  }, [])

  // const percentageOptions = [0, 5, 10, 15, 20]

  const handleSliderChange = (e) => {
    setSliderValue(e)
    // let rawValue = e[0].toString().replace(/[^0-9]/g, '')
    // setSliderValue([formatIndianCurrency(rawValue)])
  }
  const handleEditClick = () => {
    setIsEditingPlanName(true)
    setTempPlanName(planName)
  }

  const handleSavePlanName = () => {
    setPlanName(tempPlanName)
    setIsEditingPlanName(false)
  }

  function calculateSavings(years, sip, lumpSum = 0, stepUp = 0, inflation = 0, returnRate = 10) {
    const monthsInYear = 12 // Monthly compounding
    const rate = returnRate / 100 // Convert return rate to decimal
    const inflationRate = inflation / 100 // Convert inflation rate to decimal
    const stepUpFactor = stepUp / 100 // Convert step-up percentage to decimal

    // Calculate future value of lump sum investment
    let lumpSumFV = lumpSum * Math.pow(1 + rate / monthsInYear, monthsInYear * years)

    let totalSIPFV = 0 // Future value of SIPs
    let totalInvested = 0 // Track total invested amount
    let totalInflationAdjustedFV = 0 // Track inflation-adjusted SIP future value
    let currentSIP = sip // Start with initial SIP amount

    // Loop through each year
    for (let year = 1; year <= years; year++) {
      for (let month = 1; month <= monthsInYear; month++) {
        let monthsRemaining = (years - year) * monthsInYear + (monthsInYear - month + 1)
        let sipFutureValue = currentSIP * Math.pow(1 + rate / monthsInYear, monthsRemaining)
        totalSIPFV += sipFutureValue
        totalInvested += currentSIP // Accumulate total investment

        // Adjust this SIP's future value for inflation based on how long ago it was invested
        let inflationAdjustedSIPFV =
          sipFutureValue / Math.pow(1 + inflationRate, years - (year - 1) - month / monthsInYear)
        totalInflationAdjustedFV += inflationAdjustedSIPFV
      }
      // Increase SIP by step-up percentage annually
      currentSIP *= 1 + stepUpFactor
    }

    // Total future value
    let totalFV = lumpSumFV + totalSIPFV

    const finalResult = {
      totalInvested: Math.round(totalInvested / 100) * 100, // Total amount invested
      finalValue: Math.round(totalFV / 100) * 100, // Future value without inflation
      realValue: Math.round(totalInflationAdjustedFV / 100) * 100, // Corrected future value adjusted for inflation
    }
    console.log('🚀 ~ calculateSavings ~ finalResult:', finalResult)

    return finalResult
  }

  useEffect(() => {
    const res = calculateSavings(
      selectedYear,
      sliderValue[0],
      oneTimeInvestment,
      selectedPercentage,
      DEFAULT_INFLATION,
      RETURN_RATE,
    )
    setResult(res)
  }, [sliderValue, oneTimeInvestment, selectedPercentage, selectedYear])

  const savePlan = async () => {
    const basketData = baskets.contents.data
    const sipDuration = selectedYear
    const investmentType = 'GENERALSAVING'
    const lumpsumAmount = oneTimeInvestment ? Number(oneTimeInvestment) : 0
    const customSIPAmount = Number(sliderValue)

    const userBasket = filterBasket(
      basketData,
      sipDuration,
      investmentType,
      lumpsumAmount,
      customSIPAmount,
    )

    const educationConstituentsWeightage = {
      earg: 20, // Nippon Large Cap Fund
      'pp001rg-gr': 25, // Parag Parikh Flexi Cap Fund
      dfg: 25, // ICICI Prudential Value Discovery Fund
      'edutrg-gr': 10, // Edelweiss US Tech Fund
      'edmarg-gr': 20, // Edelweiss Multi Asset Allocation Fund
    }

    const retirementConstituentsWeightageSIP = {
      dynmic: 30, // ICICI Pru multi asset
      'edutrg-gr': 20, // Edelweiss US Tech Fund
      'rmfscpgp-gr': 10, // Nippon Small Cap
      'hdfcmcog-gr': 10, // HDFC Mid-Cap Opportunities Reg
      'edmarg-gr': 10, // Edelweiss Multi Asset Allocation Fund
      'qumogp-gr': 20, // Quant Momentum Fund
    }

    const retirementConstituentsWeightageOneTime = {
      dynmic: 35, // ICICI Pru multi asset
      'edutrg-gr': 20, // Edelweiss US Tech Fund
      'rmfscpgp-gr': 0, // Nippon Small Cap
      'hdfcmcog-gr': 10, // HDFC Mid-Cap Opportunities Reg
      'edmarg-gr': 10, // Edelweiss Multi Asset Allocation Fund
      'qumogp-gr': 25, // Quant Momentum Fund
    }

    const oneToThreeYrConstituentsWeightage = {
      'km35-gr': 50, // Kotak Equity Savings
      'pp001rg-gr': 50, // Parag Parikh Flexi Cap Fund
    }

    const oneYrConstituentsWeightage = {
      'edmarg-gr': 50, // Edelweiss Multi Asset Allocation Fund
      'icici1477-gr': 50, // ICICI Pru Corporate Debt Fund
    }

    const anytimeConstituentsWeightage = {
      'rmfgdgp-gr': 30, // Nippon Gold Fund
      'km35-gr': 70, // Kotak Equity Savings
    }

    const basketName = userBasket[0]?.universalBasketName?.toLowerCase()

    const filteredConstituents =
      baskets?.state === 'hasValue'
        ? baskets?.contents?.data
            ?.filter((fund) => fund?.universalBasketId === userBasket[0].universalBasketId)
            .map((fund) => ({
              ...fund,

              sipWeightage:
                sliderValue >= 5000 && basketName.includes('retirement')
                  ? retirementConstituentsWeightageSIP[`${fund.bseSchemeCode.toLowerCase()}`]
                  : null,
              weightage: basketName.includes('retirement')
                ? retirementConstituentsWeightageOneTime[`${fund.bseSchemeCode.toLowerCase()}`]
                : basketName.includes('education')
                ? educationConstituentsWeightage[`${fund.bseSchemeCode.toLowerCase()}`]
                : basketName.includes('1-3')
                ? oneToThreeYrConstituentsWeightage[`${fund.bseSchemeCode.toLowerCase()}`]
                : basketName.includes('1')
                ? oneYrConstituentsWeightage[`${fund.bseSchemeCode.toLowerCase()}`]
                : anytimeConstituentsWeightage[`${fund.bseSchemeCode.toLowerCase()}`],
            }))
        : []

    const calculateParcentageAmount = (parcentage, totalAmount) => {
      const result = totalAmount * (parcentage / 100)
      return Math.ceil(result)
    }

    const investmentBreakdownOneTime =
      oneTimeInvestment > 0
        ? filteredConstituents
            ?.map((fund) => ({
              ...fund,
              investmentAmount: calculateParcentageAmount(fund?.weightage, oneTimeInvestment),
            }))
            .filter((fund) => {
              return basketName.includes('retirement')
                ? fund.bseSchemeCode.toLowerCase() !== 'rmfscpgp-gr'
                : true
            })
        : []

    const investmentBreakdownSIP =
      sliderValue > 0
        ? filteredConstituents?.map((fund) => ({
            ...fund,
            investmentAmount: calculateParcentageAmount(fund?.weightage, sliderValue),
          }))
        : []

    const basketPayload = {
      universalBasketId: userBasket[0].universalBasketId,
      versionNumber: userBasket[0].versionNumber,
      clientCode: clientCode?.toUpperCase(),
      customerBasketName: planName,
      investmentAmount: +oneTimeInvestment,
      oneTimeInvestmentAmount: +oneTimeInvestment,
      oneTimeInvestmentBreakdown: investmentBreakdownOneTime,
      sipInvestmentAmount: +sliderValue,
      sipInvestmentBreakdown: investmentBreakdownSIP,
      category: 'GENERALSAVING',
    }

    if (plan?.customerMfBasketInvestmentId) {
      basketPayload.customerBasketInvestmentId = plan?.customerMfBasketInvestmentId
    }

    const createUserBasket = await post(`/mutualfundservice/api/basket/create`, basketPayload)

    if (createUserBasket.isError) {
      showError(
        'Plan Creation Failed',
        'We encountered an issue while creating your plan. Please try again later.',
        () => {},
      )
      return
    }

    const currentYear = new Date().getFullYear()
    const targetYear = Number(currentYear) + parseInt(selectedYear)

    const request = {
      planName: planName,
      nameOfthePurchase: planName,
      whenToPurchase: +selectedYear,
      currentCostPerYear: currentCost,
      inflation: DEFAULT_INFLATION,
      lifeStyleInflation: 0,
      sipGrowth: +selectedPercentage,
      lumpsum: +oneTimeInvestment,
      currentInvestment: currentInvestments,
      actualSIP: parseInt(sliderValue) ? parseInt(sliderValue) : 0,
      customerMfBasketInvestmentId: createUserBasket?.data?.customerBasketInvestmentId,
    }

    const payload = {
      request,
      results: {
        planName,
        monthlySIP: sliderValue,
        targetYear: targetYear,
        table: {
          1: {
            plannedContribution: result.finalValue,
            projectedExpenses: 0,
            amountToBePlanned: result.finalValue,
            fvOfCurrentValue: result.finalValue,
            corpus: 0,
            sip: sliderValue,
            fvOfLumpsum: 0,
            balanceAmountToBePlanned: 0,
          },
        },
      },
    }

    const planId = plan && plan.id
    let response
    if (planId) {
      response = await patch('/planservice/api/plan/' + planId + '/v2', payload)
    } else {
      response = await post('/planservice/api/plan/v2', payload, {
        'plan-type': 'GENERALSAVING',
      })
    }
    if (response.isError) {
      return
    }

    if (!isDematUser()) {
      setMessageModalState({
        message: 'Your KYC is incomplete.',
        linkMessage:
          'Click here to complete your KYC and to enable hassle free, one-click investing.',
        severity: 'info',
      })
      return
    }
    navigate('/dashboard/place-order', {
      state: {
        basket: createUserBasket,
        constituents: filteredConstituents,
        plan: response,
        sipAmount: +sliderValue,
        lumpsum: oneTimeInvestment ? oneTimeInvestment : 0,
        sipOrOneTime: sliderValue === 0 ? 'One-Time' : 'SIP',
      },
    })
  }

  const filterBasket = (baskets, sipDuration, name, oneTime, sip) => {
    let results = []

    results = baskets.filter((basket) => {
      const basketName = basket?.universalBasketName?.toLowerCase() || ''

      if (sipDuration > 6 && oneTimeInvestment >= 50000 && sliderValue >= 5000) {
        return basketName.includes('retirement')
      }

      if (sipDuration > 6 && oneTimeInvestment >= 50000 && sliderValue == 0) {
        return basketName.includes('retirement')
      }

      if (
        sipDuration > 6 &&
        (!oneTimeInvestment || oneTimeInvestment == 0) &&
        sliderValue >= 5000
      ) {
        return basketName.includes('retirement')
      }

      if (sipDuration > 3 && sipDuration <= 6 && oneTimeInvestment >= 5000 && sliderValue >= 5000) {
        return basketName.includes('education')
      }

      if (
        sipDuration > 3 &&
        sipDuration <= 6 &&
        (!oneTimeInvestment || oneTimeInvestment == 0) &&
        sliderValue >= 5000
      ) {
        return basketName.includes('education')
      }

      if (
        sipDuration > 3 &&
        sipDuration <= 6 &&
        oneTimeInvestment >= 5000 &&
        (!sliderValue || sliderValue == 0)
      ) {
        return basketName.includes('education')
      }

      if (sipDuration > 1 && sipDuration <= 3 && oneTimeInvestment >= 5000 && sliderValue >= 5000) {
        return basketName.includes('1-3')
      }

      if (
        sipDuration > 1 &&
        sipDuration <= 3 &&
        (!oneTimeInvestment || oneTimeInvestment == 0) &&
        sliderValue >= 5000
      ) {
        return basketName.includes('1-3')
      }

      if (
        sipDuration > 1 &&
        sipDuration <= 3 &&
        oneTimeInvestment >= 5000 &&
        (!sliderValue || sliderValue == 0)
      ) {
        return basketName.includes('1-3')
      }

      if (sipDuration <= 1 && oneTimeInvestment >= 200 && sliderValue >= 200) {
        return basketName.includes('1')
      }

      if (sipDuration <= 1 && oneTimeInvestment >= 200 && (!sliderValue || sliderValue == 0)) {
        return basketName.includes('1')
      }

      if (
        sipDuration <= 1 &&
        (!oneTimeInvestment || oneTimeInvestment == 0) &&
        sliderValue >= 200
      ) {
        return basketName.includes('1')
      }

      return basketName.includes('anytime')
    })

    return results
  }

  const MIN_ANYTIME_AMOUNT = 350
  const MIN_1Y_AMOUNT = 200

  const isAllInputDisabled =
    plan?.status === 'INVESTED' || plan?.status === 'PARTIALLY_INVESTED' ? true : false

  const isContinueDisable =
    (oneTimeInvestment > 0 && oneTimeInvestment < MIN_ANYTIME_AMOUNT && selectedYear > 1) ||
    (oneTimeInvestment > 0 && oneTimeInvestment < MIN_1Y_AMOUNT && selectedYear <= 1) ||
    (sliderValue > 0 && sliderValue < MIN_ANYTIME_AMOUNT && selectedYear > 1) ||
    (sliderValue > 0 && sliderValue < MIN_1Y_AMOUNT && selectedYear <= 1)

  return (
    <div className='min-h-screen lg:min-h-max'>
      <BackButton title='Plan for your general savings' />

      {isDesktop ? (
        <Card className='mt-6 w-full max-w-4xl mx-auto lg:rounded-xl shadow-none font-DMSans'>
          <CardContent className='p-6'>
            <div className='flex items-center justify-center mb-6'>
              <div className='flex justify-center items-center gap-2 w-full'>
                <img src={GENERALSAVING} className='rounded-full h-14 w-14' />

                {isEditingPlanName ? (
                  <div className='flex items-center gap-2'>
                    <Input
                      disabled={isAllInputDisabled}
                      value={tempPlanName}
                      onChange={(e) => setTempPlanName(e.target.value)}
                      className='text-lg h-8 lg:w-80'
                      autoFocus
                    />
                    <Button
                      disabled={isAllInputDisabled}
                      size='sm'
                      variant='ghost'
                      className='h-6 w-6 p-0'
                      onClick={handleSavePlanName}
                    >
                      <Check className='h-4 w-4' />
                    </Button>
                  </div>
                ) : (
                  <div className='text-xl font-medium flex items-center gap-2'>
                    {planName}
                    <Button
                      disabled={isAllInputDisabled}
                      variant='ghost'
                      size='sm'
                      className='h-6 w-6 p-0'
                      onClick={handleEditClick}
                    >
                      <Edit className='w-4 h-4 text-primary-600' />
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <div className='text-center mb-1'>
              <div className='text-3xl font-semibold text-black-500'>
                ₹ {toCurrency(result.finalValue)}
              </div>
              <div className='text-sm text-gray-500'>Your total investment</div>
            </div>

            <div className='flex justify-center mb-6'>
              <Popover open={isYearPopoverOpen} onOpenChange={setIsYearPopoverOpen}>
                <PopoverTrigger asChild>
                  <Button
                    variant='outline'
                    size='sm'
                    className='rounded-full text-sm px-4 py-1 h-8 bg-gray-100 hover:bg-gray-200 border-0'
                    disabled={isAllInputDisabled}
                  >
                    {selectedYear} y <ChevronDown className='ml-1 h-4 w-4' />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className='w-[250px] p-0' align='center'>
                  {yearOptions.map((option, index) => (
                    <div
                      key={index}
                      className='flex justify-between items-center p-3 hover:bg-gray-100 cursor-pointer'
                      onClick={() => {
                        setSelectedYear(+parseInt(option.label))
                        setIsYearPopoverOpen(false)
                      }}
                    >
                      <span>{option.label}</span>
                      <span>{toCurrency(option.value)}</span>
                    </div>
                  ))}
                </PopoverContent>
              </Popover>
            </div>

            <div className='space-y-6'>
              <div className='space-y-2'>
                <div className='text-sm'>Amount to invest monthly</div>
                <div className='flex justify-between items-center'>
                  <Slider
                    disabled={isAllInputDisabled}
                    value={sliderValue}
                    onValueChange={handleSliderChange}
                    min={0}
                    max={1000000}
                    step={1000}
                    className={cn('w-[60%]')}
                  />

                  <Input
                    // type='number'
                    disabled={isAllInputDisabled}
                    startAdornment='₹'
                    value={sliderValue}
                    onChange={(e) => handleSliderChange([Number(e.target.value)])}
                    className='pl-8'
                  />
                </div>
              </div>

              <div className='space-y-2 flex justify-between items-center'>
                <div className='text-sm'>One time investment</div>
                <div className='relative'>
                  <div className='absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500'>
                    ₹
                  </div>
                  <Input
                    disabled={isAllInputDisabled}
                    type='number'
                    startAdornment='₹'
                    value={oneTimeInvestment}
                    onChange={(e) => setOneTimeInvestment(e.target.value)}
                    className='pl-8'
                  />
                </div>
              </div>

              {/* <div className='space-y-2 flex justify-between items-center'>
                <div className='text-sm'>Increase amount by (annually)</div>
                <div className='flex gap-2'>
                  {percentageOptions.map((percentage) => (
                    <Button
                      disabled={isAllInputDisabled}
                      key={percentage}
                      variant={selectedPercentage === percentage ? 'default' : 'outline'}
                      size='sm'
                      className={cn(
                        'rounded-full flex-1',
                        selectedPercentage === percentage
                          ? 'bg-[#3b4a7a] hover:bg-[#2d3a61]'
                          : 'bg-gray-100 hover:bg-gray-200 border-0',
                      )}
                      onClick={() => setSelectedPercentage(percentage)}
                    >
                      {percentage} %
                    </Button>
                  ))}
                </div>
              </div> */}

              <Button
                onClick={savePlan}
                disabled={isAllInputDisabled || isContinueDisable}
                className='w-full bg-primary-600 hover:bg-primary-700 disabled:bg-primary-400 cursor-pointer'
              >
                Continue
              </Button>
            </div>
          </CardContent>
        </Card>
      ) : (
        <Card className='border-none w-full max-w-4xl mx-auto lg:rounded-xl shadow-none font-DMSans'>
          <CardContent className='p-4 space-y-6'>
            <div className='flex flex-col justify-center items-center gap-2 w-full py-6'>
              <img src={GENERALSAVING} className='rounded-full h-14 w-14' />

              {isEditingPlanName ? (
                <div className='flex items-center gap-2'>
                  <Input
                    disabled={isAllInputDisabled}
                    value={tempPlanName}
                    onChange={(e) => setTempPlanName(e.target.value)}
                    className='text-lg h-8 lg:w-80'
                    autoFocus
                  />
                  <Button
                    disabled={isAllInputDisabled}
                    size='sm'
                    variant='ghost'
                    className='h-6 w-6 p-0'
                    onClick={handleSavePlanName}
                  >
                    <Check className='h-4 w-4' />
                  </Button>
                </div>
              ) : (
                <div className='text-xl font-medium flex items-center gap-2'>
                  {planName}
                  <Button
                    disabled={isAllInputDisabled}
                    variant='ghost'
                    size='sm'
                    className='h-6 w-6 p-0'
                    onClick={handleEditClick}
                  >
                    <Edit className='w-4 h-4 text-primary-600' />
                  </Button>
                </div>
              )}
            </div>

            <div className='text-center mt-6 mb-4'>
              <div className='text-2xl font-semibold text-black-500'>
                ₹ {toCurrency(result.finalValue)}
              </div>
              <div className='text-sm text-black-400 font-medium'>Your total investment</div>
            </div>

            <div className='flex justify-center mb-6'>
              <Popover open={isYearPopoverOpen} onOpenChange={setIsYearPopoverOpen}>
                <PopoverTrigger asChild>
                  <Button
                    variant='outline'
                    size='sm'
                    className='rounded-full text-sm px-3 py-[2px] h-6 text-primary-500 bg-gray-100 hover:bg-gray-200 border-0'
                    disabled={isAllInputDisabled}
                  >
                    {selectedYear} y <ChevronDown className='ml-1 h-4 w-4' />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className='w-[250px] p-0' align='center'>
                  {yearOptions.map((option, index) => (
                    <div
                      key={index}
                      className='flex justify-between items-center p-3 hover:bg-gray-100 cursor-pointer'
                      onClick={() => {
                        setSelectedYear(+parseInt(option.label))
                        setIsYearPopoverOpen(false)
                      }}
                    >
                      <span>{option.label}</span>
                      <span>{toCurrency(option.value)}</span>
                    </div>
                  ))}
                </PopoverContent>
              </Popover>
            </div>

            <div className='flex flex-col gap-y-4 justify-between items-center'>
              <div className='flex justify-between w-full'>
                <div className='text-sm text-black-400 text-nowrap font-medium'>
                  Amount to invest monthly
                </div>
                <Input
                  type='number'
                  disabled={isAllInputDisabled}
                  startAdornment='₹'
                  value={sliderValue}
                  onChange={(e) => handleSliderChange([Number(e.target.value)])}
                  className='pl-8 h-7 max-w-28'
                />
              </div>
              <Slider
                disabled={isAllInputDisabled}
                value={sliderValue}
                onValueChange={handleSliderChange}
                min={0}
                max={1000000}
                step={1000}
                className={cn('w-full')}
              />
            </div>

            <div className='space-y-2 flex justify-between items-center'>
              <div className='text-sm text-black-400 font-medium'>One time investment</div>
              <div className='relative'>
                <div className='absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500'>
                  ₹
                </div>
                <Input
                  disabled={isAllInputDisabled}
                  type='number'
                  startAdornment='₹'
                  value={oneTimeInvestment}
                  onChange={(e) => setOneTimeInvestment(e.target.value)}
                  className='pl-8 h-7 max-w-28'
                />
              </div>
            </div>

            {/* <div className='flex flex-col justify-between items-start gap-y-3'>
              <div className='text-sm text-black-400 font-medium'>
                Increase amount by (annually)
              </div>
              <div className='flex gap-2'>
                {percentageOptions.map((percentage) => (
                  <Button
                    disabled={isAllInputDisabled}
                    key={percentage}
                    variant={selectedPercentage === percentage ? 'default' : 'outline'}
                    size='sm'
                    className={cn(
                      'rounded-full h-6 text-sm leading-5 py-[2px] px-3 flex-1',
                      selectedPercentage === percentage
                        ? 'bg-[#3b4a7a] hover:bg-[#2d3a61]'
                        : 'bg-gray-100 hover:bg-gray-200 border-0',
                    )}
                    onClick={() => setSelectedPercentage(percentage)}
                  >
                    {percentage} %
                  </Button>
                ))}
              </div>
            </div> */}

            <Button
              onClick={savePlan}
              disabled={isAllInputDisabled || isContinueDisable}
              className='w-full bg-primary-600 hover:bg-primary-700 disabled:bg-primary-400 cursor-pointer'
            >
              Continue
            </Button>
          </CardContent>
        </Card>
      )}
    </div>
  )
}

export default GeneralSavingNew
