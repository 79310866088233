/* eslint-disable */

const SPECIAL = [
  'zeroth',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelfth',
  'thirteenth',
  'fourteenth',
  'fifteenth',
  'sixteenth',
  'seventeenth',
  'eighteenth',
  'nineteenth',
]
const DECA = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet']

export const stringifyNumber = (n) => {
  if (n < 20) return SPECIAL[n]
  if (n % 10 === 0) return DECA[Math.floor(n / 10) - 2] + 'ieth'
  return DECA[Math.floor(n / 10) - 2] + 'y-' + SPECIAL[n % 10]
}

export const formatDateToStr = (d) => {
  const date = d.getDate()
  const month = d.getMonth() + 1
  const year = d.getFullYear()
  let finalDate = `${date < 10 ? '0' + date : date}/${month < 10 ? '0' + month : month}/${year}`
  return finalDate
}

export const toCamelCase = (s) => {
  return s.substring(0, 1).toUpperCase() + s.substring(1, s.length).toLowerCase()
}

export const convertDateFormat = (date) => {
  let currentDate = new Date(date?.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'))
  let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(currentDate)
  let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(currentDate)
  let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(currentDate)
  return `${day} ${month} ${year}`
}

export const convertOrderDateFormat = (data) => {
  let currentDate = new Date(data)
  return currentDate.toLocaleString('en-GB', { hour12: false })?.replace(',', '')
}

export const isWeekDay = (date) => {
  return new Date(date).getDay() % 6 != 0
}

export const isWorkingHours = (date) => {
  const startTime = 9 * 60 + 30 // 9:30AM
  const endTime = 15 * 60 + 30 // 3:30PM
  const currentTime = new Date(date)
  const time = currentTime.getHours() * 60 + currentTime.getMinutes()
  return time >= startTime && time < endTime
}

export const getReopenTiming = (marketStatus) => {
  // Check if market status is 'MAINTENANCE_WINDOW'
  if (marketStatus === 'MAINTENANCE_WINDOW') {
    // Get the current IST time
    const now = new Date()

    const istOffset = 5.5 * 60 * 60 * 1000 // IST is UTC+5:30

    const istTime = new Date(now.getTime() + istOffset)
    const hours = istTime.getUTCHours() // IST hours
    const minutes = istTime.getUTCMinutes() // IST minutes

    // Check if the time is between 3:30 and 4:40 IST
    if ((hours === 15 && minutes >= 30) || (hours === 16 && minutes <= 40)) {
      return '4:40 PM'
    }
    // Check if the time is between 8:30 and 8:50 IST
    else if (hours === 8 && minutes >= 30 && minutes <= 50) {
      return '8:50'
    }
  }

  // If the conditions are not met, return a default value (or null)
  return null
}

export const addNumberSuffix = (number) => {
  number = +number
  if (typeof number !== 'number' || isNaN(number)) {
    return number
  }

  number = number.toString().startsWith('0') ? number.toString().slice(1) : number.toString()

  const remainder = number % 100

  if (remainder >= 11 && remainder <= 13) {
    return `${number}th`
  }

  switch (number % 10) {
    case 1:
      return `${number}st`
    case 2:
      return `${number}nd`
    case 3:
      return `${number}rd`
    default:
      return `${number}th`
  }
}

export const addMonth = (today, selectedDay) => {
  const currentDate = new Date()
  let day = selectedDay
  const userDate = new Date()

  // if (selectedDay < today) {
  //   const daygap = today - selectedDay
  //   userDate.setDate(userDate.getDate() - daygap)
  //   userDate.setMonth(userDate.getMonth() + 1) // Add one month
  // }

  // if (selectedDay >= today) {
  //   const daygap = selectedDay - today
  //   userDate.setDate(userDate.getDate() + daygap)
  // userDate.setMonth(userDate.getMonth() + 1) // Add one month
  // }

  // if (selectedDay <= today) {
  userDate.setMonth(userDate.getMonth() + 1)
  // }

  // // Calculate the difference in milliseconds
  // const differenceInMs = currentDate - userDate

  // // Convert milliseconds to days
  // const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24)

  // if (Math.abs(differenceInDays) < 34) {
  //   userDate.setMonth(userDate.getMonth() + 1)
  // }

  day = selectedDay
  const month = userDate.getMonth() + 1 // Months are zero-based
  const year = userDate.getFullYear()

  // Format day and month with leading zeros if necessary
  const formattedDay = day < 10 ? `0${day}` : day
  const formattedMonth = month < 10 ? `0${month}` : month

  const res = `${formattedDay}/${formattedMonth}/${year}`

  return res
}

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const monthShortNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const areDatesDifferent = (date1, date2) => {
  // console.log('🚀 ~ areDatesDifferent ~ date1, date2:', date1, date2)
  // Convert both dates to ISO format and slice the date part (YYYY-MM-DD)
  const formattedDate1 = new Date(date1).toISOString().slice(0, 10)
  const formattedDate2 = new Date(date2).toISOString().slice(0, 10)

  // Check if the dates are different
  return formattedDate1 === formattedDate2
}

export function calculateYearsPassed(startDate) {
  // Parse the input date
  const start = new Date(startDate)

  // Check if the date is valid
  if (isNaN(start)) {
    return "Invalid date format. Please use 'YYYY-MM-DD'."
  }

  // Get the current date
  const today = new Date()

  // Calculate the time difference in milliseconds
  const timeDifference = today - start

  // Convert the time difference to years (365.25 accounts for leap years)
  const yearsPassed = timeDifference / (365.25 * 24 * 60 * 60 * 1000)

  return yearsPassed.toFixed(1) // Return the result rounded to 1 decimal place
}

export function sanitizeName(option) {
  // Regex pattern to match 'Reg' or '-Reg' case-insensitively
  const regPattern = /\s*-?Reg\b/gi

  // Check and sanitize legalName or sName
  if (option?.legalName) {
    return option.legalName?.replace(regPattern, ' ').trim()
  } else if (option?.sName) {
    return option.sName.replace(regPattern, ' ').trim()
  } else if (option?.sname) {
    return option.sname.replace(regPattern, ' ').trim()
  } else if (option?.schemeName) {
    return option.schemeName.replace(regPattern, ' ').trim()
  }

  return ''
}

export function capitalizeWords(str) {
  if (typeof str !== 'string') return '' // Ensure input is a string
  return str
    .split(' ') // Split the string into an array of words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
    .join(' ') // Join the array back into a single string
}

export const FinancialGoalsReversed = {
  0: 'CHILDEDUCATION',
  1: 'RETIREMENT',
  2: 'PURCHASE',
  3: 'MARRIAGE',
  4: 'HOLIDAY',
  5: 'EMERGENCY',
  6: 'GENERIC',
  7: 'HOME',
  8: 'ELECTRONICS',
  9: 'PASSION',
  10: 'VEHICLE',
  11: 'JEWELLERY',
  12: 'PROPERTY',
  13: 'POSTRETIREMENTVACATION',
  14: 'GENERALSAVING',
}
