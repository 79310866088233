import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toCurrency } from '../../utils/CurrencyUtil'
import { Checkbox } from '../../Components/ui/checkbox'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { Button } from '../../Components/ui/button'
import env from '../../env/env'
import placeholder from '../../assets/placeholder.svg'
import useMediaQuery from '../../hooks/useMediaQuery'
import { ChevronLeft } from 'lucide-react'
import { MFUserBaskets } from '../../store/MFBasketsStore'
import { useRecoilValueLoadable } from 'recoil'
import { capitalizeWords } from '../../utils/Util'

const UserActionPlan = () => {
  const { state, contents: investedPlans } = useRecoilValueLoadable(MFUserBaskets)
  const [adminPlans, setAdminPlans] = useState([])
  const [openPlans, setOpenPlans] = useState({})
  const [selectedPlans, setSelectedPlans] = useState([])
  const [isAllSelected, setIsAllSelected] = useState(true)
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const navigate = useNavigate()

  useEffect(() => {
    if (state === 'hasValue') {
      const adminCuratedPlans = investedPlans?.data?.filter(
        (plan) =>
          plan.createdSource === 'ACTION PLAN' &&
          (plan?.sipStatus === 'PENDING' || plan?.oneTimeStatus === 'PENDING'),
      )
      setAdminPlans(adminCuratedPlans || [])
      setSelectedPlans(adminCuratedPlans || [])
    }
  }, [state, investedPlans])

  const togglePlan = (id) => {
    setOpenPlans((prev) => ({
      ...prev,
      [id]: !prev[id],
    }))
  }

  const submitHandler = () => {
    if (selectedPlans.length > 0) {
      navigate('/dashboard/firstTime/confirmPayment', {
        state: {
          selectedPlans: selectedPlans,
        },
      })
    }
  }

  const handleCheckboxChange = (plan) => {
    setSelectedPlans((prevSelected) => {
      const isSelected = prevSelected.some(
        (p) => p.customerBasketInvestmentId === plan.customerBasketInvestmentId,
      )

      if (isSelected) {
        return prevSelected.filter(
          (p) => p.customerBasketInvestmentId !== plan.customerBasketInvestmentId,
        )
      } else {
        return [...prevSelected, plan]
      }
    })
  }

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedPlans([])
    } else {
      setSelectedPlans([...adminPlans])
    }
    setIsAllSelected(!isAllSelected)
  }

  useEffect(() => {
    setIsAllSelected(selectedPlans.length === adminPlans.length && adminPlans.length > 0)
  }, [selectedPlans, adminPlans])

  const totalLumpsum = selectedPlans
    .filter((plan) => plan?.oneTimeStatus === 'PENDING')
    .reduce((sum, item) => sum + (item.oneTimeInvestmentAmount || 0), 0)

  const totalSip = selectedPlans
    .filter((plan) => plan?.sipStatus === 'PENDING')
    .reduce((sum, item) => sum + (item.sipInvestmentAmount || 0), 0)

  const backHandler = () => {
    navigate(-1)
  }

  return (
    <div className='md:max-w-[760px] lg:max-w-[1128px] mx-5 md:mx-auto mt-10'>
      <div className='flex gap-x-1 items-center cursor-pointer font-semibold' onClick={backHandler}>
        <ChevronLeft className='h-4' />
        Home
      </div>

      <div>
        <h1 className='flex justify-between items-center mt-6'>
          <span className='font-DMSans font-semibold text-lg md:text-xl text-black-500'>
            Your Plans
          </span>
        </h1>
        <p className='font-DMSans md:text-lg text-sm font-normal text-black-400'>
          Plans have been curated as per your needs
        </p>
      </div>

      <div className='md:flex gap-4 my-6'>
        <div className='p-3 md:p-5 bg-primary-50 rounded-[7px] flex-1'>
          <h1 className='font-DMSans font-medium md:text-base text-[14px] text-[#4C4C4C] leading-[26px]'>
            One time Investment
          </h1>
          <p className='font-normal md:mt-3 text-2xl md:text-3xl text-primary-600 h-[36px] flex items-end leading-none'>
            <span className='font-DMSans md:text-[1.6rem] font-extrabold mr-2 flex items-end'>
              ₹
            </span>
            <span className='font-OrelegaOne md:text-[28px] tracking-[4%]'>
              {toCurrency(totalLumpsum)}
            </span>
          </p>
        </div>
        <div className='p-3 mt-3 md:mt-0 md:p-5 bg-primary-50 rounded-[7px] flex-1'>
          <h1 className='font-DMSans font-medium md:text-base text-[14px] text-[#4C4C4C] leading-[26px]'>
            SIP
          </h1>
          <p className='font-normal md:mt-3 text-2xl md:text-3xl text-primary-600 h-[36px] flex items-end leading-none'>
            <span className='font-DMSans md:text-[1.6rem] font-extrabold mr-2 flex items-end'>
              ₹
            </span>
            <span className='font-OrelegaOne md:text-[28px] tracking-[4%]'>
              {toCurrency(totalSip)}
            </span>
          </p>
          <p className='text-xs'>* Excluding active SIPs</p>
        </div>
      </div>

      <div className='mb-2 flex justify-between'>
        <p>Select a plan to proceed</p>
        <span
          className={`font-DMSans font-medium text-sm ${
            isAllSelected ? 'text-black-300' : 'text-primary-600'
          } cursor-pointer`}
          onClick={handleSelectAll}
        >
          {isAllSelected ? 'Deselect All' : 'Select All'}
        </span>
      </div>

      <div className='md:flex gap-2'>
        <div className='flex-[2] md:grid md:grid-cols-2 lg:grid-cols-2 flex flex-col gap-4 md:gap-4 mb-[170px] overflow-y-scroll hide-scrollbar'>
          {adminPlans?.map((plan) => {
            return (
              <div
                key={plan.customerBasketInvestmentId}
                className={`border border-[#D3DBEC] py-4 px-3 md:p-5 rounded-[6px] ${
                  openPlans[plan.customerBasketInvestmentId] ? 'max-h-[500px]' : 'max-h-[150px]'
                } overflow-hidden transition-all duration-300 ease-in-out`}
              >
                <div className='flex justify-between'>
                  <div className='flex gap-3 items-start'>
                    <Checkbox
                      checked={selectedPlans.some(
                        (p) => p.customerBasketInvestmentId === plan.customerBasketInvestmentId,
                      )}
                      onCheckedChange={() => handleCheckboxChange(plan)}
                    />
                    <img src={placeholder} className='h-6 w-6 md:h-8 md:w-8' alt='plan' />
                    <div className='font-DMSans md:text-lg text-base font-medium'>
                      {plan.customerBasketName}
                    </div>
                  </div>

                  <div>
                    {openPlans[plan.customerBasketInvestmentId] ? (
                      <ExpandLessIcon
                        sx={{ cursor: 'pointer' }}
                        onClick={() => togglePlan(plan.customerBasketInvestmentId)}
                      />
                    ) : (
                      <ExpandMoreIcon
                        sx={{ cursor: 'pointer' }}
                        onClick={() => togglePlan(plan.customerBasketInvestmentId)}
                      />
                    )}
                  </div>
                </div>

                <div
                  className='flex justify-between mt-3'
                  onClick={() => togglePlan(plan.customerBasketInvestmentId)}
                >
                  <div className='flex-1'>
                    <h1 className='font-DMSans font-medium text-xs md:text-base text-black-300'>
                      One time investment
                    </h1>
                    <p className='font-DMSans mt-2 font-semibold text-sm md:text-lg text-[#2d2d2d]'>
                      ₹{plan.oneTimeInvestmentAmount ? toCurrency(plan.oneTimeInvestmentAmount) : 0}
                    </p>
                  </div>

                  <div className='flex-1'>
                    <h1 className='font-DMSans font-medium text-xs md:text-base text-black-300 flex gap-2 items-center'>
                      SIP{' '}
                      <div>
                        <span
                          className={`text-xs ${
                            capitalizeWords(plan?.sipStatus) === 'Active'
                              ? 'text-secondary-500 bg-secondary-50 rounded-full px-2 border border-secondary-500'
                              : ''
                          }`}
                        >
                          {capitalizeWords(plan?.sipStatus) === 'Active' ? 'Active' : ''}
                        </span>
                      </div>
                    </h1>
                    <p className='font-DMSans mt-2 font-semibold text-sm md:text-lg text-[#2d2d2d]'>
                      ₹{plan.sipInvestmentAmount ? toCurrency(plan.sipInvestmentAmount) : 0}
                    </p>
                  </div>
                </div>

                <div onClick={() => togglePlan(plan.customerBasketInvestmentId)}>
                  {openPlans[plan.customerBasketInvestmentId] && (
                    <>
                      {((plan.sipInvestmentAmount > 0 && !plan.oneTimeInvestmentAmount) ||
                        (plan.oneTimeInvestmentAmount > 0 && plan.sipInvestmentAmount > 0)) && (
                        <div className='my-3 md:mt-5 border-t border-[#D3DBEC]'>
                          {plan?.sipInvestmentBreakdown?.length > 0 &&
                            plan?.sipInvestmentBreakdown?.map((breakup, index) => {
                              const percentage =
                                (breakup.investmentAmount / plan.sipInvestmentAmount) * 100
                              return (
                                <div
                                  key={index}
                                  className='flex mt-3 justify-between items-center gap-10'
                                >
                                  <div className='flex gap-2 items-center'>
                                    <img
                                      src={`${env.MF_LOGOS_URL}/${breakup.amcCode}.png`}
                                      alt={`${breakup.name} icon`}
                                      className='rounded-[8px] h-6 w-6 md:h-8 md:w-8'
                                    />
                                    <p className='font-DMSans text-sm font-medium text-black-500'>
                                      {isDesktop
                                        ? breakup?.schemeName
                                        : `${breakup?.schemeName.slice(0, 20)}...`}
                                    </p>
                                  </div>
                                  <p className='font-DMSans text-sm font-medium text-black-500'>
                                    {percentage}%
                                  </p>
                                </div>
                              )
                            })}
                        </div>
                      )}

                      {plan.oneTimeInvestmentAmount > 0 && plan.sipInvestmentAmount === 0 && (
                        <div className='my-3 md:mt-5 border-t border-[#D3DBEC]'>
                          {plan?.oneTimeInvestmentBreakdown.length > 0 &&
                            plan?.oneTimeInvestmentBreakdown?.map((breakup, index) => {
                              const percentage =
                                (breakup.investmentAmount / plan.oneTimeInvestmentAmount) * 100
                              return (
                                <div
                                  key={index}
                                  className='flex mt-3 justify-between items-center gap-10'
                                >
                                  <div className='flex gap-2 items-center'>
                                    <img
                                      src={`${env.MF_LOGOS_URL}/${breakup.amcCode}.png`}
                                      alt={`${breakup.name} icon`}
                                      className='rounded-[8px] h-6 w-6 md:h-8 md:w-8'
                                    />
                                    <p className='font-DMSans text-sm font-medium text-black-500'>
                                      {isDesktop
                                        ? breakup?.schemeName
                                        : `${breakup?.schemeName.slice(0, 20)}...`}
                                    </p>
                                  </div>
                                  <p className='font-DMSans text-sm font-medium text-black-500'>
                                    {percentage}%
                                  </p>
                                </div>
                              )
                            })}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div
        className={`fixed bottom-0 left-0 right-0 pb-8 px-5 bg-white mt-2 lg:max-w-[1128px] mx-auto`}
        style={{ boxShadow: '0px 4px 21.6px 0px #00000040', borderRadius: '24px 24px 0px 0px' }}
      >
        <div className='mx-auto'>
          <p className='pt-4'>
            Reach out to us for any queries on{' '}
            <a className='text-primary-500' href='tel:+918217801975'>
              +91-9035189836
            </a>
          </p>
          <Button
            disabled={selectedPlans?.length > 0 ? false : true}
            className={`w-full mt-4 ${
              selectedPlans?.length === 0 ? 'bg-primary-300' : 'bg-primary-500'
            }`}
            onClick={submitHandler}
          >
            Proceed
          </Button>
        </div>
      </div>
    </div>
  )
}

export default UserActionPlan
