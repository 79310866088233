/* eslint-disable */

import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from '../Components/Footer'
import BottomNavbar from '../Components/BottomNavbar'
import Header from '../Components/Header'
import Navbar from '../Components/Navbar'
import { getUserDetails } from '../utils/AuthUtil'
import { useRecoilState } from 'recoil'
import { userDetailsState } from './LoginState'

const PostLoginContainer = () => {
  const location = useLocation()
  const isMFPage = location.pathname.includes('mutual-funds')

  const hideBottonNav =
    location.pathname.includes('tax-filing') ||
    location.pathname.includes('firstTime') ||
    location.pathname.includes('place-order') ||
    location.pathname.includes('order-payment') ||
    location.pathname.includes('payment-request') ||
    location.pathname.includes('one-time-payment') ||
    location.pathname.includes('actionplan/mandate') ||
    location.pathname.includes('/dashboard/holdings')

  const [userDetails, setUserDetails] = useRecoilState(userDetailsState)

  const hideTopNav =
    location.pathname.includes('mutual-funds') || location.pathname.includes('tax-filing')

  useEffect(() => {
    setUserDetails(getUserDetails())
  }, [])

  return (
    <>
      {hideTopNav ? null : <Header />}

      <Navbar />

      <div className='flex justify-center'>
        <div
          className={`w-full ${!location.pathname.includes('/tax-filing') && 'lg:mt-[52px]'} ${
            isMFPage ||
            location.pathname.includes('/tax-filing') ||
            location.pathname.includes('firstTime')
              ? 'max-w-auto mb-0'
              : 'max-w-[1128px] mb-16 lg:pb-12 lg:pt-4'
          }`}
        >
          <Outlet />
          {hideBottonNav ? null : <BottomNavbar />}
          <Footer />
        </div>
      </div>
    </>
  )
}

export default PostLoginContainer
