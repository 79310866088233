import { atom, selector } from 'recoil'
import { get } from '../http/restservice'

const MFUserBasketsAtom = atom({
  key: 'MFUserBasketsAtom',
  default: [],
})

const MFTimeBasedBaskets = selector({
  key: 'MFTimeBasedBaskets',
  get: async () => {
    const response = await get(`/mutualfundservice/api/basket`)

    if (response.isError) {
      return []
    }

    return response
  },
})

const MFBasketOrders = selector({
  key: 'MFBasketOrders',
  get: async () => {
    const response = await get(`/mutualfundservice/api/basket/orderHistory`)

    if (response.isError) {
      return []
    }

    return response
  },
})

const MFBasketHoldings = selector({
  key: 'MFBasketHoldings',
  get: async () => {
    const response = await get(`/mutualfundservice/api/basket/holdings`)

    if (response.isError) {
      return []
    }

    return response
  },
})

const MFUserBaskets = selector({
  key: 'MFUserBaskets',
  get: async () => {
    const response = await get(`/mutualfundservice/api/basket/getAllUserCreatedBasket`)

    if (response.isError) {
      return []
    }

    return response
  },
})

const MFUserBasketsSelector = selector({
  key: 'MFUserBasketsSelector',
  get: async () => {
    const response = await get(`/mutualfundservice/api/basket/getAllUserCreatedBasket`)
    return response.isError ? [] : response
  },
})

export {
  MFTimeBasedBaskets,
  MFBasketOrders,
  MFBasketHoldings,
  MFUserBaskets,
  MFUserBasketsAtom,
  MFUserBasketsSelector,
}
