import * as React from 'react'
import * as SliderPrimitive from '@radix-ui/react-slider'

import { cn } from '../../lib/utils'

const Slider = React.forwardRef(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn('relative flex w-full touch-none select-none items-center', className)}
    {...props}
  >
    <SliderPrimitive.Track className='relative h-3 w-full grow overflow-hidden rounded-full bg-black-50'>
      <SliderPrimitive.Range className='absolute h-full bg-primary' />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb
      className='flex justify-center items-center h-8 w-4 rounded-full bg-background ring-offset-background transition-colors focus-visible:outline-none cursor-pointer disabled:pointer-events-none disabled:opacity-50 text-primary-600'
      style={{ boxShadow: '0px 2px 6px rgba(65, 85, 141, 0.24)' }}
    >
      <svg width='6' height='12' viewBox='0 0 6 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M5.5 11.2439C5.5 11.4563 5.42855 11.6354 5.28565 11.7812C5.14273 11.9271 4.96565 12 4.7544 12C4.54313 12 4.36458 11.9282 4.21875 11.7845C4.07292 11.6408 4 11.4628 4 11.2504L4 0.756083C4 0.543694 4.07145 0.364583 4.21435 0.21875C4.35727 0.072917 4.53435 0 4.7456 0C4.95687 0 5.13542 0.0718336 5.28125 0.2155C5.42708 0.35918 5.5 0.537215 5.5 0.749604L5.5 11.2439ZM2 11.2439C2 11.4563 1.92855 11.6354 1.78565 11.7812C1.64273 11.9271 1.46565 12 1.2544 12C1.04313 12 0.864583 11.9282 0.71875 11.7845C0.572917 11.6408 0.5 11.4628 0.5 11.2504L0.5 0.756083C0.5 0.543694 0.571451 0.364583 0.714354 0.21875C0.857271 0.072917 1.03435 0 1.2456 0C1.45687 0 1.63542 0.0718336 1.78125 0.2155C1.92708 0.35918 2 0.537215 2 0.749604L2 11.2439Z'
          fill='#808DB3'
        />
      </svg>
    </SliderPrimitive.Thumb>
  </SliderPrimitive.Root>
))
Slider.displayName = SliderPrimitive.Root.displayName

export { Slider }
