/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import '../stylesheet.scss'
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil'
import { useThemeMode } from '../Provider/ThemeContextProvider'
import { userDetailsState } from '../Pages/LoginState'
import { getUserDetails, isDematUser, removeToken } from '../utils/AuthUtil'
import env from '../env/env'
import { NotificationsSelector } from '../store/AccountDetailsStore'
import { patch } from '../http/restservice'
import { navTitles } from '../constants/navbar'
import { Sheet, SheetClose, SheetContent, SheetTrigger } from './ui/sheet'
import { Avatar, AvatarFallback } from './ui/avatar'
import useMediaQuery from '../hooks/useMediaQuery'
import { DrawerContext } from '../Provider/DrawerContext'
import { messageModalState } from '../store/Feedback'
import { NavbarContext } from '../Provider/ShowNavbar'
import { ChevronLeft, NotificationsNoneOutlined, SignalCellularAlt } from '@mui/icons-material'
import { SSEContext } from '../Provider/SSEProvider'
import BlurIn from './ui/blur-in'
import { AnimatePresence } from 'framer-motion'
import XIcon from '@mui/icons-material/X'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import YouTubeIcon from '@mui/icons-material/YouTube'
import InstagramIcon from '@mui/icons-material/Instagram'
import {
  AccountBalanceWalletOutlined,
  CurrencyExchangeOutlined,
  EmojiEventsOutlined,
  LocalMallOutlined,
  PersonOutlineOutlined,
  SupportAgentOutlined,
} from '@mui/icons-material'

const socials = [
  {
    id: 1,
    icon: <YouTubeIcon sx={{ color: 'white', height: '20px', width: '20px' }} />,
    linkTo: 'https://www.youtube.com/@fabits.social',
  },
  {
    id: 2,
    icon: <LinkedInIcon sx={{ color: 'white', height: '20px', width: '20px' }} />,
    linkTo: 'https://www.linkedin.com/company/fabits/',
  },
  {
    id: 3,
    icon: <XIcon sx={{ color: 'white', height: '20px', width: '20px' }} />,
    linkTo: 'https://x.com/fabitssocial',
  },
  {
    id: 4,
    icon: <InstagramIcon sx={{ color: 'white', height: '20px', width: '20px' }} />,
    linkTo: 'https://www.instagram.com/fabits.social',
  },
]

const Header = ({ handleOpenNav }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const location = useLocation()
  const { darkMode, toggleDarkMode } = useThemeMode()
  const [userDetails, setUserDetails] = useRecoilState(userDetailsState)
  const theme = useTheme()
  const { state, contents: notifications } = useRecoilValueLoadable(NotificationsSelector)
  const refreshNotifications = useRecoilRefresher_UNSTABLE(NotificationsSelector)
  const { marketStatus, message } = useContext(SSEContext)
  const [notificationsVar, setNotificationsVar] = useState([])
  const [openMore, setOpenMore] = useState(false)
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const { isDrawerOpen } = useContext(DrawerContext)
  const { setDisplayNavbar } = useContext(NavbarContext)
  const setMessageModalState = useSetRecoilState(messageModalState)
  const [showNavbar, setShowNavbar] = useState(true)
  const [lastScrollY, setLastScrollY] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [openDiscover, setOpenDiscover] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [showMarketStatus, setShowMarketStatus] = useState(true)

  useEffect(() => {
    if (openDiscover || showMenu) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [openDiscover, showMenu])

  useEffect(() => {
    setDisplayNavbar(showNavbar)
  }, [showNavbar])

  const handleScroll = () => {
    const currentScrollY = window.scrollY

    if (currentScrollY > lastScrollY && currentScrollY > 50) {
      setShowNavbar(false)
    } else {
      setShowNavbar(true)
    }

    setLastScrollY(currentScrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [lastScrollY])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleLogout = () => {
    removeToken()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (state === 'hasValue' && notifications?.length) setNotificationsVar(notifications)
  }, [state, notifications])

  // const openHelpWidget = () => {
  //   console.log('open')
  //   const contact_form_token = localStorage.getItem('contact_form_token')
  //   if (!contact_form_token || (contact_form_token && isTokenExpired(contact_form_token))) {
  //     console.log('get contact form token')
  //     getContactFormJwtToken()
  //   } else {
  //     window.authenticate(contact_form_token)
  //   }
  //   window.openContactForm()
  // }

  // const getContactFormJwtToken = async () => {
  //   const payload = {
  //     name: userDetails?.firstName + ' ' + userDetails?.lastName,
  //     email: userDetails?.email,
  //     exp: Math.round(Date.now() / 1000) + 300,
  //   }
  //   try {
  //     const response = await post('customerservice/api/customer/getContactFormToken', payload)
  //     if (response.isError) {
  //       return
  //     }
  //     if (response) {
  //       localStorage.setItem('contact_form_token', response.token)
  //       window.authenticate(response.token)
  //     }
  //   } catch (e) {
  //     console.error('error', e)
  //   }
  // }

  useEffect(() => {
    const access_token = localStorage.getItem('access_token')
    if (!access_token) {
      navigate('/login')
      return
    }
    setUserDetails(getUserDetails())
    window.initContactForm()
    refreshNotifications()
  }, [])

  const refer = () => {
    navigate('/dashboard/refer')
  }

  const profileHandler = () => {
    handleClose()
    navigate('/dashboard/profile')
  }

  const handleMarkAsRead = async (notificationId) => {
    try {
      const response = await patch(`customerservice/api/notification/${notificationId}/markAsRead`)
      if (!response.isError) {
        setNotificationsVar((prevState) => {
          return prevState.filter((notification) => notification.notificationId !== notificationId)
        })
      }
      console.log(response)
      // refreshNotifications()
    } catch (e) {
      console.log(e)
    }
  }

  const findNavTitle = navTitles.filter(
    (el) => el.linkPath === location.pathname || el.highlightUrls.includes(location.pathname),
  )

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY
      setScrollPosition(position)
      setIsTransitioning(position > 300 && position < 404)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const isMFPage = location.pathname.includes('mutual-funds')

  const getNavbarStyle = () => {
    if (!isMFPage) return 'bg-white border-b'
    if (isMFPage && scrollPosition < 400) return 'bg-[#242F4E]'
    if (isMFPage && scrollPosition >= 504) return 'bg-white border-b'
    return 'bg-gradient-to-b from-[#eceef4] to-white'
  }

  const getTextStyle = () => {
    if (!isMFPage) return 'text-black-400'
    if (scrollPosition < 300) return 'text-primary-100'
    if (scrollPosition >= 404) return 'text-black-400'
    return `text-primary-100 transition-colors duration-300 ${
      isTransitioning ? 'text-opacity-50' : ''
    }`
  }

  useEffect(() => {
    let timer

    if (showMarketStatus) {
      timer = setTimeout(() => {
        setShowMarketStatus(false)
      }, 5000) // Hide status after 5 seconds
    }

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [showMarketStatus, message])

  return (
    <>
      <div
        className={`sticky top-0 lg:hidden z-10 py-4 px-4 flex justify-between items-center ${getNavbarStyle()} mb-0 no-underline`}
      >
        <div className='flex gap-x-2 items-center lg:hidden'>
          <img
            src={
              isMFPage && scrollPosition < 404 ? `${env.SVGS}/logo2.svg` : `${env.SVGS}/logo.svg`
            }
            alt=''
            onClick={() => {
              clevertap.event.push('Clicked Button', {
                Action: 'Logo',
                Page_name: location.pathname,
              })
              navigate('/dashboard')
            }}
            className='cursor-pointer'
          />

          {isDematUser() &&
            (marketStatus === 'OPEN' ||
              marketStatus === 'MAINTENANCE_WINDOW' ||
              marketStatus === 'AMO_ORDERS' ||
              marketStatus === 'SPECIAL_TRADING_SESSION') && (
              <div
                className={`text-xs font-medium my-4 flex gap-x-2 justify-center items-center ${
                  marketStatus === 'OPEN' || marketStatus === 'SPECIAL_TRADING_SESSION'
                    ? 'text-secondary-600'
                    : 'text-warning-600'
                }`}
              >
                {marketStatus === 'OPEN' || marketStatus === 'SPECIAL_TRADING_SESSION' ? (
                  <span
                    className='relative flex h-3 w-3'
                    onClick={() => setShowMarketStatus((prev) => !prev)}
                  >
                    <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-secondary-600'></span>
                    <span className='relative inline-flex rounded-full h-3 w-3 bg-secondary-600'></span>
                  </span>
                ) : (
                  <span className='h-3 w-3 border-[1.5px] border-dashed border-warning-500 rounded-full'></span>
                )}

                <AnimatePresence mode='wait'>
                  {showMarketStatus && (
                    <BlurIn word={message} className='text-xs md:text-sm' duration={0.8} />
                  )}
                </AnimatePresence>
              </div>
            )}
        </div>

        <div className='flex lg:hidden justify-center items-center gap-x-4'>
          {/* {!isDesktop && (
            <NotificationsNoneOutlined
              id='web-inbox-placeholder'
              sx={{
                color: `${isMFPage && scrollPosition < 200 ? 'white' : '#575757'}`,
                width: '24px',
                height: '24px',
              }}
            />
          )} */}

          <div>
            {isDematUser() && (
              <Sheet>
                <SheetTrigger>
                  <Avatar className='cursor-pointer'>
                    <AvatarFallback className='bg-primary-200'>
                      <div className='rounded-full text-white flex justify-center items-center text-xl'>
                        {userDetails?.firstName?.slice(0, 1)}
                      </div>
                    </AvatarFallback>
                  </Avatar>
                </SheetTrigger>
                <SheetContent side='right' className='w-full sm:max-w-none'>
                  <SheetClose className='flex py-4'>
                    <ChevronLeft />{' '}
                    <span className='text-base font-DMSans font-semibold'>Profile</span>
                  </SheetClose>

                  <div className='flex justify-center py-4 px-5'>
                    <div className='grid justify-items-center'>
                      <div className='h-[72px] w-[72px] bg-primary-200 rounded-full text-white flex justify-center items-center text-xl'>
                        {userDetails?.firstName?.slice(0, 1)}
                      </div>
                      <div className='text-black-500 font-DMSans font-semibold mt-2 text-xl'>
                        {userDetails.firstName} {userDetails.lastName}
                      </div>
                    </div>
                  </div>

                  <div className='px-5 grid gap-4 my-4'>
                    <div>
                      <p className='text-primary-200 font-DMSans font-medium text-[10px] leading-3'>
                        ACCOUNT
                      </p>
                      <div className='ml-2'>
                        <SheetClose asChild>
                          <Link
                            className='no-underline text-black-500 flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                            to='/dashboard/profile'
                          >
                            <PersonOutlineOutlined
                              sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                            />
                            <p className='text-sm font-medium font-DMSans'>Account Details</p>
                          </Link>
                        </SheetClose>

                        <SheetClose asChild>
                          <Link
                            className='no-underline text-black-500 flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                            to='/dashboard/refer'
                          >
                            <EmojiEventsOutlined
                              sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                            />
                            <p className='text-sm font-medium font-DMSans'>Refer & Rewards</p>
                          </Link>
                        </SheetClose>
                        <SheetClose asChild>
                          <Link
                            className='no-underline text-black-500 flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                            to='/dashboard/funds'
                          >
                            <AccountBalanceWalletOutlined
                              sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                            />
                            <p className='text-sm font-medium font-DMSans'>Wallet</p>
                          </Link>
                        </SheetClose>
                      </div>
                    </div>
                    <div>
                      <p className='text-primary-200 font-medium text-[10px] leading-3'>
                        INVESTMENTS
                      </p>
                      <div className='ml-2'>
                        <SheetClose asChild>
                          <Link
                            className='no-underline text-black-500 flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                            to='/dashboard/sips'
                          >
                            <CurrencyExchangeOutlined
                              sx={{ fontSize: '18px', lineHeight: '28px', color: '#2d2d2d' }}
                            />
                            <p className='text-sm font-medium font-DMSans'>SIPs</p>
                          </Link>
                        </SheetClose>

                        <SheetClose asChild>
                          <Link
                            className='no-underline text-black-500 flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                            to='/dashboard/portfolio'
                          >
                            <LocalMallOutlined
                              sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                            />
                            <p className='text-sm font-medium font-DMSans'>My Portfolio</p>
                          </Link>
                        </SheetClose>

                        <SheetClose asChild>
                          <Link
                            className='no-underline text-black-500 font-medium flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                            to='/dashboard/theme'
                          >
                            <SignalCellularAlt
                              sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                            />
                            <p className='text-sm font-medium font-DMSans'>
                              Exchange Traded Funds (ETFs)
                            </p>
                          </Link>
                        </SheetClose>
                      </div>
                    </div>
                    <div>
                      <p className='text-primary-200 font-medium text-[10px] leading-3'>CONNECT</p>
                      <div className='ml-2'>
                        <SheetClose asChild>
                          <Link
                            className='no-underline text-black-500 flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                            to='/dashboard/support'
                          >
                            <SupportAgentOutlined
                              sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                            />
                            <p className='text-sm font-medium font-DMSans'>Support</p>
                          </Link>
                        </SheetClose>
                      </div>
                    </div>
                    <div className='flex justify-center'>
                      <div className='flex justify-between w-44 p-0'>
                        {socials.map((social, idx) => {
                          return (
                            <div
                              key={social.id + idx}
                              className=' h-8 w-8 rounded-full flex justify-center items-center cursor-pointer bg-black-500'
                              onClick={() => window.open(social.linkTo)}
                            >
                              {social.icon}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>

                  <div
                    className='cursor-pointer px-5 my-4  flex justify-center'
                    onClick={() => handleLogout()}
                  >
                    <div className='px-5 py-3 font-DMSans rounded-md font-medium text-sm text-error-500 hover:bg-error-200 '>
                      Logout
                    </div>
                  </div>
                </SheetContent>
              </Sheet>
            )}

            {!isDematUser() && (
              <Sheet>
                <SheetTrigger>
                  <Avatar className='cursor-pointer'>
                    <AvatarFallback className='bg-primary-200'>
                      <div className='rounded-full text-white flex justify-center items-center text-xl'>
                        {userDetails?.firstName?.slice(0, 1)}
                      </div>
                    </AvatarFallback>
                  </Avatar>
                </SheetTrigger>
                <SheetContent side='right' className='w-full sm:max-w-none'>
                  <SheetClose className='flex py-4'>
                    <ChevronLeft /> Profile
                  </SheetClose>

                  <div className='flex justify-center py-4 px-5'>
                    <div className='grid justify-items-center'>
                      <div className='h-[72px] w-[72px] bg-primary-200 rounded-full text-white flex justify-center items-center text-xl'>
                        {userDetails?.firstName?.slice(0, 1)}
                      </div>
                      <div className='text-black-500 font-DMSans font-semibold mt-2 text-xl'>
                        {userDetails.firstName} {userDetails.lastName}
                      </div>
                    </div>
                  </div>

                  <div className='px-5 grid gap-4 my-4'>
                    <div>
                      <p className='text-primary-200 font-DMSans font-medium text-[10px] leading-3'>
                        ACCOUNT
                      </p>
                      <SheetClose asChild>
                        <Link
                          className='no-underline text-black-500 font-medium flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                          to='/dashboard/theme'
                        >
                          <SignalCellularAlt
                            sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                          />
                          <p className='text-sm font-medium font-DMSans'>
                            Exchange Traded Funds (ETFs)
                          </p>
                        </Link>
                      </SheetClose>

                      <SheetClose asChild className='ml-2'>
                        <Link
                          className='no-underline text-black-500 flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                          to='/dashboard/refer'
                        >
                          <EmojiEventsOutlined
                            sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                          />
                          <p className='text-sm font-medium font-DMSans'>Refer & Rewards</p>
                        </Link>
                      </SheetClose>
                    </div>

                    <div>
                      <p className='text-primary-200 font-medium text-[10px] leading-3'>CONNECT</p>
                      <SheetClose asChild className='ml-2'>
                        <Link
                          className='no-underline text-black-500 flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                          to='/dashboard/support'
                        >
                          <SupportAgentOutlined
                            sx={{ fontSize: '20px', lineHeight: '28px', color: '#2d2d2d' }}
                          />
                          <p className='text-sm font-medium font-DMSans'>Support</p>
                        </Link>
                      </SheetClose>
                    </div>
                    <div className='flex justify-center'>
                      <div className='flex justify-between w-44 p-0'>
                        {socials.map((social, idx) => {
                          return (
                            <div
                              key={social.id + idx}
                              className=' h-8 w-8 rounded-full flex justify-center items-center cursor-pointer bg-black-500'
                              onClick={() => window.open(social.linkTo)}
                            >
                              {social.icon}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>

                  <div
                    className='cursor-pointer px-5 my-4  flex justify-center'
                    onClick={() => handleLogout()}
                  >
                    <div className='px-5 py-3 font-DMSans rounded-md font-medium text-sm text-error-500'>
                      Logout
                    </div>
                  </div>
                </SheetContent>
              </Sheet>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
