/* eslint-disable */
import { ArrowDown, ArrowUp, MoreVerticalIcon, MoveRight, Plus } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Button } from '../../Components/ui/button'
import {
  ArrowCircleDownOutlined,
  ArrowCircleUpOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Refresh,
} from '@mui/icons-material'
import { MutualFundsOrders } from '../../store/MFStore'
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil'
import Loader from '../../Components/Loader/Loader'
import { Card, CardContent, CardHeader, CardTitle } from '../..//Components/ui/card'
import env from '../../env/env'
import { toCurrency } from '../../utils/CurrencyUtil'
import { Link, useNavigate } from 'react-router-dom'
import { MFBasketHoldings } from '../../store/MFBasketsStore'
import { Separator } from '../../Components/ui/separator'
import MFInvestmentSkeleton from '../../Components/SkeletonLoaders/MFInvestmentSkeleton'
import NoInvestments from '../../assets/images/nodata.svg'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '../../Components/ui/menubar'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../Components/ui/alert-dialog'
import { post } from '../../http/restservice'
import { userDetailsState } from '../LoginState'
import { useModal } from '../../hooks/use-modal-store'
import IconsSelector from '../../store/IconsStore'
import { FinancialGoalsReversed } from '../../utils/Util'

const MFBasketsInvestments = () => {
  const navigate = useNavigate()
  const orders = useRecoilValueLoadable(MFBasketHoldings)
  console.log('🚀 ~ MFBasketsInvestments ~ orders:', orders)
  const [groupedOrders, setGroupedOrders] = useState({})
  const [expandedIndex, setExpandedIndex] = useState(null)
  const refreshOrder = useRecoilRefresher_UNSTABLE(MutualFundsOrders)
  const [userDetails] = useRecoilState(userDetailsState)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const clientCode = userDetails ? userDetails?.uid?.split('-')[0] : userData?.uid?.split('-')[0]
  const { onOpen } = useModal()
  const iconsList = useRecoilValue(IconsSelector)

  const groupByBasketId = (orders) =>
    orders.reduce((acc, { customerBasketInvestmentId, basketStatus, ...order }) => {
      if (!acc[customerBasketInvestmentId]) {
        acc[customerBasketInvestmentId] = []
      }
      acc[customerBasketInvestmentId].push({ basketStatus, customerBasketInvestmentId, ...order })

      return acc
    }, {})

  useEffect(() => {
    if (orders.state === 'hasValue' && orders?.contents?.holdings?.length > 0) {
      setGroupedOrders(groupByBasketId(orders.contents.holdings))
    } else if (orders.state === 'hasError') {
      console.error('Error fetching orders:', orders.contents)
    }
  }, [orders])

  const getTotalUnits = () => {
    return orders.contents.holdings.reduce((acc, order) => {
      return acc + Number(order.netUnits)
    }, 0)
  }

  const getTotalAmount = () => {
    return orders.contents.holdings.reduce((acc, order) => {
      return acc + Number(order.currentNav * order.netUnits)
    }, 0)
  }

  const getTotalInvestedAmount = () => {
    return orders.contents.holdings.reduce((acc, order) => {
      return acc + Number(order.netInvestedAmount)
    }, 0)
  }

  const getBasketCurrentAmount = (basketOrders) => {
    return basketOrders.reduce((acc, order) => {
      return acc + Number(order.currentNav * order.netUnits)
    }, 0)
  }

  const getBasketInvestedAmount = (basketOrders) => {
    return basketOrders.reduce((acc, order) => {
      return acc + Number(order.netInvestedAmount)
    }, 0)
  }

  function truncateToTwoDecimals(num) {
    return Math.trunc(num * 100) / 100
  }

  const calculateParcentage = (invested, returns) => {
    const result = ((returns - invested) / invested) * 100
    return truncateToTwoDecimals(result)
  }

  const getStatus = (currentStatus) => {
    const statusMapping = {
      allotment: 'Allotted',
      partial: 'Partial',
      failed: 'Failed',
      processed: 'In Progress',
    }

    // Convert currentStatus to lowercase for case-insensitive matching
    const lowerCaseStatus = currentStatus.toLowerCase()

    // Iterate over the keys in statusMapping
    for (const keyword in statusMapping) {
      if (lowerCaseStatus.includes(keyword)) {
        return statusMapping[keyword]
      }
    }

    // Default return value if no keywords are matched
    return 'Unknown Status'
  }

  const getReturns = () => {
    const total = getTotalAmount()
    const invested = getTotalInvestedAmount()

    // Calculate returns in percentage
    const returns = ((total - invested) / invested) * 100

    return returns < 0 ? toCurrency(returns) : toCurrency(returns)
  }

  const handleMFBasketRedeem = (basket) => {
    // console.log('🚀 ~ handleMFBasketRedeem ~ basket:', basket)
    navigate('/dashboard/basket/redeem/place-order', {
      state: {
        basket,
      },
    })
  }

  switch (orders.state) {
    case 'loading':
      return <MFInvestmentSkeleton />
    case 'hasValue':
      return (
        <div className='py-2'>
          <div className='mb-6 px-2'>
            {orders?.contents?.holdings?.length > 0 && (
              <div className='w-full lg:max-w-2xl mx-auto px-6 py-4 rounded-xl md:rounded-3xl border text-nowrap bg-[#F4F7FE]'>
                <div className='flex justify-between items-center space-x-6'>
                  <div className='space-y-1 flex-1'>
                    <p className='font-semibold text-primary-500 text-lg'>
                      ₹{' '}
                      {toCurrency(
                        orders?.contents?.holdings?.reduce(
                          (sum, item) => sum + item.netInvestedAmount,
                          0,
                        ) || 0,
                      )}
                    </p>

                    <p className='text-sm text-black-400'>Invested</p>
                  </div>
                  <Separator orientation='vertical' className='h-11' />
                  <div className='space-y-1 flex-1'>
                    <p className='font-semibold text-primary-500 text-lg'>
                      ₹{' '}
                      {toCurrency(
                        orders?.contents?.holdings?.reduce(
                          (sum, item) => sum + item.netUnits * item.currentNav,
                          0,
                        ) || 0,
                      )}
                    </p>
                    <p className='text-sm text-black-400 flex gap-x-1 items-center'>
                      Current{' '}
                      <span
                        className={`${
                          getReturns(orders) < 0
                            ? 'bg-error-600 text-error-600'
                            : 'bg-secondary-600 text-secondary-600'
                        } font-semibold  rounded-[50%] h-5 w-5 flex justify-center items-center mr-1 sm:hidden`}
                      >
                        {getReturns(orders) < 0 ? (
                          <ArrowDown className='h-4 w-4 text-white' />
                        ) : (
                          <ArrowUp className='h-4 w-4 text-white' />
                        )}
                      </span>
                      <span className='text-secondary-600 font-semibold sm:hidden'>
                        {' '}
                        {getReturns(orders)} %
                      </span>
                    </p>
                  </div>
                  <Separator orientation='vertical' className='h-11 hidden sm:block' />
                  <div className='space-y-1 hidden sm:block flex-1'>
                    <p className='font-semibold text-lg'>
                      ₹{' '}
                      {toCurrency(
                        (orders?.contents?.holdings?.reduce(
                          (sum, item) => sum + item.netUnits * item.currentNav,
                          0,
                        ) || 0) -
                          (orders?.contents?.holdings?.reduce(
                            (sum, item) => sum + item.netInvestedAmount,
                            0,
                          ) || 0),
                      )}
                    </p>
                    <div className='flex gap-x-1 items-center text-sm text-black-400'>
                      Returns{' '}
                      <p className='font-semibold text-secondary-600 flex items-center'>
                        <span
                          className={`${
                            getReturns(orders) < 0
                              ? 'bg-error-600 text-error-600'
                              : 'bg-secondary-600 text-secondary-600'
                          } rounded-[50%] h-5 w-5 flex justify-center items-center text-white mr-1`}
                        >
                          {getReturns(orders) < 0 ? (
                            <ArrowDown className='h-4 w-4 text-white' />
                          ) : (
                            <ArrowUp className='h-4 w-4 text-white' />
                          )}
                        </span>
                        <span
                          className={`${
                            getReturns(orders) < 0 ? 'text-error-600' : 'text-secondary-600'
                          } `}
                        >
                          {getReturns(orders)} %
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className='bg-white'>
            {orders?.contents?.status === 'SUCCESS' && orders?.contents?.holdings?.length > 0 && (
              <>
                <div className='lg:mx-auto overflow-hidden'>
                  <div className='hidden md:flex justify-between py-3 pl-14 pr-2 bg-[#F4F7FE] text-primary-400 leading-5 text-sm rounded-xl'>
                    <div>Plan Name</div>

                    <div className='flex'>
                      <div className='w-[150px] text-right pr-3'>Invested</div>
                      <div className='w-[150px] text-right pr-3'>Current</div>
                      <div className='w-[150px] text-right pr-3'>Returns</div>
                    </div>
                  </div>

                  {orders?.contents?.holdings?.length > 0 ? (
                    Object.entries(groupedOrders).map(([key, basketOrders], index) => {
                      // const sortedOrders = [...basketOrders].sort(
                      //   (a, b) => b.customerBasketOrderNo - a.customerBasketOrderNo,
                      // )

                      // const partialOrders = basketOrders.filter(
                      //   (order) => order.basketStatus === 'PARTIAL',
                      // )

                      // const failedOrders = basketOrders.filter(
                      //   (order) => order.basketStatus === 'FAILED',
                      // )

                      return (
                        <div key={key} className='cursor-pointer w-full'>
                          <div className='py-2 text-sm font-medium text-black-400 mb-4 mt-2 leading-5 px-4 lg:px-0'>
                            <div
                              className='flex justify-between items-center w-full'
                              onClick={() => {
                                setExpandedIndex((prevIndex) =>
                                  prevIndex === index ? null : index,
                                )
                              }}
                            >
                              <div className='w-full md:w-auto'>
                                <div className='flex md:justify-between items-start md:items-center gap-x-2'>
                                  <img
                                    src={
                                      iconsList[
                                        'light' + FinancialGoalsReversed[basketOrders[0]?.planType]
                                      ]
                                        ? iconsList[
                                            'light' +
                                              FinancialGoalsReversed[basketOrders[0]?.planType]
                                          ]
                                        : `${env.SVGS}/basket.svg`
                                    }
                                    className='h-12 w-12 rounded-[8px] mt-1'
                                  />

                                  <div className='w-full'>
                                    <div className='flex justify-between gap-x-4 items-center'>
                                      <p className='font-semibold text-gray-900  md:max-w-full text-wrap leading-6'>
                                        {basketOrders[0]?.customerBasketName}
                                      </p>

                                      <div className='flex gap-x-2 items-center'>
                                        <span className='flex-shrink-0 text-black-200 md:hidden'>
                                          <KeyboardArrowDown sx={{ fontSize: '24px' }} />
                                        </span>

                                        <Menubar>
                                          <MenubarMenu className='bg-transparent pointer-events-none'>
                                            <MenubarTrigger
                                              onClick={(e) => e.stopPropagation()}
                                              className='md:hidden hover:bg-[#F4F7FE] px-1 py-[10px] rounded-full h-6 w-6 flex justify-center items-center cursor-pointer'
                                            >
                                              <MoreVerticalIcon className='h-4' />
                                            </MenubarTrigger>

                                            <MenubarContent className='rounded-[10px] flex flex-col gap-y-1'>
                                              <MenubarItem
                                                className='pl-2 text-sm text-black-500 cursor-pointer  hover:bg-blue-100'
                                                onClick={(e) => e.stopPropagation()}
                                              >
                                                <div
                                                  onClick={() => handleMFBasketRedeem(basketOrders)}
                                                >
                                                  Redeem
                                                </div>
                                                {/* <AlertDialog>
                                                  <AlertDialogTrigger
                                                    asChild
                                                    onClick={(e) => e.stopPropagation()}
                                                    className='w-full'
                                                  >
                                                    <div>Redeem</div>
                                                  </AlertDialogTrigger>
                                                  <AlertDialogContent className='font-DMSans'>
                                                    <AlertDialogHeader>
                                                      <AlertDialogTitle className='text-center text-xl'>
                                                        Are you sure you want to redeem your goal ?
                                                      </AlertDialogTitle>
                                                      <AlertDialogDescription className='text-center text-base'>
                                                        This action cannot be undone. This will
                                                        process your redemption request and may
                                                        affect your savings plan.
                                                      </AlertDialogDescription>
                                                    </AlertDialogHeader>
                                                    <AlertDialogFooter>
                                                      <AlertDialogCancel>No</AlertDialogCancel>
                                                      <AlertDialogAction
                                                        onClick={() =>
                                                          handleBasketRedeem(basketOrders)
                                                        }
                                                      >
                                                        Yes
                                                      </AlertDialogAction>
                                                    </AlertDialogFooter>
                                                  </AlertDialogContent>
                                                </AlertDialog> */}
                                              </MenubarItem>
                                            </MenubarContent>
                                          </MenubarMenu>
                                        </Menubar>
                                      </div>
                                    </div>

                                    <div className='flex md:hidden gap-x-1 items-center'>
                                      {calculateParcentage(
                                        getBasketInvestedAmount(basketOrders),
                                        getBasketCurrentAmount(basketOrders),
                                      ) != 0 && (
                                        <span>
                                          {calculateParcentage(
                                            getBasketInvestedAmount(basketOrders),
                                            getBasketCurrentAmount(basketOrders),
                                          ) > 0 ? (
                                            <ArrowCircleUpOutlined
                                              style={{ height: '16px', width: '16px' }}
                                              className='text-secondary-600'
                                            />
                                          ) : (
                                            <ArrowCircleDownOutlined
                                              style={{ height: '16px', width: '16px' }}
                                              className='text-error-600'
                                            />
                                          )}
                                        </span>
                                      )}

                                      <p
                                        className={`font-semibold leading-6 ${
                                          calculateParcentage(
                                            getBasketInvestedAmount(basketOrders),
                                            getBasketCurrentAmount(basketOrders),
                                          ) > 0
                                            ? 'text-secondary-600'
                                            : calculateParcentage(
                                                getBasketInvestedAmount(basketOrders),
                                                getBasketCurrentAmount(basketOrders),
                                              ) < 0
                                            ? 'text-error-600'
                                            : 'text-black-500'
                                        }`}
                                      >
                                        {calculateParcentage(
                                          getBasketInvestedAmount(basketOrders),
                                          getBasketCurrentAmount(basketOrders),
                                        )}{' '}
                                        %
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className='mt-4 px-2 text-xs flex md:hidden justify-between items-center text-nowrap gap-x-4'>
                                  <div>
                                    <p className='font-semibold leading-6 text-black-500'>
                                      ₹ {toCurrency(getBasketInvestedAmount(basketOrders))}
                                    </p>
                                    <p className='text-black-400'>Invested</p>
                                  </div>
                                  <div>
                                    <p className='font-semibold leading-6 text-black-500'>
                                      ₹ {toCurrency(getBasketCurrentAmount(basketOrders))}
                                    </p>
                                    <p className='text-black-400'>Current Value</p>
                                  </div>

                                  <div>
                                    <p
                                      className={`font-semibold leading-6 ${
                                        getBasketCurrentAmount(basketOrders) -
                                          getBasketInvestedAmount(basketOrders) >
                                        0
                                          ? 'text-secondary-600'
                                          : getBasketCurrentAmount(basketOrders) -
                                              getBasketInvestedAmount(basketOrders) <
                                            0
                                          ? 'text-error-600'
                                          : 'text-black-500'
                                      }`}
                                    >
                                      ₹{' '}
                                      {toCurrency(
                                        getBasketCurrentAmount(basketOrders) -
                                          getBasketInvestedAmount(basketOrders),
                                      )}
                                    </p>
                                    <p className='text-black-400'>Gain/Loss</p>
                                  </div>
                                </div>
                              </div>

                              <div className='hidden md:flex items-center gap-x-4'>
                                <div className='flex justify-end items-center gap-x-1 min-w-[150px]'>
                                  <p className='font-semibold leading-6 text-black-500'>
                                    ₹ {toCurrency(getBasketInvestedAmount(basketOrders))}
                                  </p>
                                </div>

                                <div className='flex justify-end items-center gap-x-1 min-w-[150px]'>
                                  <p className='font-semibold leading-6 text-black-500'>
                                    ₹ {toCurrency(getBasketCurrentAmount(basketOrders))}
                                  </p>
                                </div>

                                <div className='flex justify-end items-center gap-x-1 min-w-[150px]'>
                                  <p
                                    className={`font-semibold leading-6  ${
                                      calculateParcentage(
                                        getBasketInvestedAmount(basketOrders),
                                        getBasketCurrentAmount(basketOrders),
                                      ) > 0
                                        ? 'text-secondary-600'
                                        : calculateParcentage(
                                            getBasketInvestedAmount(basketOrders),
                                            getBasketCurrentAmount(basketOrders),
                                          ) < 0
                                        ? 'text-error-600'
                                        : 'text-black-500'
                                    }`}
                                  >
                                    {calculateParcentage(
                                      getBasketInvestedAmount(basketOrders),
                                      getBasketCurrentAmount(basketOrders),
                                    )}{' '}
                                    %
                                  </p>

                                  {expandedIndex === null ? (
                                    <KeyboardArrowDown
                                      className='flex-shrink-0 text-black-200'
                                      sx={{ fontSize: '24px' }}
                                    />
                                  ) : (
                                    <KeyboardArrowUp
                                      className='flex-shrink-0 text-black-200'
                                      sx={{ fontSize: '24px' }}
                                    />
                                  )}

                                  <Menubar>
                                    <MenubarMenu className='bg-transparent pointer-events-none'>
                                      <MenubarTrigger
                                        onClick={(e) => e.stopPropagation()}
                                        className='hover:bg-[#F4F7FE] px-1 py-[10px] rounded-full h-10 w-7 flex justify-center items-center cursor-pointer'
                                      >
                                        <MoreVerticalIcon className='h-4' />
                                      </MenubarTrigger>

                                      <MenubarContent
                                        side='left'
                                        // align='left'
                                        alignOffset={8}
                                        className='rounded-[10px] flex flex-col gap-y-1'
                                      >
                                        <MenubarItem
                                          className='pl-2 text-sm text-black-500 cursor-pointer hover:bg-blue-100'
                                          // onClick={(e) => e.stopPropagation()}
                                          onClick={() => handleMFBasketRedeem(basketOrders)}
                                        >
                                          Redeem
                                          {/* <AlertDialog>
                                            <AlertDialogTrigger
                                              asChild
                                              onClick={(e) => e.stopPropagation()}
                                              className='w-full'
                                            >
                                              <div>Redeem</div>
                                            </AlertDialogTrigger>
                                            <AlertDialogContent className='font-DMSans'>
                                              <AlertDialogHeader>
                                                <AlertDialogTitle className='text-center text-xl'>
                                                  Are you sure you want to redeem your goal ?
                                                </AlertDialogTitle>
                                                <AlertDialogDescription className='text-center text-base'>
                                                  This action cannot be undone. This will process
                                                  your redemption request and may affect your
                                                  savings plan.
                                                </AlertDialogDescription>
                                              </AlertDialogHeader>
                                              <AlertDialogFooter>
                                                <AlertDialogCancel>No</AlertDialogCancel>
                                                <AlertDialogAction
                                                  onClick={() => handleBasketRedeem(basketOrders)}
                                                >
                                                  Yes
                                                </AlertDialogAction>
                                              </AlertDialogFooter>
                                            </AlertDialogContent>
                                          </AlertDialog> */}
                                        </MenubarItem>

                                        {/* 
                                                            <MenubarItem
                                                              onClick={() => {
                                                                setOpen(false)
                                                              }}
                                                              className='py-2 pl-3 text-sm cursor-pointer hover:bg-red-100 hover:text-red-500'
                                                            >
                                                              Cancel
                                                            </MenubarItem> */}
                                      </MenubarContent>
                                    </MenubarMenu>
                                  </Menubar>
                                </div>
                              </div>
                            </div>

                            <div
                              className={`text-sm lg:pl-6 px-2 flex-col justify-between items-start mt-4 text-gray-500 overflow-hidden transition-all duration-300 ease-in-out ${
                                expandedIndex === index
                                  ? 'max-h-max opacity-100 pt-4 border-b'
                                  : 'max-h-0 opacity-0 border-t'
                              }`}
                            >
                              {basketOrders?.map((order, index) => {
                                return (
                                  <div
                                    className={`py-4 flex items-center lg:items-start w-full`}
                                    key={index}
                                  >
                                    <div className='flex justify-between w-full'>
                                      <div
                                        className='flex justify-between items-center w-full'
                                        onClick={() => {
                                          setExpandedIndex((prevIndex) =>
                                            prevIndex === index ? null : index,
                                          )
                                        }}
                                      >
                                        <div className='w-full md:w-auto'>
                                          <div className='flex justify-between items-start gap-x-2'>
                                            <img
                                              src={`${env.MF_LOGOS_URL}/${order?.amcCode}.png`}
                                              className='h-12 w-12 rounded-[8px]'
                                            />
                                            <div className='w-full md:w-auto'>
                                              <p className='font-semibold text-gray-900 text-wrap md:max-w-full leading-6'>
                                                {order?.legalName
                                                  ? order?.legalName
                                                  : order?.schemeName}
                                              </p>

                                              <div className='flex justify-between md:justify-normal w-full gap-x-5'>
                                                <div className='text-xs md:text-sm flex justify-between md:justify-start gap-x-12 leading-5 flex-shrink-0 text-right w-full'>
                                                  <p className='text-black-400 leading-5 font-medium'>
                                                    NAV : {toCurrency(order.currentNav)}
                                                  </p>

                                                  {order.netUnits && (
                                                    <p className='font-medium text-black-400'>
                                                      {order.netUnits
                                                        ? toCurrency(order.netUnits)
                                                        : null}{' '}
                                                      Units
                                                    </p>
                                                  )}
                                                </div>
                                              </div>

                                              <div className='flex text-xs md:hidden justify-between items-center gap-x-2'>
                                                {order.netInvestedAmount && (
                                                  <p className='font-semibold leading-6 text-black-500'>
                                                    ₹{' '}
                                                    {order.netInvestedAmount
                                                      ? toCurrency(order.netInvestedAmount)
                                                      : null}
                                                  </p>
                                                )}

                                                {order.currentNav && order.netUnits && (
                                                  <p className='flex items-center gap-x-1 font-semibold leading-6 text-black-500'>
                                                    ₹{' '}
                                                    {toCurrency(order.currentNav * order.netUnits)}
                                                    {calculateParcentage(
                                                      order.netInvestedAmount,
                                                      order.currentNav * order.netUnits,
                                                    ) > 0 ? (
                                                      <ArrowCircleUpOutlined
                                                        style={{ height: '16px', width: '16px' }}
                                                        className='text-secondary-600'
                                                      />
                                                    ) : (
                                                      <ArrowCircleDownOutlined
                                                        style={{ height: '16px', width: '16px' }}
                                                        className='text-error-600'
                                                      />
                                                    )}
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className='flex items-center gap-x-1'>
                                          <div className='hidden md:flex justify-end items-center gap-x-1 min-w-[150px] pr-4'>
                                            <p className='font-semibold leading-6 text-black-500'>
                                              ₹ {toCurrency(order.netInvestedAmount)}
                                            </p>
                                          </div>

                                          <div className='hidden md:flex justify-end items-center gap-x-1 min-w-[150px]'>
                                            <p className='font-semibold leading-6 text-black-500'>
                                              ₹ {toCurrency(order.currentNav * order.netUnits)}
                                            </p>
                                          </div>

                                          <div className='hidden md:flex justify-end items-center gap-x-1 min-w-[150px] pr-4'>
                                            <p
                                              className={`font-semibold leading-6 ${
                                                calculateParcentage(
                                                  order.netInvestedAmount,
                                                  order.currentNav * order.netUnits,
                                                ) > 0
                                                  ? 'text-secondary-600'
                                                  : calculateParcentage(
                                                      order.netInvestedAmount,
                                                      order.currentNav * order.netUnits,
                                                    ) < 0
                                                  ? 'text-error-600'
                                                  : 'text-black-500'
                                              }`}
                                            >
                                              {calculateParcentage(
                                                order.netInvestedAmount,
                                                order.currentNav * order.netUnits,
                                              )}{' '}
                                              %
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className='bg-white flex items-center justify-center'>
                      <Card className='w-full max-w-sm text-center border-none shadow-none font-DMSans'>
                        <CardHeader className='flex flex-col gap-y-6 justify-center items-center'>
                          <img
                            className='h-14 w-14'
                            src={`${env.SVGS}/error-icon.svg`}
                            alt='Error-Icon'
                          />
                          <CardTitle className='font-semibold text-base'>
                            Oops! Something Went Wrong
                          </CardTitle>
                        </CardHeader>
                        <CardContent className='flex flex-col gap-y-6'>
                          <p className='text-sm leading-5 text-black-400 mb-6'>
                            We're sorry, but it seems there was an issue connecting to our server.
                            Please check your internet connection or try refreshing the page. If the
                            problem persists, try again later.
                          </p>

                          <div className='flex gap-x-2 justify-between'>
                            <Button
                              className='w-full'
                              variant='fabitsWhite'
                              onClick={() => window.location.reload()}
                            >
                              <Refresh className='mr-2 h-4 w-4 text-black-300' /> Refresh
                            </Button>

                            <Link className='flex-1' to='/dashboard/mutual-funds'>
                              <Button className='w-full'>
                                View Funds
                                <MoveRight className='ml-2 h-4 w-4' />
                              </Button>
                            </Link>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  )}
                </div>
              </>
            )}

            {orders?.contents?.status === 'SUCCESS' ||
              (orders?.contents?.length === 0 && (
                <div className='bg-white flex items-center justify-center'>
                  <Card className='w-full max-w-2xl text-center border-none shadow-none'>
                    <CardHeader>
                      <img className='mx-auto' src={NoInvestments} alt='Error-Icon' />
                      <CardTitle className='text-xl'>You haven’t set any goals yet</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <p className='text-sm text-black-400 mb-4'>
                        Take the first step towards your financial future by setting up a goal
                        today.
                      </p>
                      <Link to='/dashboard/plans'>
                        <Button className='w-full'>
                          <Plus className='mr-2 h-4 w-4' /> Create Goal
                        </Button>
                      </Link>
                    </CardContent>
                  </Card>
                </div>
              ))}

            {orders?.contents?.status === 'FAILURE' && (
              <div className='bg-white flex items-center justify-center'>
                <Card className='w-full max-w-sm text-center border-none shadow-none font-DMSans'>
                  <CardHeader className='flex flex-col gap-y-6 justify-center items-center'>
                    <img
                      className='h-14 w-14'
                      src={`${env.SVGS}/error-icon.svg`}
                      alt='Error-Icon'
                    />
                    <CardTitle className='font-semibold text-base'>
                      Oops! Something Went Wrong
                    </CardTitle>
                  </CardHeader>
                  <CardContent className='flex flex-col gap-y-6'>
                    <p className='text-sm leading-5 text-black-400 mb-6'>
                      We're sorry, but it seems there was an issue connecting to our server. Please
                      check your internet connection or try refreshing the page. If the problem
                      persists, try again later.
                    </p>

                    <div className='flex gap-x-2 justify-between'>
                      <Button
                        className='w-full'
                        variant='fabitsWhite'
                        onClick={() => window.location.reload()}
                      >
                        <Refresh className='mr-2 h-4 w-4 text-black-300' /> Refresh
                      </Button>

                      <Link className='flex-1' to='/dashboard/mutual-funds'>
                        <Button className='w-full'>
                          View Funds
                          <MoveRight className='ml-2 h-4 w-4' />
                        </Button>
                      </Link>
                    </div>
                  </CardContent>
                </Card>
              </div>
            )}
          </div>
        </div>
      )

    case 'hasError':
      return (
        <div className='bg-white flex items-center justify-center'>
          <Card className='w-full max-w-3xl text-center border-none shadow-none font-DMSans'>
            <CardHeader className='flex flex-col gap-y-6 justify-center items-center'>
              <img className='h-14 w-14' src={`${env.SVGS}/error-icon.svg`} alt='Error-Icon' />
              <CardTitle className='font-semibold text-base'>Oops! Something Went Wrong</CardTitle>
            </CardHeader>
            <CardContent className='flex flex-col gap-y-6'>
              <p className='text-sm leading-5 text-black-400 mb-6'>
                We're sorry, but it seems there was an issue connecting to our server. Please check
                your internet connection or try refreshing the page. If the problem persists, try
                again later.
              </p>
            </CardContent>
          </Card>
        </div>
      )

    default:
      return null
  }
}

export const Boilerplate = ({ state, desc }) => {
  return (
    <div className='h-3/5 flex items-center justify-center p-4'>
      <Card className='w-full max-w-sm text-center border-none shadow-none'>
        <CardHeader>
          <img className='h-14 w-14' src={`${env.SVGS}/error-icon.svg`} alt='Error-Icon' />
          <div className='w-20 h-20 mx-auto mb-4 flex justify-center items-center'>
            <img src={`${env.SVGS}/error-icon.svg`} />
          </div>
          <CardTitle className='text-xl'>{state}</CardTitle>
        </CardHeader>
        <CardContent>
          <p className='text-muted-foreground mb-6'>{desc}</p>
          {/* <Link to='/dashboard/mutual-funds'>
            <Button className='w-full'>
              <Plus className='mr-2 h-4 w-4' /> Create SIP
            </Button>
          </Link> */}
        </CardContent>
      </Card>
    </div>
  )
}

export default MFBasketsInvestments
