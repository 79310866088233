/* eslint-disable */

import { useMemo, useEffect, useState, useContext } from 'react'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import { get } from '../../http/restservice'
import env from '../../env/env'
import PlansCarousel from '../../Components/Home/PlansCarousel'
import useMediaQuery from '../../hooks/useMediaQuery'
import { getUserDetails } from '../../utils/AuthUtil'

//SVGS to upload
import insurance from '../../assets/newHome/insurance.svg'
import taxPlanning from '../../assets/newHome/taxPlanning.svg'
import { AccountDetailsNewSelector } from '../../store/AccountDetailsStore'
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'
import { MFUserBaskets } from '../../store/MFBasketStore'
import { isDematUser } from '../../utils/AuthUtil'
import { Skeleton } from '../../Components/ui/skeleton'
import { SSEContext } from '../../Provider/SSEProvider'
import { MutualFundsHolding } from '../../store/MFStore'
import { toCurrency } from '../../utils/CurrencyUtil'
import {
  ArrowDown,
  ArrowUp,
  CheckCircle,
  CheckIcon,
  ChevronDown,
  ChevronUp,
  CircleAlert,
  Copy,
} from 'lucide-react'
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../Components/ui/tooltip'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import { HomeCarouselCards } from '../../store/HomePageStore'
import IntroVideo from './IntroVideo'

const Card = ({ title, desc, gradient, icon: Icon, onClick, topCard, bottomCard }) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      className={`h-full ${isDematUser() && 'max-w-full '}`}
      style={{
        border: gradient ? '1px solid transparent' : '',
        borderRadius: '16px',
        background: gradient
          ? 'linear-gradient(white, white) padding-box, linear-gradient(93.32deg, rgba(31, 160, 230, 0.8) 0%, rgba(236, 41, 197, 0.8) 49.96%, rgba(236, 127, 38, 0.8) 99.91%) border-box'
          : '#F4F7FE',
        outline: `${isHovered && !gradient ? '1px solid #D3DBEC' : 'none'}`,
      }}
    >
      <div
        className={`flex-1 p-4 cursor-pointer h-full ${
          gradient && 'lg:flex lg:flex-col lg:items-center lg:justify-center'
        }`}
        style={{
          background: gradient
            ? 'linear-gradient(92.56deg, rgba(31, 160, 230, 0.08) 3.63%, rgba(236, 41, 197, 0.08) 47.06%, rgba(236, 127, 38, 0.08) 90.49%), #FFFFFF'
            : '#F4F7FE',
          // boxShadow: '0px 6px 16px rgba(239, 149, 80, 0.12)',
          borderRadius: '16px',
        }}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className='flex justify-between'>
          <h1
            className={`text-lg font-medium flex items-center gap-x-2 ${
              gradient &&
              'pb-2 bg-gradient-to-r from-purple-500 via-pink-500 to-pink-400 bg-clip-text text-transparent lg:font-medium lg:justify-center lg:text-[32px]'
            } ${topCard && !gradient && 'lg:text-2xl'} ${bottomCard && '!text-base lg:!text-xl'}`}
          >
            {Icon && <Icon className='h-6 w-6' />}
            {title}
          </h1>
          {isDesktop && !gradient && <ChevronRightIcon sx={{ color: '#9E9E9E' }} />}
        </div>
        <p
          className={`text-sm mt-2 font-normal ${
            gradient
              ? 'bg-gradient-to-r from-blue-400 via-pink-400 to-pink-300 bg-clip-text text-transparent'
              : 'text-black-400 '
          } ${topCard ? 'lg:mt-4' : 'lg:mt-3'}`}
        >
          {desc}
        </p>
      </div>
    </div>
  )
}

const Homepage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const [loginDetails, setLoginDetails] = useState({})
  const [isFICompleted, setIsFICompleted] = useState(false)
  const refreshSummary = useRecoilRefresher_UNSTABLE(AccountDetailsNewSelector)
  const [adminPlans, setAdminPlans] = useState([])
  const [activeAdminPlans, setActiveAdminPlans] = useState([])
  const { state: mfbasketState, contents: investedPlans } = useRecoilValueLoadable(MFUserBaskets)
  const refreshPlans = useRecoilRefresher_UNSTABLE(MFUserBaskets)
  const { marketStatus, message } = useContext(SSEContext)
  const mfHolding = useRecoilValueLoadable(MutualFundsHolding)
  const { state: etfState, contents: accountDetails } =
    useRecoilValueLoadable(AccountDetailsNewSelector)

  const carouselCards = useRecoilValueLoadable(HomeCarouselCards)

  const [expanded, setExpanded] = useState(false)

  const [showVideo, setShowVideo] = useState(location?.state?.hasSeenVideo)
  const [showDialog, setShowDialog] = useState(true)

  let isUserFirstTime =
    location?.state?.firstTimeUser == true || location?.state?.hasSeenVideo === false

  useEffect(() => {
    const hasSeenVideo = localStorage.getItem('hasSeenVideo')

    if (!hasSeenVideo) {
      setShowVideo(true)
    }
  }, [])

  const handleVideoEnd = () => {
    localStorage.setItem('hasSeenVideo', 'true')
    setShowVideo(false)
  }

  useEffect(() => refreshPlans(), [])

  useEffect(() => {
    if (mfbasketState === 'hasValue' && Array.isArray(investedPlans?.data)) {
      const adminCuratedPlans = investedPlans?.data?.filter(
        (plan) =>
          plan.createdSource === 'ACTION PLAN' &&
          (plan?.sipStatus === 'PENDING' || plan?.oneTimeStatus === 'PENDING'),
      )

      const activePlans = investedPlans?.data?.filter(
        (plan) =>
          plan.createdSource === 'ACTION PLAN' &&
          (plan?.sipStatus === 'ACTIVE' || plan?.oneTimeStatus === 'ACTIVE'),
      )

      setActiveAdminPlans(activePlans)
      setAdminPlans(adminCuratedPlans)
    }
  }, [mfbasketState, investedPlans?.data])

  const cards = [
    {
      id: 1,
      title: 'Start here',
      desc: 'Discover your perfect fund in a few taps',
    },
    {
      id: 2,
      title:
        activeAdminPlans.length && !adminPlans.length
          ? 'You are all set'
          : 'Curating your plans...',
      desc: 'We will get in touch with you soon',
    },
    {
      id: 3,
      title: 'Action Center',
      desc: 'Proceed with your plans',
    },
    {
      id: 4,
      title: (
        <div className='block gap-x-[10px] items-center'>
          <img src={`${env.S3_BASE_URL}/firstTime/analytics.svg`} height={40} width={40} />
          <p>2024 Returns</p>
        </div>
      ),
      desc: 'Start saving for life’s bigger financial responsibilities',
    },
    {
      id: 5,
      title: (
        <div className='block gap-x-[10px] items-center'>
          <TrackChangesIcon sx={{ height: '32px', width: '32px' }} />
          <p>Goal Planning</p>
        </div>
      ),
      desc: 'Start saving for life’s bigger financial responsibilities',
    },

    {
      id: 6,
      title: (
        <div className='block gap-x-[10px] items-center'>
          <img src={insurance || '/placeholder.svg'} height={32} width={32} />
          <p>Insurance</p>
        </div>
      ),
      desc: 'Maximise your income by optimising your tax liabilities',
    },
    {
      id: 7,
      title: (
        <div className='block gap-x-[10px] items-center'>
          <img src={taxPlanning || '/placeholder.svg'} height={32} width={32} />
          <p>Tax Planning</p>
        </div>
      ),
      desc: 'Personalised tax planning to maximise deductions and savings',
    },
  ]

  const getTopCards = () => {
    const filteredCards = []

    if (!isFICompleted && adminPlans.length === 0) {
      filteredCards.push(cards[0])
    }

    if (isFICompleted && adminPlans.length === 0) {
      filteredCards.push(cards[1])
    }

    if (adminPlans.length > 0) {
      filteredCards.push(cards[2])
    }

    if (
      !isDematUser() ||
      (isDematUser() &&
        Object.keys(accountDetails).length === 0 &&
        mfHolding?.contents?.mainData?.length === 0)
    ) {
      filteredCards.push(cards[3])
    }

    return filteredCards
  }

  const topCards = getTopCards()

  const topCardsDesktop = useMemo(() => topCards, [topCards])
  const bottomCards = useMemo(
    () =>
      cards.slice(
        !isDematUser() ||
          (isDematUser() &&
            Object.keys(accountDetails).length === 0 &&
            mfHolding?.contents?.mainData?.length === 0)
          ? 4
          : 3,
      ),
    [accountDetails, mfHolding?.contents?.mainData],
  )

  const bottomCardsDesktop = useMemo(
    () =>
      cards.slice(
        !isDematUser() ||
          (isDematUser() &&
            Object.keys(accountDetails).length === 0 &&
            mfHolding?.contents?.mainData?.length === 0)
          ? 4
          : 3,
      ),
    [accountDetails, mfHolding?.contents?.mainData],
  )

  useEffect(() => refreshSummary(), [])

  const isQuestionaireCompleted = async () => {
    const url = 'https://apimywealth.fabits.com/customerservice/api/firstIdeas'
    const phoneNumber = loginDetails.phoneNumber

    try {
      const response = await get(url, {}, { phoneNumber })
      setIsFICompleted(response?.isCompleted)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setLoginDetails(getUserDetails())

    if (loginDetails?.phoneNumber) {
      isQuestionaireCompleted()
    }
  }, [loginDetails?.phoneNumber])

  const redirect = (id) => {
    if (id === 1) {
      navigate('/dashboard/firstTime/questionnaire')
    } else if (id === 2) {
      return
    } else if (id === 3) {
      navigate('/dashboard/firstTime/actionPlanUser')
    } else if (id === 4) {
      navigate('/dashboard/firstTime/returns')
    } else if (id === 5) {
      navigate('/dashboard/plans')
    } else if (id === 6) {
      navigate('/dashboard/insurance')
    } else if (id === 7) {
      navigate('/dashboard/tax-filing')
    }
  }

  const getCurrentInvestmentTotal = () => {
    const investment =
      mfHolding.state === 'hasValue' && mfHolding?.contents?.mainData?.length > 0
        ? mfHolding?.contents?.mainData
            ?.filter((item) => Number(item.balance) > 0)
            ?.reduce((sum, item) => sum + Number(item.holding), 0)
        : 0

    const etf = Object.keys(accountDetails).length > 0 ? +accountDetails.currentInvestment : 0

    const total = investment + etf

    return total.toFixed(2)
  }

  const getCurrentInvestedTotal = () => {
    const investment =
      mfHolding.state === 'hasValue' && mfHolding?.contents?.mainData?.length > 0
        ? mfHolding?.contents?.mainData
            ?.filter((item) => Number(item.balance) > 0)
            ?.reduce((sum, item) => sum + Number(item.netInvestedAmt), 0)
        : 0

    const etf = Object.keys(accountDetails).length > 0 ? +accountDetails.totalInvestment : 0

    const total = investment + etf

    return total.toFixed(2)
  }

  const calculateParcentage = (invested, returns) => {
    const result = ((returns - invested) / invested) * 100
    return result.toFixed(2)
  }

  return (
    <>
      <div className='bg-white h-full px-4 lg:px-0 py-4 flex flex-col gap-4 lg:mx-auto lg:max-w-[1128px]'>
        {!isDematUser() && (
          <div className='mb-10'>
            <h1 className='font-OrelegaOne font-normal text-2xl'>
              Complete your KYC to begin investing
            </h1>
            <p className='font-DMSans font-normal text-base text-primary-600 mt-3'>
              <a href='https://onboarding.fabits.com/' className='flex items-center gap-2'>
                Complete KYC details <ArrowOutwardIcon className='h-6 w-6' />
              </a>
            </p>
          </div>
        )}
        {isDematUser() &&
          Object.keys(accountDetails).length === 0 &&
          mfHolding?.contents?.mainData?.length === 0 && (
            <div className='mb-10'>
              <h1 className='font-OrelegaOne font-normal text-2xl'>
                Start building your empire today!
              </h1>
              <p className='font-DMSans font-normal text-base mt-3'>
                <a className='flex items-center gap-2 text-black-600 cursor-pointer'>
                  Your KYC is all set{' '}
                  <span className='bg-secondary-600 text-white rounded-full flex items-center justify-center p-0.5'>
                    <CheckIcon className='h-3 w-3' />
                  </span>
                </a>
              </p>
            </div>
          )}
        <div
          className={`flex flex-col lg:flex-row gap-4 lg:max-w-[1128px] ${
            isDematUser() && 'lg:min-h-[191px]'
          }`}
        >
          {/* AT A Glance section */}
          {isDematUser() && marketStatus !== 'DAILY_MAINTENANCE_WINDOW' && (
            <>
              {etfState === 'hasValue' &&
              mfHolding.state === 'hasValue' &&
              etfState !== 'hasError' &&
              mfHolding.state !== 'hasError' &&
              (Object.keys(accountDetails).length > 0 ||
                mfHolding?.contents?.mainData?.length > 0) ? (
                <>
                  <div className='rounded-[20px] border mt-2 p-5 lg:hidden'>
                    <div
                      className='h-auto flex flex-col gap-y-4 p-2'
                      onClick={() => setExpanded(!expanded)}
                    >
                      <div className='flex flex-col justify-center items-center gap-0'>
                        <h1 className='text-center text-[#41558D] kerning-[48px] mb-1'>
                          <span className='text-3xl font-extrabold'> &#8377;</span>{' '}
                          <span className='text-4xl font-OrelegaOne'>
                            {toCurrency(getCurrentInvestmentTotal()).split('.')[0]}
                          </span>
                          {getCurrentInvestmentTotal().includes('.') && (
                            <span className='text-2xl font-OrelegaOne'>
                              .{getCurrentInvestmentTotal().split('.')[1]}
                            </span>
                          )}
                        </h1>
                      </div>

                      <div className='flex justify-between'>
                        <div className='flex flex-col justify-center items-center'>
                          <p className='text-black-500 font-semibold text-l'>
                            &#8377; {toCurrency(getCurrentInvestedTotal()).split('.')[0]}
                          </p>
                          <p className='text-[#727272] font-medium text-[14px] kerning-[20px]'>
                            Invested
                          </p>
                        </div>

                        <div className='flex flex-col justify-center items-center w-[50%]'>
                          <p className='text-black-500 font-semibold text-l'>
                            &#8377;{' '}
                            {toCurrency(getCurrentInvestmentTotal() - getCurrentInvestedTotal())}
                          </p>
                          <p className='flex justify-center items-center gap-x-2 text-[#727272] font-medium text-sm'>
                            Returns{' '}
                            {calculateParcentage(
                              getCurrentInvestedTotal(),
                              getCurrentInvestmentTotal(),
                            ) != 0 && (
                              <span
                                className={`${
                                  calculateParcentage(
                                    getCurrentInvestedTotal(),
                                    getCurrentInvestmentTotal(),
                                  ) > 0
                                    ? 'bg-secondary-600'
                                    : 'bg-error-600'
                                } rounded-[50%] h-5 w-5 flex justify-center items-center`}
                              >
                                {calculateParcentage(
                                  getCurrentInvestedTotal(),
                                  getCurrentInvestmentTotal(),
                                ) > 0 ? (
                                  <ArrowUp className='h-4 w-3 text-white' />
                                ) : (
                                  <ArrowDown className='h-4 w-3 text-white' />
                                )}
                              </span>
                            )}
                            <span
                              className={`font-semibold ${
                                calculateParcentage(
                                  getCurrentInvestedTotal(),
                                  getCurrentInvestmentTotal(),
                                ) > 0
                                  ? 'text-secondary-600'
                                  : calculateParcentage(
                                      getCurrentInvestedTotal(),
                                      getCurrentInvestmentTotal(),
                                    ) < 0
                                  ? 'text-error-600'
                                  : 'text-black-600'
                              }`}
                            >
                              {calculateParcentage(
                                getCurrentInvestedTotal(),
                                getCurrentInvestmentTotal(),
                              )}{' '}
                              %
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    {Object.keys(accountDetails).length > 0 && (
                      <div className='w-full flex flex-col justify-end items-end mt-2'>
                        <span
                          onClick={() => setExpanded(!expanded)}
                          className={`cursor-pointer text-sm flex items-center gap-x-1 text-black-400 ${
                            expanded && 'mb-2'
                          }`}
                        >
                          View more
                          {expanded ? (
                            <ChevronUp className='h-3 w-3' />
                          ) : (
                            <ChevronDown className='h-3 w-3' />
                          )}
                        </span>
                        <div
                          className={`text-sm flex-col justify-between items-start w-full overflow-hidden transition-all duration-300 ease-in-out ${
                            expanded ? 'max-h-max opacity-100 pt-2 border-t' : 'max-h-0 opacity-0'
                          }`}
                        >
                          <div className='flex justify-between w-full'>
                            <p className='text-black-500 '>Mutual Funds</p>

                            <p className='flex gap-x-1 flex-nowrap font-semibold text-right'>
                              &#8377;{' '}
                              {toCurrency(
                                mfHolding?.contents?.mainData?.reduce(
                                  (sum, item) => sum + Number(item.holding),
                                  0,
                                ) || 0,
                              )}{' '}
                              <span
                                className={`font-semibold ${
                                  calculateParcentage(
                                    mfHolding?.contents?.mainData?.reduce(
                                      (sum, item) => sum + item.netInvestedAmt,
                                      0,
                                    ) || 0,
                                    mfHolding?.contents?.mainData?.reduce(
                                      (sum, item) => sum + item.holding,
                                      0,
                                    ) || 0,
                                  ) > 0
                                    ? 'text-secondary-600'
                                    : 'text-error-600'
                                }`}
                              >
                                (
                                {toCurrency(
                                  calculateParcentage(
                                    mfHolding?.contents?.mainData?.reduce(
                                      (sum, item) => sum + item.netInvestedAmt,
                                      0,
                                    ) || 0,
                                    mfHolding?.contents?.mainData?.reduce(
                                      (sum, item) => sum + item.holding,
                                      0,
                                    ) || 0,
                                  ),
                                )}{' '}
                                %)
                              </span>
                            </p>
                          </div>

                          <div className='flex justify-between w-full  mt-1'>
                            <p className='text-black-500'>Stocks & ETFs</p>

                            {mfHolding?.contents?.mainData?.length > 0 && (
                              <div className='flex flex-nowrap gap-x-1 font-semibold'>
                                <p>&#8377; {toCurrency(accountDetails.currentInvestment)}</p>

                                <span
                                  className={` ${
                                    calculateParcentage(
                                      accountDetails.totalInvestment,
                                      accountDetails.currentInvestment,
                                    ) > 0
                                      ? 'text-secondary-600'
                                      : 'text-error-600'
                                  }`}
                                >
                                  (
                                  {toCurrency(
                                    calculateParcentage(
                                      accountDetails.totalInvestment,
                                      accountDetails.currentInvestment,
                                    ),
                                  )}{' '}
                                  %)
                                </span>
                              </div>
                            )}
                          </div>

                          <div className='flex justify-between text-black-400'>
                            <p className='text-right'></p>
                            {/* <p className='text-right text-xs'>Invested</p> */}
                            <p className='text-right text-xs'>Current</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    className='p-6 rounded-[24px] border h-auto w-full hidden lg:min-h-[191px] lg:block lg:flex-[2]'
                    // style={{ filter: 'blur(8px)' }}
                  >
                    <div className='flex justify-between mb-3'>
                      <h1 className='text-primary-300 font-medium text-sm mb-3'>AT A GLANCE</h1>
                      {(marketStatus === 'OPEN' ||
                        marketStatus === 'MAINTENANCE_WINDOW' ||
                        marketStatus === 'AMO_ORDERS' ||
                        marketStatus === 'SPECIAL_TRADING_SESSION') && (
                        <p
                          className={`text-sm font-medium flex gap-x-2 items-center ${
                            marketStatus === 'OPEN' || marketStatus === 'SPECIAL_TRADING_SESSION'
                              ? 'text-secondary-600'
                              : 'text-warning-600'
                          }`}
                        >
                          {message}

                          {marketStatus === 'OPEN' || marketStatus === 'SPECIAL_TRADING_SESSION' ? (
                            <span className='relative flex h-3 w-3'>
                              <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-secondary-600'></span>
                              <span className='relative inline-flex rounded-full h-3 w-3 bg-secondary-600'></span>
                            </span>
                          ) : (
                            <span className='h-3 w-3 border-[1.5px] border-dashed border-warning-500 rounded-full'></span>
                          )}
                        </p>
                      )}
                    </div>

                    <div className='flex flex-wrap justify-between items-end w-full'>
                      <div>
                        <h1 className='text-2xl text-primary-500'>
                          <span className='text-[1.6rem] font-extrabold'>&#8377;</span>{' '}
                          <span className='font-OrelegaOne text-[2rem] tracking-[4%]'>
                            {toCurrency(getCurrentInvestmentTotal()).split('.')[0] || 0}
                          </span>
                          {getCurrentInvestmentTotal().includes('.') && (
                            <span className='text-2xl font-OrelegaOne'>
                              .{toCurrency(getCurrentInvestmentTotal()).split('.')[1]}
                            </span>
                          )}
                        </h1>
                        <p className='text-sm flex justify-center items-center gap-x-2 font-medium text-black-300'>
                          Current Value
                          <TooltipProvider delayDuration={300}>
                            <Tooltip>
                              <TooltipTrigger>
                                <CircleAlert
                                  className='h-5 w-5 text-white rotate-180'
                                  fill='#727272'
                                />
                              </TooltipTrigger>
                              <TooltipContent className='bg-black-500 max-w-[275px]'>
                                <p className='text-white'>
                                  Investments could take up to 4 working days to reflect in your
                                  account
                                </p>
                                <TooltipArrow />
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </p>
                      </div>
                      <div>
                        <h1 className='font-semibold leading-6 text-xl'>
                          &#8377; {toCurrency(getCurrentInvestedTotal()).split('.')[0] || 0}
                        </h1>
                        <p className='text-sm font-medium text-black-300'>Invested Amount</p>
                      </div>

                      <div>
                        <h1 className='font-semibold leading-6 text-xl text-center'>
                          &#8377;{' '}
                          {toCurrency(getCurrentInvestmentTotal() - getCurrentInvestedTotal())}
                        </h1>
                        <div className='flex gap-x-2 justify-center items-center'>
                          <p className='text-sm font-medium text-black-300'>Returns</p>

                          {calculateParcentage(
                            getCurrentInvestedTotal(),
                            getCurrentInvestmentTotal(),
                          ) != 0 && (
                            <span
                              className={`${
                                calculateParcentage(
                                  getCurrentInvestedTotal(),
                                  getCurrentInvestmentTotal(),
                                ) > 0
                                  ? 'bg-secondary-600'
                                  : 'bg-error-600'
                              } rounded-[50%] h-5 w-5 flex justify-center items-center text-white`}
                            >
                              {calculateParcentage(
                                getCurrentInvestedTotal(),
                                getCurrentInvestmentTotal(),
                              ) > 0 ? (
                                <ArrowUp className='h-4 w-3' />
                              ) : (
                                <ArrowDown className='h-4 w-3' />
                              )}
                            </span>
                          )}

                          <span
                            className={`font-semibold ${
                              calculateParcentage(
                                getCurrentInvestedTotal(),
                                getCurrentInvestmentTotal(),
                              ) > 0
                                ? 'text-secondary-600'
                                : calculateParcentage(
                                    getCurrentInvestedTotal(),
                                    getCurrentInvestmentTotal(),
                                  ) < 0
                                ? 'text-error-600'
                                : 'text-black-600'
                            }`}
                          >
                            {toCurrency(
                              calculateParcentage(
                                getCurrentInvestedTotal(),
                                getCurrentInvestmentTotal(),
                              ),
                            )}
                            %
                          </span>
                        </div>
                      </div>
                    </div>

                    {Object.keys(accountDetails).length > 0 && (
                      <div className='w-full flex flex-col justify-end items-end'>
                        <div className='flex justify-end items-center w-full mt-4'>
                          {/* <div className='text-primary-600 text-sm font-medium flex items-center gap-x-4'>
                            <Link to='/dashboard/sips'>
                              View SIPs
                              <ArrowOutward
                                sx={{
                                  fontSize: '18px',
                                  marginBottom: '2px',
                                }}
                              />
                            </Link>
                            <Link to='/dashboard/portfolio'>
                              My Portfolio
                              <ArrowOutward
                                sx={{
                                  fontSize: '18px',
                                  marginBottom: '2px',
                                }}
                              />
                            </Link>
                            <Link to='/dashboard/sips'>
                              Order History
                              <ArrowOutward
                                sx={{
                                  fontSize: '18px',
                                  marginBottom: '2px',
                                }}
                              />
                            </Link>
                          </div> */}

                          <span
                            onClick={() => setExpanded(!expanded)}
                            className={`cursor-pointer text-sm flex items-center gap-x-1 text-black-400 ${
                              expanded && 'mb-2'
                            }`}
                          >
                            View more
                            {expanded ? (
                              <ChevronUp className='h-4 w-4' />
                            ) : (
                              <ChevronDown className='h-4 w-4' />
                            )}
                          </span>
                        </div>

                        <div
                          className={`text-sm flex-col justify-between items-start w-full overflow-hidden transition-all duration-300 ease-in-out ${
                            expanded ? 'max-h-max opacity-100 pt-2 border-t' : 'max-h-0 opacity-0'
                          }`}
                        >
                          {mfHolding?.contents?.mainData?.length > 0 && (
                            <div className='flex justify-between w-full font-semibold'>
                              <p className='text-black-500 flex-1'>Mutual Funds</p>

                              <p className='flex-1 text-right'>
                                &#8377;{' '}
                                {toCurrency(
                                  mfHolding?.contents?.mainData?.reduce(
                                    (sum, item) => sum + Number(item.netInvestedAmt),
                                    0,
                                  ) || 0,
                                )}{' '}
                              </p>

                              <p className='flex-1 text-right'>
                                &#8377;{' '}
                                {toCurrency(
                                  mfHolding?.contents?.mainData?.reduce(
                                    (sum, item) => sum + Number(item.holding),
                                    0,
                                  ) || 0,
                                )}{' '}
                              </p>

                              <div className='flex-1 flex gap-x-1 items-center justify-end'>
                                <h1 className='font-semibold leading-6 text-sm text-center'>
                                  &#8377;{' '}
                                  {toCurrency(
                                    (mfHolding?.contents?.mainData?.reduce(
                                      (sum, item) => sum + item.holding,
                                      0,
                                    ) || 0) -
                                      (mfHolding?.contents?.mainData?.reduce(
                                        (sum, item) => sum + item.netInvestedAmt,
                                        0,
                                      ) || 0),
                                  )}
                                </h1>
                                <div className='flex gap-x-2 justify-center items-center'>
                                  <span
                                    className={`font-semibold ${
                                      calculateParcentage(
                                        mfHolding?.contents?.mainData?.reduce(
                                          (sum, item) => sum + item.netInvestedAmt,
                                          0,
                                        ) || 0,
                                        mfHolding?.contents?.mainData?.reduce(
                                          (sum, item) => sum + item.holding,
                                          0,
                                        ) || 0,
                                      ) > 0
                                        ? 'text-secondary-600'
                                        : 'text-error-600'
                                    }`}
                                  >
                                    (
                                    {toCurrency(
                                      calculateParcentage(
                                        mfHolding?.contents?.mainData?.reduce(
                                          (sum, item) => sum + item.netInvestedAmt,
                                          0,
                                        ) || 0,
                                        mfHolding?.contents?.mainData?.reduce(
                                          (sum, item) => sum + item.holding,
                                          0,
                                        ) || 0,
                                      ),
                                    )}
                                    ) %
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className='flex justify-between w-full font-semibold mt-1'>
                            <p className='text-black-500 flex-1'>Stocks & ETFs</p>

                            <p className='flex-1 text-right'>
                              &#8377; {toCurrency(accountDetails.totalInvestment)}
                            </p>

                            <p className='flex-1 text-right'>
                              &#8377; {toCurrency(accountDetails.currentInvestment)}
                            </p>

                            <div className='flex-1 flex gap-x-1 items-center justify-end'>
                              <h1 className='font-semibold leading-6 text-sm text-center'>
                                &#8377;{' '}
                                {toCurrency(
                                  accountDetails.currentInvestment - accountDetails.totalInvestment,
                                )}
                              </h1>
                              <div className='flex gap-x-2 justify-center items-center'>
                                <span
                                  className={`font-semibold ${
                                    calculateParcentage(
                                      accountDetails.totalInvestment,
                                      accountDetails.currentInvestment,
                                    ) > 0
                                      ? 'text-secondary-600'
                                      : 'text-error-600'
                                  }`}
                                >
                                  (
                                  {toCurrency(
                                    calculateParcentage(
                                      accountDetails.totalInvestment,
                                      accountDetails.currentInvestment,
                                    ),
                                  )}
                                  ) %
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className='flex justify-between text-black-400'>
                            <p className='flex-1 text-right'></p>
                            <p className='flex-1 text-right text-xs'>Invested</p>
                            <p className='flex-1 text-right text-xs'>Current</p>
                            <p className='flex-1 text-right text-xs'>Returns</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {(etfState === 'loading' || mfHolding.state === 'loading') && (
                    <>
                      <div className='rounded-3xl border p-8 flex-[2] mx-auto hidden lg:block'>
                        <div className='flex justify-between items-center mb-8'>
                          <h2 className='text-primary-300 font-medium text-sm mb-3'>AT A GLANCE</h2>
                          <div className='flex items-center gap-2'>
                            <Skeleton className='h-4 w-28' />
                            <Skeleton className='h-4 w-4 rounded-full' />
                          </div>
                        </div>

                        <div className='flex justify-between ga-x-8'>
                          {/* Current Value */}
                          <div className='space-y-2'>
                            <div className='flex items-center gap-2'>
                              <Skeleton className='h-8 w-40' />
                              <Skeleton className='h-6 w-6 rounded-full' />
                            </div>
                            <Skeleton className='h-5 w-32' />
                          </div>

                          {/* Invested Amount */}
                          <div className='space-y-2'>
                            <Skeleton className='h-8 w-32' />
                            <Skeleton className='h-5 w-40' />
                          </div>

                          {/* Returns */}
                          <div className='space-y-2'>
                            <div className='flex items-center gap-2'>
                              <Skeleton className='h-8 w-24' />
                              <Skeleton className='h-6 w-6 rounded-full' />
                            </div>
                            <Skeleton className='h-5 w-20' />
                          </div>
                        </div>
                      </div>

                      <div className='rounded-3xl border p-5 w-full mx-auto lg:hidden'>
                        {/* Main Value - Centered at top */}
                        <div className='flex justify-center mb-10'>
                          <Skeleton className='h-9 w-40' />
                        </div>

                        {/* Two column layout for bottom values */}
                        <div className='grid grid-cols-2 gap-4'>
                          {/* Invested Amount - Left */}
                          <div className='space-y-2'>
                            <Skeleton className='h-8 w-24' />
                            <Skeleton className='h-5 w-20' />
                          </div>

                          {/* Returns - Right */}
                          <div className='flex flex-col items-end space-y-2'>
                            <div className='flex items-center gap-2'>
                              <Skeleton className='h-8 w-20' />
                              <Skeleton className='h-6 w-6 rounded-full' />
                            </div>
                            <Skeleton className='h-5 w-16' />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}

          {mfbasketState === 'hasValue' && mfbasketState !== 'hasError' ? (
            <div
              className={`flex-1 ${
                (!isDematUser() ||
                  (isDematUser() &&
                    Object.keys(accountDetails).length === 0 &&
                    mfHolding?.contents?.mainData?.length === 0)) &&
                'flex md:flex-row flex-col gap-4'
              }`}
            >
              {(isDesktop ? topCardsDesktop : topCards).map((card) => (
                <div
                  className={`${!isDematUser() ? 'flex-1' : 'lg:h-[191px]'} ${
                    isDematUser() &&
                    Object.keys(accountDetails).length === 0 &&
                    mfHolding?.contents?.mainData?.length === 0 &&
                    'flex-[2]'
                  }`}
                  key={card.id}
                >
                  <Card
                    topCard
                    gradient={card.id === 1 || card.id === 2 || card.id === 3}
                    key={card.id}
                    {...card}
                    onClick={() => redirect(card.id)}
                  />
                </div>
              ))}
            </div>
          ) : (
            <>
              {mfbasketState === 'loading' && (
                <div className='rounded-xl overflow-hidden border bg-gradient-to-r from-blue-50 via-purple-50 to-pink-50 p-6'>
                  <Skeleton className='h-8 w-40 mb-4 z-10 bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100' />
                  <Skeleton className='h-5 w-64 z-10 bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100' />
                </div>
              )}
            </>
          )}
        </div>

        <div className='lg:flex gap-4 lg:max-w-[1128px]'>
          {/* In mobile view: 2024 Returns and Goal Planning take full width */}
          {!isDesktop && (
            <>
              {/* 2024 Returns card - full width on mobile */}
              {bottomCards.find((card) => card.id === 4) && (
                <div className='w-full mb-4'>
                  <Card
                    bottomCard
                    key={4}
                    {...bottomCards.find((card) => card.id === 4)}
                    onClick={() => redirect(4)}
                  />
                </div>
              )}

              {/* Goal Planning card - full width on mobile */}
              {bottomCards.find((card) => card.id === 5) && (
                <div className='w-full mb-4'>
                  <Card
                    bottomCard
                    key={5}
                    {...bottomCards.find((card) => card.id === 5)}
                    onClick={() => redirect(5)}
                  />
                </div>
              )}

              {/* Insurance and Tax Planning in a 2x2 grid */}
              <div className='grid grid-cols-2 gap-4'>
                {bottomCards
                  .filter((card) => card.id === 6 || card.id === 7)
                  .map((card) => (
                    <div key={card.id}>
                      <Card bottomCard {...card} onClick={() => redirect(card.id)} />
                    </div>
                  ))}
              </div>
            </>
          )}

          {/* Desktop view: all cards in a single row */}
          {isDesktop && (
            <>
              {bottomCardsDesktop.map((card) => (
                <div className='flex-1 h-full' key={card.id}>
                  <Card bottomCard key={card.id} {...card} onClick={() => redirect(card.id)} />
                </div>
              ))}
            </>
          )}
        </div>
        {carouselCards.state === 'hasValue' && carouselCards.state !== 'hasError' ? (
          <div className='lg:max-w-[1128px] mt-10'>
            <h1 className='font-semibold text-lg'>Understand your Money</h1>

            <div className='flex flex-col gap-y-5 mt-6'>
              <PlansCarousel newPage contentCarouselData={carouselCards.contents} />
            </div>
          </div>
        ) : (
          <>
            {/* Understand your money section */}
            <div className='mb-6'>
              <Skeleton className='h-8 w-30 lg:w-64 mb-6' />
              <div className='flex gap-x-6 justify-between overflow-x-hidden'>
                <Skeleton className='h-48 w-full rounded-xl' />
                <Skeleton className='hidden md:block h-48 w-full rounded-xl' />
                <Skeleton className='hidden lg:block h-48 w-full rounded-xl' />
              </div>
            </div>
          </>
        )}
      </div>

      {isUserFirstTime && showVideo && (
        <IntroVideo
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          handleVideoEnd={handleVideoEnd}
        />
      )}
    </>
  )
}

export default Homepage
