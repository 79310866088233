import { useModal } from '../../hooks/use-modal-store'
import { post } from '../../http/restservice'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { messageModalState } from '../../store/Feedback'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../Components/ui/card'
import { usePaymentProcessingTimer } from '../../hooks/useProcessingTimer'
import { Progress } from '../../Components/ui/progress'

const MFBasketBankPayment = () => {
  const payData = JSON.parse(localStorage.getItem('basketPaymentStatusData')) || null
  const location = useLocation()
  let responseString = location?.state?.response ? location?.state?.response : payData.response
  const { onOpen } = useModal()
  const setMessageModalState = useSetRecoilState(messageModalState)
  const [timeLeft, setTimeLeft] = useState(180) // 5 minutes = 300 seconds
  const { progress } = usePaymentProcessingTimer(180) // 2 minutes
  // const baskets = useRecoilValueLoadable(MFTimeBasedBaskets)

  const parsedData = useMemo(() => {
    try {
      return JSON.parse(sessionStorage.getItem('oneTimeBasketOrders')) || {}
    } catch {
      return {}
    }
  }, [])

  const paymentStatus = async () => {
    try {
      const response = await post('/mutualfundservice/api/bseStar/mfUpload/paymentStatus', {
        clientCode: payData?.clientCode,
        orderNo: payData?.orderNo,
        isBasketOrder: true,
      })

      if (response.isError) {
        setMessageModalState({
          message: response?.response?.message,
          severity: 'error',
        })
      }

      if (
        timeLeft <= 0 &&
        response?.status === 'SUCCESS' &&
        response?.data === 'AWAITING FOR RESPONSE FROM BILLDESK ( DIRECT )'
      ) {
        onOpen('openActionPlanPopup', {
          title: 'Order Placed & Payment Status Pending',
          message:
            'Your order has been successfully placed and is awaiting payment status confirmation. It will be updated within 1-2 working days. Thank you for your patience!',
          severity: 'info',
          redirectTo: '/dashboard/mutual-funds/orders',
        })

        return
      }

      if (
        !response.isError &&
        response?.status === 'SUCCESS' &&
        response?.data === 'APPROVED ( DIRECT )'
      ) {
        // const filteredConstituents = orders.map((fund) => {
        //   const statusItem = baskets?.contents?.data?.find((el) => {
        //     return fund.schemeCode.toLowerCase() == el.bseSchemeCode.toLowerCase()
        //   })
        //   // Check if statusItem is found to avoid undefined errors
        //   if (statusItem) {
        //     return {
        //       ...fund, // Include the fund data
        //       ...statusItem,
        //     }
        //   }
        // })

        onOpen('openActionPlanPopup', {
          title: '',
          message:
            'Payment Successful! 🎉 Your Internet-banking transaction has been completed securely. Thank you for your payment!',
          severity: 'success',
          redirectTo: '/dashboard/mutual-funds/orders',
        })
        // onOpen('openActionPlanPopup', {
        //   severity: 'success',
        //   // baskets: {
        //   //   basket1: filteredConstituents,
        //   // },
        // })
      }
      // if (!response.isError && response.status === 'SUCCESS') {
      //   const filteredConstituents = orders.map((fund) => {
      //     const statusItem = baskets?.contents?.data?.find((el) => {
      //       return fund.schemeCode.toLowerCase() == el.bseSchemeCode.toLowerCase()
      //     })
      //     // Check if statusItem is found to avoid undefined errors
      //     if (statusItem) {
      //       return {
      //         ...fund, // Include the fund data
      //         ...statusItem,
      //       }
      //     }
      //   })

      //   // onOpen('openPopup', {
      //   //   title: 'Payment Successful',
      //   //   message:
      //   //     'Payment Successful! 🎉 Your Internet-banking transaction has been completed securely. Thank you for your payment!',
      //   //   severity: 'success',
      //   // })

      //   onOpen('openBasketPopup', {
      //     severity: 'success',
      //     baskets: {
      //       basket1: filteredConstituents,
      //     },
      //   })
      // }
    } catch (error) {
      console.log('🚀 ~ paymentStatus ~ error:', error)
    }
  }

  useEffect(() => {
    if (!location?.state) {
      if (timeLeft % 10 === 0 || timeLeft === 0) {
        paymentStatus(parsedData)
      }

      if (timeLeft > 0) {
        const timerId = setTimeout(() => {
          setTimeLeft((prevTime) => prevTime - 1)
        }, 1000)

        // Cleanup timeout on unmount or timeLeft change
        return () => clearTimeout(timerId)
      }
    }
  }, [timeLeft, JSON.stringify(payData)])

  useEffect(() => {
    if (location?.state) {
      // Create a container for the form and inject the HTML
      const container = document.getElementById('bankPage')

      setTimeout(() => {
        // Automatically submit the form
        const form = container.querySelector('form[name="Bankfrm"]')
        if (form) {
          // form.target = '_blank'
          form.submit()
        }
      }, 3000)

      // Clean up the container after submission
      return () => {
        document.body.removeChild(container)
      }
    }
  }, [responseString])

  return (
    <div className='flex justify-center items-center h-screen bg-white'>
      {!location?.state ? (
        <div>
          <Card className='w-full max-w-md mx-auto font-DMSans rounded-3xl border-none shadow-none'>
            <CardHeader>
              <CardTitle className='text-2xl font-bold text-center'>
                Processing Your Payment
              </CardTitle>
              <CardDescription className='text-center'>
                Please wait while we process your payment. This may take 1-2 minutes.
              </CardDescription>
            </CardHeader>
            <CardContent className='space-y-4'>
              <div className='relative pt-1'>
                <Progress value={progress} className='h-2 bg-primary-100' />
              </div>
              {/* <p className='text-center text-sm text-black-500'>
                Time remaining: {Math.floor(time / 60)}:{(time % 60).toString().padStart(2, '0')}
              </p> */}
              <div className='text-center text-sm font-medium text-black-400 animate-pulse'>
                Please do not close the app or navigate away from this page.
              </div>
            </CardContent>
          </Card>
        </div>
      ) : (
        <div id='bankPage' dangerouslySetInnerHTML={{ __html: responseString }} />
      )}
    </div>
  )
}

export default MFBasketBankPayment
