import { selector } from 'recoil'
import { get } from '../http/restservice'

const HomeCarouselCards = selector({
  key: 'HomeCarouselCards',
  get: async () => {
    const response = await get('/customerservice/api/carouselContent', null, {})
    if (response.isError) {
      return []
    }
    return response
  },
})

export { HomeCarouselCards }
