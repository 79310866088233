import { useEffect, useState } from 'react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../Components/ui/select'
import { Input } from '../Components/ui/input'
import { Label } from '../Components/ui/label'
import { Button } from '../Components/ui/button'
import { X } from 'lucide-react'
import { post } from '../http/restservice'
import { useLocation, useNavigate } from 'react-router-dom'

const UserSource = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [selectedSource, setSelectedSource] = useState('')
  const [otherSource, setOtherSource] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  useEffect(() => {
    if (location) {
      setPhoneNumber(
        typeof location.state?.phoneNumber === 'number'
          ? location.state?.phoneNumber.toString()
          : location.state?.phoneNumber,
      )
    }
  }, [])

  const sources = [
    'YouTube',
    'Instagram',
    'Facebook',
    'Google',
    'Friends & Family',
    'Blog',
    'Ads (Instagram, YouTube, Search...)',
    'Corporate Event',
    'Financial Advisor',
    'Others',
  ]

  const handleSourceChange = (value) => {
    setSelectedSource(value)
    if (value !== 'Others') {
      setOtherSource('')
    }
  }

  const handleCloseOthers = () => {
    setSelectedSource('')
    setOtherSource('')
  }

  const handleSubmit = async () => {
    try {
      const source = selectedSource !== '' ? selectedSource : otherSource
      const response = await post('/customerservice/api/customer/customerSource', {
        source,
        phoneNumber: `+91${phoneNumber}`,
      })
      response.includes('successfully') &&
        navigate('/dashboard', {
          state: {
            firstTimeUser: true,
            hasSeenVideo: false,
          },
        })
    } catch (error) {
      console.log('🚀 ~ handleSubmit ~ error:', error)
    }
  }

  return (
    <div className=' w-full max-w-md space-y-8 lg:border lg:bg-white lg:rounded-3xl lg:min-w-[432px] lg:mx-auto lg:py-12 lg:px-[72px] lg:relative flex flex-col justify-between'>
      <div className='flex flex-col space-y-4'>
        <Label className='font-DMSans text-lg' htmlFor='source'>
          Where did you hear about us?
        </Label>
        {selectedSource !== 'Others' ? (
          <Select onValueChange={handleSourceChange} value={selectedSource}>
            <SelectTrigger className='border' id='source'>
              <SelectValue placeholder='Select a source' />
            </SelectTrigger>
            <SelectContent className='relative z-[99999999]'>
              {sources.map((source) => (
                <SelectItem key={source} value={source}>
                  {source}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        ) : (
          <div className='relative'>
            <Input
              type='text'
              id='otherSource'
              placeholder='Please specify'
              value={otherSource}
              onChange={(e) => setOtherSource(e.target.value)}
              className='pr-10'
            />
            <Button
              type='button'
              variant='ghost'
              size='icon'
              className='absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent'
              onClick={handleCloseOthers}
              aria-label='Close other input'
            >
              <X className='h-4 w-4' />
            </Button>
          </div>
        )}
      </div>

      <div className='flex justify-between items-center'>
        <div
          className='text-black-200 hover:text-black-10 cursor-pointer'
          onClick={() => navigate('/newUser')}
        >
          Skip
        </div>
        <Button className='bg-primary-500 hover:bg-primary-400 text-white' onClick={handleSubmit}>
          Continue
        </Button>
      </div>

      {selectedSource && (
        <p className='text-sm text-muted-foreground'>
          You selected: {selectedSource === 'Others' ? otherSource || 'Others' : selectedSource}
        </p>
      )}
    </div>
  )
}

export default UserSource
