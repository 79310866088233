/* eslint-disable */

import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import { toCurrency } from '../../utils/CurrencyUtil'
import { Button } from '../../Components/ui/button'
import { BankDetails } from '../../store/AccountDetailsStore'
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValueLoadable } from 'recoil'
import env from '../../env/env'
import { AccountBalance } from '@mui/icons-material'
import placeholder from '../../assets/placeholder.svg'
import { Popover, PopoverContent, PopoverTrigger } from '../../Components/ui/popover'
import { CalendarIcon, Pen } from 'lucide-react'
import { addMonth, addNumberSuffix, capitalizeWords, sanitizeName } from '../../utils/Util'
import refresh from '../../assets/SVGS/refresh.svg'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '../../Components/ui/sheet'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../Components/ui/dialog'
import { Card } from '../../Components/ui/card'
import { userDetailsState } from '../LoginState'
import { post } from '../../http/restservice'
import useMediaQuery from '../../hooks/useMediaQuery'
import { useModal } from '../../hooks/use-modal-store'
import { MFUserBaskets } from '../../store/MFBasketsStore'
import { useErrorPopup } from '../../hooks/useErrorPopup'

function SIPConfirmation({
  open,
  setOpen,
  plans,
  sipDate,
  flowStep,
  setFlowStep,
  sipPlans,
  oneTimePlans,
  onRequestComplete,
}) {
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const [src, setSrc] = useState('')
  const { contents: bankDetails } = useRecoilValueLoadable(BankDetails)
  let ifscCode = bankDetails?.ifscCode
  const extractedBranch = ifscCode?.match(/^[A-Za-z]{4}/)[0]?.toLowerCase()
  const iconSrc = `${env.BANK_LOGOS}/${extractedBranch}/symbol.svg`
  const userData = JSON.parse(localStorage.getItem('userData'))
  const [upiId, setUpiId] = useState(userData?.upiId || '')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [userDetails, setUserDetails] = useRecoilState(userDetailsState)
  const clientCode = userDetails ? userDetails?.uid?.split('-')[0] : userData?.uid?.split('-')[0]
  const navigate = useNavigate()
  const { onOpen } = useModal()
  const { showError } = useErrorPopup()

  const [expandedPlans, setExpandedPlans] = useState({})

  const toggleShowFunds = (planId) => {
    setExpandedPlans((prev) => ({
      ...prev,
      [planId]: !prev[planId],
    }))
  }

  useEffect(() => {
    setSrc(iconSrc)
  }, [iconSrc])

  const calculateParcentageAmount = (parcentage, totalAmount) => {
    const result = totalAmount * (parcentage / 100)
    return Math.ceil(result)
  }

  const handleSipOneTime = () => {
    if (flowStep === 0) {
      handleXSips()
    }

    if (flowStep === 1) {
      placeOneTimeOrder()
    }
  }

  const handleXSips = async () => {
    try {
      setLoading(true)

      const sipSum =
        sipPlans
          ?.filter(
            (plan) =>
              plan.sipStatus === 'PENDING' &&
              plan.sipInvestmentAmount &&
              plan.sipInvestmentBreakdown?.length,
          )
          ?.reduce((acc, plan) => acc + plan.sipInvestmentAmount, 0) || 0

      const payload = {
        clientCode: clientCode.toUpperCase(),
        amount: sipSum.toString(),
        sipStartDate: sipDate,
        mandateType: 'UNIVERSAL',
      }

      const response = await post(
        'mutualfundservice/api/bseStar/mfUpload/mandateRegistration',
        payload,
      )

      setLoading(false)

      if (response?.isError) {
        setError(
          'Mandate registration failed or mandate details are currently unavailable. Please try again later or contact support.',
        )

        return
      }

      const sips = sipPlans
        ?.filter(
          (plan) =>
            plan.sipStatus === 'PENDING' &&
            plan.sipInvestmentAmount &&
            plan.sipInvestmentBreakdown?.length,
        )
        .map((plan) => ({
          sipData: plan?.sipInvestmentBreakdown?.map((item) => ({
            schemeCode: item.bseSchemeCode,
            installmentAmount: calculateParcentageAmount(item.weightage, plan.sipInvestmentAmount),
          })),
          clientCode: clientCode?.toUpperCase(),
          startDate: sipDate,
          mandateId: response?.mandateId,
          customerBasketInvestmentId: plan.customerBasketInvestmentId,
        }))

      if (
        (response?.mandateStatus === 'NEW' || response?.mandateStatus === null) &&
        response?.status === 'SUCCESS'
      ) {
        sessionStorage.setItem(
          'actionPlanSipAndOneTime',
          JSON.stringify({
            sip: sips,
          }),
        )

        navigate('/dashboard/actionplan/mandate', {
          state: {
            mandateId: response?.mandateId,
            clientCode: clientCode.toUpperCase(),
            sipDate: sipDate,
          },
        })
      }

      if (
        response?.status === 'SUCCESS' &&
        response?.mandateStatus !== 'NEW' &&
        response?.mandateStatus !== null &&
        response?.alreadyExists
      ) {
        const handleSipRegistration = sips.length && (await handleXSipRegistration(sips))

        onRequestComplete()

        if (sips.length && handleSipRegistration.status === 'SUCCESS') {
          setOpen(false)
          onOpen('openActionPlanPopup', {
            title: 'Investment Successful',
            message:
              'Your SIP investment has been successfully processed through your registered mandate. It will take 2-3 working days to clear. Stay invested and watch your wealth grow! 🚀 ',
            severity: 'sipsuccess',
            redirectTo: 1,
          })
        }
      }
    } catch (error) {
      setLoading(false)
      console.log('🚀 ~ placeSIPOrder ~ error:', error)
    }
  }

  const handleXSipRegistration = async (payload) => {
    try {
      const xSIPRegistration = await post(
        'mutualfundservice/api/illusion/multiBasketSipRegistrationOrder',
        payload,
      )

      if (xSIPRegistration?.isError) {
        showError(
          'SIPs Registration Failed',
          'We encountered an issue while registering your SIP. Please try again or contact support for assistance.',
          () => {},
        )
        return
      }

      if (xSIPRegistration.status === 'SUCCESS') {
        return xSIPRegistration
      }
    } catch (error) {
      console.log('Error registering XSIP:', error)
    }
  }

  const placeOneTimeOrder = async (payload) => {
    try {
      setLoading(true)

      const payload = oneTimePlans
        ?.filter((plan) => plan.oneTimeInvestmentAmount > 0)
        ?.map((plan) => ({
          orderType: 'PURCHASE',
          orderData: plan?.oneTimeInvestmentBreakdown?.map((fund) => ({
            schemeCode: fund.bseSchemeCode,
            orderVal: fund.investmentAmount,
          })),
          clientCode: clientCode.toUpperCase(),
          customerBasketInvestmentId: plan.customerBasketInvestmentId,
        }))

      const response = await post('mutualfundservice/api/basket/multiBasketOneTimeOrder', payload)

      setLoading(false)

      if (response.isError) {
        setError('Unable to process your orders at this time. Please try again')
      }

      // const orderNumberMatch = response.data.match(/ORDER NO: (\d+)/)

      // const orderNumber = orderNumberMatch ? orderNumberMatch[1] : null

      if (response.status === 'SUCCESS') {
        const failures = response.basketResponses
          .map((response) => {
            const failedOrders = response.orders.filter((order) => order.status === 'FAILURE')
            return failedOrders.length > 0 ? { ...response, orders: failedOrders } : null
          })
          ?.filter((response) => response !== null)

        if (failures.length) {
          setError('Unable to process your orders at this time. Please try again')
          return
        }

        // console.log(oneTimePlans)
        const basketIds = payload.map((basket) => basket.customerBasketInvestmentId)

        const orders =
          response?.basketResponses?.length > 0
            ? response?.basketResponses?.flatMap((basket) => basket.orders)
            : []

        sessionStorage.setItem('oneTimeActionPlanOrders', JSON.stringify(orders))

        const lumpsumAmount = oneTimePlans?.reduce(
          (sum, investment) => sum + (investment.oneTimeInvestmentAmount || 0),
          0,
        )

        const paymentPayload = {
          clientCode: clientCode.toUpperCase(),
          orders: orders,
          lumpsumAmount: lumpsumAmount,
          basketIds: basketIds,
        }

        navigate('/dashboard/actionplan/one-time-payment', {
          state: {
            paymentPayload,
          },
        })
      } else {
        setMessageModalState({
          message: response.data.responsestring,
          linkMessage: response.data.responsestring,
          severity: 'error',
        })
      }
    } catch (error) {
      console.log('🚀 ~ placeOneTimeOrder ~ error:', error)
    }
  }

  const SIPContent = () => (
    <div className='flex flex-col space-y-4 w-full font-DMSans'>
      <div>
        <h2 className='text-2xl font-semibold leading-8 text-black-500'>
          {flowStep === 0 ? 'Confirm Mandate setup' : 'Place your orders'}
        </h2>
        <p className='text-black-400 leading-6 mt-1'>
          {flowStep === 0
            ? 'We will use your mandate to set up your new SIP.'
            : 'Will take you to payment processing page.'}
        </p>
      </div>

      <div className='max-h-[400px] overflow-y-scroll hide-scrollbar space-y-4'>
        {sipPlans.length > 0 &&
          flowStep === 0 &&
          sipPlans.map((plan) => (
            <Card className='p-4 rounded-xl'>
              <div className='space-y-4'>
                <div className='flex items-center gap-3 pb-4 border-b'>
                  <div className='rounded-lg border p-2 w-12 h-12 flex items-center justify-center'>
                    <img src={`${env.SVGS}/basket.svg`} alt='basket logo' className='w-full' />
                  </div>
                  <div className='flex-1'>
                    <div className='flex justify-between'>
                      <span className='font-medium'>{plan?.customerBasketName}</span>
                      <span className='font-semibold text-nowrap'>
                        ₹ {plan?.sipInvestmentAmount}
                      </span>
                    </div>
                    {/* <p className='text-sm text-black-500'>Something that is going to be here</p> */}
                  </div>
                </div>

                {expandedPlans[plan.customerBasketInvestmentId] &&
                  plan?.sipInvestmentBreakdown.map((fund) => (
                    <div className='flex items-center gap-3 pb-3 pl-4' key={fund.schemeCode}>
                      <div className='p-2 w-12 h-12 flex items-center justify-center'>
                        <img
                          src={`${env.MF_LOGOS_URL}/${fund?.amcCode}.png`}
                          alt={fund?.schemeName}
                          className='w-full rounded-lg'
                        />
                      </div>
                      <div className='flex-1'>
                        <div className='flex justify-between'>
                          {console.log(fund)}
                          <span className='font-medium'>{sanitizeName(fund)}</span>
                          <span className='font-semibold text-nowrap'>
                            ₹ {fund?.investmentAmount}
                          </span>
                        </div>
                        {/* <p className='text-sm text-black-500'>Something that is going to be here</p> */}
                      </div>
                    </div>
                  ))}

                <button
                  onClick={() => toggleShowFunds(plan.customerBasketInvestmentId)}
                  className='text-primary-500 font-medium text-sm flex items-center'
                >
                  {expandedPlans[plan.customerBasketInvestmentId] ? (
                    <>
                      Show less <ChevronUp className='ml-1 h-4 w-4' />
                    </>
                  ) : (
                    <>
                      Show more <ChevronDown className='ml-1 h-4 w-4' />
                    </>
                  )}
                </button>
              </div>
            </Card>
          ))}

        {oneTimePlans.length > 0 &&
          flowStep === 1 &&
          oneTimePlans.map((plan) => (
            <Card className='p-4 rounded-xl'>
              <div className='space-y-4'>
                <div className='flex items-center gap-3 pb-4 border-b'>
                  <div className='rounded-lg border p-2 w-12 h-12 flex items-center justify-center'>
                    <img src={`${env.SVGS}/basket.svg`} alt='basket logo' className='w-full' />
                  </div>
                  <div className='flex-1'>
                    <div className='flex justify-between'>
                      <span className='font-medium'>{plan?.customerBasketName}</span>
                      <span className='font-semibold text-nowrap'>
                        ₹ {plan?.oneTimeInvestmentAmount}
                      </span>
                    </div>
                    {/* <p className='text-sm text-black-500'>Something that is going to be here</p> */}
                  </div>
                </div>

                {expandedPlans[plan.customerBasketInvestmentId] &&
                  plan?.oneTimeInvestmentBreakdown.map((fund) => (
                    <div className='flex items-center gap-3 pb-3 pl-4' key={fund.schemeCode}>
                      <div className='p-2 w-12 h-12 flex items-center justify-center'>
                        <img
                          src={`${env.MF_LOGOS_URL}/${fund?.amcCode}.png`}
                          alt={fund?.schemeName}
                          className='w-full rounded-lg'
                        />
                      </div>
                      <div className='flex-1'>
                        <div className='flex justify-between'>
                          {console.log(fund)}
                          <span className='font-medium'>{sanitizeName(fund)}</span>
                          <span className='font-semibold text-nowrap'>
                            ₹ {fund?.investmentAmount}
                          </span>
                        </div>
                        {/* <p className='text-sm text-black-500'>Something that is going to be here</p> */}
                      </div>
                    </div>
                  ))}

                <button
                  onClick={() => toggleShowFunds(plan.customerBasketInvestmentId)}
                  className='text-primary-500 font-medium text-sm flex items-center'
                >
                  {expandedPlans[plan.customerBasketInvestmentId] ? (
                    <>
                      Show less <ChevronUp className='ml-1 h-4 w-4' />
                    </>
                  ) : (
                    <>
                      Show more <ChevronDown className='ml-1 h-4 w-4' />
                    </>
                  )}
                </button>
              </div>
            </Card>
          ))}
      </div>

      {flowStep === 0 && (
        <div>
          <h3 className='text-nowrap flex gap-x-1 items-center text-black-400 mb-2'>
            Pay with just <span className='text-primary-700 font-semibold'>One Tap</span>
          </h3>
          <Card
            className={`cursor-pointer p-4 rounded-xl flex items-center gap-3 font-DMSans ${
              flowStep === 0 && 'bg-[#F4F7FE]'
            }`}
            onClick={() => {
              setFlowStep(0), setError(null)
            }}
          >
            <div className='relative'>
              <div className='flex-shrink-0'>
                {src ? (
                  <div className='h-11 w-11 rounded-full border border-[#D3DBEC] flex justify-center items-center'>
                    <img
                      src={src}
                      alt='bank logos'
                      onError={() => {
                        setSrc(null)
                      }}
                      height={24}
                      width={24}
                    />
                  </div>
                ) : (
                  <AccountBalance className='text-primary-200' />
                )}
              </div>
              <div className='absolute -bottom-1 -right-1 w-6 h-6 flex items-center justify-center'>
                <img
                  src={refresh}
                  alt='bank logos'
                  onError={() => {
                    setSrc(null)
                  }}
                  height={24}
                  width={24}
                />
              </div>
            </div>
            <div className='flex-1'>
              <div className='font-medium'>
                Mandate <span className='text-xs text-black-500'>(Takes 3 to 4 days to clear)</span>
              </div>
              <div className='text-sm text-black-400'>
                {' '}
                {capitalizeWords(bankDetails && bankDetails?.bankName)} A/c ending in{' '}
                <strong>
                  {bankDetails && bankDetails?.accountNo && bankDetails?.accountNo.slice(-4)}
                </strong>
              </div>
            </div>
          </Card>
        </div>
      )}

      {/* <div>
        <h3 className='text-nowrap flex items-center gap-x-1 text-black-400 mb-2'>
          Other payment method <span className='text-sm'>(One time)</span>
        </h3>
        <div className='space-y-3'>
          <Card
            className={`cursor-pointer p-4 rounded-xl flex items-center gap-3 font-DMSans ${
              flowStep === 1 && 'bg-[#F4F7FE]'
            }`}
            onClick={() => {
              setFlowStep(1), setError(null)
            }}
          >
            <div className='flex-shrink-0'>
              {src ? (
                <div className='h-11 w-11 rounded-full border border-[#D3DBEC] flex justify-center items-center'>
                  <img
                    src={src}
                    alt='bank logos'
                    onError={() => {
                      setSrc(null)
                    }}
                    height={24}
                    width={24}
                  />
                </div>
              ) : (
                <AccountBalance className='text-primary-200' />
              )}
            </div>

            <div className='flex-1'>
              <div className='font-medium'>Net banking</div>
              <div className='text-sm text-black-400'>
                {' '}
                {capitalizeWords(bankDetails && bankDetails?.bankName)} A/c ending in{' '}
                <strong>
                  {bankDetails && bankDetails?.accountNo && bankDetails?.accountNo.slice(-4)}
                </strong>
              </div>
            </div>
          </Card>

          <Card
            className={`cursor-pointer p-4 rounded-xl flex items-center gap-3 font-DMSans ${
              flowStep === 2 && 'bg-[#F4F7FE]'
            }`}
            onClick={() => setFlowStep(2)}
          >
            <div className='flex-shrink-0'>
              {src ? (
                <div className='h-11 w-11 rounded-full border border-[#D3DBEC] flex justify-center items-center'>
                  <img
                    src={upi}
                    alt='bank logos'
                    onError={() => {
                      setSrc(null)
                    }}
                    height={24}
                    width={24}
                  />
                </div>
              ) : (
                <AccountBalance className='text-primary-200' />
              )}
            </div>

            <div className='flex-1'>
              <div className='font-medium'>
                UPI <span className='text-sm text-black-400'>(Max. ₹ 2 Lakhs)</span>
              </div>
              <div className='text-sm text-black-400'>{upiId}</div>
            </div>
          </Card>
        </div>
      </div> */}

      {error && <div className='text-sm text-error-500 text-center'>{error}</div>}

      <Button
        className='w-full py-6 text-sm rounded-xl bg-primary-500 hover:bg-primary-600'
        onClick={handleSipOneTime}
        loading={loading}
      >
        {flowStep === 0 ? 'Proceed' : 'Place order'}
      </Button>
    </div>
  )

  return (
    <>
      {isDesktop ? (
        <div className='p-4'>
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent
              className='sm:max-w-[600px] max-h-[90vh] overflow-y-auto hide-scrollbar'
              showclose={true}
            >
              <DialogHeader>
                <DialogTitle className='sr-only'>SIP Confirmation</DialogTitle>
              </DialogHeader>
              <SIPContent />
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        <div className='p-4'>
          <Sheet open={open} onOpenChange={() => setOpen()}>
            <SheetContent side='bottom' showClose={true} className='h-auto rounded-t-3xl'>
              <SheetHeader className='text-left'>
                <SheetTitle className='sr-only'>SIP Confirmation</SheetTitle>
              </SheetHeader>
              <SIPContent />
            </SheetContent>
          </Sheet>
        </div>
      )}
    </>
  )
}

const UserBankDetails = ({ day }) => {
  const [src, setSrc] = useState('')
  const { contents: bankDetails } = useRecoilValueLoadable(BankDetails)

  let ifscCode = bankDetails?.ifscCode
  const extractedBranch = ifscCode?.match(/^[A-Za-z]{4}/)[0]?.toLowerCase()
  const iconSrc = `${env.BANK_LOGOS}/${extractedBranch}/symbol.svg`

  useEffect(() => {
    setSrc(iconSrc)
  }, [iconSrc])

  return (
    <div className=''>
      <h1 className='font-DMSans font-normal text-sm lg:text-lg text-black-400'>Bank</h1>

      <div className='p-4 flex items-center gap-4 rounded-[16px] border border-[#D3DBEC] mt-2'>
        <div className='flex-shrink-0'>
          {src ? (
            <div className='h-[35.56px] w-[35.56px] rounded-full border border-[#D3DBEC] flex justify-center items-center'>
              <img
                src={src}
                alt='bank logos'
                onError={() => {
                  setSrc(null)
                }}
                height={24}
                width={24}
              />
            </div>
          ) : (
            <AccountBalance className='text-primary-200' />
          )}
        </div>
        <div className='flex flex-col'>
          <span className='text-[#2f3c5a] font-semibold'>
            {bankDetails && bankDetails?.accountHolderName}
          </span>
          <span className='text-[#2f3c5a] text-xs'>
            {bankDetails && bankDetails?.bankName} A/c ending in{' '}
            <strong>
              {bankDetails && bankDetails?.accountNo && bankDetails?.accountNo.slice(-4)}
            </strong>
          </span>
        </div>
      </div>
    </div>
  )
}

const PaymentConfirmation = () => {
  const location = useLocation()

  const { state, contents: investedPlans } = useRecoilValueLoadable(MFUserBaskets)

  const refreshPlans = useRecoilRefresher_UNSTABLE(MFUserBaskets)
  const storedData = localStorage.getItem('selectedActionPlans')
  const storedPlans = storedData ? JSON.parse(storedData) : []

  const [selectedPlans, setSelectedPlans] = useState(location?.state?.selectedPlans || storedPlans)
  console.log('🚀 ~ PaymentConfirmation ~ selectedPlans:', selectedPlans)

  const [flowStep, setFlowStep] = useState(0)
  const navigate = useNavigate()
  const [expandedPlans, setExpandedPlans] = useState({})
  const sipDates = [3, 5, 10, 15, 22, 25]
  const [sipDate, setSipDate] = useState('')
  const { onOpen } = useModal()

  const currentDate = new Date()
  const today = currentDate.getDate()
  const [selectedDay, setSelectedDay] = useState(5)
  const [open, setOpen] = useState(false)
  const isDesktop = useMediaQuery('(min-width: 425px)')
  // const [sipDate, setSipDate] = useState('')
  // const [nextSipDay, nextSipMonth, nextSipYear] = sipDate.split('/')
  // const today = new Date().getDate()

  // useEffect(() => {
  //   const res = addMonth(today, selectedDay)
  //   setSipDate(res)
  // }, [selectedDay, today])

  useEffect(() => {
    localStorage.setItem('selectedActionPlans', JSON.stringify(selectedPlans))
  }, [selectedPlans])

  const sipPlans = selectedPlans?.filter(
    (plan) => Number(plan.sipInvestmentAmount) > 0 && plan.sipStatus === 'PENDING',
  )

  const oneTimePlans = selectedPlans?.filter(
    (plan) => Number(plan.oneTimeInvestmentAmount) > 0 && plan.oneTimeStatus === 'PENDING',
  )

  const updatePlanStatuses = () => {
    setSelectedPlans((prevPlans) => {
      return prevPlans.map((plan) => {
        // Find matching investment plan by customerBasketInvestmentId
        const matchingInvestedPlan = investedPlans?.data?.find(
          (investedPlan) =>
            investedPlan.customerBasketInvestmentId === plan.customerBasketInvestmentId,
        )

        // If a match is found, update sipStatus and oneTimeStatus
        if (matchingInvestedPlan) {
          return {
            ...plan,
            sipStatus: matchingInvestedPlan.sipStatus,
            oneTimeStatus: matchingInvestedPlan.oneTimeStatus,
          }
        }

        // If no match, return the original plan
        return plan
      })
    })
  }

  const refreshInvestPlans = async () => {
    refreshPlans() // Force a refresh of the selector
  }

  useEffect(() => {
    updatePlanStatuses() // Ensure statuses update after setting investedPlans
  }, [location, investedPlans]) // Re-run when the fetch state changes

  useEffect(() => {
    if (sipPlans.length) {
      setFlowStep(0)
      return
    }

    if (oneTimePlans.length) {
      setFlowStep(1)
      return
    }
  }, [sipPlans, oneTimePlans])

  useEffect(() => {
    const res = addMonth(today, selectedDay)
    setSipDate(res)
  }, [selectedDay, today])

  const toggleExpand = (id) => {
    setExpandedPlans((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  const submitHandler = () => {
    setOpen(true)
  }

  const backHandler = () => {
    navigate(-1)
  }

  return (
    <div className='lg:max-w-[1128px] md:max-w-[760px] mx-5 md:mx-auto mt-10'>
      <span className='flex gap-1 items-center cursor-pointer' onClick={backHandler}>
        <ChevronLeftRoundedIcon sx={{ fontSize: '20px' }} />
        <span className='font-DMSans font-semibold text-base'>Back</span>
      </span>

      <div className='mt-6'>
        <h1 className='font-DMSans font-semibold text-lg md:text-xl text-black-500'>
          Confirm Payment
        </h1>
        {flowStep === 0 && (
          <p className='font-DMSans font-normal md:text-lg text-sm text-black-400'>
            We will use your mandate to set up your new SIP.
          </p>
        )}
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-3 overflow-y-scroll hide-scrollbar pb-[365px] lg:pb-[320px]'>
        {selectedPlans?.map((plan) => {
          return (
            <div
              key={plan.customerBasketInvestmentId}
              className='border border-[#D3DBEC] p-3 md:p-4 rounded-[12.72px]'
            >
              <div className='flex justify-between items-center'>
                <div className='flex gap-[10px]'>
                  <img src={placeholder} className='h-6 w-6 md:h-8 md:w-8' alt='plan' />
                  <h1 className='font-DMSans text-black-500 font-semibold text-base md:text-lg truncate'>
                    {isDesktop
                      ? plan.customerBasketName
                      : `${plan.customerBasketName.slice(0, 15)}...`}
                  </h1>
                </div>
                <p className='font-DMSans text-black-500 font-semibold text-base md:text-lg whitespace-nowrap'>
                  ₹ {toCurrency(plan.oneTimeInvestmentAmount + plan.sipInvestmentAmount)}
                </p>
              </div>

              <hr className='my-[10px]' />

              <div className='flex mt-2 gap-2'>
                <div className='flex-1'>
                  <div>
                    <p className='font-DMSans text-black-300 font-medium text-xs md:text-base mb-2'>
                      One-Time Investment
                    </p>
                    <div className='flex gap-2'>
                      <p className='font-DMSans text-black-500 font-semibold text-lg'>
                        ₹ {toCurrency(plan.oneTimeInvestmentAmount) || 0}
                      </p>
                    </div>
                  </div>
                  {plan.oneTimeInvestmentAmount > 0 && (
                    <p
                      className='mt-3 text-primary-700 font-DMSans font-medium text-xs cursor-pointer'
                      onClick={() => {
                        onOpen('oneTimeBreakdown', {
                          constituents: plan?.oneTimeInvestmentBreakdown,
                        })
                      }}
                    >
                      View Funds
                    </p>
                  )}
                </div>
                <div className='flex-1'>
                  <div>
                    <p className='font-DMSans text-black-300 font-medium text-xs md:text-base mb-2'>
                      SIP Investment
                    </p>
                    <div className='flex gap-2'>
                      <p className='font-DMSans text-black-500 font-semibold text-lg'>
                        ₹ {toCurrency(plan.sipInvestmentAmount) || 0}
                      </p>
                    </div>
                  </div>
                  {plan.sipInvestmentAmount > 0 && (
                    <p
                      className='mt-3 text-primary-700 font-DMSans font-medium text-xs cursor-pointer'
                      onClick={() => {
                        onOpen('sipBreakdown', { constituents: plan?.sipInvestmentBreakdown })
                      }}
                    >
                      View Funds
                    </p>
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>

      <div
        className={`fixed bottom-0 left-0 right-0 px-5 lg:max-w-[1128px] mx-auto md:px-3 bg-white mt-2`}
        style={{ boxShadow: '0px 4px 21.6px 0px #00000040', borderRadius: '24px 24px 0px 0px' }}
      >
        <div className='mx-auto'>
          {flowStep === 0 && (
            <div className='mt-4'>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={'outline'}
                    className='pl-3 font-normal border-none bg-primary-50 rounded-lg w-full flex justify-between mx-auto'
                  >
                    <span className='text-primary-500 flex items-center gap-x-2'>
                      <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                      {addNumberSuffix(+selectedDay)} of every month
                    </span>

                    <Pen className='h-4 w-4 text-primary-500' />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className='p-0 w-auto rounded-full' align='center' side='top'>
                  <div className='flex gap-x-2 p-2'>
                    {sipDates.map((day, index) => (
                      <Button
                        key={(day + index).toString()}
                        className={`h-8 w-8 rounded-full inline-block ${
                          selectedDay !== +day
                            ? 'bg-white border border-primary-50 text-black-400 hover:bg-primary-50'
                            : 'text-white'
                        }`}
                        size='simple'
                        onClick={() => setSelectedDay(+day)}
                      >
                        {day}
                      </Button>
                    ))}
                  </div>
                </PopoverContent>
              </Popover>
            </div>
          )}

          <div className='my-6'>
            <UserBankDetails day={addNumberSuffix(+selectedDay)} />
          </div>

          <Button
            disabled={selectedPlans?.length > 0 ? false : true}
            className={`w-full ${
              selectedPlans?.length === 0 ? 'bg-primary-300' : 'bg-primary-500'
            }`}
            onClick={submitHandler}
          >
            {flowStep === 0 ? 'Proceed' : 'Proceed to one-time payment'}
          </Button>

          <SIPConfirmation
            open={open}
            setOpen={setOpen}
            plans={selectedPlans}
            sipDate={sipDate}
            flowStep={flowStep}
            setFlowStep={setFlowStep}
            sipPlans={sipPlans}
            oneTimePlans={oneTimePlans}
            onRequestComplete={() => {
              refreshInvestPlans(), updatePlanStatuses()
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default PaymentConfirmation
