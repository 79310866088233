/* eslint-disable */

import React, { useState } from 'react'
import { Button } from '../../Components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogClose,
} from '../../Components/ui/dialog'
import tick from '../../assets/SVGS/success.gif'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import useMediaQuery from '../../hooks/useMediaQuery'
import env from '../../env/env'
import { useModal } from '../../hooks/use-modal-store'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from '../ui/skeleton'

const SuccessPopup = ({ isModalOpen, onClose, baskets }) => {
  const [expandedBaskets, setExpandedBaskets] = useState({})
  const isDesktop = useMediaQuery('(min-width: 640px)')
  const navigate = useNavigate()

  const toggleBasketExpansion = (basketKey) => {
    setExpandedBaskets((prev) => ({
      ...prev,
      [basketKey]: !prev[basketKey],
    }))
  }

  const basketDetails = baskets

  return isDesktop ? (
    <Dialog open={isModalOpen} onOpenChange={() => onClose()}>
      <DialogContent className='p-0 max-w-[450px] max-h-[90%] w-full gap-0'>
        <DialogHeader
          style={{
            background: 'radial-gradient(73.76% 91.42% at 23.07% 21.94%, #7EDD65 0%, #368421 100%)',
            borderRadius: '24px 24px 0px 0px',
          }}
        >
          <div className='p-6'>
            <DialogTitle className={'flex items-center justify-center'}>
              <img src={tick} alt='tick' className='h-[40%] w-[196px]' />
            </DialogTitle>
            <DialogDescription className='font-DMSans text-xl text-white font-semibold text-center'>
              Basket Order Successful
            </DialogDescription>
          </div>
        </DialogHeader>

        {/* {basketDetails ? (
          <div className='min-h-[300px] max-h-[300px] mb-14 overflow-y-scroll hide-scrollbar'>
            {Object.entries(basketDetails).map(([basketKey, basketItems]) => {
              return (
                <div
                  key={basketKey}
                  className='m-6 p-4 border border-[#D3DBEC] rounded-[16px] flex flex-col gap-3'
                >
                  <div
                    className={`flex gap-2 ${
                      expandedBaskets[basketKey] ? 'border-b-[1px] pb-5' : ''
                    } `}
                  >
                    <img src={`${env.SVGS}/basket.svg`} className='h-12 w-12 rounded-[8px] mt-1' />

                    <div>
                      {basketItems[0]?.customerBasketName && (
                        <p className='font-DMSans font-semibold text-base text-black-500'>
                          {basketItems[0]?.customerBasketName}
                        </p>
                      )}
                      {basketItems[0]?.status && (
                        <p className='font-DMSans font-normal text-sm text-black-400'>
                          {basketItems[0]?.status}
                        </p>
                      )}
                    </div>
                  </div>

                  {expandedBaskets[basketKey] &&
                    basketItems.map((fund, index) => {
                      return (
                        <div key={index} className='max-w-[238px]'>
                          <div
                            className={`flex justify-center gap-2 ${
                              index === basketDetails.length ? 'border-b-[1px]' : ''
                            } pb-4 ml-4`}
                          >
                            <img
                              src={`${env.SVGS}/basket.svg`}
                              className='h-12 w-12 rounded-[8px] mt-1'
                            />
                            <div>
                              <p className='font-DMSans font-semibold text-base text-black-500'>
                                {fund?.schemeName ? fund?.schemeName : fund?.legalName}
                              </p>
                              <p className='font-DMSans font-normal text-sm text-black-400'>
                                {fund?.status}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    })}

                  <p
                    className='text-primary-700 font-medium font-DMSans text-sm cursor-pointer'
                    onClick={() => toggleBasketExpansion(basketKey)}
                  >
                    {expandedBaskets[basketKey] ? 'Show less' : 'Show more'}
                  </p>
                </div>
              )
            })}
          </div>
        ) : (
          <div className='min-h-[300px] max-h-[300px] mt-6 p-4'>
            <div className='border p-2 rounded-lg'>
              <div className='flex items-center space-x-4'>
                <Skeleton className='h-12 w-12 rounded-lg' />
                <div className='space-y-2'>
                  <Skeleton className='h-4 w-[200px]' />
                  <Skeleton className='h-4 w-[100px]' />
                </div>
              </div>
              <Skeleton className='h-4 mt-2 w-[100px]' />
            </div>
          </div>
        )} */}

        <div className='space-y-4 px-4 min-h-[250px] pt-16 border'>
          <h1 className='text-center'>{baskets?.title}</h1>
          <p className='text-center text-sm text-black-400'>{baskets?.message}</p>
        </div>

        <DialogFooter className='fixed bottom-1 left-0 right-0 p-6 pt-0 flex justify-between'>
          <Button
            variant='outline'
            className='text-[#41558D] font-DMSans text-sm font-medium flex-1'
            onClick={() => {
              onClose(), navigate(baskets?.redirectTo)
            }}
          >
            Close
          </Button>
          <Button
            variant=''
            className='font-DMSans text-sm font-medium flex-1'
            onClick={() => {
              onClose(), navigate('/dashboard/mutual-funds/orders')
            }}
          >
            Explore More
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  ) : (
    <Dialog open={isModalOpen} onOpenChange={() => onClose()}>
      <DialogContent className='p-0 w-full max-w-2xl h-full gap-0 content-between'>
        <DialogHeader
          style={{
            background: 'radial-gradient(73.76% 91.42% at 23.07% 21.94%, #7EDD65 0%, #368421 100%)',
            // borderRadius: '24px 24px 0px 0px',
          }}
        >
          <div className='p-6'>
            <DialogTitle className={'flex items-center justify-center h-[248px]'}>
              <img src={tick} alt='tick' className='h-[196px] w-[196px]' />
            </DialogTitle>
            <DialogDescription className='font-DMSans text-xl text-white font-semibold mt-[66px]'>
              Basket Order Successful
            </DialogDescription>
          </div>
        </DialogHeader>

        {/* {baskets ? (
          <div className='max-h-[486px] overflow-y-scroll'>
            {Object.entries(basketDetails).map(([basketKey, basketItems]) => {
              return (
                <div
                  key={basketKey}
                  className={`m-6 p-4 border border-[#D3DBEC] rounded-[16px] flex flex-col gap-3`}
                >
                  <div
                    className={`flex gap-2 ${
                      expandedBaskets[basketKey] ? 'border-b-[1px] pb-5' : ''
                    } `}
                  >
                    <img src={`${env.SVGS}/basket.svg`} className='h-12 w-12 rounded-[8px] mt-1' />

                    <div>
                      {basketItems[0]?.customerBasketName && (
                        <p className='font-DMSans font-semibold text-base text-black-500'>
                          {basketItems[0]?.customerBasketName}
                        </p>
                      )}
                      {basketItems[0]?.status && (
                        <p className='font-DMSans font-normal text-sm text-black-400'>
                          {basketItems[0]?.status}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className='flex flex-col'>
                    {expandedBaskets[basketKey] &&
                      basketItems.map((fund, index) => {
                        return (
                          <div key={fund.id} className='max-w-[238px] flex justify-center'>
                            <div
                              className={`flex gap-2 ${
                                index === basketDetails.length ? 'border-b-[1px]' : ''
                              } py-4 ml-4`}
                            >
                              <img
                                src={`${env.SVGS}/basket.svg`}
                                className='h-12 w-12 rounded-[8px] mt-1'
                              />
                              <div>
                                <p className='font-DMSans font-semibold text-base text-black-500'>
                                  {fund?.schemeName ? fund?.schemeName : fund?.legalName}
                                </p>
                                <p className='font-DMSans font-normal text-sm text-black-400'>
                                  {fund?.status}
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                  </div>

                  <p
                    className='text-primary-700 font-medium font-DMSans text-sm cursor-pointer'
                    onClick={() => toggleBasketExpansion(basketKey)}
                  >
                    {expandedBaskets[basketKey] ? 'Show less' : 'Show more'}
                  </p>
                </div>
              )
            })}
          </div>
        ) : (
          <div className='mt-6 p-4'>
            <div className='border p-2 rounded-lg'>
              <div className='flex items-center space-x-4'>
                <Skeleton className='h-12 w-12 rounded-lg' />
                <div className='space-y-2'>
                  <Skeleton className='h-4 w-[200px]' />
                  <Skeleton className='h-4 w-[100px]' />
                </div>
              </div>
              <Skeleton className='h-4 mt-2 w-[100px]' />
            </div>
          </div>
        )} */}

        <div className='space-y-4 px-4'>
          <h1 className='text-center'>{baskets?.title}</h1>
          <p className='text-center text-sm text-black-400'>{baskets?.message}</p>
        </div>

        <DialogFooter className='p-6 pt-0 flex flex-row gap-2 justify-between items-end'>
          <Button
            variant='outline'
            className='text-[#41558D] font-DMSans text-sm font-medium flex-1'
            onClick={() => {
              onClose(), navigate(baskets?.redirectTo)
            }}
          >
            Close
          </Button>
          <Button
            variant=''
            className='font-DMSans text-sm font-medium flex-1'
            onClick={() => {
              onClose(), navigate('/dashboard/mutual-funds/orders')
            }}
          >
            Order History
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

const FailedPopup = ({ isModalOpen, onClose, baskets }) => {
  const [expandedBaskets, setExpandedBaskets] = useState({})

  const toggleBasketExpansion = (basketKey) => {
    setExpandedBaskets((prev) => ({
      ...prev,
      [basketKey]: !prev[basketKey],
    }))
  }

  const isDesktop = useMediaQuery('(min-width: 640px)')
  const basketDetails = baskets

  return isDesktop ? (
    <Dialog open={isModalOpen} onOpenChange={() => onClose()}>
      <DialogContent className='sm:max-w-md p-0 w-[504px] gap-0'>
        <DialogHeader
          style={{
            borderRadius: '24px 24px 0px 0px',
          }}
        >
          <div className='p-6'>
            <DialogTitle className='flex items-center justify-center'>
              <div className='h-[62px] w-[62px] border-[12px] border-[#FFEDED] flex justify-center items-center rounded-full'>
                <ErrorRoundedIcon
                  sx={{
                    height: '54px',
                    width: '54px',
                    color: '#FF6666',
                    boxSizing: 'border-box',
                  }}
                />
              </div>
            </DialogTitle>
            <DialogDescription className='font-DMSans text-xl font-semibold mt-6'>
              Basket Order Failed
            </DialogDescription>
          </div>
        </DialogHeader>

        {baskets && (
          <div className='max-h-[426px] overflow-y-scroll'>
            {Object.entries(basketDetails).map(([basketKey, basketItems]) => {
              return (
                <div
                  key={basketKey}
                  className='m-6 mt-0 p-4 border border-[#D3DBEC] rounded-[16px] flex flex-col gap-3'
                >
                  <div
                    className={`flex gap-2 ${
                      expandedBaskets[basketKey] ? 'border-b-[1px] pb-5' : ''
                    } `}
                  >
                    <img src={`${env.SVGS}/basket.svg`} className='h-12 w-12 rounded-[8px] mt-1' />

                    <div>
                      <p className='font-DMSans font-semibold text-base text-error-600'>
                        {basketItems[0].customerBasketName}
                      </p>
                      <p className='font-DMSans font-normal text-sm text-error-600'>
                        {basketItems[0].status}
                      </p>
                    </div>
                  </div>

                  {expandedBaskets[basketKey] &&
                    basketItems.map((fund, index) => {
                      return (
                        <div key={fund.id} className='max-w-[238px]'>
                          <div
                            className={`flex justify-center gap-2 ${
                              index === basketItems.length ? 'border-b-[1px]' : ''
                            } pb-4 ml-4`}
                          >
                            <img
                              src={`${env.SVGS}/basket.svg`}
                              className='h-12 w-12 rounded-[8px] mt-1'
                            />
                            <div>
                              <p className='font-DMSans font-semibold text-base text-error-600'>
                                {fund?.schemeName ? fund?.schemeName : fund?.legalName}
                              </p>
                              <p className='font-DMSans font-normal text-sm text-error-600'>
                                {fund?.status}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    })}

                  <p
                    className='text-primary-700 font-medium font-DMSans text-sm cursor-pointer'
                    onClick={() => toggleBasketExpansion(basketKey)}
                  >
                    {expandedBaskets[basketKey] ? 'Show less' : 'Show more'}
                  </p>
                </div>
              )
            })}
          </div>
        )}

        <DialogFooter className='p-6 pt-0 flex justify-between'>
          <Button
            variant='outline'
            className='text-[#41558D] font-DMSans text-sm font-medium flex-1'
          >
            Retry
          </Button>
          <Button variant='' className='font-DMSans text-sm font-medium flex-1'>
            Get Help
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  ) : (
    <Dialog open={isModalOpen} onOpenChange={() => onClose()}>
      <DialogContent className='p-0 w-full max-w-2xl h-full gap-0 content-between'>
        <DialogHeader
          className={`justify-center`}
          style={{
            borderRadius: '24px 24px 0px 0px',
          }}
        >
          <div className='p-6'>
            <DialogTitle className='flex items-center justify-center '>
              <div className='h-[62px] w-[62px] border-[12px] border-[#FFEDED] flex justify-center items-center rounded-full'>
                <ErrorRoundedIcon
                  sx={{
                    height: '54px',
                    width: '54px',
                    color: '#FF6666',
                    boxSizing: 'border-box',
                  }}
                />
              </div>
            </DialogTitle>
            <DialogDescription className='font-DMSans text-xl font-semibold mt-6'>
              Basket Order Failed
            </DialogDescription>
          </div>
        </DialogHeader>

        <div className='max-h-[800px] overflow-y-scroll'>
          {Object.entries(basketDetails).map(([basketKey, basketItems]) => {
            return (
              <div
                key={basketKey}
                className='m-6 mt-0 p-4 border border-[#D3DBEC] rounded-[16px] flex flex-col gap-3'
              >
                <div
                  className={`flex gap-2 ${
                    expandedBaskets[basketKey] ? 'border-b-[1px] pb-5' : ''
                  } `}
                >
                  <img src={`${env.SVGS}/basket.svg`} className='h-12 w-12 rounded-[8px] mt-1' />

                  <div>
                    <p className='font-DMSans font-semibold text-base text-error-600'>
                      {basketItems[0].customerBasketName}
                    </p>
                    <p className='font-DMSans font-normal text-sm text-error-600'>
                      {basketItems[0].status}
                    </p>
                  </div>
                </div>

                {expandedBaskets[basketKey] && (
                  <div className='max-w-[238px]'>
                    {basketItems.map((fund, index) => (
                      <div
                        key={fund.id}
                        className={`flex justify-center gap-2 ${
                          index === basketItems.length ? 'border-b-[1px]' : ''
                        } py-4 ml-4`}
                      >
                        <img
                          src={`${env.SVGS}/basket.svg`}
                          className='h-12 w-12 rounded-[8px] mt-1'
                        />
                        <div>
                          <p className='font-DMSans font-semibold text-base text-error-600'>
                            {fund?.schemeName ? fund?.schemeName : fund?.legalName}
                          </p>
                          <p className='font-DMSans font-normal text-sm text-error-600'>
                            {fund?.status}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <p
                  className='text-primary-700 font-medium font-DMSans text-sm cursor-pointer'
                  onClick={() => toggleBasketExpansion(basketKey)}
                >
                  {expandedBaskets[basketKey] ? 'Show less' : 'Show more'}
                </p>
              </div>
            )
          })}
        </div>

        <DialogFooter className='p-6 pt-0 flex flex-row gap-2 items-end justify-between'>
          <Button
            variant='outline'
            className='text-[#41558D] font-DMSans text-sm font-medium flex-1'
          >
            Retry
          </Button>
          <Button variant='' className='font-DMSans text-sm font-medium flex-1'>
            Get Help
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

const PartialPopup = ({ isModalOpen, onClose, baskets }) => {
  const [expandedBaskets, setExpandedBaskets] = useState({})
  const isDesktop = useMediaQuery('(min-width: 640px)')

  const toggleBasketExpansion = (basketKey) => {
    setExpandedBaskets((prev) => ({
      ...prev,
      [basketKey]: !prev[basketKey],
    }))
  }

  const basketDetails = baskets

  const statusCount =
    baskets &&
    Object.values(basketDetails)
      .flat()
      .reduce(
        (acc, item) => {
          if (item.status === 'SUCCESS') {
            acc.success += 1
          } else if (item.status === 'FAILED') {
            acc.failed += 1
          }
          return acc
        },
        { success: 0, failed: 0 },
      )

  return isDesktop ? (
    <Dialog open={isModalOpen} onOpenChange={() => onClose()}>
      <DialogContent className='sm:max-w-md p-0 w-[504px] gap-0'>
        <DialogHeader
          style={{
            borderRadius: '24px 24px 0px 0px',
          }}
        >
          <div className='p-6'>
            <DialogTitle className='flex items-center justify-center'>
              <div className='h-[62px] w-[62px] border-[12px] border-[#FFEDED] flex justify-center items-center rounded-full'>
                <ErrorRoundedIcon
                  sx={{
                    height: '54px',
                    width: '54px',
                    color: '#FF6666',
                    boxSizing: 'border-box',
                  }}
                />
              </div>
            </DialogTitle>
            <DialogDescription className='font-DMSans text-xl font-semibold mt-6'>
              Partial Order Successful ({statusCount?.failed}/
              {statusCount?.failed + statusCount?.success})
            </DialogDescription>
          </div>
        </DialogHeader>

        {baskets && (
          <div className='max-h-[426px] overflow-y-scroll'>
            {Object.entries(basketDetails).map(([basketKey, basketItems]) => {
              const failedCount = basketItems.filter((item) => item.status === 'FAILED').length

              return (
                <div
                  key={basketKey}
                  className='m-6 mt-0 p-4 border border-[#D3DBEC] rounded-[16px] flex flex-col gap-3'
                >
                  <div
                    className={`flex gap-2 ${
                      expandedBaskets[basketKey] ? 'border-b-[1px] pb-5' : ''
                    } `}
                  >
                    <img src={`${env.SVGS}/basket.svg`} className='h-12 w-12 rounded-[8px] mt-1' />

                    <div>
                      <p className='font-DMSans font-semibold text-base text-error-600'>
                        {basketItems[0].customerBasketName}
                      </p>
                      <p className='font-DMSans font-normal text-sm text-error-600'>
                        ({failedCount}/{basketItems.length}) Unsuccessful
                      </p>
                    </div>
                  </div>

                  {expandedBaskets[basketKey] &&
                    basketItems.map((fund, index) => {
                      return (
                        <div key={index} className='flex ml-4'>
                          <div>
                            <div
                              className={`flex gap-2 ${
                                index === basketItems.length ? 'border-b-[1px]' : ''
                              } pb-4`}
                            >
                              <img
                                src={`${env.SVGS}/basket.svg`}
                                className='h-12 w-12 rounded-[8px] mt-1'
                              />
                              <div>
                                <p
                                  className={`font-DMSans font-semibold text-base ${
                                    fund.status === 'SUCCESS' ? 'text-black-500' : 'text-error-600'
                                  }`}
                                >
                                  {fund?.schemeName ? fund?.schemeName : fund?.legalName}
                                </p>
                                <p
                                  className={`font-DMSans font-normal text-sm ${
                                    fund.status === 'SUCCESS' ? 'text-black-400' : 'text-error-600'
                                  } `}
                                >
                                  {fund?.status}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}

                  <p
                    className='text-primary-700 font-medium font-DMSans text-sm cursor-pointer'
                    onClick={() => toggleBasketExpansion(basketKey)}
                  >
                    {expandedBaskets[basketKey] ? 'Show less' : 'Show more'}
                  </p>
                </div>
              )
            })}
          </div>
        )}

        <DialogFooter className='p-6 pt-0 flex justify-between'>
          <Button
            variant='outline'
            className='text-[#41558D] font-DMSans text-sm font-medium flex-1'
            onClick={onClose}
          >
            Close
          </Button>
          <Button variant='' className='font-DMSans text-sm font-medium flex-1'>
            Get Help
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  ) : (
    <Dialog open={isModalOpen} onOpenChange={() => onClose()}>
      <DialogContent className='p-0 w-full max-w-2xl h-full gap-0 content-between'>
        <DialogHeader
          className={`justify-center`}
          style={{
            borderRadius: '24px 24px 0px 0px',
          }}
        >
          <div className='p-6'>
            <DialogTitle className='flex items-center justify-center'>
              <div className='h-[62px] w-[62px] border-[12px] border-[#FFEDED] flex justify-center items-center rounded-full'>
                <ErrorRoundedIcon
                  sx={{
                    height: '54px',
                    width: '54px',
                    color: '#FF6666',
                    boxSizing: 'border-box',
                  }}
                />
              </div>
            </DialogTitle>
            <DialogDescription className='font-DMSans text-xl font-semibold mt-6'>
              Partial Order Successful ({statusCount?.failed}/
              {statusCount?.failed + statusCount?.success})
            </DialogDescription>
          </div>
        </DialogHeader>

        {baskets && (
          <div className='max-h-[800px] overflow-y-scroll'>
            {Object.entries(basketDetails)?.map(([basketKey, basketItems]) => {
              const failedCount = basketItems?.filter((item) => item.status === 'FAILED').length
              return (
                <div
                  key={basketKey}
                  className='m-6 mt-0 p-4 border border-[#D3DBEC] rounded-[16px] flex flex-col gap-3'
                >
                  <div
                    className={`flex gap-2 ${
                      expandedBaskets[basketKey] ? 'border-b-[1px] pb-5' : ''
                    } `}
                  >
                    <img src={`${env.SVGS}/basket.svg`} className='h-12 w-12 rounded-[8px] mt-1' />

                    <div>
                      <p className='font-DMSans font-semibold text-base text-error-600'>
                        {basketItems[0].customerBasketName}
                      </p>
                      <p className='font-DMSans font-normal text-sm text-error-600'>
                        ({failedCount}/{basketItems.length}) Unsuccessful
                      </p>
                    </div>
                  </div>

                  {expandedBaskets[basketKey] &&
                    basketItems.map((fund, index) => {
                      return (
                        <div key={index} className='flex'>
                          <div className=''>
                            <div
                              className={`flex gap-2 ${
                                index === basketItems.length ? 'border-b-[1px]' : ''
                              } pb-4 ml-4`}
                            >
                              <img
                                src={`${env.SVGS}/basket.svg`}
                                className='h-12 w-12 rounded-[8px] mt-1'
                              />
                              <div>
                                <p
                                  className={`font-DMSans font-semibold text-base ${
                                    fund.status === 'SUCCESS' ? 'text-black-500' : 'text-error-600'
                                  }  `}
                                >
                                  {fund?.schemeName ? fund?.schemeName : fund?.legalName}
                                </p>
                                <p
                                  className={`font-DMSans font-normal text-sm ${
                                    fund.status === 'SUCCESS' ? 'text-black-400' : 'text-error-600'
                                  } `}
                                >
                                  {fund?.status}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}

                  <p
                    className='text-primary-700 font-medium font-DMSans text-sm cursor-pointer'
                    onClick={() => toggleBasketExpansion(basketKey)}
                  >
                    {expandedBaskets[basketKey] ? 'Show less' : 'Show more'}
                  </p>
                </div>
              )
            })}
          </div>
        )}

        <DialogFooter className='p-6 pt-0 flex flex-row items-end justify-between gap-2'>
          <Button
            variant='outline'
            className='text-[#41558D] font-DMSans text-sm font-medium flex-1'
            onClick={onClose}
          >
            Close
          </Button>
          <Button variant='' className='font-DMSans text-sm font-medium flex-1'>
            Get Help
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

const SipSuccess = ({ isModalOpen, onClose, data }) => {
  const navigate = useNavigate()

  return (
    <Dialog open={isModalOpen} onOpenChange={() => onClose()}>
      <DialogContent
        className='h-screen md:h-auto z-[10001] flex flex-col justify-center items-center md:p-6 md:w-[362px]'
        style={{
          background:
            data?.severity === 'sipsuccess'
              ? 'radial-gradient(73.76% 91.42% at 23.07% 21.94%, #7EDD65 0%, #368421 100%)'
              : data?.severity === 'info'
              ? 'radial-gradient(73.76% 91.42% at 23.07% 21.94%, #DD9365 0%, #843C21 100%)'
              : 'radial-gradient(73.76% 91.42% at 23.07% 21.94%, #6597DD 0%, #213784 100%)',
        }}
      >
        <DialogHeader>
          <DialogTitle className='flex justify-center items-center'>
            <img src={tick} alt={data?.severity} />
          </DialogTitle>
          <DialogTitle className='font-DMSans text-center font-semibold text-xl text-white text-wrap leading-7 tracking-[-0.04em]'>
            {data && data?.title}
          </DialogTitle>
          <DialogDescription className='font-DMSans font-normal text-sm text-white text-wrap text-center leading-5'>
            {data && data?.message}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className='justify-center flex-row mt-6'>
          <div className='w-full flex justify-center gap-x-6'>
            <DialogClose
              onClick={() => {
                onClose()
                if (data?.onClose) {
                  navigate(data.onClose, {
                    state: {
                      from: data?.from ? data?.from : '',
                    },
                  })
                }
              }}
              style={{ background: '#FFFFFF3D' }}
              className='h-10 w-[149px] rounded-[8px]'
            >
              <span className='font-DMSans font-medium text-sm text-white'>Close</span>
            </DialogClose>
            <button
              className=' h-10 w-[149px] rounded-[8px] bg-white'
              onClick={() => {
                onClose(),
                  navigate(data.redirectTo, {
                    state: {
                      from: data?.from ? data?.from : '',
                    },
                  })
              }}
            >
              <span
                className='font-DMSans font-medium text-sm'
                style={{
                  color:
                    data.severity === 'sipsuccess'
                      ? '#3C8B27'
                      : data.severity === 'info'
                      ? '#853C22'
                      : '#223785',
                }}
              >
                Continue
              </span>
            </button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

const InfoPopup = ({ isModalOpen, onClose, baskets }) => {
  const navigate = useNavigate()

  return (
    <Dialog open={isModalOpen} onOpenChange={() => onClose()}>
      <DialogContent
        className='h-screen md:h-auto z-[10001] flex flex-col justify-center items-center md:p-6 md:w-[362px]'
        style={{
          background:
            baskets?.severity === 'sipsuccess'
              ? 'radial-gradient(73.76% 91.42% at 23.07% 21.94%, #7EDD65 0%, #368421 100%)'
              : baskets?.severity === 'info'
              ? 'radial-gradient(73.76% 91.42% at 23.07% 21.94%, #DD9365 0%, #843C21 100%)'
              : 'radial-gradient(73.76% 91.42% at 23.07% 21.94%, #6597DD 0%, #213784 100%)',
        }}
      >
        <DialogHeader>
          <DialogTitle className='flex justify-center items-center'>
            <img src={tick} alt={baskets?.severity} />
          </DialogTitle>
          <DialogTitle className='font-DMSans text-center font-semibold text-xl text-white text-wrap leading-7 tracking-[-0.04em]'>
            {baskets && baskets?.title}
          </DialogTitle>
          <DialogDescription className='font-DMSans font-normal text-sm text-white text-wrap text-center leading-5'>
            {baskets && baskets?.message}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className='justify-center flex-row mt-6'>
          <div className='w-full flex justify-center gap-x-6'>
            <DialogClose
              onClick={() => {
                onClose(), navigate(baskets.redirectTo)
              }}
              style={{ background: '#FFFFFF3D' }}
              className='h-10 w-[149px] rounded-[8px]'
            >
              <span className='font-DMSans font-medium text-sm text-white'>Close</span>
            </DialogClose>
            <button
              className=' h-10 w-[149px] rounded-[8px] bg-white'
              onClick={() => {
                onClose(), navigate(baskets.redirectTo)
              }}
            >
              <span
                className='font-DMSans font-medium text-sm'
                style={{
                  color:
                    baskets?.severity === 'sipsuccess'
                      ? '#3C8B27'
                      : baskets?.severity === 'info'
                      ? '#853C22'
                      : '#223785',
                }}
              >
                Continue
              </span>
            </button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

const ActionPlanPopup = () => {
  const { isOpen, onClose, type, data } = useModal()
  const isModalOpen = isOpen && type === 'openActionPlanPopup'

  return (
    <>
      {data?.severity === 'sipsuccess' && (
        <SipSuccess isModalOpen={isModalOpen} onClose={onClose} data={data} />
      )}

      {data?.severity === 'success' && (
        <SuccessPopup isModalOpen={isModalOpen} onClose={onClose} baskets={data} />
      )}

      {data?.severity === 'info' && (
        <InfoPopup isModalOpen={isModalOpen} onClose={onClose} baskets={data} />
      )}

      {data?.severity === 'error' && (
        <FailedPopup isModalOpen={isModalOpen} onClose={onClose} baskets={data?.baskets} />
      )}

      {data?.severity !== 'sipsuccess' &&
        data?.severity !== 'success' &&
        data?.severity !== 'error' &&
        data?.severity !== 'info' && (
          <PartialPopup isModalOpen={isModalOpen} onClose={onClose} baskets={data?.baskets} />
        )}
    </>
  )
}

export default ActionPlanPopup
